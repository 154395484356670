import { Grid } from "@mui/material";
import AppLayout from "../layouts/app";
import PurchaseOrderM from "../components/POManagement";
import SearchPONumber from "../components/POManagement/SearchPONumber";

const POManagement = () => {
  return (
    <AppLayout
      window={undefined}
      heading="PO Management"
      searchField={<SearchPONumber />}
    >
      <Grid container spacing={2} mt={0}>
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          <PurchaseOrderM />
        </Grid>
      </Grid>
    </AppLayout>
  );
};

export default POManagement;
