import { Grid, Button } from "@mui/material";
import { Fragment } from "react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import DateRangeSelector from "../../Finance/CustomCalender";
import TodayIcon from "@mui/icons-material/Today";
import Reports from "../../SVG/Reports";
import { subMonths, format, endOfMonth, startOfMonth } from "date-fns";
import { KALA_70 } from "../../../styles/colors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React, { useEffect } from "react";

function OrderStatusForm({ orderSelectionBtn, setOrderSelectionBtn }: any) {
  const handleOrders = (value: string) => {
    setOrderSelectionBtn(value);
  };

  return (
    <Fragment>
      <Grid
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "12px",
        }}
      >
        <Grid item>
          <Button
            color="primary"
            variant={
              orderSelectionBtn == "All Orders" ? "contained" : "outlined"
            }
            onClick={() => handleOrders("All Orders")}
            startIcon={orderSelectionBtn == "All Orders" && <CheckCircleIcon />}
            sx={{
              color: orderSelectionBtn == "All Orders" ? "white" : KALA_70,
              // minWidth: "400px",
              fontWeight: 500,
              borderColor: "#CACCCF",
              "&:hover": {
                borderColor: "#CACCCF",
              },
              height: "36px",
              padding: "8px 12px",
              borderRadius: "24px",
            }}
          >
            All Orders
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant={
              orderSelectionBtn == "Unsettled Orders" ? "contained" : "outlined"
            }
            onClick={() => handleOrders("Unsettled Orders")}
            startIcon={
              orderSelectionBtn == "Unsettled Orders" && <CheckCircleIcon />
            }
            sx={{
              color:
                orderSelectionBtn == "Unsettled Orders" ? "white" : KALA_70,
              // minWidth: "400px",
              fontWeight: 500,
              borderColor: "#CACCCF",
              "&:hover": {
                borderColor: "#CACCCF",
              },
              height: "36px",
              padding: "8px 12px",
              borderRadius: "24px",
            }}
          >
            Unsettled Orders
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant={
              orderSelectionBtn == "Settled Orders" ? "contained" : "outlined"
            }
            onClick={() => handleOrders("Settled Orders")}
            startIcon={
              orderSelectionBtn == "Settled Orders" && <CheckCircleIcon />
            }
            sx={{
              color: orderSelectionBtn == "Settled Orders" ? "white" : KALA_70,
              // minWidth: "400px",
              fontWeight: 500,
              borderColor: "#CACCCF",
              "&:hover": {
                borderColor: "#CACCCF",
              },
              height: "36px",
              padding: "8px 12px",
              borderRadius: "24px",
            }}
          >
            Settled Orders
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default OrderStatusForm;
