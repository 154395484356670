import React, { useEffect, useState } from "react";
import { Unstable_Popup as Popup, PopupProps } from "@mui/base/Unstable_Popup";
import { Box, styled, Theme } from "@mui/system";
import LogoMark from "../../components/SVG/LogoMark";
import Feedback from "./Feedback";
import Issue from "./Issue";
import WriteToUs from "./WriteToUs";
import { HelpState, closePopup } from "./helpSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import HelpIcon from "@mui/icons-material/Help";
import { Grid, Typography, useMediaQuery } from "@mui/material";

export default function Help() {
  return (
    <Box
      sx={{
        position: "absolute",
        right: "20px",
        bottom: "80px",
      }}
    >
      <PopupWithTrigger
        id="popup-without-portal-fixed"
        buttonLabel="No portal, 'fixed' strategy"
        disablePortal
      />
    </Box>
  );
}

function PopupWithTrigger(props: PopupProps & { buttonLabel: string }) {
  const { id, buttonLabel, ...other } = props;

  const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };
  const { state }: HelpState = useSelector((state: RootState) => state.help);

  const open = Boolean(anchor);

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const dispatch: AppDispatch = useDispatch();

  const renderPopup = () => {
    switch (state) {
      case 30:
        return <Feedback handleClose={handleClick} />;
      case 20:
        return <Issue handleClose={handleClick} />;
      default:
        return <WriteToUs handleClose={handleClick} />;
    }
  };

  const handlePopUp = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(closePopup());
    handleClick(event);
  };

  useEffect(() => {
    // On escape key press close the popup

    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        dispatch(closePopup());
        setAnchor(null);
      }
    };
    if (anchor) {
      window.addEventListener("keydown", handleEsc);
    } else {
      window.removeEventListener("keydown", handleEsc);
    }
    return () => window.removeEventListener("keydown", handleEsc);
  }, [anchor]);

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  if (!isOnline || isSmall) {
    return <></>;
  }

  return (
    <div>
      <Button aria-describedby={id} type="button" onClick={handlePopUp}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent={"center"}
        >
          <Grid item>
            <HelpIcon color="disabled" />
          </Grid>
          <Grid item style={{ paddingLeft: "2px" }}>
            <Typography color="info.light" variant="subtitle1">
              Help
            </Typography>
          </Grid>
        </Grid>
      </Button>

      <StyledPopup
        placement="top-end"
        id={id}
        open={open}
        anchor={anchor}
        {...other}
      >
        <PopupBody sx={{ minWidth: "440px" }}>{renderPopup()}</PopupBody>
      </StyledPopup>
    </div>
  );
}

const StyledPopup = styled(Popup)`
  z-index: 1;
`;

const grey = {
  50: "#f6f8fa",
  200: "#d0d7de",
  500: "#6e7781",
  700: "#424a53",
  900: "#24292f",
};

const PopupBody = styled("div")(
  ({ theme }: { theme: Theme }) => `
  background: #FFFFFF;
  display: flex;
  align-items: center;
  padding: 8px, 16px, 8px, 8px;
  margin: 8px;
  border-radius: 40px;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0px 1px 8px 0px #00000033, 0px 3px 3px 0px #0000001f,
    0px 3px 4px 0px #00000024;
`
);

const Button = styled("button")`
  border-radius: 40px;
  padding: 8px;
  cursor: pointer;
  transition: all 150ms ease;
  box-shadow: 0px 1px 8px 0px #00000033, 0px 3px 3px 0px #0000001f,
    0px 3px 4px 0px #00000024;
  background-color: #ffffff;
  
  }
`;
