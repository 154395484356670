import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../api";

export const addPO = createAsyncThunk("po/addPO", async (payload: any, { rejectWithValue }) => {
  try {
    const response = await API.post("/po", { ...payload });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data.error);
  }
});

export const duplicatePO = createAsyncThunk("po/duplicate", async (payload: any, {rejectWithValue}) => {
  try {
    const response = await API.get(`/po/duplicate?poNumber=${payload.poNumber}`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data.error);
  }
})
