import { createSlice, original } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { defaultMessage, errorMessage } from "../../utils";
import { getGenerateLedgerReport } from "./ReportsApi";
import { MIRCHI_70 } from "../../styles/colors";

export interface LedgerReportState {
  loading: boolean;
  list: any;
  error: string | null;
}

export const initialState: LedgerReportState = {
  loading: false,
  error: null,
  list: [],
};

export const ledgerReportSlice = createSlice({
  name: "ledgerReport",
  initialState,
  reducers: {},
  extraReducers: {
    [getGenerateLedgerReport.pending.type]: (state, action) => {
      state.loading = true;
    },
    [getGenerateLedgerReport.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.list = action.payload || [];
    },
    [getGenerateLedgerReport.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = action.payload || "Something went wrong";
      defaultMessage("No invoices found for selected date range. Recheck and try again.", MIRCHI_70);
    },
  },
});

export default ledgerReportSlice.reducer;
