import React, { useContext, useMemo } from "react";
import DOMPurify from "dompurify";

const sanitizedData = (data: string) => ({
  __html: DOMPurify.sanitize(data),
});

export const HTMLParser = ({ string = "notes" }) => {
  return (
    <div className="Notes" dangerouslySetInnerHTML={sanitizedData(string)} />
  );
};
