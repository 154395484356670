import { Divider, Grid } from "@mui/material";

const CustomDivider = ({ color = '#F1F4F7', thickness = '0.25rem', fullWidth = true }) => {
  return (
    <Grid item xs={fullWidth ? 12 : undefined}>
      <Divider
        sx={{
          borderBottomWidth: thickness,
          borderColor: color,
        }}
      />
    </Grid>
  );
};


export default CustomDivider;
