import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { postLoginCreatePassword, postLoginResetPassword } from "./loginApi";
import { AuthState } from "./LoginModuleSlice";
import { errorMessage } from "../../utils";

export interface CreatePasswordState {
  state: AuthState,
  loading: boolean,
  error: string,
  password: string,
  response?: any
}

const initialState: CreatePasswordState = {
  state: AuthState.SetPassword,
  loading: false,
  error: '',
  password: '',
  response: ''
};

export const CreatePasswordSlice = createSlice({
  name: "CreatePassword",
  initialState,
  reducers: {
    updateAuthState: (state) => {
      state.state = AuthState.SetPassword;
    }
  },
  extraReducers: {
    [postLoginCreatePassword.pending.type]: (state, action) => {
      state.loading = true;
      state.password = action.meta.arg.password;
      state.error = ''
    },
    [postLoginCreatePassword.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.error = '';
      // if (action.payload.response_code === 80) {
      //   state.state = AuthState.PasswordLogin;
      // }
      if (action.payload.response_code === 80) {
        state.state = AuthState.PasswordLogin;
      }
      state.response = action.payload;
    },
    [postLoginCreatePassword.rejected.type]: (state, action) => {
      state.loading = false;
      state.response = '';
      errorMessage(action.payload || 'Something went wrong');
      state.error = action.payload || 'Something went wrong';
      state.state = AuthState.SetPassword;
    },
    [postLoginResetPassword.pending.type]: (state, action) => {
      state.loading = true;
      state.password = action.meta.arg.password;
      state.error = ''
    },
    [postLoginResetPassword.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.error = '';
      state.response = action.payload;
      state.state = AuthState.SetPassword;
    },
    [postLoginResetPassword.rejected.type]: (state, action) => {
      state.loading = false;
      state.response = '';
      errorMessage(action.payload || 'Something went wrong');
      state.error = action.payload || 'Something went wrong';
      state.state = AuthState.SetPassword;
    },
  },
});
export const { updateAuthState } = CreatePasswordSlice.actions
export default CreatePasswordSlice.reducer;
