import { createSlice, original } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { duplicatePO } from "./poApi";

export interface DuplicatePONumberState {
  loading: boolean,
  response: any,
  error: string | null,
}

export const initialState: DuplicatePONumberState = {
  loading: true,
  error: null,
  response: [],
}

export const duplicatePONumberSlice = createSlice({
  name: 'duplicatePONumber',
  initialState,
  reducers: {},
  extraReducers: {
    [duplicatePO.pending.type]: (state, action) => {
      state.loading = true;
    },
    [duplicatePO.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.response = action.payload.data;
    },
    [duplicatePO.rejected.type]: (state, action) => {
      state.loading = false;
      state.response = [];
      state.error = action.payload || 'Something went wrong';
    },
  }
})

export default duplicatePONumberSlice.reducer