import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import BijakLogo from "../../components/SVG/BijakLogo";
import LogoMark from "../../components/SVG/LogoMark";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { ConfigState } from "./maintenanceSlice";
import { getConfig } from "./maintenanceApi";
import { useNavigate } from "react-router-dom";
import MaintenanceSVG from "../../components/SVG/Maintenance";

export default function Maintenance() {
  const dispatch: AppDispatch = useDispatch();

  const { config, loading, error }: ConfigState = useSelector(
    (state: RootState) => state.config
  );

  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getConfig(""));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (config?.maintenance === false) {
      navigate("/");
    }
  }, [config]);

  return (
    <Box
      justifyContent={"center"}
      alignItems={"center"}
      display={"flex"}
      sx={{ height: "95vh" }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} justifyContent={"center"} display={"flex"}>
          <BijakLogo />
          <LogoMark />
        </Grid>
        <Grid item xs={12} justifyContent={"center"} display={"flex"}>
          <MaintenanceSVG />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography variant="h4" color="info.dark">
            Planned maintenance in progress.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography variant="subtitle1" color="info.light">
            We're preparing to serve you better.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
