const Error = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="160"
        height="160"
        viewBox="0 0 160 160"
        fill="none"
      >
        <path
          d="M72.0996 136.393C78.1196 136.06 83.3996 132.48 89.0196 130.287C95.6396 127.7 102.993 127.02 109.973 128.347C115.206 129.34 120.446 131.62 123.673 135.853C122.32 137.273 120.373 137.947 118.486 138.48C113.86 139.78 109.08 140.527 104.28 140.7C98.9729 140.893 93.6729 140.4 88.3729 140.167C83.3396 139.953 78.0663 139.92 73.6529 137.493"
          fill="#ACDAEA"
        />
        <path
          d="M96.1664 138.847C95.9064 138.847 95.6531 138.72 95.5064 138.48C95.2797 138.113 95.3931 137.633 95.7597 137.407C96.1597 137.16 105.6 131.32 113.013 130.2C113.446 130.133 113.84 130.427 113.9 130.853C113.966 131.28 113.673 131.68 113.246 131.74C106.146 132.813 96.6731 138.673 96.5797 138.727C96.4464 138.807 96.3064 138.847 96.1664 138.847Z"
          fill="#73C5D9"
        />
        <path
          d="M42.5137 129.233C42.5137 129.233 54.9337 123.4 64.2937 130.447L61.5003 135.153L46.0403 135.813"
          fill="#ACDAEA"
        />
        <path
          d="M39.4404 131.293C39.4404 131.293 59.3871 126.493 71.1004 134.773C82.8138 143.053 93.9138 139.827 96.9738 137.553C100.034 135.28 113.48 128.953 123.28 130.207V143.58H39.9871L39.4404 131.293Z"
          fill="#D1E8F2"
        />
        <path
          d="M64.0063 131.693C63.9729 131.693 63.9329 131.687 63.8996 131.667C59.4929 129.647 51.1329 130.167 51.0529 130.173C50.8863 130.193 50.7863 130.073 50.7796 129.933C50.7729 129.793 50.8796 129.667 51.0196 129.66C51.3663 129.64 59.5929 129.12 64.1196 131.193C64.2529 131.253 64.3063 131.407 64.2463 131.54C64.1996 131.633 64.1063 131.693 64.0063 131.693Z"
          fill="#73C5D9"
        />
        <path
          d="M142.234 143.707H17.7669C17.4736 143.707 17.2402 143.473 17.2402 143.18C17.2402 142.887 17.4736 142.653 17.7669 142.653H142.234C142.527 142.653 142.76 142.887 142.76 143.18C142.76 143.473 142.52 143.707 142.234 143.707Z"
          fill="#73C5D9"
        />
        <path
          d="M41.7537 69.9933H38.0137V143.18H41.7537V69.9933Z"
          fill="#59B5C8"
        />
        <path
          d="M125.726 69.9933H121.986V143.18H125.726V69.9933Z"
          fill="#59B5C8"
        />
        <path
          d="M122.153 69.9933H120.653V143.18H122.153V69.9933Z"
          fill="#249AA8"
        />
        <path
          d="M43.2598 69.7133H41.7598V142.9H43.2598V69.7133Z"
          fill="#249AA8"
        />
        <path
          d="M13.4867 75.86L17.4334 59.7667C17.6467 58.8933 18.0534 58.3533 18.5 58.3533H141.507C141.953 58.3533 142.36 58.8933 142.573 59.7667L146.52 75.86C146.96 77.6667 146.38 79.8733 145.453 79.8733H14.5534C13.6267 79.8733 13.04 77.6667 13.4867 75.86Z"
          fill="#D1E8F2"
        />
        <path
          d="M14.553 80.4C14.2664 80.4 14.0264 80.1667 14.0264 79.8734C14.0264 79.58 14.2597 79.3467 14.553 79.3467L142.326 78.9867C142.613 78.9867 142.853 79.22 142.853 79.5134C142.853 79.8067 142.62 80.04 142.326 80.04L14.553 80.4Z"
          fill="#73C5D9"
        />
        <path
          d="M37.9062 52.9933L41.7129 54.9333V55.5666L43.2396 56.8866V52.72H37.9062"
          fill="#007F80"
        />
        <path
          d="M39.207 47.38C40.0204 46.3333 45.3337 34.76 45.6804 33.48C45.9204 32.58 46.0404 31.6467 46.347 30.7733C46.9004 29.1933 48.047 27.8733 49.3737 26.8533C50.7004 25.8333 52.207 25.0733 53.7004 24.3267C53.867 24.24 54.067 24.1533 54.2404 24.2333C54.3537 24.28 54.427 24.3867 54.4804 24.5C54.8337 25.2133 54.4804 26.06 54.1204 26.7733C51.887 31.1267 48.047 40.3333 46.587 45"
          fill="#F69E69"
        />
        <path
          d="M123.426 47.4534C122.613 46.4067 117.3 34.8334 116.953 33.5534C116.713 32.6534 116.593 31.72 116.286 30.8467C115.733 29.2667 114.586 27.9467 113.26 26.9267C111.933 25.9067 110.426 25.1467 108.933 24.4C108.766 24.3134 108.566 24.2267 108.393 24.3067C108.28 24.3534 108.206 24.46 108.153 24.5734C107.8 25.2867 108.153 26.1334 108.513 26.8467C110.746 31.2 114.586 40.4067 116.046 45.0734"
          fill="#F69E69"
        />
        <path
          opacity="0.25"
          d="M113.82 27.3666L114.707 28.2533C114.707 28.2533 114.227 31.6533 111.593 33.2666C111.593 33.2666 110.993 32.8466 111.367 31.9266"
          fill="#26282B"
        />
        <path
          d="M39.207 47.38C40.0204 46.3333 45.3337 34.76 45.6804 33.48C45.9204 32.58 46.0404 31.6467 46.347 30.7733C46.9004 29.1933 48.047 27.8733 49.3737 26.8533C50.7004 25.8333 52.207 25.0733 53.7004 24.3267C53.867 24.24 54.067 24.1533 54.2404 24.2333C54.3537 24.28 54.427 24.3867 54.4804 24.5C54.8337 25.2133 54.4804 26.06 54.1204 26.7733C51.887 31.1267 48.047 40.3333 46.587 45"
          fill="#F69E69"
        />
        <path
          opacity="0.25"
          d="M51.5534 32.2667C51.5534 32.2667 48.7001 30.4133 47.9268 28.18C47.9268 28.18 49.0268 26.9867 49.4868 26.76"
          fill="#26282B"
        />
        <path
          d="M58.5733 46.8667L55.8467 41.9733L65.4533 17.0267L66.3733 20.7867L68.7067 20.14L65.04 46.8667H58.5733Z"
          fill="#EE8900"
        />
        <path
          d="M62.2666 35.1733L64.1933 28.6733L67.4333 29.4533L66.5866 35.7333L62.2666 35.1733Z"
          fill="#A65F00"
        />
        <path
          d="M105.074 46.4L107.62 41.4533L98.1871 16.56L97.2738 20.32L94.9404 19.6733L98.6071 46.4H105.074Z"
          fill="#EE8900"
        />
        <path
          d="M101.373 34.7066L99.6196 29.5866L96.2129 28.98L97.2062 35.18L101.373 34.7066Z"
          fill="#A65F00"
        />
        <path
          d="M62.9666 32.9733H100.827L105.22 46.8667H58.5732L62.9666 32.9733Z"
          fill="#D98000"
        />
        <path
          d="M66.5264 17.98H96.6998L99.9264 30.9466H63.6064L66.5264 17.98Z"
          fill="#D98000"
        />
        <path
          d="M54.5938 14.8733L63.6071 30.9466L65.8404 22.2866L57.8604 9.33331"
          fill="#FFA000"
        />
        <path
          d="M108.82 15.2066L99.9269 30.9466L97.7002 22.2866L105.334 9.33331"
          fill="#FFA000"
        />
        <path
          d="M53.9066 16.0933L62.9666 32.9733L58.5732 46.8667L48.2266 25.34L53.9066 16.0933Z"
          fill="#FFA000"
        />
        <path
          opacity="0.38"
          d="M53.0065 31.0133C53.0065 31.0133 51.7865 30.5267 51.1465 31.4067L51.7731 32.7133"
          fill="#26282B"
        />
        <path
          opacity="0.38"
          d="M58.9399 25.48L58.8532 25.2933L48.2266 25.34L48.6932 26.3067L59.7666 27.0133L58.9399 25.48Z"
          fill="#26282B"
        />
        <path
          opacity="0.38"
          d="M53.8131 32.9134C53.9864 32.5867 54.4798 32.6934 54.5064 33.06C54.5664 33.94 54.5864 35.0934 54.3931 35.7734C54.0464 36.9934 54.7731 37.2534 54.5931 38.5934L52.7598 34.7467L53.8131 32.9134Z"
          fill="#26282B"
        />
        <path
          d="M109.327 16.0933L100.827 32.9733L105.22 46.8667L114.994 25.56L109.327 16.0933Z"
          fill="#FFA000"
        />
        <path
          opacity="0.38"
          d="M110.12 30.68C110.52 31.06 110.733 31.5933 110.853 32.1267C110.88 32.2333 110.9 32.3333 110.953 32.4267C111.007 32.52 111.1 32.5933 111.207 32.5933C111.393 32.5933 111.5 32.3933 111.633 32.2667C111.733 32.1667 111.867 32.1 112.007 32.08C111.647 32.8133 111.287 33.5533 110.927 34.2867"
          fill="#26282B"
        />
        <path
          opacity="0.38"
          d="M108.86 30.8933C108.607 31.5733 108.42 32.2933 108.433 33.02C108.447 33.5933 108.587 34.16 108.693 34.7266C108.953 36.1933 108.967 37.7 108.773 39.1733L110.413 35.5533"
          fill="#26282B"
        />
        <path
          opacity="0.38"
          d="M104.734 25.3134L103.94 26.8067L114.574 26.4734L114.994 25.56L104.734 25.3134Z"
          fill="#26282B"
        />
        <path
          d="M50.6936 47.38C50.6869 46.0933 51.5802 40.5867 51.5736 39.3C51.5736 38.74 51.5736 38.16 51.7602 37.6267C52.0002 36.9533 52.5136 36.4267 52.9469 35.86C53.8136 34.7267 54.3869 33.1533 53.7469 31.8733C53.5469 31.4667 53.1469 31.0867 52.6936 31.16C52.1736 31.2467 51.9469 31.8667 51.8869 32.3867C51.8336 32.9133 51.8136 33.5067 51.4136 33.8467C50.9536 34.24 50.2002 34.04 49.7936 33.5933C49.3869 33.1467 49.0269 32.2933 48.8936 31.7C47.7269 33.0733 46.9869 34.8667 46.2936 36.5267C46.2269 36.6867 46.2002 36.9267 46.3602 36.98"
          fill="#F69E69"
        />
        <path
          d="M111.114 37.9533C110.687 36.7067 109.86 35.6467 109.28 34.4667C108.7 33.2867 108.407 31.8267 109.06 30.6867C109.127 30.5733 109.207 30.46 109.32 30.4133C109.627 30.28 109.92 30.62 110.06 30.9267C110.28 31.4067 110.427 31.9133 110.5 32.4333C110.547 32.7733 110.614 33.1867 110.94 33.3067C111.207 33.4067 111.494 33.2333 111.747 33.1C112.314 32.8 112.974 32.6667 113.614 32.7267C113.92 32.7533 114.227 32.8267 114.487 32.9933C114.734 33.1467 114.92 33.38 115.074 33.62C115.9 34.9333 115.78 36.6067 116.1 38.1267C116.454 39.7667 117.34 41.2467 117.794 42.8667C117.994 43.5733 118.107 44.3133 118.027 45.0467C117.907 46.1467 117.36 47.16 116.7 48.0533C115.334 49.9067 113.48 51.3533 112.134 53.22C112.18 53.16 112.147 52.92 112.154 52.84C112.16 52.7067 112.167 52.58 112.167 52.4467C112.174 52.18 112.18 51.92 112.187 51.6533C112.194 51.1333 112.194 50.6133 112.187 50.0933C112.167 49.0467 112.107 47.9933 112.014 46.9533C111.92 45.9067 111.787 44.86 111.62 43.82C111.554 43.3867 111.58 42.9733 111.594 42.4533C111.607 42.0533 111.487 41.7 111.5 41.3C111.527 40.3 111.494 39.2933 111.22 38.3267C111.194 38.1867 111.154 38.0733 111.114 37.9533Z"
          fill="#F69E69"
        />
        <path
          d="M61.4397 7.48666L65.3464 16.0933H97.713L101.453 7.46C101.873 6.49333 101.16 5.41333 100.106 5.41333H62.7797C61.713 5.41333 60.9997 6.51333 61.4397 7.48666Z"
          fill="#FCBA18"
        />
        <path
          opacity="0.7"
          d="M61.4397 7.48666L65.3464 16.0933H97.713L101.453 7.46C101.873 6.49333 101.16 5.41333 100.106 5.41333H62.7797C61.713 5.41333 60.9997 6.51333 61.4397 7.48666Z"
          fill="#26282B"
        />
        <path
          d="M48.2266 25.34L53.9066 16.0933H109.327L114.82 25.26L48.2266 25.34Z"
          fill="#FCBA18"
        />
        <path
          d="M54.5938 14.8733C54.7938 15.1466 108.7 14.9933 108.7 14.9933L105.334 9.33331H57.8604L54.5938 14.8733Z"
          fill="#FCBA18"
        />
        <path
          d="M58.6201 8.15333L102.147 8.34666C103.28 8.35333 103.987 7.12666 103.427 6.14666C103.167 5.69333 102.68 5.41333 102.153 5.41333H62.0401C61.0068 5.41333 60.0401 5.94 59.4801 6.81333L58.6201 8.15333Z"
          fill="#FCBA18"
        />
        <path
          d="M118.633 95.0533C118.633 95.0533 131.473 81.72 130.387 76.2933C129.3 70.86 120.673 62.7533 110.12 78.6866L108.573 91.5933L118.633 95.0533Z"
          fill="#F69E69"
        />
        <path
          opacity="0.38"
          d="M59.6798 25.3266L67.6131 5.41331L61.5398 5.33331C61.0198 5.33331 60.5464 5.60665 60.2798 6.04665L48.6064 25.34L59.6798 25.3266Z"
          fill="#26282B"
        />
        <path
          d="M59.0866 25.3266L67.1532 5.40665L61.0532 5.33331C60.5332 5.33331 60.0599 5.60665 59.7932 6.04665L48.2266 25.34L59.0866 25.3266Z"
          fill="#FCBA18"
        />
        <path
          d="M62.0995 7.58002C61.9662 7.64002 61.8795 7.78002 61.9062 7.92668C61.9329 8.08002 62.0662 8.17335 62.2195 8.18002L62.0995 7.58002Z"
          fill="#AD6C00"
        />
        <path
          d="M62.2197 7.55334L62.3397 8.15335C62.4731 8.09335 62.5597 7.95335 62.5331 7.80668C62.4997 7.65334 62.3664 7.55334 62.2197 7.55334Z"
          fill="#AD6C00"
        />
        <path
          d="M61.6065 9.86668C61.6332 9.72002 61.5599 9.57335 61.4132 9.51335C61.2732 9.46002 61.1132 9.51335 61.0332 9.64002L61.6065 9.86668Z"
          fill="#AD6C00"
        />
        <path
          d="M61.5603 9.98002L60.9937 9.75336C60.967 9.90002 61.0403 10.0467 61.187 10.1067C61.327 10.1667 61.4803 10.1067 61.5603 9.98002Z"
          fill="#AD6C00"
        />
        <path
          d="M55.7196 20.4C55.5863 20.46 55.4996 20.6 55.5263 20.7467C55.553 20.9 55.6863 20.9934 55.8396 21L55.7196 20.4Z"
          fill="#AD6C00"
        />
        <path
          d="M55.8398 20.3734L55.9598 20.9734C56.0932 20.9134 56.1798 20.7734 56.1532 20.6267C56.1198 20.4734 55.9865 20.3734 55.8398 20.3734Z"
          fill="#AD6C00"
        />
        <path
          d="M55.113 22.3867C54.9997 22.2933 54.833 22.2867 54.713 22.3867C54.593 22.4867 54.573 22.6467 54.6463 22.78L55.113 22.3867Z"
          fill="#AD6C00"
        />
        <path
          d="M55.1932 22.4867L54.7266 22.88C54.8399 22.9734 55.0066 22.98 55.1266 22.88C55.2399 22.78 55.2666 22.6134 55.1932 22.4867Z"
          fill="#AD6C00"
        />
        <path
          d="M58.9931 13.9C58.8597 13.96 58.7731 14.1 58.7997 14.2467C58.8264 14.4 58.9597 14.4934 59.1131 14.5L58.9931 13.9Z"
          fill="#AD6C00"
        />
        <path
          d="M59.1133 13.8734L59.2333 14.4734C59.3666 14.4134 59.4533 14.2734 59.4266 14.1267C59.3933 13.9734 59.2599 13.88 59.1133 13.8734Z"
          fill="#AD6C00"
        />
        <path
          d="M58.5066 16.1534C58.5133 16.0067 58.4266 15.8667 58.2799 15.8267C58.1333 15.7867 57.9866 15.86 57.9199 15.9934L58.5066 16.1534Z"
          fill="#AD6C00"
        />
        <path
          d="M58.4736 16.2733L57.8802 16.1133C57.8736 16.26 57.9602 16.4 58.1069 16.44C58.2602 16.48 58.4069 16.4067 58.4736 16.2733Z"
          fill="#AD6C00"
        />
        <path
          opacity="0.38"
          d="M103.72 25.3L95.9668 5.41333H101.82C102.34 5.41333 102.68 5.60666 102.947 6.05333L114.513 25.48L103.72 25.3Z"
          fill="#26282B"
        />
        <path
          d="M104.173 25.3L96.4199 5.41333H102.167C102.687 5.41333 103.16 5.68666 103.427 6.12666L114.993 25.5533L104.173 25.3Z"
          fill="#FCBA18"
        />
        <path
          d="M100.634 6.82666C100.767 6.88666 100.854 7.02666 100.827 7.17333C100.8 7.32666 100.667 7.41999 100.514 7.42666L100.634 6.82666Z"
          fill="#AD6C00"
        />
        <path
          d="M100.513 6.79999L100.393 7.39999C100.26 7.33999 100.173 7.19999 100.2 7.05332C100.233 6.89999 100.367 6.80665 100.513 6.79999Z"
          fill="#AD6C00"
        />
        <path
          d="M101.126 9.11333C101.1 8.96666 101.173 8.82 101.32 8.75999C101.46 8.70666 101.62 8.75999 101.7 8.88666L101.126 9.11333Z"
          fill="#AD6C00"
        />
        <path
          d="M101.173 9.23332L101.74 9.00665C101.766 9.15332 101.693 9.29999 101.546 9.35999C101.406 9.41332 101.253 9.35332 101.173 9.23332Z"
          fill="#AD6C00"
        />
        <path
          d="M107.02 19.6467C107.154 19.7067 107.24 19.8467 107.214 19.9933C107.187 20.1467 107.054 20.24 106.9 20.2467L107.02 19.6467Z"
          fill="#AD6C00"
        />
        <path
          d="M106.893 19.6266L106.773 20.2266C106.64 20.1666 106.553 20.0266 106.58 19.88C106.613 19.7266 106.747 19.6266 106.893 19.6266Z"
          fill="#AD6C00"
        />
        <path
          d="M107.62 21.64C107.733 21.5467 107.9 21.54 108.02 21.64C108.14 21.74 108.16 21.9 108.087 22.0334L107.62 21.64Z"
          fill="#AD6C00"
        />
        <path
          d="M107.54 21.7333L108.007 22.1267C107.893 22.22 107.727 22.2267 107.607 22.1267C107.493 22.0267 107.473 21.8667 107.54 21.7333Z"
          fill="#AD6C00"
        />
        <path
          d="M103.74 13.1467C103.873 13.2067 103.96 13.3467 103.933 13.4933C103.907 13.6467 103.773 13.74 103.62 13.7467L103.74 13.1467Z"
          fill="#AD6C00"
        />
        <path
          d="M103.62 13.1266L103.5 13.7266C103.367 13.6666 103.28 13.5266 103.307 13.38C103.34 13.2266 103.473 13.1266 103.62 13.1266Z"
          fill="#AD6C00"
        />
        <path
          d="M104.227 15.4067C104.22 15.26 104.307 15.12 104.454 15.08C104.6 15.04 104.747 15.1133 104.814 15.2467L104.227 15.4067Z"
          fill="#AD6C00"
        />
        <path
          d="M104.26 15.5266L104.853 15.3666C104.86 15.5133 104.773 15.6533 104.626 15.6933C104.473 15.7333 104.326 15.6533 104.26 15.5266Z"
          fill="#AD6C00"
        />
        <path
          d="M52.5937 78.68C42.0403 62.7467 33.4137 70.8533 32.327 76.2867C31.2403 81.72 44.0803 95.0467 44.0803 95.0467L53.5603 89.4133L52.5937 78.68Z"
          fill="#F69E69"
        />
        <path
          d="M66.5329 72.7267C66.5329 72.7267 63.7663 72.92 64.4396 74.84C65.1129 76.76 66.8796 78.7734 66.8796 78.7734"
          fill="#F69E69"
        />
        <path
          d="M95.3935 72.7267C95.3935 72.7267 98.1602 72.92 97.4869 74.84C96.8135 76.76 95.0469 78.7734 95.0469 78.7734"
          fill="#F69E69"
        />
        <path
          d="M92.9667 154.42C102.22 154.42 109.727 146.96 109.82 137.707C109.893 129.873 109.653 125 108.487 118.447C107.14 110.847 108.427 109.767 112.853 102.127C113.573 100.88 117.393 95.8467 118.733 95.0533C118.733 95.0533 108.913 88.8333 110.22 78.68C110.22 78.68 95.6401 95.0133 81.4067 95.2067C67.1734 95.0133 52.5934 78.68 52.5934 78.68C53.9001 88.8267 44.0801 95.0533 44.0801 95.0533C45.4201 95.8467 49.2401 100.88 49.9601 102.127C54.3867 109.767 55.6734 110.847 54.3267 118.447C53.1401 125.153 53.1134 130.3 53.4201 138.26C53.7667 147.287 61.2201 154.413 70.2534 154.413H92.9667V154.42Z"
          fill="#0797E0"
        />
        <path
          d="M33.0331 80.0933C32.8931 79.7333 30.0131 73.6333 33.0331 63.1267C36.0531 52.62 43.4331 37.56 45.7064 38.2733C47.9797 38.9867 50.9597 39.98 50.6797 48.36C50.3931 56.74 50.4264 66.0733 46.8597 72.06C43.2931 78.0467 33.0331 80.0933 33.0331 80.0933Z"
          fill="#F69E69"
        />
        <path
          d="M129.68 80.0933C129.82 79.7333 132.7 73.6333 129.68 63.1267C126.66 52.62 119.28 37.56 117.006 38.2733C114.733 38.9867 111.753 39.98 112.033 48.36C112.32 56.74 112.286 66.0733 115.853 72.06C119.42 78.0467 129.68 80.0933 129.68 80.0933Z"
          fill="#F69E69"
        />
        <path
          d="M70.0996 86.5467L73.7796 95.66L82.7129 104.6L89.9596 95.5733L92.7529 85.5067L70.0996 86.5467Z"
          fill="#F69E69"
        />
        <path
          d="M89.4663 89.38C89.4663 89.38 83.1463 97.7467 72.2263 91.9133L70.0996 86.5533L89.4663 89.38Z"
          fill="#26282B"
        />
        <path
          d="M81.1801 92.0333C73.3068 92.0333 66.9268 85.6533 66.9268 77.78V70.2933C66.9268 65.8467 70.5334 62.24 74.9801 62.24H87.3801C91.8268 62.24 95.4334 65.8467 95.4334 70.2933V77.78C95.4334 85.6533 89.0534 92.0333 81.1801 92.0333Z"
          fill="#F69E69"
        />
        <path
          d="M81.1801 92.34C73.1534 92.34 66.6201 85.8067 66.6201 77.78V70.2934C66.6201 65.6867 70.3668 61.9333 74.9801 61.9333H87.3801C91.9868 61.9333 95.7401 65.68 95.7401 70.2934C95.7401 70.46 95.6068 70.6 95.4334 70.6C95.2668 70.6 95.1268 70.4667 95.1268 70.2934C95.1268 66.02 91.6468 62.54 87.3734 62.54H74.9734C70.7001 62.54 67.2201 66.02 67.2201 70.2934V77.78C67.2201 85.4733 73.4801 91.7334 81.1668 91.7334C84.4601 91.7334 87.6534 90.5667 90.1668 88.44C90.2934 88.3334 90.4868 88.3467 90.5934 88.4733C90.7001 88.6 90.6868 88.7933 90.5601 88.9C87.9534 91.12 84.6134 92.34 81.1801 92.34Z"
          fill="#26282B"
        />
        <path
          d="M52.5936 78.9333C52.5069 78.9333 52.4202 78.8867 52.3802 78.8067C52.3402 78.7333 48.2069 71.64 43.6536 71.3067C43.5136 71.2933 43.4136 71.18 43.4202 71.04C43.4336 70.9 43.5536 70.8 43.6869 70.8067C48.5002 71.1667 52.6336 78.2533 52.8069 78.5533C52.8736 78.6733 52.8336 78.8266 52.7136 78.8933C52.6802 78.92 52.6336 78.9333 52.5936 78.9333Z"
          fill="#26282B"
        />
        <path
          d="M110.22 78.9333C110.173 78.9333 110.127 78.92 110.08 78.8933C109.967 78.82 109.933 78.66 110.007 78.5466C110.193 78.26 114.667 71.5333 117.707 70.8133C117.84 70.78 117.973 70.8666 118.007 71C118.04 71.1333 117.953 71.2666 117.82 71.3C114.967 71.9733 110.467 78.7533 110.42 78.82C110.38 78.8933 110.3 78.9333 110.22 78.9333Z"
          fill="#26282B"
        />
        <path
          d="M56.3268 114.807C56.2201 114.807 56.1201 114.74 56.0868 114.627C52.4001 102.587 44.0001 95.3134 43.9201 95.24C43.8135 95.1534 43.8001 94.9934 43.8935 94.8867C43.9801 94.78 44.1401 94.7667 44.2468 94.86C44.3335 94.9334 52.8401 102.3 56.5735 114.48C56.6135 114.613 56.5401 114.753 56.4068 114.793C56.3801 114.807 56.3535 114.807 56.3268 114.807Z"
          fill="#004965"
        />
        <path
          d="M106.52 114.807C106.494 114.807 106.474 114.807 106.447 114.793C106.314 114.753 106.24 114.613 106.28 114.48C110.014 102.3 118.52 94.9333 118.607 94.86C118.714 94.7733 118.867 94.78 118.96 94.8867C119.047 94.9933 119.04 95.1467 118.934 95.24C118.847 95.3133 110.447 102.587 106.76 114.627C106.727 114.74 106.627 114.807 106.52 114.807Z"
          fill="#004965"
        />
        <path
          d="M82.7138 104.847C82.6471 104.847 82.5871 104.82 82.5338 104.773L73.5471 95.7734L72.9138 94.52C72.8538 94.4 72.9005 94.2467 73.0205 94.1867C73.1405 94.1267 73.2938 94.1734 73.3538 94.2934L73.9738 95.5067L82.8871 104.413C82.9871 104.513 82.9871 104.667 82.8871 104.767C82.8471 104.827 82.7805 104.847 82.7138 104.847Z"
          fill="#004965"
        />
        <path
          d="M90.2129 114.173C90.2129 114.173 95.7262 114.66 100.26 114.173L100.653 115.94C100.653 115.94 95.9262 116.82 90.2129 115.94V114.173Z"
          fill="#004965"
        />
        <path
          d="M91.133 91.34C91.133 91.34 91.7997 99.7933 89.4597 105.52C89.4597 105.52 87.8663 101.5 84.833 101.96C84.8397 101.953 90.433 94.14 91.133 91.34Z"
          fill="#004965"
        />
        <path
          d="M72.2002 91.5467C72.2002 91.5467 72.6202 99.7934 74.9602 105.52C74.9602 105.52 76.5935 100.507 80.0469 101.64C80.0469 101.64 72.9002 94.3467 72.2002 91.5467Z"
          fill="#004965"
        />
        <path
          d="M78.1797 81.98C79.0197 82.5867 80.0664 82.9067 81.0997 82.88C81.6864 82.8667 82.2664 82.7467 82.8464 82.6267C82.9264 82.6133 83.0064 82.5933 83.073 82.5467C83.2664 82.42 83.293 82.1267 83.4864 81.9933C82.873 81.5533 82.1664 81.2133 81.413 81.12C80.6597 81.02 79.8597 81.18 79.2597 81.64"
          fill="#B40000"
        />
        <path
          d="M77.2596 73.42C77.9003 73.42 78.4196 72.9006 78.4196 72.26C78.4196 71.6193 77.9003 71.1 77.2596 71.1C76.619 71.1 76.0996 71.6193 76.0996 72.26C76.0996 72.9006 76.619 73.42 77.2596 73.42Z"
          fill="#26282B"
        />
        <path
          d="M85.3465 72.8934C85.9872 72.8934 86.5065 72.374 86.5065 71.7334C86.5065 71.0927 85.9872 70.5734 85.3465 70.5734C84.7059 70.5734 84.1865 71.0927 84.1865 71.7334C84.1865 72.374 84.7059 72.8934 85.3465 72.8934Z"
          fill="#26282B"
        />
        <path
          d="M80.0602 77.8667C79.8469 77.8667 79.6536 77.8333 79.4802 77.7667C79.0269 77.5933 78.6802 77.1933 78.5802 76.7133C78.4802 76.2467 78.6402 75.7267 78.9802 75.3867C79.1469 75.22 79.3469 75.1067 79.5336 75C79.6536 74.9333 79.7802 74.86 79.8869 74.7733C80.1736 74.5533 80.3669 74.2067 80.4136 73.8467C80.4269 73.7133 80.5536 73.6133 80.6936 73.6267C80.8336 73.64 80.9269 73.7667 80.9136 73.9067C80.8536 74.4 80.5936 74.86 80.2002 75.1667C80.0736 75.2667 79.9269 75.3467 79.7869 75.4267C79.6202 75.52 79.4602 75.6133 79.3336 75.7333C79.1069 75.9533 79.0069 76.2933 79.0736 76.6C79.1402 76.9067 79.3669 77.1733 79.6602 77.2867C79.9002 77.38 80.2069 77.38 80.5669 77.2867C80.9602 77.1867 81.2936 77.0067 81.5136 76.7734C81.7136 76.56 82.0269 76.5 82.3202 76.62C82.4802 76.6867 82.6736 76.6533 82.7669 76.54C82.8336 76.46 82.8536 76.3333 82.8202 76.2067C82.7869 76.08 82.7069 75.98 82.6069 75.9467C82.4802 75.9 82.4069 75.76 82.4536 75.6267C82.5002 75.5 82.6402 75.4267 82.7736 75.4733C83.0269 75.56 83.2269 75.7867 83.3069 76.0733C83.3869 76.36 83.3269 76.6533 83.1536 76.86C82.9202 77.1333 82.5069 77.2267 82.1402 77.0733C82.0602 77.04 81.9536 77.0334 81.8869 77.1067C81.5269 77.4934 81.0469 77.6733 80.7002 77.7667C80.4602 77.84 80.2536 77.8667 80.0602 77.8667Z"
          fill="#26282B"
        />
        <path
          d="M74.4664 72.58C74.3331 72.58 74.2197 72.4733 74.2197 72.34C74.2197 72.32 74.1931 70.0866 76.6864 69.62C76.8264 69.5933 76.9531 69.6866 76.9797 69.82C77.0064 69.9533 76.9131 70.0866 76.7797 70.1133C74.7064 70.5 74.7197 72.26 74.7197 72.3333C74.7197 72.46 74.6131 72.58 74.4664 72.58C74.4731 72.58 74.4664 72.58 74.4664 72.58Z"
          fill="#26282B"
        />
        <path
          d="M88.3804 72.06C88.2404 72.06 88.127 71.94 88.1337 71.8066C88.1337 71.7333 88.147 69.9733 86.0737 69.5866C85.9404 69.56 85.847 69.4333 85.8737 69.2933C85.9004 69.16 86.027 69.0666 86.167 69.0933C88.6604 69.56 88.6337 71.7933 88.6337 71.8133C88.627 71.9533 88.5204 72.06 88.3804 72.06Z"
          fill="#26282B"
        />
        <path
          d="M78.4329 79.7266C77.9596 79.7933 77.4596 79.8667 77.0862 80.1733C76.5462 80.62 76.4529 81.3933 76.4062 82.0933C78.1796 82.0866 79.9529 82.0733 81.7196 82.0666C83.1863 82.06 84.6596 82.0533 86.1196 81.8667C85.8663 81.14 85.5329 80.3866 84.8796 79.9733C84.1129 79.4866 83.1729 79.64 82.3196 79.5133C82.0396 79.4733 81.7663 79.4066 81.4863 79.3933C81.0596 79.3666 80.6329 79.4333 80.2129 79.4933C79.6196 79.5666 79.0263 79.6466 78.4329 79.7266Z"
          fill="#26282B"
        />
        <path
          d="M70.3004 69.4667C70.1671 69.8534 70.0004 70.2467 69.7338 70.5867C69.1004 71.4067 67.9538 71.8867 67.3471 72.72C66.8338 73.4334 66.7871 74.2867 66.7604 75.1C66.4271 73.6667 66.1938 72.22 66.0471 70.7534C65.9804 70.0667 65.9338 69.38 66.0271 68.6867C66.2538 67.0267 67.2938 65.36 68.4938 64.0134C68.9204 63.5334 69.7804 63.3534 70.1938 63.8934C70.4404 64.2134 70.5004 64.7 70.5804 65.08C70.6738 65.5267 70.7271 65.9734 70.7471 66.4334C70.7871 67.4534 70.6404 68.48 70.3004 69.4667Z"
          fill="#26282B"
        />
        <path
          d="M91.9931 69.4667C92.1265 69.8534 92.2931 70.2467 92.5598 70.5867C93.1931 71.4067 94.3398 71.8867 94.9465 72.72C95.4598 73.4334 95.5065 74.2867 95.5331 75.1C95.8665 73.6667 96.0998 72.22 96.2465 70.7534C96.3131 70.0667 96.3598 69.38 96.2665 68.6867C96.0398 67.0267 94.9998 65.36 93.7998 64.0134C93.3731 63.5334 92.5131 63.3534 92.0998 63.8934C91.8531 64.2134 91.7931 64.7 91.7131 65.08C91.6198 65.5267 91.5665 65.9734 91.5465 66.4334C91.5065 67.4534 91.6531 68.48 91.9931 69.4667Z"
          fill="#26282B"
        />
        <path
          d="M75.2267 58.5266C77.4933 57.3266 80.14 56.7666 82.66 57.22C84.62 57.5733 86.42 58.5066 88.1467 59.5C89.3867 60.2133 90.6133 60.96 91.8133 61.74C92.7 62.32 93.6933 63.16 93.5133 64.2066C93.34 65.24 92.1533 65.7133 91.1467 66C88.04 66.8866 84.82 67.7867 81.6267 67.32C79.9667 67.08 78.38 66.48 76.7667 66.0066C75.62 65.6733 74.46 65.4 73.2867 65.1933C72.6933 65.0933 72.1 65.0066 71.5067 64.9333C71.14 64.8933 70.78 64.8266 70.5067 64.5466C70.04 64.0666 70.1467 63.2933 70.3867 62.7266C70.7467 61.8666 71.5133 61.2266 72.2 60.6266C73.1333 59.82 74.14 59.1 75.2267 58.5266Z"
          fill="#26282B"
        />
        <path
          opacity="0.38"
          d="M60.46 25.34L64.6866 26.76H98.8733L103.26 25.24"
          fill="#26282B"
        />
        <path
          d="M50.6931 46.72C50.6865 46.72 50.6731 46.72 50.6665 46.72C50.5731 46.7067 50.5131 46.62 50.5331 46.5267C50.5398 46.48 51.3131 41.9267 51.4131 39.2933C51.5065 36.78 52.4198 36.1467 52.6065 36.0467C54.0065 34.68 54.0998 33.12 53.7598 32.2C53.5665 31.6867 53.2331 31.34 52.8998 31.32C52.6731 31.3067 52.4998 31.3667 52.3665 31.5C52.0265 31.8467 52.0665 32.5533 52.0665 32.56C52.0798 33.4333 51.5798 34.3 50.8131 34.7133C50.7331 34.76 50.6331 34.7267 50.5865 34.6467C50.5398 34.5667 50.5731 34.4667 50.6531 34.42C51.3131 34.0667 51.7465 33.32 51.7331 32.5733C51.7331 32.5467 51.6798 31.72 52.1265 31.2667C52.3265 31.06 52.5931 30.9667 52.9131 30.9867C53.3798 31.0133 53.8265 31.4333 54.0665 32.08C54.4465 33.1 54.3465 34.82 52.8198 36.3L52.7731 36.3333C52.7665 36.34 51.8465 36.8 51.7531 39.3067C51.6531 41.96 50.8798 46.5333 50.8731 46.58C50.8465 46.66 50.7731 46.72 50.6931 46.72Z"
          fill="#26282B"
        />
        <path
          d="M50.8195 34.14C50.5795 34.14 50.2929 34.0867 50.0062 33.92C49.2862 33.4933 48.8595 32.5 48.7329 30.9533C48.7262 30.86 48.7929 30.78 48.8862 30.7733C48.9795 30.7667 49.0595 30.8333 49.0662 30.9267C49.1795 32.3333 49.5595 33.2667 50.1729 33.6333C50.7395 33.9733 51.3262 33.72 51.3529 33.7067C51.4395 33.6733 51.5329 33.7067 51.5729 33.7933C51.6129 33.88 51.5729 33.9733 51.4929 34.0133C51.4729 34.0133 51.1995 34.14 50.8195 34.14Z"
          fill="#26282B"
        />
        <path
          d="M46.8604 72.3C46.8204 72.3 46.7804 72.2933 46.747 72.2733C46.627 72.2067 46.5804 72.06 46.6404 71.9333C46.667 71.88 49.467 66.5 49.8604 60.04C49.867 59.9 50.007 59.8 50.127 59.8067C50.267 59.8133 50.367 59.9333 50.3604 60.0733C49.9604 66.6467 47.107 72.12 47.0804 72.1733C47.0337 72.2533 46.947 72.3 46.8604 72.3Z"
          fill="#26282B"
        />
        <path
          d="M115.854 72.3C115.76 72.3 115.674 72.2467 115.627 72.16C115.494 71.8933 112.394 65.5933 112.494 61.3933C112.494 61.2533 112.6 61.1667 112.747 61.1467C112.887 61.1533 112.994 61.2667 112.994 61.4C112.894 65.4733 116.04 71.8667 116.074 71.9333C116.134 72.06 116.087 72.2067 115.96 72.2667C115.934 72.2933 115.894 72.3 115.854 72.3Z"
          fill="#26282B"
        />
        <path
          d="M112.047 48.76C111.96 48.76 111.887 48.6867 111.88 48.6C111.84 47.42 111.053 38.3067 110.933 37.64C110.873 37.3067 110.547 36.7667 110.173 36.14C109.8 35.5133 109.373 34.8067 109.033 34.0333C108.533 32.8933 108.447 31.56 108.827 30.78C108.973 30.4733 109.187 30.28 109.447 30.22C109.6 30.18 109.753 30.2133 109.893 30.3067C110.513 30.7267 110.78 32.44 110.807 32.6333C110.84 32.82 110.887 33 111.013 33.0733C111.153 33.1467 111.347 33.16 111.56 33.1067C111.72 33.0667 111.873 33 112.033 32.9267L112.167 32.8667C112.64 32.66 113.067 32.5533 113.46 32.5467C113.98 32.5333 114.427 32.6867 114.76 32.9733C114.96 33.14 115.107 33.3533 115.227 33.5333C115.673 34.2067 116.007 34.94 116.227 35.72C116.253 35.8067 116.2 35.9 116.113 35.9267C116.027 35.9533 115.933 35.9 115.907 35.8133C115.7 35.0667 115.373 34.3667 114.947 33.72C114.833 33.5467 114.707 33.3667 114.54 33.2267C114.267 32.9933 113.88 32.8733 113.46 32.88C113.107 32.8867 112.727 32.98 112.287 33.1733L112.153 33.2333C111.987 33.3067 111.813 33.3867 111.627 33.4333C111.42 33.4867 111.12 33.5133 110.847 33.3733C110.56 33.22 110.5 32.8933 110.467 32.6933C110.393 32.2 110.12 30.8733 109.693 30.5867C109.633 30.5467 109.573 30.5333 109.513 30.5467C109.313 30.5933 109.187 30.7867 109.113 30.9333C108.827 31.52 108.807 32.7267 109.327 33.9067C109.66 34.66 110.08 35.36 110.453 35.98C110.86 36.66 111.187 37.2 111.26 37.5933C111.38 38.2667 112.173 47.42 112.213 48.6C112.213 48.68 112.14 48.76 112.047 48.76Z"
          fill="#26282B"
        />
        <path
          d="M79.0331 154.667C78.9064 154.667 78.7997 154.573 78.7864 154.44C77.1931 139.26 77.7997 121.247 80.2331 111.6C81.2131 107.72 82.6131 104.547 84.5331 101.9C86.7264 98.8667 89.2264 94.7734 89.2531 94.7334C89.3264 94.6134 89.4798 94.58 89.5931 94.6533C89.7131 94.7267 89.7464 94.88 89.6731 94.9934C89.6464 95.0334 87.1398 99.14 84.9331 102.193C83.0531 104.793 81.6731 107.913 80.7131 111.727C78.2931 121.32 77.6864 139.267 79.2797 154.387C79.2931 154.527 79.1931 154.647 79.0597 154.66C79.0531 154.667 79.0397 154.667 79.0331 154.667Z"
          fill="#004965"
        />
      </svg>
    </>
  );
};

export default Error;
