import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import {
  Button,
  Checkbox,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  Typography,
  Radio,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { DateDropDownMenu } from "./CustomCalender";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { FinanceState, initialState, updateFilters } from "./financeSlice";
import OrderStatusMenu from "./FilterDropDowns";
import SortByDropDown from "./SortByDropDown";
import { useLocation } from "react-router-dom";

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
  },
}));

export function FinanceFilters({ setDateSelectionBtn }: any) {
  //filter selected
  const { loading, filters, count }: FinanceState = useSelector(
    (state: RootState) => state.financeItems
  );

  const [showFilterModule, setShowFilterModule] = React.useState(true);
  const [orderStatus, setOrderStatus] = React.useState(true);
  const [disburseDate, setDisburseDate] = React.useState(false);
  const [dueDate, setDueDate] = React.useState(true);
  const [filtersCleared, setfiltersCleared] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [orderStatusBy, setOrderStatusBy] = React.useState("Unsettled");
  const [calenderFilterBy, setCalenderFilterBy] = React.useState("");

  const defaultState = () => {
    setCalenderFilterBy("");
    setDueDate(true);
    setDisburseDate(false);
    setOrderStatusBy("Unsettled");
    setShowFilterModule(true);
    setOrderStatus(true);
  };

  const location = useLocation();

  React.useEffect(() => {
    if (location.state?.reload) {
      dispatch(updateFilters(initialState.filters));
      defaultState();
    }
  }, [location]);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch: AppDispatch = useDispatch();

  const filterDispatchDisburse = (
    startDate: string | null,
    endDate: string | null
  ) => {
    dispatch(
      updateFilters({
        disburseStartDate: startDate,
        disburseEndDate: endDate,
        pageNo: 0,
      })
    );
  };

  const filterDispatchDue = (
    startDate: string | null,
    endDate: string | null
  ) => {
    dispatch(
      updateFilters({
        dueStartDate: startDate,
        dueEndDate: endDate,
        pageNo: 0,
      })
    );
  };

  React.useEffect(()=>{
    setDateSelectionBtn(calenderFilterBy);
  }, [calenderFilterBy])

  const clearCurrentDate = () => {
    dispatch(
      updateFilters({
        disburseStartDate: null,
        disburseEndDate: null,
        dueStartDate: null,
        dueEndDate: null,
        pageNo: 0,
      })
    );
  };

  const clearAllFilters = () => {
    setOrderStatus(false);
    setDisburseDate(false);
    setDueDate(false);
    setCalenderFilterBy("");
    setfiltersCleared(true);
    dispatch(
      updateFilters({
        orderStatus: null,
        disburseStartDate: null,
        disburseEndDate: null,
        dueStartDate: null,
        dueEndDate: null,
        pageNo: 0,
      })
    );
  };

  React.useEffect(() => {
    if (filters.orderOrInvoiceNumber != null) {
      clearAllFilters();
      setfiltersCleared(false);
      setShowFilterModule(false);
    } else {
      defaultState();
    }
  }, [filters.orderOrInvoiceNumber]);

  const sortByDispatchCall = (
    sortKey: string | null,
    sortOrder: string | null
  ) => {
    dispatch(
      updateFilters({
        sortKey: sortKey,
        sortOrder: sortOrder,
        pageNo: 0,
      })
    );
  };

  return (
    <div style={{ padding: "0 1rem" }}>
      <Grid
        container
        mb={2}
        alignItems={"center"}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography
            variant="h6"
            noWrap
            sx={{ color: "info.main", fontWeight: 600 }}
            component="div"
          >
            Orders
          </Typography>
        </Grid>
        <Grid item alignItems={"center"}>
          {showFilterModule ? (
            <Grid container alignItems={"center"} spacing={2}>
              <Grid item alignItems={"center"}>
                <SortByDropDown
                  sortDispatchCall={sortByDispatchCall}
                  filtersCleared={filtersCleared}
                  setfiltersCleared={setfiltersCleared}
                  disabled={
                    !orderStatus && !disburseDate && !dueDate && count === 0
                  }
                />
              </Grid>
              <Grid item alignItems={"center"}>
                <div>
                  <Button
                    id="filter-select-dropdown"
                    aria-controls={open ? "filter-select-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="outlined"
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownRoundedIcon />}
                    sx={{
                      color: "info.dark",
                      minWidth: "180px",
                      justifyContent: "space-between",
                    }}
                    color="info"
                    disabled={
                      !orderStatus && !disburseDate && !dueDate && count === 0
                    }
                  >
                    <Grid container>
                      <Grid item paddingRight="6px">
                        <FilterAltRoundedIcon />
                      </Grid>
                      <Grid item>Filters</Grid>
                    </Grid>
                  </Button>
                  <StyledMenu
                    id="filter-select-menu"
                    MenuListProps={{
                      "aria-labelledby": "filter-select-dropdown",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        setOrderStatus(!orderStatus);
                        if (orderStatus) {
                          dispatch(
                            updateFilters({
                              orderStatus: null,
                            })
                          );
                        }
                      }}
                    >
                      <Checkbox checked={orderStatus} />
                      <ListItemText primary={"Order Status"} />
                    </MenuItem>
                    <Divider
                      sx={{
                        borderStyle: "dashed",
                        margin: "0px 16px",
                        borderColor: "info.light",
                      }}
                    />

                    <MenuItem
                      onClick={() => {
                        setDisburseDate(!disburseDate);
                        setDueDate(false);
                        setCalenderFilterBy("");
                        clearCurrentDate();
                      }}
                    >
                      <Radio
                        checked={disburseDate}
                        value="a"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "a" }}
                      />
                      <ListItemText primary={"Disbursement Date"} />
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setDueDate(!dueDate);
                        setDisburseDate(false);
                        setCalenderFilterBy("");
                        clearCurrentDate();
                      }}
                    >
                      <Radio
                        checked={dueDate}
                        value="b"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "b" }}
                      />
                      <ListItemText primary={"Due Date"} />
                    </MenuItem>
                  </StyledMenu>
                </div>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {orderStatus || disburseDate || dueDate ? (
        <Grid
          container
          mb={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item flexGrow={1}>
            <Grid
              container
              spacing={3}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Grid item>
                <Typography>Selected Filters:</Typography>
              </Grid>
              {orderStatus ? (
                <Grid item>
                  <OrderStatusMenu
                    filterBy={orderStatusBy}
                    setFilterBy={setOrderStatusBy}
                  />
                </Grid>
              ) : null}
              {disburseDate || dueDate ? (
                <Grid item>
                  <DateDropDownMenu
                    buttonName={disburseDate ? "Disbursement Date" : "Due Date"}
                    futureOptions={disburseDate ? false : true}
                    filterDispatchCall={
                      disburseDate ? filterDispatchDisburse : filterDispatchDue
                    }
                    filterBy={calenderFilterBy}
                    setFilterBy={setCalenderFilterBy}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item>
            <Button
              variant="text"
              sx={{
                color: "info.dark",
                columnGap: 1,
              }}
              onClick={clearAllFilters}
            >
              <CloseRoundedIcon />
              <Typography variant="subtitle2" color={"info.dark"}>
                Clear All Filters
              </Typography>
            </Button>
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
}
