import { createSlice } from '@reduxjs/toolkit'
import { getOrderDetails } from '../financeApi';
import { errorMessage } from '../../../utils';


export interface OrderDetailsState {
  loading: boolean,
  error: string | null,
  currentOrder: any,

}

const initialState: OrderDetailsState = {
  loading: false,
  error: null,
  currentOrder: {},

}

export const orderDetailsSlice = createSlice({
  name: 'orderDetails',
  initialState,
  reducers: {

  },
  extraReducers: {
    [getOrderDetails.pending.type]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.currentOrder = {};
    },
    [getOrderDetails.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.currentOrder = {
        ...action.payload.orderDetailsDto,
        paymentDetails: [
          ...(action.payload.creditNoteDetailPaymentHistoryDtos || []).map((item: any) => ({ ...item, type: 'creditNote' })),
          ...(action.payload.orderDetailPaymentHistoryDtos || [])
        ]
      };
      if (action.payload.message?.includes('Invalid')) {
        state.error = action.payload.message;
        errorMessage(state.error || '');
      }
    },
    [getOrderDetails.rejected.type]: (state, action) => {
      state.loading = false;
      state.currentOrder = {};
      state.error = action.payload || 'Something went wrong';
      // errorMessage(state.error || '');
    },
  }
})

// Action creators are generated for each case reducer function
// export const { updateFilters } = orderDetailsSlice.actions



export default orderDetailsSlice.reducer