import React, { useEffect, useMemo } from "react";
import { useFormikContext, Form } from "formik";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteIcon from "@mui/icons-material/CancelOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { AppDispatch, RootState } from "../../store";
import { HelpState, updateFormValues } from "./poSlice";
import Loader from "../Loader";
import { UserDetailsState } from "../UserProfile/userSlice";
import FileUpload from "../FileUpload";
import { FormikCommodityDropdown } from "../dropDowns/commodity";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { QUANTITY_UNITS } from "../NoData/constants";
import { FormikVarietyDropdown } from "../dropDowns/variety";
import { duplicatePO } from "./poApi";

import { ConfigState } from "../../features/maintenance/maintenanceSlice";
import { displayPDForImage } from "../../utils";

const POForm = (props: { handleClose: any }) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  }: any = useFormikContext();
  const dispatch: AppDispatch = useDispatch();

  const [addMore, setAddMore] = React.useState(false);
  const [uploadFile, setUploadFile] = React.useState(true);

  const { loading, error }: HelpState = useSelector(
    (state: RootState) => state.addPurchaseOrder
  );

  const { config }: ConfigState = useSelector(
    (state: RootState) => state.config
  );

  const { response }: UserDetailsState = useSelector(
    (state: RootState) => state.userDetails
  );

  const handleDuplicatePONumber = async (e: any) => {
    const response = await dispatch(duplicatePO({ poNumber: e.target.value }));
    if (response.payload.response_code == 80) {
      setFieldValue("poNumber", e.target.value);
    } else if (response.payload.response_code == 90) {
      setFieldError(
        "poNumber",
        "Entered PO number already exists. Please check again"
      );
    }
    setFieldTouched("poNumber", true, false);
  };

  const functionalKeys = [
    "ArrowLeft",
    "ArrowRight",
    "Backspace",
    "Delete",
    "Tab",
    "End",
    "Home",
  ];

  const handleKeyDownTenure = (event: any) => {
    const keyPressed = event.key;
    const isAllowedKey =
      /^[0-9]$/.test(keyPressed) || functionalKeys.includes(keyPressed);
    if (!isAllowedKey) {
      event.preventDefault();
    }
  };

  const handleKeyDownRate = (event: any) => {
    const keyPressed = event.key;
    const inputValue = event.target.value;
    const isAllowedKey =
      /^[0-9]$/.test(keyPressed) ||
      (keyPressed === "." && !inputValue.includes(".")) ||
      functionalKeys.includes(keyPressed);
    if (!isAllowedKey) {
      event.preventDefault();
    }
  };

  const handleKeyDownPoNumber = (event: any) => {
    const keyPressed = event.key;
    if (keyPressed === "%") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const { imageList } = values;
    if (imageList?.length > 0) {
      setUploadFile(false);
      setAddMore(true);
    } else {
      setAddMore(false);
      setUploadFile(true);
    }
  }, [values.imageList]);

  const handleAddMore = () => {
    setUploadFile(true);
    setAddMore(false);
  };

  return (
    <Form noValidate>
      <Grid container spacing={2} gap={{ xs: 1, md: 0 }}>
        <Loader loading={loading} />
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {values.imageList?.map((item: any, index: number) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Grid
                  container
                  display={"flex"}
                  justifyContent="center"
                  alignContent={"space-between"}
                  sx={{ height: "100%" }}
                >
                  {displayPDForImage(item, {
                    borderRadius: "0.25rem",
                    border: "1px solid #ffffff",
                    width: "100%",
                    height: "110px",
                    flex: 1,
                    boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.16);",
                  })}
                  <Button
                    variant="text"
                    sx={{
                      color: "info.dark",
                      padding: "0px",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                    endIcon={<DeleteIcon />}
                    onClick={() => {
                      values.imageList.splice(index, 1);
                      dispatch(
                        updateFormValues({
                          ...values,
                          imageList: values.imageList,
                        })
                      );
                    }}
                  >
                    Remove
                  </Button>
                </Grid>
              </Grid>
            ))}
            {uploadFile &&
              (config.maxPoDocuments || 3) > values.imageList?.length && (
                <Grid item xs={12} sm={6} md={4}>
                  <FileUpload
                    filePrefix="poDocuments"
                    name="imageList"
                    // maxDocuments={config.maxPoDocuments}
                    maxFileSize={config.maxPoDocSizeMB}
                  >
                    Upload PO Document
                  </FileUpload>
                </Grid>
              )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" color="text.secondary">
              Accepted formats: JPG, PNG or PDF. Maximum file size:{" "}
              {config.maxPoDocSizeMB} MB
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              sx={{
                marginTop: "1rem",
                padding: "0 1rem",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle2" color={"info.dark"}>
                Upload upto {config.maxPoDocuments} documents of PO
              </Typography>
              <Button
                variant="outlined"
                startIcon={<AddCircleIcon />}
                color="primary"
                sx={{ borderRadius: "6.25rem", float: "right" }}
                onClick={handleAddMore}
                disabled={
                  !addMore || config.maxPoDocuments <= values.imageList?.length
                }
              >
                Add More
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" fontWeight={600}>
            Purchase Order Details *
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              name="poNumber"
              label="Purchase Order Number"
              required={true}
              defaultValue={values?.poNumber}
              onBlur={handleBlur}
              variant="outlined"
              style={{ marginBottom: "16px" }}
              onKeyDown={handleKeyDownPoNumber}
              onChange={(e) => handleDuplicatePONumber(e)}
              error={touched.poNumber && Boolean(errors.poNumber)}
              helperText={touched.poNumber && errors.poNumber}
            />
          </FormControl>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormikCommodityDropdown
                id="discovery-demand-post-commodity"
                fullWidth
                defaultValue=""
                label="Commodity"
                name="commodity"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormikVarietyDropdown
                id="variety-name"
                fullWidth
                defaultValue=""
                label="Variety"
                name="variety"
                commodity_id={values?.commodity?.id}
              />
              <TextField
                name="tenure"
                label="Tenure"
                defaultValue={values?.tenure}
                onBlur={handleBlur}
                variant="outlined"
                style={{ marginBottom: "16px" }}
                onChange={handleChange}
                error={touched.tenure && Boolean(errors.tenure)}
                helperText={touched.tenure && errors.tenure}
                onKeyDown={handleKeyDownTenure}
              />
              <Grid container spacing={1}>
                <Grid item md={8} sm={12}>
                  <TextField
                    name="price"
                    label="Rate"
                    id="rate"
                    variant="outlined"
                    defaultValue={values?.price}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    onKeyDown={handleKeyDownRate}
                    error={touched.price && Boolean(errors.price)}
                    helperText={touched.price ? errors?.price : null}
                  />
                </Grid>
                <Grid item md={4} sm={12}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      name="priceUnit"
                      id="unit"
                      label="Unit"
                      onChange={handleChange}
                      defaultValue={values.priceUnit}
                      error={touched.priceUnit && Boolean(errors.priceUnit)}
                      helperText={touched.priceUnit ? errors?.priceUnit : null}
                      select
                    >
                      {QUANTITY_UNITS.map((item) => (
                        <MenuItem
                          id={`item-${item.id}`}
                          key={item.id}
                          value={item.name || item.id}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            "& .MuiOutlinedInput-input ": {
              overflow: "hidden",
            },
          }}
        >
          <TextField
            fullWidth
            id="comment"
            onChange={handleChange}
            name="comment"
            placeholder={"Comment"}
            multiline
            rows={10}
            onBlur={handleBlur}
            error={touched.comment && Boolean(errors.comment)}
            helperText={touched.comment && errors.comment}
            value={values.comment}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {300 - values.comment.length}
                </InputAdornment>
              ),
              style: {
                alignItems: "flex-end",
                justifyContent: "flex-end",
                display: "flex",
              },
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: "white",
            position: "sticky",
            paddingBottom: "12px",
            bottom: 0,
            zIndex: 5,
            gap: "24px",
          }}
        >
          <LoadingButton
            color="primary"
            id="login-module-btn"
            variant="contained"
            fullWidth
            disableElevation={true}
            type="submit"
            loading={loading}
            style={{ padding: "16px", borderRadius: "8px" }}
            startIcon={<CheckCircleRoundedIcon />}
            // disabled={Object.keys(errors).length > 0 || !values?.poNumber}
          >
            Add Purchase Order
          </LoadingButton>
        </Grid>
      </Grid>
    </Form>
  );
};

export default POForm;
