import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../api";

export const getToastNotifications = createAsyncThunk("user/notifications/toast",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await API.get(`/notification/toast`);
      const { callback } = payload;
      if (callback) callback(response.data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error);
    }
  }
)

export const markAsReadToastNotifications = createAsyncThunk("user/notifications/toast/markAsRead",
  async (id: any, { rejectWithValue }) => {
    try {
      const response = await API.put(`/notification/status/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error);
    }
  }
)