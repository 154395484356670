import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { addPO } from './poApi'
import { errorMessage } from '../../utils'

export interface HelpState {
  comment: string,
  loading: boolean,
  poNumber: string,
  price?: number | null,
  priceUnit?: ''
  error?: string,
  commodityName?: string,
  varietyName?: string,
  unitId: number | null,
  commodityId: number | null,
  tenure?: number | null,
  imageList: any[]
}

export const initialState: HelpState = {
  comment: '',
  loading: false,
  error: '',
  poNumber: '',
  price: null,
  priceUnit: '',
  commodityName: '',
  varietyName: '',
  unitId: null,
  commodityId: null,
  tenure: null,
  imageList: []
}

export const helpSlice = createSlice({
  name: 'help',
  initialState,
  reducers: {
    updateFormValues: (state, action: PayloadAction<any>) => {
      state.comment = action.payload.comment
      state.commodityName = action.payload.commodityName
      state.price = action.payload?.price
      state.priceUnit = action.payload?.priceUnit
      state.poNumber = action.payload.poNumber
      state.varietyName = action.payload.varietyName
      state.tenure = action.payload.tenure
      state.imageList = action.payload.imageList
    },
  },
  extraReducers: {
    [addPO.pending.type]: (state, action) => {
      state.loading = true;
      state.error = '';
    },
    [addPO.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.comment = ''
      state.poNumber = ''
      state.price = null
      state.priceUnit = ''
      state.commodityName = ''
      state.varietyName = ''
      state.tenure = null
    },
    [addPO.rejected.type]: (state, action) => {
      errorMessage(action.payload || 'Something went wrong');
      state.loading = false;
      state.error = action.payload || 'Something went wrong';
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateFormValues } = helpSlice.actions

export default helpSlice.reducer