import {
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Button,
  styled,
  Autocomplete,
  Chip,
  CircularProgress,
} from "@mui/material";
import { Fragment } from "react";
import React, { useState, useEffect } from "react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DescriptionIcon from "@mui/icons-material/Description";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { InvoiceNumberState } from "../invoiceCheckSlice";
import {
  getInvoiceNumber,
  getGenerateLedgerReport,
  getGenerateLateFeeReport,
  getGenerateReports,
} from "../ReportsApi";
import { KALA_70, KALA_80, MIRCHI_70 } from "../../../styles/colors";
import { GenerateReportState } from "../GenerateReports/generateReportSlice";
import HelpIcon from "@mui/icons-material/Help";
import DateRangeReport from "../DateRangeReport";
import moment from "moment";
import { subMonths, format, endOfMonth, startOfMonth } from "date-fns";
import { LightTooltip } from "../ReportsFilter";
import { LateFeeReportState } from "../lateFeeReportsSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";
import { defaultMessage } from "../../../utils";
import { ConfigState } from "../../../features/maintenance/maintenanceSlice";
import { analytics, trackAnalytics } from "../../../Analytics";

interface Option {
  invoiceNumber: any;
  loanId: any;
  lateFees: any;
  dueDate: any;
}

function LateFeeFormReport() {
  const dispatch: AppDispatch = useDispatch();
  const [calenderFilterBy, setCalenderFilterBy] = React.useState("");
  const [openDateRange, setOpenDateRange] = React.useState(false);
  const [applied, setApplied] = React.useState(false);
  const [dateSelectionBtn, setDateSelectionBtn] = useState("Last 3 Months");
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [showToast, setShowToast] = useState(false);
  const [range, setRange] = React.useState([
    {
      startDate: startOfMonth(subMonths(new Date(), 3)),
      endDate: endOfMonth(subMonths(new Date(), 1)),
      key: "selection",
    },
  ]);

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showToast]);

  useEffect(() => {
    if (showToast) {
      defaultMessage(
        "Your request has been submitted. You will be notified when the report is ready.",
        KALA_80
      );
    }
  }, [showToast]);

  const { loading, response, error }: InvoiceNumberState = useSelector(
    (state: RootState) => state.checkInvoiceNumber
  );

  useEffect(() => {
    if (response?.buyerInvoiceDtoList) {
      setSelectedOptions(response.buyerInvoiceDtoList);
    }
  }, [response?.buyerInvoiceDtoList]);

  const { loading: lateFeeLoading }: LateFeeReportState = useSelector(
    (state: RootState) => state.LateFeeBreakdownReport
  );

  const { config }: ConfigState = useSelector(
    (state: RootState) => state.config
  );

  const { mobileNumber, response: mobileRes }: any = useSelector(
    (state: RootState) => state.mobileNum
  );

  useEffect(() => {
    const payoutStartDate = moment(range[0].startDate).format("YYYY-MM-DD");
    const payoutEndDate = moment(range[0].endDate).format("YYYY-MM-DD");
    if (
      (dateSelectionBtn == "Date Range" && applied) ||
      dateSelectionBtn == "Last 3 Months" ||
      dateSelectionBtn == "Last Month" ||
      dateSelectionBtn == "Current Month"
    ) {
      dispatch(
        getInvoiceNumber({
          payoutStartDate: payoutStartDate,
          payoutEndDate: payoutEndDate,
        })
      );
    }
  }, [dispatch, getInvoiceNumber, dateSelectionBtn, applied, range]);

  useEffect(() => {
    setSelectedOptions([]);
  }, [dateSelectionBtn]);

  const handleSelectionChange = (event: any, newValue: any) => {
    if (newValue !== null) {
      const newSelectedOption = newValue[newValue.length - 1];
      setSelectedOptions([
        ...selectedOptions,
        {
          invoiceNumber: newSelectedOption.invoiceNumber,
          loanId: newSelectedOption.loanId,
          lateFees: newSelectedOption.lateFees,
          dueDate: newSelectedOption.dueDate,
        },
      ]);
    }
  };

  const handleGenerateReport = async () => {
    //SEGMENT PART
    //when user clicks on the generate report button
    const pageData = {
      source_page: "reports_tab",
      landing_page: "reports_tab",
      event_type: "click",
      tab: "generate",
      report_name: "late_fees_details",
      payload: {
        date_range: `${format(range[0].startDate, "dd MMM yyyy")} - ${format(
          range[0].endDate,
          "dd MMM yyyy"
        )}`,
        selected_range_btn: dateSelectionBtn,
        invoices: selectedOptions,
      },
    };

    trackAnalytics("generate_report_btn", pageData);
    const lateFeeResponse = dispatch(
      await getGenerateLateFeeReport({
        invoiceList: selectedOptions,
      })
    );

    if ((await lateFeeResponse).payload.response_code == 80) {
      // setDateSelectionBtn("Last 3 Months");
      setShowToast(true);
      // setApplied(false);
      // setRange([
      //   {
      //     startDate: startOfMonth(subMonths(new Date(), 3)),
      //     endDate: endOfMonth(subMonths(new Date(), 1)),
      //     key: "selection",
      //   },
      // ]);
      // setSelectedOptions([]);
    }
  };

  return (
    <Fragment>
      <Grid item>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <Grid
              container
              direction="row"
              sx={{ gap: "24px", alignItems: "center" }}
            >
              <Grid item>
                <LightTooltip
                  title={config.Tooltip?.dueDateRangeReport}
                  style={{ cursor: "pointer" }}
                  placement="bottom-start"
                >
                  <Typography fontWeight={600} color={KALA_70}>
                    Due Date Range{" "}
                    <sup>
                      <HelpIcon
                        sx={{
                          width: "10px",
                          height: "10px",
                          color: KALA_70,
                        }}
                      />
                    </sup>
                  </Typography>
                </LightTooltip>
              </Grid>
              <DateRangeReport
                applied={applied}
                setApplied={setApplied}
                openDateRange={openDateRange}
                setOpenDateRange={setOpenDateRange}
                dateSelectionBtn={dateSelectionBtn}
                setDateSelectionBtn={setDateSelectionBtn}
                range={range}
                setRange={setRange}
                calenderFilterBy={calenderFilterBy}
                setCalenderFilterBy={setCalenderFilterBy}
                reportFilter={"LateFeeReport"}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1} justifyContent={"space-between"}>
              <Grid item xs={9.7}>
                {!loading && response?.buyerInvoiceDtoList?.length > 0 ? (
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    value={selectedOptions}
                    options={
                      loading
                        ? []
                        : response?.buyerInvoiceDtoList
                        ? response.buyerInvoiceDtoList.filter(
                            (option: Option) =>
                              option?.invoiceNumber &&
                              !selectedOptions.some(
                                (selectedOption: Option) =>
                                  selectedOption.loanId === option.loanId
                              )
                          )
                        : []
                    }
                    onChange={handleSelectionChange}
                    size="small"
                    clearIcon={null}
                    popupIcon={<KeyboardArrowDownRoundedIcon />}
                    sx={{
                      "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
                        padding: "4px 15px",
                        minHeight: "48px",
                        marginBottom: "16px",
                      },
                    }}
                    getOptionLabel={(option: any) =>
                      `${option?.invoiceNumber} (Late fees charged: ₹${option?.lateFees})`
                    }
                    renderTags={(value, getTagProps) =>
                      value &&
                      value.map((option: any, index) => {
                        return (
                          <LightTooltip
                            title={
                              <Grid
                                container
                                direction={"column"}
                                style={{ textAlign: "start" }}
                              >
                                <Grid item>
                                  <div>Due Date: {option?.dueDate}</div>
                                </Grid>
                                <Grid item>
                                  <div>
                                    Late Fee Charged: ₹{option?.lateFees}
                                  </div>
                                </Grid>
                              </Grid>
                            }
                            style={{ cursor: "pointer" }}
                            placement="bottom-start"
                          >
                            <Chip
                              variant="outlined"
                              label={option?.invoiceNumber}
                              onDelete={() => {
                                const filteredOptions = selectedOptions.filter(
                                  (selectedOption: any) =>
                                    selectedOption.loanId !== option.loanId
                                );
                                setSelectedOptions(filteredOptions);
                              }}
                              size="small"
                              style={{
                                borderRadius: "8px",
                                padding: "8px",
                                gap: "8px",
                                color: KALA_70,
                                margin: "2px",
                                height: "28px",
                                fontWeight: "500",
                              }}
                            />
                          </LightTooltip>
                        );
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        defaultValue="Select Invoices"
                        placeholder={
                          selectedOptions.length <
                          response.buyerInvoiceDtoList.length
                            ? "Select Invoices"
                            : undefined
                        }
                        // onKeyPress={handleKeyPress}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <InputAdornment
                                position="start"
                                sx={{
                                  display: "flex",
                                }}
                              >
                                <DescriptionIcon
                                  sx={{
                                    width: "24px",
                                    height: "24px",
                                    color: "#52575C",
                                  }}
                                />
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: "1px solid #CACCCF",
                            },
                          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              border: "1px solid #CACCCF",
                            },
                        }}
                      />
                    )}
                  />
                ) : (
                  <TextField
                    variant="outlined"
                    size="small"
                    defaultValue="No Invoices Found"
                    fullWidth
                    disabled
                    InputProps={{
                      startAdornment: (
                        <>
                          <InputAdornment position="start">
                            <DescriptionIcon
                              sx={{
                                width: "24px",
                                height: "24px",
                                color: "#52575C",
                              }}
                            />
                          </InputAdornment>
                        </>
                      ),
                      endAdornment: (
                        <>
                          <InputAdornment
                            position="end"
                            style={{ gap: "16px" }}
                          >
                            <ErrorIcon
                              sx={{
                                width: "24px",
                                height: "24px",
                                color: MIRCHI_70,
                              }}
                            />
                            <KeyboardArrowDownRoundedIcon />
                          </InputAdornment>
                        </>
                      ),
                    }}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={2.3}
                sx={{
                  width: "fit-content",
                }}
              >
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    onClick={handleGenerateReport}
                    id="generate-form-btn"
                    style={{
                      borderRadius: "8px",
                      padding: "8px 16px",
                      gap: "8px",
                    }}
                    disabled={selectedOptions?.length == 0 || lateFeeLoading}
                  >
                    Generate Report
                    {lateFeeLoading ? (
                      <CircularProgress
                        color="inherit"
                        style={{ width: "20px", height: "20px" }}
                      />
                    ) : (
                      <ArrowForwardIcon fontSize="small" />
                    )}
                  </Button>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default LateFeeFormReport;
