interface Props {
  width?: string;
  height?: string;
  style?: React.CSSProperties;
}

const Reports = (props: Props) => {
  const { width = 24, height = 24, style = {} } = props;
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V6L16 2ZM11 18V14H9L13 8V12H15L11 18ZM16.2 6.8C15.6 6.8 15.2 6.3 15.2 5.8V3.2L18.8 6.8H16.2Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Reports;
