import { Grid, Typography } from "@mui/material";
import NoDataSVG from "../SVG/NoDataSVG";

export default function NoData() {
  return (
    <Grid container sx={{}}>
      <Grid item xs={12} sx={{ padding: "2rem 0" }}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} sx={{}}>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "center",
                color: "info.dark",
              }}
            >
              No new notifications at the
              <br />
              moment. Stay tuned for updates!
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <NoDataSVG />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
