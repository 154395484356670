import React, { useEffect, useState, Fragment, useMemo } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, Tab, Tabs, Typography } from "@mui/material";
import { AppDispatch, RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetails } from "../financeApi";
import { OrderDetailsState } from "./orderDetailsSlice";
import pluralize from "pluralize";
import OrderDetailsSkelton from "./OrderDetailsSkelton";
import { DisbursementDetails } from "./DisbursementDetails";
import OrderDetailsTab from "./OrderDetailsTab";
import { TabContext, TabPanel } from "@mui/lab";
import queryString from "query-string";
import { trackAnalytics } from "../../../Analytics";
import { ConfigState } from "../../../features/maintenance/maintenanceSlice";

interface Props {
  children: React.ReactNode;
  orderId?: string | number;
  loanId?: string | number;
  defaultState?: boolean;
  onClose?: () => void;
  source?: string;
}

export default function OrderDetails(props: Props) {
  const [open, setOpen] = useState(props.defaultState || false);

  useEffect(() => {
    addEventListener("popstate", (event) => {
      setOpen(false);
    });

    return () => {
      removeEventListener("popstate", (event) => {});
    };
  }, []);

  const query: any = React.useMemo(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const [currentTab, setCurrentTab] = useState(query.tab || "orderDetails");

  const dispatch: AppDispatch = useDispatch();

  const { currentOrder, loading, error }: OrderDetailsState = useSelector(
    (state: RootState) => state.orderDetails
  );

  const { mobileNumber, response }: any = useSelector(
    (state: RootState) => state.mobileNum
  );

  const { orderId, loanId, source } = props;
  const { config }: ConfigState = useSelector(
    (state: RootState) => state.config
  );

  useEffect(() => {
    if (open && orderId) {
      dispatch(getOrderDetails({ orderId }));
    }
    if (open && loanId) {
      dispatch(getOrderDetails({ loanId }));
    }
  }, [open, orderId, loanId]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (error && open) {
      setOpen(false);
    }
  }, [error]);

  const handleClose = () => {
    setOpen(false);
  };

  const {
    buyerInvoiceNumber,
    dueDate,
    orderId: apiOrderID,
    totalOutAmount,
    dueInDays,
  } = currentOrder;

  const dueDateText = useMemo(() => {
    if (totalOutAmount <= 0) {
      return "";
    }
    if (dueInDays === 0) {
      return (
        <Typography variant="subtitle2">This order is due today</Typography>
      );
    } else if (dueInDays < 0) {
      return (
        <Typography variant="subtitle2">
          This order will be due in{" "}
          <span style={{ fontWeight: 700 }}>
            {Math.abs(dueInDays)} {pluralize("day", dueInDays)}
          </span>{" "}
        </Typography>
      );
    } else {
      return (
        <Typography variant="subtitle2">
          This order is due by{" "}
          <span style={{ fontWeight: 700 }}>
            {Math.abs(dueInDays)} {pluralize("day", dueInDays)}
          </span>
        </Typography>
      );
    }
  }, [dueInDays, dueDate]);

  const handleSetCurrentTab = (
    event: React.ChangeEvent<{}>,
    newValue: string
  ) => {
    //SEGMENT PART
    //when user lands on orders details popup from orders tab or payment history tab or deeplink and when user switch tabs inside order_details_popup
    const pageData = {
      source_page: "order_details_popup",
      landing_page: "order_details_popup",
      event_type: "click",
      popup_tab: newValue,
      tab_clicked_on: newValue,
    };
    trackAnalytics("order_details_popup_tab_click", pageData);
    const pageD = {
      source_page: "order_details_popup",
      landing_page: "order_details_popup",
      event_type: "pageload",
      popup_tab: newValue,
    };

    trackAnalytics("order_details_popup_pageload", pageD);
    setCurrentTab(newValue);
  };
  //SEGMENT PART
  //when user lands on orders details popup from orders tab or payment history tab or deeplink and when user switch tabs inside order_details_popup
  useEffect(() => {
    let pageData;
    if (query.utm_source && query.tab) {
      pageData = {
        source_page: "deeplink",
        landing_page: "order_details_popup",
        event_type: "pageload",
        popup_tab: currentTab,
      };
    } else if (!query.utm_source && query.tab) {
      pageData = {
        source_page: "notification",
        landing_page: "order_details_popup",
        event_type: "pageload",
        popup_tab: currentTab,
      };
    } else {
      pageData = {
        source_page: source,
        landing_page: "order_details_popup",
        event_type: "pageload",
        popup_tab: currentTab,
      };
    }
    if (open) {
      trackAnalytics("order_details_popup_pageload", pageData);
    }
  }, [location, open]);

  return (
    <Fragment>
      <Box onClick={handleClickOpen}>{props.children}</Box>
      <Dialog
        fullWidth
        maxWidth={"lg"}
        open={open}
        onClose={props.onClose ? props.onClose : handleClose}
        PaperProps={{ sx: { borderRadius: "16px" } }}
      >
        {loading ? (
          <OrderDetailsSkelton />
        ) : (
          <>
            <TabContext value={currentTab}>
              <DialogContent sx={{ mt: 0, p: 0 }}>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    padding: "1rem",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "1rem",
                  }}
                >
                  <Grid item xs={12} sx={{ width: "100%" }}>
                    <Grid
                      container
                      alignItems={"center"}
                      sx={{ padding: "0 1rem" }}
                    >
                      <Grid item xs={10}>
                        <Grid container>
                          <Tabs
                            value={currentTab}
                            onChange={handleSetCurrentTab}
                            aria-label="wrapped label tabs example"
                            sx={{
                              minHeight: "0px",
                              "& .MuiButtonBase-root": {
                                minHeight: "2.5rem",
                              },
                            }}
                          >
                            <Tab
                              value={"orderDetails"}
                              sx={{
                                padding: "0",
                                marginRight: "3rem",
                                fontWeight: 600,
                                fontSize: "1.25rem",
                                lineHeight: "1.75rem",
                                textTransform: "none",
                                borderBottomWidth: "3px",
                              }}
                              label="Order Details"
                            />
                            {config?.disbursementDetailsFlag && (
                              <Tab
                                value={"disbursementDetails"}
                                sx={{
                                  padding: "0",
                                  fontWeight: 600,
                                  fontSize: "1.25rem",
                                  lineHeight: "1.75rem",
                                  borderBottomWidth: "3px",
                                  textTransform: "none",
                                }}
                                label="Disbursement Details"
                              />
                            )}
                          </Tabs>
                        </Grid>
                      </Grid>
                      <Grid item xs={2} sx={{}}>
                        <CloseIcon
                          onClick={props.onClose ? props.onClose : handleClose}
                          style={{ cursor: "pointer", float: "right" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={2}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      sx={{ padding: "0 1rem" }}
                    >
                      <Grid item sx={{ display: "flex", columnGap: 1 }}>
                        <Typography sx={{ color: "info.dark" }}>
                          Invoice Number:
                        </Typography>
                        <Typography
                          sx={{ color: "info.main", fontWeight: 600 }}
                        >
                          {buyerInvoiceNumber === "NA" || !buyerInvoiceNumber
                            ? "N/A"
                            : buyerInvoiceNumber}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sx={{ display: "flex", margin: "0 24px", columnGap: 1 }}
                      >
                        <Typography sx={{ color: "info.dark" }}>
                          Order ID:
                        </Typography>
                        <Typography
                          sx={{ color: "info.main", fontWeight: 600 }}
                        >
                          {apiOrderID}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          color: dueInDays > 0 ? "error.main" : "#114D96",
                          columnGap: "4px",
                        }}
                        alignItems={"center"}
                      >
                        {currentTab === "orderDetails" &&
                        "dueInDays" in currentOrder ? (
                          <>
                            {totalOutAmount <= 0 ? (
                              ""
                            ) : (
                              <InfoRoundedIcon style={{ marginRight: "4px" }} />
                            )}
                            <Typography>{dueDateText}</Typography>
                          </>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} width={"100%"}>
                    <TabPanel
                      value={"orderDetails"}
                      sx={{ padding: 0 }}
                      tabIndex={0}
                    >
                      <OrderDetailsTab />
                    </TabPanel>
                    {config?.disbursementDetailsFlag && (
                      <TabPanel
                        value={"disbursementDetails"}
                        sx={{ padding: 0 }}
                        tabIndex={1}
                      >
                        <DisbursementDetails
                          invoiceNumber={buyerInvoiceNumber}
                          orderId={orderId}
                          loanId={loanId}
                        />
                      </TabPanel>
                    )}
                  </Grid>
                </Grid>
              </DialogContent>
            </TabContext>
          </>
        )}
      </Dialog>
    </Fragment>
  );
}
