import { Grid, InputAdornment, TextField } from "@mui/material";
import AppLayout from "../layouts/app";
import PaymentHistory from "../components/PaymentHistory";
import SearchUtrField from "../components/PaymentHistory/SearchUtrField";
import { useDispatch, useSelector } from "react-redux";
import {
  PaymentState,
  updateFilters,
  initialState,
} from "../components/PaymentHistory/paymentHistorySlice";
import * as React from "react";
import { AppDispatch, RootState } from "../store";
import { useNavigate, useLocation } from "react-router-dom";
import { analytics, trackAnalytics } from "../Analytics";
import queryString from "query-string";

const PaymentHistoryPage = () => {
  const { list, loading, filters, count }: PaymentState = useSelector(
    (state: RootState) => state.paymentHistory
  );

  const { mobileNumber, response }: any = useSelector(
    (state: RootState) => state.mobileNum
  );
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();

  const query: any = React.useMemo(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  //SEGMENT PART
  //when user lands on payment history tab
  React.useEffect(() => {
    const prevRoute = location?.state?.prevRoute;
    let pageData;
    if (query.tab && query.utm_source) {
      pageData = {
        source_page: "deeplink",
        landing_page: "payment_history_tab",
        event_type: "pageload",
      };
    } else if (prevRoute) {
      pageData = {
        source_page:
          (prevRoute == "/orders" && "orders_tab") ||
          (prevRoute == "/payments" && "payment_history_tab") ||
          (prevRoute == "/reports" && "reports_tab"),
        landing_page: "payment_history_tab",
        event_type: "pageload",
      };
    }
    if (pageData) {
      trackAnalytics("payment_history_tab_pageload", pageData);
    }
  }, [location]);

  return (
    <AppLayout
      window={undefined}
      heading="Finance"
      searchField={<SearchUtrField />}
    >
      <Grid container spacing={2} mt={0}>
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          <PaymentHistory />
        </Grid>
      </Grid>
    </AppLayout>
  );
};

export default PaymentHistoryPage;
