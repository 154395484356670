import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { Grid, Typography } from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";
import { HelpState } from "./poSlice";
import POForm from "./POForm";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { addPO } from "./poApi";
import { getPurchaseOrder } from "../POManagement/poManagementApi";
import { POState, updateFilters } from "../POManagement/poManagementSlice";
import { QUANTITY_UNITS } from "../NoData/constants";

const validationSchema = yup.object({
  poNumber: yup.string().required("Please enter a Purchase Order Number"),
  imageList: yup
    .array()
    .of(yup.string())
    .required("Please upload a document")
    .min(1, "Please upload a document"),
  comment: yup.string().max(300, "Please limit your input to 300 characters"),
  priceUnit: yup.string(),
  price: yup
    .string()
    .nullable()
    .test(
      "unit-required",
      "Please select a unit of measurement.",
      function (value) {
        const priceFieldValue = this.resolve(yup.ref("price"));
        const priceUnitFieldValue = this.resolve(yup.ref("priceUnit"));
        if (
          priceFieldValue !== undefined &&
          priceFieldValue !== null &&
          !priceUnitFieldValue
        ) {
          return false;
        }
        return true;
      }
    ),
});

export default function AddPO(props: any) {
  const dispatch: AppDispatch = useDispatch();

  const { list, loading, filters, count }: POState = useSelector(
    (state: RootState) => state.purchaseOrder
  );

  const handleSubmit = async (values: any) => {
    values.unitId = QUANTITY_UNITS.find(
      (item) => item.name == values.priceUnit
    )?.id;
    values.commodityName = values?.commodity?.name;
    values.commodityId = values?.commodity?.id;
    values.varietyName = values?.variety?.name;
    values.varietyId = values?.variety?.id;
    const response = await dispatch(addPO(values));
    if (response.payload.response_code == 80) {
      props.onClose();
    }
  };

  const {
    comment,
    commodityName,
    varietyName,
    poNumber,
    price,
    priceUnit,
    tenure,
  }: HelpState = useSelector((state: RootState) => state.addPurchaseOrder);

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid
        item
        xs={12}
        style={{
          paddingBottom: "20px",
          backgroundColor: "white",
          position: "sticky",
          top: 0,
          zIndex: 5,
          gap: "24px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography fontWeight={700} variant="h3">
            Add Purchase Order
          </Typography>
          <ClearOutlinedIcon
            style={{
              cursor: "pointer",
              justifyContent: "right !important",
              marginRight: 0,
            }}
            onClick={props.onClose}
          />
        </div>
      </Grid>
      <Grid item xs={12} style={{ zIndex: 0 }}>
        <Typography variant="subtitle1" fontWeight={600}>
          Purchase Order Document(s) *
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Formik
          initialValues={{
            comment,
            commodityName,
            poNumber,
            price,
            priceUnit,
            varietyName,
            tenure,
            imageList: [],
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {() => <POForm {...props} />}
        </Formik>
      </Grid>
    </Grid>
  );
}
