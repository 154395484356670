import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import {
  Button,
  Checkbox,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { DateDropDownMenu } from "../Finance/CustomCalender";
import POManagementSort from "./poManagementSort";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddPurchaseOrder from "./addPurchaseOrder";
import { POState, updateFilters } from "./poManagementSlice";
import POStateMenu from "./poStateMenu";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
  },
}));

function POManagementFilters() {
  const [poState, setPOState] = React.useState(true);
  const [createdDateState, setCreatedDateState] = React.useState(true);
  const [poStateBy, setPOStateBy] = React.useState("");
  const [calenderFilterBy, setCalenderFilterBy] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch: AppDispatch = useDispatch();
  const [addPODrawerOpen, setAddPODrawerOpen] = React.useState(false);
  const { filters, count }: POState = useSelector(
    (state: RootState) => state.purchaseOrder
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterCreationDate = (
    startDate: string | null,
    endDate: string | null
  ) => {
    dispatch(
      updateFilters({
        startCreateDate:
          startDate === null ? null : moment(startDate).format("YYYY-MM-DD"),
        endCreateDate:
          endDate === null ? null : moment(endDate).format("YYYY-MM-DD"),
        pageNo: 0,
      })
    );
  };

  useEffect(() => {
    if (filters.purchaseOrderNumber != null) {
      clearFilters();
    } else {
      setPOState(true);
      setCreatedDateState(true);
      setCalenderFilterBy("");
    }
  }, [filters.purchaseOrderNumber]);

  const clearFilters = () => {
    setCreatedDateState(false);
    setPOState(false);
    setCalenderFilterBy("");
    dispatch(
      updateFilters({
        ...filters,
        purchaseStatus: "",
        startCreateDate: null,
        endCreateDate: null,
        pageNo: 0,
      })
    );
  };

  const handlePOStateCheckbox = () => {
    setPOState(!poState);
    if (poState) {
      dispatch(
        updateFilters({
          purchaseStatus: null,
        })
      );
    }
  };

  const handleCreatedDateCheckbox = () => {
    setCreatedDateState(!createdDateState);
    if (createdDateState) {
      setCalenderFilterBy("");
      dispatch(
        updateFilters({
          ...filters,
          startCreateDate: null,
          endCreateDate: null,
          pageNo: 0,
        })
      );
    }
  };

  return (
    <div>
      <Grid
        width="100%"
        container
        alignItems="center"
        my={2}
        px={4}
        py={4}
        justifyContent="space-between"
      >
        <Grid item style={{ padding: 0 }}>
          <Typography variant="h6" sx={{ color: "info.main", fontWeight: 600 }}>
            Purchase Orders
          </Typography>
        </Grid>
        <Grid item style={{ padding: 0 }}>
          <div style={{ display: "flex", columnGap: 16 }}>
            <AddPurchaseOrder />
            <POManagementSort
              disabled={
                filters.purchaseOrderNumber == null &&
                !createdDateState &&
                !poState &&
                count === 0
              }
            />
            <Button
              variant="outlined"
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownRoundedIcon />}
              color="info"
              disabled={
                filters.purchaseOrderNumber == null &&
                !createdDateState &&
                !poState &&
                count === 0
              }
              sx={{
                color: "info.dark",
                minWidth: "180px",
                justifyContent: "space-between",
              }}
            >
              <Grid container>
                <Grid item paddingRight="6px">
                  <FilterAltRoundedIcon />
                </Grid>
                <Grid item>Filters</Grid>
              </Grid>
            </Button>
            <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
              <MenuItem onClick={handlePOStateCheckbox}>
                <Checkbox checked={poState} />
                <ListItemText primary={"PO State"} />
              </MenuItem>
              <MenuItem onClick={handleCreatedDateCheckbox}>
                <Checkbox checked={createdDateState} />
                <ListItemText primary={"Created Date"} />
              </MenuItem>
            </StyledMenu>
          </div>
        </Grid>
      </Grid>
      {createdDateState || poState ? (
        <Grid container alignItems="center" px={4} mb={2} width="100%">
          <Grid item xs={9}>
            <Grid container alignItems="center" spacing={3}>
              <Grid item>
                <Typography>Selected Filters:</Typography>
              </Grid>
              {poState ? (
                <Grid item>
                  <POStateMenu
                    filterBy={poStateBy}
                    setFilterBy={setPOStateBy}
                  />
                </Grid>
              ) : null}
              {createdDateState ? (
                <Grid item>
                  <DateDropDownMenu
                    buttonName={"Created Date"}
                    futureOptions={false}
                    filterDispatchCall={filterCreationDate}
                    filterBy={calenderFilterBy}
                    setFilterBy={setCalenderFilterBy}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={3} textAlign="right">
            <Button
              variant="text"
              onClick={clearFilters}
              sx={{ columnGap: 1, color: "info.dark" }}
            >
              <CloseRoundedIcon />
              <Typography variant="subtitle2">Clear All Filters</Typography>
            </Button>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </div>
  );
}

export default POManagementFilters;
