import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddPO from "../AddPO/AddPO";
import { KARELA_70 } from "../../styles/colors";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { UserDetailsState } from "../UserProfile/userSlice";

type Anchor = "top" | "left" | "bottom" | "right";

export default function AddPurchaseOrder() {
  const { response }: UserDetailsState = useSelector(
    (state: RootState) => state.userDetails
  );
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: 500, padding: "0 1.5rem" }}
      role="presentation"
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <AddPO onClose={toggleDrawer("right", false)} />
    </Box>
  );
  if (!response?.poCreateAccess) {
    return <></>;
  }
  return (
    <React.Fragment key={"right"}>
      <Button
        variant="contained"
        onClick={toggleDrawer("right", true)}
        sx={{
          backgroundColor: KARELA_70,
          borderRadius: "8px",
          display: "flex",
          justifyContent: "space-between",
          padding: "8px 16px",
          color: "white",
          cursor: "pointer",
        }}
      >
        <AddCircleIcon fontSize="small" />
        <Grid item paddingLeft="6px">
          Add Purchase Order
        </Grid>
      </Button>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        {list("right")}
      </Drawer>
    </React.Fragment>
  );
}
