import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getNotifications } from "./notificationApi";
import _ from "lodash";
import {
  getToastNotifications,
  markAsReadToastNotifications,
} from "../../layouts/app/appAPI";

export interface NotificationsState {
  response: any;
  loading: boolean;
  error?: string;
  dataList: any[];
}

export const initialState: NotificationsState = {
  loading: false,
  error: "",
  response: {},
  dataList: [],
};

export const notificationsSlice = createSlice({
  name: "help",
  initialState,
  reducers: {},
  extraReducers: {
    [getNotifications.pending.type]: (state, action) => {
      state.loading = true;
      state.error = "";
      state.response = {};
      if (action.meta.arg.pageNo === 0) {
        state.dataList = [];
      }
    },
    [getNotifications.fulfilled.type]: (state, action) => {
      const { dataList, ...rest } = action.payload;
      state.loading = false;
      state.error = "";
      state.dataList = [...(state.dataList || []), ...(dataList || [])];
      state.response = {
        ...rest,
        dataList: _.uniqBy(
          [...(state.response.dataList || []), ...(dataList || [])],
          "notificationRefId"
        ),
      };
    },
    [getNotifications.rejected.type]: (state, action) => {
      state.loading = false;
      state.dataList = [];
      state.error = action.payload || "Something went wrong";
    },
    [markAsReadToastNotifications.fulfilled.type]: (state, action) => {
      const index = _.findIndex(state.dataList, {
        notificationRefId: action.meta.arg,
      });
      console.log("index", index);
      console.log("action", state.dataList);
      state.dataList[index] = {
        ...state.dataList[index],
        read: true,
      };
    },
    [getToastNotifications.fulfilled.type]: (state, action) => {
      state.response.redIcon = action.payload.redIcon;
    },
  },
});

// Action creators are generated for each case reducer function

export default notificationsSlice.reducer;
