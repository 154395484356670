import { Grid, Typography } from "@mui/material";
import AuthLayout from "../layouts/auth";
import VerifyOTP from "../components/Login/VerifyOTP";
import * as yup from "yup";
import { Formik } from "formik";
import BijakLogo from "../components/Login/BijakLogo";
import ArrowBack from "../components/Login/ArrowBack";
import { AuthState } from "../components/Login/LoginModuleSlice";
import CreatePassword from "./CreatePassword";
import Loader from "../components/Loader";
import { RootState, AppDispatch } from "../store";
import { postLoginOtp } from "../components/Login/loginApi";
import { useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ResetPassword from "./ResetPassword";
import { analytics, trackAnalytics } from "../Analytics";
import { useLocation } from "react-router-dom";

const validationSchema = yup.object({
  otp: yup
    .string()
    .min(6, "Please enter a valid OTP to continue")
    .required("OTP is required"),
});

function PasswordOTPVerification() {
  const dispatch: AppDispatch = useDispatch();
  const { state, loading: loginOtpVerifyLoading } = useSelector(
    (state: RootState) => state.loginOtpVerify
  );
  const location = useLocation();

  const { mobileNumber, response } = useSelector(
    (state: RootState) => state.mobileNum
  );

  //SEGMENT PART
  //When Enter OTP screen is loaded
  useEffect(() => {
    const pageData = {
      source_page: "password_input_screen",
      landing_page: "otp_input_screen",
      event_type: "pageload",
      Mobile_Number: mobileNumber,
    };

    trackAnalytics("otp_pageload", pageData);
  }, [location]);

  const handleSubmit = (values: any) => {
    const { otp } = values;
    const buyerId = response.buyerId;
    dispatch(postLoginOtp({ mobileNumber, otp, buyerId }));
    //SEGMENT PART
    //When user click on verify otp button in enter OTP screen
    const pageData = {
      source_page: "reset_password_otp_screen",
      landing_page: "create_password_screen",
      event_type: "click",
      Mobile_Number: mobileNumber,
      buyer_ptr_id: undefined,
    };

    trackAnalytics("btn_verify_otp", pageData);
  };

  const { loading: loginLoading } = useSelector(
    (state: RootState) => state.login
  );

  const renderAuth = useMemo(() => {
    switch (state) {
      case AuthState.VerifyOtp:
        return (
          <Grid container spacing={3}>
            <BijakLogo />
            <Grid item xs={12}>
              <Typography
                className="font-bold"
                color="primary.main"
                variant="h2"
                mb={1}
              >
                <ArrowBack url="/login" />
                Verify OTP to reset Password
              </Typography>
              <Typography
                className="font-bold"
                color="info.light"
                variant="body2"
              >
                Enter OTP Sent to +91 {mobileNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} mt={1}>
              <Formik
                initialValues={{
                  otp: "",
                }}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values) => handleSubmit(values)}
              >
                {() => <VerifyOTP />}
              </Formik>
            </Grid>
          </Grid>
        );
      case AuthState.SetPassword:
        return <ResetPassword />;
    }
  }, [state]);

  return (
    <>
      <Loader loading={loginOtpVerifyLoading || loginLoading} />
      {renderAuth}
    </>
  );
}
export default PasswordOTPVerification;
