import { TableRow, styled } from "@mui/material";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "primary.light",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "secondary.light",
  },
}));

export default StyledTableRow;
