import { Grid, Typography } from "@mui/material";
import ValueSkelton from "../../Skelton/ValueSkelton";

const GenerateReportTableSkeleton = () => {
  return (
    <>
      {[...Array(10)].map((_, index) => (
        <Grid
          container
          direction="row"
          sx={{
            padding: "16px",
            backgroundColor:
              index % 2 == 0 ? "primary.contrastText" : "secondary.light",
            borderRadius: "4px",
            height: "96px",
          }}
        >
          <Grid item xs={10}>
            <Grid container direction="column" width={"100%"}>
              <Grid item>
                <ValueSkelton minWidth={"20%"}/>
              </Grid>
              <Grid item>
                <ValueSkelton minWidth={"40%"} />
              </Grid>
              <Grid item>
                <ValueSkelton minWidth={"100%"} />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                backgroundColor: "#E8E8E8",
                borderRadius: "24px",
                padding: "4px 8px",
                boxSizing: "content-box",
                justifyContent: "space-between",
                gap: "4px",
              }}
            >
              <Typography>
                <ValueSkelton minWidth={"20px"} variant={"circular"} />
              </Typography>
              <Typography>
                <ValueSkelton minWidth={"76px"} />
              </Typography>
            </div>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default GenerateReportTableSkeleton;
