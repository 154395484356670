import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { logoutUser } from "./logoutApi";
import { errorMessage } from "../../utils";


export interface LogoutSate {
  loading: boolean;
  error?: string;
  response?: any;
}

export const initialState: LogoutSate = {
  loading: false,
  error: "",
  response: null
};

export const logoutSlice = createSlice({
  name: "logOut",
  initialState,
  reducers: {},
  extraReducers: {
    [logoutUser.pending.type]: (state) => {
      state.loading = true;
      state.error = "";
    },
    [logoutUser.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.response = action.payload;
    },
    [logoutUser.rejected.type]: (state, action) => {
      state.response = null;
      state.loading = false;
      state.error = action.payload || "Something went wrong";
      errorMessage(action.payload || "Something went wrong")
    },
  },
});

// Action creators are generated for each case reducer function

export default logoutSlice.reducer;
