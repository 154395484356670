import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../api";

export const postHelp = createAsyncThunk(
  "data/submitHelpForm",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await API.post("/login/help", { ...payload });
      return response.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data.error);
    }
  }
);
