import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { POState, updateFilters, initialState } from "./poManagementSlice";
import React from "react";

const SearchPONumber = () => {
  const { loading, filters }: POState = useSelector(
    (state: RootState) => state.purchaseOrder
  );
  const [searchText, setSearchText] = React.useState<string>(
    filters.purchaseOrderNumber || ""
  );
  const dispatch: AppDispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleClick = () => {
    if (searchText !== filters.purchaseOrderNumber) {
      dispatch(
        updateFilters({ pageNo: 0, purchaseOrderNumber: searchText || null })
      );
    }
  };

  const handleClose = () => {
    setSearchText("");
    dispatch(updateFilters({ pageNo: 0, purchaseOrderNumber: null }));
  };

  const handleSearch = (event: any) => {
    if (
      event.key === "Enter" &&
      searchText !== filters.purchaseOrderNumber
    )
      dispatch(
        updateFilters({ pageNo: 0, purchaseOrderNumber: searchText || null })
      );
  };

  React.useEffect(() => {
    if (filters.purchaseOrderNumber == null && searchText) {
      setSearchText("");
    }
  }, [filters.purchaseOrderNumber]);

  React.useEffect(() => {
    return () => {
      setSearchText("");
      dispatch(updateFilters({ ...initialState.filters }));
    };
  }, []);

  return (
    <TextField
      disabled={loading}
      variant="outlined"
      placeholder="Search PO Number"
      fullWidth
      autoComplete="off"
      name="tech_id"
      value={searchText}
      onChange={handleChange}
      sx={{
        bgcolor: "white",
        maxWidth: "360px",
      }}
      onKeyDown={handleSearch}
      onKeyUp={handleSearch}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {filters?.purchaseOrderNumber &&
            filters?.purchaseOrderNumber === searchText ? (
              <CloseIcon
                onClick={handleClose}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    color: "primary.main",
                  },
                  "&:clicked": {
                    color: "primary.main",
                  },
                }}
              />
            ) : (
              <SearchIcon
                onClick={handleClick}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    color: "primary.main",
                  },
                  "&:clicked": {
                    color: "primary.main",
                  },
                }}
              />
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchPONumber;
