import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { Box, Paper, Typography } from "@mui/material";
import { BLUE } from "../../styles/colors";
import POManagementTable from "./poManagementTable";
import POManagementFilters from "./poManagementFilters";

const PurchaseOrderM = () => {
  return (
    <Paper
      sx={{
        width: "97%",
        m: "0 auto",
        mt: 5,
        borderRadius: 4,
        minHeight: "85vh",
        mb: "20px",
      }}
    >
      <POManagementFilters />
      <POManagementTable />
    </Paper>
  );
};

export default PurchaseOrderM;
