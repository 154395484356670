import DialogContent from "@mui/material/DialogContent";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Grid,
  Typography,
  Box,
  Tooltip,
  styled,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import ValueSkelton from "../../Skelton/ValueSkelton";
import OrderDivider from "../../../assets/images/order-border.png";

const ORDER_DIVIDER_STYLE = {
  borderStyle: "solid",
  borderImageSource: `url(${OrderDivider})`,
  borderImageRepeat: "repeat",
  borderImageWidth: "0px 0px 2px 0px",
  borderImageSlice: "0 0 4 0",
};

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [` .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.info.main,
    color: "#ffffff",
    boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.16)",
    fontSize: "0.875rem",
    fontWeight: 600,
    lineHeight: "1.25rem",
    padding: "0.375rem 0.75rem",
    borderRadius: "0.25rem",
    marginRight: "8px !important",
  },
}));

export default function OrderDetailsSkelton() {
  return (
    <>
      <DialogTitle>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item xs={2}>
            <Typography fontSize={20} fontWeight={600}>
              Order Details
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  color: "error.main",
                  columnGap: "4px",
                }}
                alignItems={"center"}
              >
                <Typography sx={{ display: "flex" }}>
                  <InfoRoundedIcon style={{ marginRight: "4px" }} />
                  <ValueSkelton />
                </Typography>
              </Grid>
              <Grid item sx={{ display: "flex", columnGap: 1 }}>
                <Typography sx={{ color: "info.dark" }}>
                  Invoice Number:
                </Typography>
                <Typography
                  sx={{
                    color: "info.main",
                    fontWeight: 600,
                  }}
                >
                  <ValueSkelton />
                </Typography>
              </Grid>
              <Grid item sx={{ display: "flex", columnGap: 1 }}>
                <Typography sx={{ color: "info.dark" }}>Order ID:</Typography>
                <Typography sx={{ color: "info.main", fontWeight: 600 }}>
                  <ValueSkelton />
                </Typography>
              </Grid>
              <Grid item>
                <CloseIcon style={{ cursor: "pointer" }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={8} paddingRight={"32px"} style={{ paddingTop: 0 }}>
            <Grid
              container
              alignItems="center"
              sx={ORDER_DIVIDER_STYLE}
              height={"84px"}
            >
              <Grid item xs={4}>
                <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
                  Disbursement Date
                </Typography>
                <Typography
                  sx={{ color: "info.main", fontWeight: 500 }}
                  variant="subtitle1"
                >
                  <ValueSkelton />
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
                  Due Date
                </Typography>
                <Typography
                  sx={{ color: "info.main", fontWeight: 500 }}
                  variant="subtitle1"
                >
                  <ValueSkelton />
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
                  Tenure
                </Typography>
                <Typography
                  sx={{ color: "info.main", fontWeight: 500 }}
                  variant="subtitle1"
                >
                  <ValueSkelton />
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              height={"84px"}
              sx={ORDER_DIVIDER_STYLE}
            >
              <Grid item xs={4}>
                <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
                  Order Amount
                </Typography>
                <Typography
                  sx={{ color: "info.main", fontWeight: 500 }}
                  variant="subtitle1"
                >
                  <ValueSkelton />
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
                  Order Amount Paid
                </Typography>
                <Typography
                  sx={{ color: "info.main", fontWeight: 500 }}
                  variant="subtitle1"
                >
                  <ValueSkelton />
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
                  Order Amount Outstanding
                </Typography>
                <Typography
                  sx={{ color: "info.main", fontWeight: 500 }}
                  variant="subtitle1"
                >
                  <ValueSkelton />
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              height={"84px"}
              sx={ORDER_DIVIDER_STYLE}
            >
              <Grid item xs={4}>
                <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
                  Late Fees Charged
                </Typography>
                <Typography
                  sx={{
                    color: "info.main",
                    fontWeight: 500,
                  }}
                  variant="subtitle1"
                >
                  <ValueSkelton />
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
                  Late Fees Paid
                </Typography>
                <Typography
                  sx={{ color: "info.main", fontWeight: 500 }}
                  variant="subtitle1"
                >
                  <ValueSkelton />
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
                  Late Fees Outstanding
                </Typography>
                <Typography
                  sx={{ color: "info.main", fontWeight: 500 }}
                  variant="subtitle1"
                >
                  <ValueSkelton />
                </Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="center" height={"84px"}>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    color: "info.dark",
                    display: "flex",
                    alignItems: "center",
                  }}
                  paddingBottom="8px"
                >
                  Total Paid Till Date
                  <CustomTooltip title="Total Paid Till Date = Order Amount Paid + Late Fees Paid">
                    <InfoIcon
                      sx={{
                        cursor: "pointer",
                        fontSize: "20px",
                        marginLeft: "4px",
                      }}
                    />
                  </CustomTooltip>
                </Typography>
                <Typography
                  sx={{ color: "info.main", fontWeight: 500 }}
                  variant="subtitle1"
                >
                  <ValueSkelton />
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
                  Net Amount Outstanding
                </Typography>
                <Typography
                  sx={{
                    color: "error.main",
                    fontWeight: 500,
                  }}
                  variant="subtitle1"
                >
                  <ValueSkelton />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Box
            sx={{ width: 2, bgcolor: "info.contrastText", mr: "-2px" }}
            style={{ paddingTop: 0 }}
          />
          <Grid item xs={4} style={{ paddingLeft: 32 }}>
            <Typography
              variant="subtitle1"
              color={"info.main"}
              fontWeight={500}
            >
              Payment History
            </Typography>

            <Grid container>
              <Grid
                item
                xs={12}
                mt={1}
                sx={{
                  maxHeight: 292,
                  overflowX: "hidden",
                }}
              >
                {Array(3)
                  .fill({})
                  ?.map((payment: any) => (
                    <Box
                      sx={{
                        borderRadius: "8px",
                        backgroundColor: "secondary.light",
                        mt: 1,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Grid container p={2}>
                        <Grid item paddingBottom="10px" xs={12}>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "info.dark" }}
                          >
                            <ValueSkelton />
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          mb="4px"
                          sx={{
                            justifyContent: "space-between",
                            display: "flex",
                          }}
                          xs={12}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "info.dark" }}
                          >
                            Payment Received
                          </Typography>
                          <Typography
                            sx={{ color: "info.main", fontWeight: 500 }}
                            variant="subtitle1"
                          >
                            <ValueSkelton />
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sx={{
                            justifyContent: "space-between",
                            display: "flex",
                          }}
                          xs={12}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "info.dark" }}
                          >
                            Amount Settled Against This Order
                          </Typography>
                          <Typography
                            sx={{ color: "info.main", fontWeight: 500 }}
                            variant="subtitle1"
                          >
                            <ValueSkelton />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
}
