import {
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Button,
  styled,
  Autocomplete,
  Chip,
  CircularProgress,
  Menu,
  MenuItem,
} from "@mui/material";
import { Fragment } from "react";
import React, { useState, useEffect } from "react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DescriptionIcon from "@mui/icons-material/Description";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { InvoiceNumberState } from "../invoiceCheckSlice";
import { getGenerateOrdersReport } from "../ReportsApi";
import { KALA_70, KALA_80, MIRCHI_70 } from "../../../styles/colors";
import HelpIcon from "@mui/icons-material/Help";
import DateRangeReport from "../DateRangeReport";
import moment from "moment";
import { subMonths, format, endOfMonth, startOfMonth } from "date-fns";
import { LightTooltip } from "../ReportsFilter";
import { defaultMessage, reportTypeMapping } from "../../../utils";
import { ConfigState } from "../../../features/maintenance/maintenanceSlice";
import OrderStatusForm from "./OrderStatusForm";
import { OrdersReportState } from "./ordersReportSice";
import { AnyAction } from "@reduxjs/toolkit";
import { trackAnalytics } from "../../../Analytics";

interface Option {
  invoiceNumber: any;
  loanId: any;
  lateFees: any;
  dueDate: any;
}

function OrdersFormReport() {
  const dispatch: AppDispatch = useDispatch();
  const [calenderFilterBy, setCalenderFilterBy] = React.useState("");
  const [openDateRange, setOpenDateRange] = React.useState(false);
  const [applied, setApplied] = React.useState(false);
  const [orderSelectionBtn, setOrderSelectionBtn] =
    useState("Unsettled Orders");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValue, setSelectedValue] = useState("Due Date");
  const [dateSelectionBtn, setDateSelectionBtn] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [range, setRange] = React.useState([
    {
      startDate: startOfMonth(subMonths(new Date(), 3)),
      endDate: endOfMonth(subMonths(new Date(), 1)),
      key: "selection",
    },
  ]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelection = (value: any) => {
    setSelectedValue(value);
    setDateSelectionBtn("");
    setRange([
      {
        startDate: startOfMonth(subMonths(new Date(), 3)),
        endDate: endOfMonth(subMonths(new Date(), 1)),
        key: "selection",
      },
    ]);
    setApplied(false);
    setOpenDateRange(false);
    setCalenderFilterBy("");
    handleClose();
  };

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showToast]);

  const { response: generateReportResponse }: any = useSelector(
    (state: RootState) => state.generateReportList
  );

  const { mobileNumber, response: mobileRes }: any = useSelector(
    (state: RootState) => state.mobileNum
  );

  useEffect(() => {
    if (!showToast) return;
    let message: any =
      "Your request has been submitted. You will be notified when the report is ready.";
    if (dateSelectionBtn == "") {
      message = (
        <>
          Your request has been submitted. The report will be generated from
          <br />
          {generateReportResponse[0].feInput[0].startDate} to
          {" " + generateReportResponse[0].feInput[0].endDate}
        </>
      );
    }
    defaultMessage(message, KALA_80);
  }, [showToast]);

  const { loading }: OrdersReportState = useSelector(
    (state: RootState) => state.OrdersReport
  );

  const { config }: ConfigState = useSelector(
    (state: RootState) => state.config
  );

  const orderTypeMappings: any = {
    "Settled Orders": "SETTLED",
    "Unsettled Orders": "UNSETTLED",
  };

  const handleGenerateReport = async () => {
    const payload = {
      startDate:
        dateSelectionBtn == ""
          ? null
          : moment(range[0].startDate).format("YYYY-MM-DD"),
      endDate:
        dateSelectionBtn == ""
          ? null
          : moment(range[0].endDate).format("YYYY-MM-DD"),
      ordersType: orderTypeMappings[orderSelectionBtn] || "ALL",
      dateType:
        dateSelectionBtn == "" || selectedValue == "Due Date"
          ? "DUEDATE"
          : "PAYOUTDATE",
      sortType:
        dateSelectionBtn == "" || selectedValue == "Due Date" ? "ASC" : "DESC",
      columnName:
        dateSelectionBtn == "" || selectedValue == "Due Date"
          ? "dueDate"
          : "payoutDate",
    };
    const pageData = {
      source_page: "reports_tab",
      landing_page: "reports_tab",
      event_type: "click",
      tab: "generate",
      report_name: reportTypeMapping("ORDER", config),
      payload: {
        ...payload,
        selected_range_btn: dateSelectionBtn,
      },
    };

    trackAnalytics("generate_report_btn", pageData);
    const ordersResponse = dispatch(await getGenerateOrdersReport(payload));
    if ((await ordersResponse).payload.response_code == 80) {
      setShowToast(true);
    }
  };

  return (
    <Fragment>
      <Grid item>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <Grid
              container
              direction="row"
              alignItems={"center"}
              sx={{ gap: "24px", alignItems: "center" }}
            >
              <Grid item>
                <LightTooltip
                  title={config.Tooltip?.orderStatusReport}
                  style={{ cursor: "pointer" }}
                  placement="bottom-start"
                >
                  <Typography fontWeight={600} color={KALA_70}>
                    Order Status{" "}
                    <sup>
                      <HelpIcon
                        sx={{
                          width: "10px",
                          height: "10px",
                          color: KALA_70,
                        }}
                      />
                    </sup>
                  </Typography>
                </LightTooltip>
              </Grid>
              <OrderStatusForm
                orderSelectionBtn={orderSelectionBtn}
                setOrderSelectionBtn={setOrderSelectionBtn}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1} alignItems={"center"}>
              <Grid item xs={9.8}>
                <Grid
                  container
                  direction="row"
                  sx={{ gap: "24px", alignItems: "center" }}
                >
                  <Grid item>
                    <LightTooltip
                      title={
                        selectedValue == "Due Date"
                          ? config.Tooltip?.dueDateOrderReport
                          : config.Tooltip?.disbursementDateOrderReport
                      }
                      style={{ cursor: "pointer" }}
                      placement="bottom-start"
                    >
                      <Button
                        endIcon={<KeyboardArrowDownRoundedIcon />}
                        variant="outlined"
                        onClick={handleClick}
                        sx={{
                          "&:hover": {
                            border: "1.5px solid #A0A4A8",
                          },
                          color: KALA_70,
                          height: "36px",
                          padding: "0.5rem",
                          border: "1.5px solid #A0A4A8",
                          borderRadius: "0.5rem",
                        }}
                      >
                        <Typography
                          fontWeight={600}
                          color={KALA_70}
                          fontSize={"0.875rem"}
                        >
                          {selectedValue}{" "}
                          <sup>
                            <HelpIcon
                              sx={{
                                width: "10px",
                                height: "10px",
                                color: KALA_70,
                              }}
                            />
                          </sup>
                        </Typography>
                      </Button>
                    </LightTooltip>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={() => handleSelection("Due Date")}>
                        Due Date
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleSelection("Disbursement Date")}
                      >
                        Disbursement Date
                      </MenuItem>
                    </Menu>
                  </Grid>
                  <DateRangeReport
                    applied={applied}
                    setApplied={setApplied}
                    openDateRange={openDateRange}
                    setOpenDateRange={setOpenDateRange}
                    dateSelectionBtn={dateSelectionBtn}
                    setDateSelectionBtn={setDateSelectionBtn}
                    range={range}
                    setRange={setRange}
                    calenderFilterBy={calenderFilterBy}
                    setCalenderFilterBy={setCalenderFilterBy}
                    reportFilter={
                      selectedValue == "Due Date"
                        ? "DueReport"
                        : "DisbursementReport"
                    }
                    // DisbursementReport
                    // DueReport
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={2.2}
                justifyContent={"right"}
                sx={{
                  display: "flex",
                  width: "fit-content",
                  justifyContent: "flex-end",
                }}
              >
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    // fullWidth
                    type="submit"
                    onClick={handleGenerateReport}
                    id="generate-form-btn"
                    style={{
                      borderRadius: "8px",
                      padding: "0.75rem 1rem",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                    disabled={loading}
                  >
                    Generate Report
                    {loading ? (
                      <CircularProgress
                        color="inherit"
                        style={{ width: "20px", height: "20px" }}
                      />
                    ) : (
                      <ArrowForwardIcon fontSize="small" />
                    )}
                  </Button>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default OrdersFormReport;
