import { createSlice, current, original } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { errorMessage } from "../../../utils";
import {
  getGenerateReports,
  getGenerateLedgerReport,
  getGenerateLateFeeReport,
  getGeneratePaymentHistoryReport,
  getGenerateOrdersReport
} from "../ReportsApi";
import { getToastNotifications } from "../../../layouts/app/appAPI";

export interface GenerateReportState {
  loading: boolean;
  response: any;
  error: string | null;
  count: number;
  filters: {
    pageNo: number;
    pageSize: number;
  };
  reportMesg: string | null
}

export const initialState: GenerateReportState = {
  loading: true,
  error: null,
  response: [],
  count: 0,
  filters: {
    pageNo: 0,
    pageSize: 100,
  },
  reportMesg: null 
};

export const generateReportSlice = createSlice({
  name: "generateReportList",
  initialState,
  reducers: {
    updateFilters: (state, action: PayloadAction<any>) => {
      if (
        !_.isEqual(original(state.filters), {
          ...state.filters,
          ...action.payload,
        })
      ) {
        state.filters = { ...state.filters, ...action.payload };
      }
    },
  },
  extraReducers: {
    [getGenerateReports.pending.type]: (state, action) => {
      state.loading = true;
    },
    [getGenerateReports.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.response = action.payload.reportData || [];
      state.count = action.payload.totalCount || 0;
      state.reportMesg = action.payload.reportMsg;
    },
    [getGenerateReports.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = action.payload || "Something went wrong";
      errorMessage(state.error || "");
    },
    [getGenerateLedgerReport.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.response = [
        action.payload.reportFetchResponseDtoList[0],
        ...state.response,
      ];
      state.count = state.count + 1;
    },
    [getGenerateLateFeeReport.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.response = [
        action.payload.reportFetchResponseDtoList[0],
        ...state.response,
      ];
      state.count = state.count + 1;
    },
    [getGeneratePaymentHistoryReport.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.response = [
        action.payload.reportFetchResponseDtoList[0],
        ...state.response,
      ];
      state.count = state.count + 1;
    },
    [getGenerateOrdersReport.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.response = [
        action.payload.reportFetchResponseDtoList[0],
        ...state.response,
      ];
      state.count = state.count + 1;
    },
    [getToastNotifications.fulfilled.type]: (state, action) => {
      const notifications = action.payload.dataList;
      notifications?.forEach((notification: any) => {
        const index = current(state.response).findIndex(
          (report: any) => report.reportId == notification.reportId
        );
        if (index !== -1) {
          state.response[index].status =
            notification.reportStatus == "COMPLETE"
              ? "Complete"
              : notification.reportStatus == "ERROR"
              ? "Error"
              : null;
        }
      });
    },
  },
});

export const { updateFilters } = generateReportSlice.actions;

export default generateReportSlice.reducer;
