import { createSlice } from "@reduxjs/toolkit";
import {
  postLoginWithPassword,
  postLoginResetPasswordOtp,
  postLoginResetPassword,
  resendOTP,
} from "./loginApi";
import { AuthState } from "./LoginModuleSlice";
import { errorMessage } from "../../utils";
import { getUserDetails } from "../UserProfile/userApi";

export interface LoginViaPasswordVerifyState {
  state: AuthState;
  loading: boolean;
  error: string;
  response?: any;
  password?: string;
  mobileNumber: string;
  resentOtpResponseCode: number;
}

const initialState: LoginViaPasswordVerifyState = {
  state: AuthState.PasswordLogin,
  loading: false,
  error: "",
  response: "",
  password: "",
  mobileNumber: "",
  resentOtpResponseCode: 0,
};

export const LoginViaPasswordSlice = createSlice({
  name: "LoginViaPasswordVerify",
  initialState,
  reducers: {
    forgotPassword: (state) => {
      state.state = AuthState.VerifyOtp;
    },
    updateAuthState: (state) => {
      state.state = AuthState.PasswordLogin;
    },
  },
  extraReducers: {
    [postLoginWithPassword.pending.type]: (state, action) => {
      state.error = "";
      state.loading = true;
    },
    [postLoginWithPassword.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.password = action.meta.arg.password;
      state.response = action.payload;
    },
    [postLoginWithPassword.rejected.type]: (state, action) => {
      state.loading = false;
      state.response = "";
      errorMessage(action.payload || "Something went wrong");
      state.error = action.payload || "Something went wrong";
    },
    [postLoginResetPasswordOtp.pending.type]: (state, action) => {
      state.error = "";
      state.mobileNumber = action.meta.arg;
      state.loading = true;
    },
    [postLoginResetPasswordOtp.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.state = AuthState.VerifyOtp;
      state.response = action.payload;
    },
    [postLoginResetPasswordOtp.rejected.type]: (state, action) => {
      state.loading = false;
      state.response = "";
      errorMessage(action.payload || "Something went wrong");
      state.error = action.payload || "Something went wrong";
      state.state = AuthState.PasswordLogin;
    },
    [postLoginResetPassword.fulfilled.type]: (state, action) => {
      state.state = AuthState.PasswordLogin;
    },
    [resendOTP.pending.type]: (state, action) => {
      state.loading = true;
    },
    [resendOTP.fulfilled.type]: (state, action) => {
      state.loading = false;
      errorMessage(action.payload.message || "Something went wrong");
      state.response = action.payload;
    },
    [resendOTP.rejected.type]: (state, action) => {
      state.loading = false;
      state.resentOtpResponseCode = action.payload.response_code;
      errorMessage(action.payload.error || "Something went wrong");
      state.error = action.payload.error || "Something went wrong";
    },
    [getUserDetails.fulfilled.type]: (state, action) => {
      state.state = AuthState.PasswordLogin;
      state.mobileNumber = action.payload.mobileNumber;
    },
  },
});

export const { forgotPassword, updateAuthState } =
  LoginViaPasswordSlice.actions;
export default LoginViaPasswordSlice.reducer;
