interface Props {
  width?: string;
  height?: string;
  style?: React.CSSProperties;
}

const NoDataSVG = (props: Props) => {
  return (
    <svg
      width="300"
      height="300"
      viewBox="0 0 300 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M135.188 255.737C146.475 255.112 156.375 248.4 166.912 244.287C179.325 239.437 193.113 238.162 206.2 240.65C216.012 242.512 225.837 246.787 231.887 254.725C229.35 257.387 225.7 258.65 222.162 259.65C213.487 262.087 204.525 263.487 195.525 263.812C185.575 264.175 175.637 263.25 165.7 262.812C156.262 262.412 146.375 262.35 138.1 257.8"
        fill="#ACDAEA"
      />
      <path
        d="M180.313 260.338C179.825 260.338 179.35 260.1 179.075 259.65C178.65 258.963 178.863 258.063 179.55 257.638C180.3 257.175 198 246.225 211.9 244.125C212.713 244 213.45 244.55 213.563 245.35C213.688 246.15 213.138 246.9 212.338 247.013C199.025 249.025 181.263 260.013 181.088 260.113C180.838 260.263 180.575 260.338 180.313 260.338Z"
        fill="#73C5D9"
      />
      <path
        d="M79.7125 242.313C79.7125 242.313 103 231.375 120.55 244.588L115.313 253.413L86.325 254.65"
        fill="#ACDAEA"
      />
      <path
        d="M73.95 246.175C73.95 246.175 111.35 237.175 133.312 252.7C155.275 268.225 176.087 262.175 181.825 257.913C187.562 253.65 212.775 241.788 231.15 244.138V269.213H74.975L73.95 246.175Z"
        fill="#D1E8F2"
      />
      <path
        d="M120.012 246.925C119.95 246.925 119.875 246.912 119.812 246.875C111.55 243.087 95.875 244.062 95.725 244.075C95.4125 244.112 95.225 243.887 95.2125 243.625C95.2 243.362 95.4 243.125 95.6625 243.112C96.3125 243.075 111.737 242.1 120.225 245.987C120.475 246.1 120.575 246.387 120.462 246.637C120.375 246.812 120.2 246.925 120.012 246.925Z"
        fill="#73C5D9"
      />
      <path
        d="M266.687 269.45H33.3125C32.7625 269.45 32.325 269.013 32.325 268.463C32.325 267.913 32.7625 267.475 33.3125 267.475H266.687C267.237 267.475 267.675 267.913 267.675 268.463C267.675 269.013 267.225 269.45 266.687 269.45Z"
        fill="#73C5D9"
      />
      <path
        d="M78.2875 131.237H71.275V268.462H78.2875V131.237Z"
        fill="#59B5C8"
      />
      <path
        d="M235.737 131.237H228.725V268.462H235.737V131.237Z"
        fill="#59B5C8"
      />
      <path
        d="M229.037 131.237H226.225V268.462H229.037V131.237Z"
        fill="#249AA8"
      />
      <path d="M81.1125 130.712H78.3V267.937H81.1125V130.712Z" fill="#249AA8" />
      <path
        d="M25.2875 142.238L32.6875 112.063C33.0875 110.425 33.85 109.413 34.6875 109.413H265.325C266.163 109.413 266.925 110.425 267.325 112.063L274.725 142.238C275.55 145.625 274.463 149.763 272.725 149.763H27.2875C25.55 149.763 24.45 145.625 25.2875 142.238Z"
        fill="#D1E8F2"
      />
      <path
        d="M27.2875 150.75C26.75 150.75 26.3 150.313 26.3 149.763C26.3 149.213 26.7375 148.775 27.2875 148.775L266.863 148.1C267.4 148.1 267.85 148.538 267.85 149.088C267.85 149.638 267.413 150.075 266.863 150.075L27.2875 150.75Z"
        fill="#73C5D9"
      />
      <path
        d="M71.075 99.3626L78.2125 103V104.188L81.075 106.663V98.8501H71.075"
        fill="#007F80"
      />
      <path
        d="M73.5125 88.8373C75.0375 86.8748 84.9999 65.1748 85.6499 62.7748C86.0999 61.0873 86.3249 59.3373 86.8999 57.6998C87.9374 54.7373 90.0874 52.2623 92.5749 50.3498C95.0624 48.4373 97.8874 47.0123 100.687 45.6123C101 45.4498 101.375 45.2873 101.7 45.4373C101.912 45.5248 102.05 45.7248 102.15 45.9373C102.812 47.2748 102.15 48.8623 101.475 50.1998C97.2874 58.3623 90.0874 75.6248 87.3499 84.3748"
        fill="#F69E69"
      />
      <path
        d="M231.425 88.975C229.9 87.0125 219.938 65.3125 219.288 62.9125C218.838 61.225 218.613 59.475 218.038 57.8375C217 54.875 214.85 52.4 212.363 50.4875C209.875 48.575 207.05 47.15 204.25 45.75C203.938 45.5875 203.563 45.425 203.238 45.575C203.025 45.6625 202.888 45.8625 202.788 46.075C202.125 47.4125 202.788 49 203.463 50.3375C207.65 58.5 214.85 75.7625 217.588 84.5125"
        fill="#F69E69"
      />
      <path
        opacity="0.25"
        d="M213.412 51.3125L215.075 52.975C215.075 52.975 214.175 59.35 209.237 62.375C209.237 62.375 208.112 61.5875 208.812 59.8625"
        fill="#26282B"
      />
      <path
        d="M73.5125 88.8373C75.0375 86.8748 84.9999 65.1748 85.6499 62.7748C86.0999 61.0873 86.3249 59.3373 86.8999 57.6998C87.9374 54.7373 90.0874 52.2623 92.5749 50.3498C95.0624 48.4373 97.8874 47.0123 100.687 45.6123C101 45.4498 101.375 45.2873 101.7 45.4373C101.912 45.5248 102.05 45.7248 102.15 45.9373C102.812 47.2748 102.15 48.8623 101.475 50.1998C97.2874 58.3623 90.0874 75.6248 87.3499 84.3748"
        fill="#F69E69"
      />
      <path
        opacity="0.25"
        d="M96.6626 60.4998C96.6626 60.4998 91.3125 57.0248 89.8625 52.8373C89.8625 52.8373 91.925 50.5998 92.7875 50.1748"
        fill="#26282B"
      />
      <path
        d="M109.825 87.8748L104.713 78.6998L122.725 31.9248L124.45 38.9748L128.825 37.7623L121.95 87.8748H109.825Z"
        fill="#EE8900"
      />
      <path
        d="M116.75 65.9502L120.362 53.7627L126.438 55.2252L124.85 67.0002L116.75 65.9502Z"
        fill="#A65F00"
      />
      <path
        d="M197.012 86.9998L201.787 77.7248L184.1 31.0498L182.387 38.0998L178.012 36.8873L184.887 86.9998H197.012Z"
        fill="#EE8900"
      />
      <path
        d="M190.075 65.0749L186.788 55.4749L180.4 54.3374L182.263 65.9624L190.075 65.0749Z"
        fill="#A65F00"
      />
      <path
        d="M118.062 61.8252H189.05L197.287 87.8752H109.825L118.062 61.8252Z"
        fill="#D98000"
      />
      <path
        d="M124.737 33.7124H181.312L187.362 58.0249H119.262L124.737 33.7124Z"
        fill="#D98000"
      />
      <path
        d="M102.363 27.8875L119.263 58.025L123.45 41.7875L108.488 17.5"
        fill="#FFA000"
      />
      <path
        d="M204.037 28.5125L187.362 58.025L183.188 41.7875L197.5 17.5"
        fill="#FFA000"
      />
      <path
        d="M101.075 30.1748L118.063 61.8248L109.825 87.8748L90.425 47.5123L101.075 30.1748Z"
        fill="#FFA000"
      />
      <path
        opacity="0.38"
        d="M99.3875 58.1501C99.3875 58.1501 97.1 57.2376 95.9 58.8876L97.075 61.3376"
        fill="#26282B"
      />
      <path
        opacity="0.38"
        d="M110.513 47.7748L110.35 47.4248L90.425 47.5123L91.3 49.3248L112.063 50.6498L110.513 47.7748Z"
        fill="#26282B"
      />
      <path
        opacity="0.38"
        d="M100.9 61.7127C101.225 61.1002 102.15 61.3002 102.2 61.9877C102.313 63.6377 102.35 65.8002 101.988 67.0752C101.338 69.3627 102.7 69.8502 102.363 72.3627L98.925 65.1502L100.9 61.7127Z"
        fill="#26282B"
      />
      <path
        d="M204.988 30.1748L189.05 61.8248L197.288 87.8748L215.613 47.9248L204.988 30.1748Z"
        fill="#FFA000"
      />
      <path
        opacity="0.38"
        d="M206.475 57.5249C207.225 58.2374 207.625 59.2374 207.85 60.2374C207.9 60.4374 207.937 60.6249 208.037 60.7999C208.137 60.9749 208.312 61.1124 208.512 61.1124C208.862 61.1124 209.062 60.7374 209.312 60.4999C209.5 60.3124 209.75 60.1874 210.012 60.1499C209.337 61.5249 208.662 62.9124 207.987 64.2874"
        fill="#26282B"
      />
      <path
        opacity="0.38"
        d="M204.113 57.9248C203.638 59.1998 203.288 60.5498 203.313 61.9123C203.338 62.9873 203.6 64.0498 203.8 65.1123C204.288 67.8623 204.313 70.6873 203.95 73.4498L207.025 66.6623"
        fill="#26282B"
      />
      <path
        opacity="0.38"
        d="M196.375 47.4624L194.887 50.2624L214.825 49.6374L215.612 47.9249L196.375 47.4624Z"
        fill="#26282B"
      />
      <path
        d="M95.05 88.8373C95.0375 86.4248 96.7125 76.0998 96.7 73.6873C96.7 72.6373 96.7 71.5498 97.05 70.5498C97.5 69.2873 98.4625 68.2998 99.275 67.2373C100.9 65.1123 101.975 62.1623 100.775 59.7623C100.4 58.9998 99.6501 58.2873 98.8001 58.4248C97.8251 58.5873 97.4001 59.7498 97.2876 60.7248C97.1876 61.7123 97.15 62.8248 96.4 63.4623C95.5375 64.1998 94.125 63.8248 93.3625 62.9873C92.6 62.1498 91.925 60.5498 91.675 59.4373C89.4875 62.0123 88.1 65.3748 86.8 68.4873C86.675 68.7873 86.625 69.2373 86.925 69.3373"
        fill="#F69E69"
      />
      <path
        d="M208.337 71.1626C207.537 68.8251 205.987 66.8376 204.9 64.6251C203.812 62.4126 203.262 59.6751 204.487 57.5376C204.612 57.3251 204.762 57.1126 204.975 57.0251C205.55 56.7751 206.1 57.4126 206.362 57.9876C206.775 58.8876 207.05 59.8376 207.187 60.8126C207.275 61.4501 207.4 62.2251 208.012 62.4501C208.512 62.6376 209.05 62.3126 209.525 62.0626C210.587 61.5001 211.825 61.2501 213.025 61.3626C213.6 61.4126 214.175 61.5501 214.662 61.8626C215.125 62.1501 215.475 62.5876 215.762 63.0376C217.312 65.5001 217.087 68.6376 217.687 71.4876C218.35 74.5626 220.012 77.3376 220.862 80.3751C221.237 81.7001 221.45 83.0876 221.3 84.4626C221.075 86.5251 220.05 88.4251 218.812 90.1001C216.25 93.5751 212.775 96.2876 210.25 99.7876C210.337 99.6751 210.275 99.2251 210.287 99.0751C210.3 98.8251 210.312 98.5876 210.312 98.3376C210.325 97.8376 210.337 97.3501 210.35 96.8501C210.362 95.8751 210.362 94.9001 210.35 93.9251C210.312 91.9626 210.2 89.9876 210.025 88.0376C209.85 86.0751 209.6 84.1126 209.287 82.1626C209.162 81.3501 209.212 80.5751 209.237 79.6001C209.262 78.8501 209.037 78.1876 209.062 77.4376C209.112 75.5626 209.05 73.6751 208.537 71.8626C208.487 71.6001 208.412 71.3876 208.337 71.1626Z"
        fill="#F69E69"
      />
      <path
        d="M115.2 14.0374L122.525 30.1749H183.213L190.225 13.9874C191.013 12.1749 189.675 10.1499 187.7 10.1499H117.713C115.713 10.1499 114.375 12.2124 115.2 14.0374Z"
        fill="#FCBA18"
      />
      <path
        opacity="0.7"
        d="M115.2 14.0374L122.525 30.1749H183.213L190.225 13.9874C191.013 12.1749 189.675 10.1499 187.7 10.1499H117.713C115.713 10.1499 114.375 12.2124 115.2 14.0374Z"
        fill="#26282B"
      />
      <path
        d="M90.425 47.5123L101.075 30.1748H204.988L215.288 47.3623L90.425 47.5123Z"
        fill="#FCBA18"
      />
      <path
        d="M102.363 27.8875C102.738 28.4 203.813 28.1125 203.813 28.1125L197.5 17.5H108.488L102.363 27.8875Z"
        fill="#FCBA18"
      />
      <path
        d="M109.912 15.2874L191.525 15.6499C193.65 15.6624 194.975 13.3624 193.925 11.5249C193.437 10.6749 192.525 10.1499 191.537 10.1499H116.325C114.387 10.1499 112.575 11.1374 111.525 12.7749L109.912 15.2874Z"
        fill="#FCBA18"
      />
      <path
        d="M222.437 178.225C222.437 178.225 246.512 153.225 244.475 143.05C242.437 132.863 226.262 117.663 206.475 147.538L203.575 171.738L222.437 178.225Z"
        fill="#F69E69"
      />
      <path
        opacity="0.38"
        d="M111.9 47.4875L126.775 10.15L115.387 10C114.412 10 113.525 10.5125 113.025 11.3375L91.1375 47.5125L111.9 47.4875Z"
        fill="#26282B"
      />
      <path
        d="M110.788 47.4875L125.913 10.1375L114.475 10C113.5 10 112.613 10.5125 112.113 11.3375L90.425 47.5125L110.788 47.4875Z"
        fill="#FCBA18"
      />
      <path
        d="M116.437 14.2124C116.187 14.3249 116.025 14.5874 116.075 14.8624C116.125 15.1499 116.375 15.3249 116.662 15.3374L116.437 14.2124Z"
        fill="#AD6C00"
      />
      <path
        d="M116.662 14.1626L116.887 15.2876C117.137 15.1751 117.3 14.9126 117.25 14.6376C117.187 14.3501 116.937 14.1626 116.662 14.1626Z"
        fill="#AD6C00"
      />
      <path
        d="M115.513 18.4999C115.563 18.2249 115.425 17.9499 115.15 17.8374C114.888 17.7374 114.588 17.8374 114.438 18.0749L115.513 18.4999Z"
        fill="#AD6C00"
      />
      <path
        d="M115.425 18.7126L114.363 18.2876C114.313 18.5626 114.45 18.8376 114.725 18.9501C114.988 19.0626 115.275 18.9501 115.425 18.7126Z"
        fill="#AD6C00"
      />
      <path
        d="M104.475 38.25C104.225 38.3625 104.063 38.625 104.113 38.9C104.163 39.1875 104.413 39.3625 104.7 39.375L104.475 38.25Z"
        fill="#AD6C00"
      />
      <path
        d="M104.7 38.2002L104.925 39.3252C105.175 39.2127 105.337 38.9502 105.287 38.6752C105.225 38.3877 104.975 38.2002 104.7 38.2002Z"
        fill="#AD6C00"
      />
      <path
        d="M103.337 41.9748C103.125 41.7998 102.812 41.7873 102.587 41.9748C102.362 42.1623 102.325 42.4623 102.462 42.7123L103.337 41.9748Z"
        fill="#AD6C00"
      />
      <path
        d="M103.488 42.1626L102.613 42.9001C102.825 43.0751 103.138 43.0876 103.363 42.9001C103.575 42.7126 103.625 42.4001 103.488 42.1626Z"
        fill="#AD6C00"
      />
      <path
        d="M110.613 26.0625C110.363 26.175 110.2 26.4375 110.25 26.7125C110.3 27 110.55 27.175 110.838 27.1875L110.613 26.0625Z"
        fill="#AD6C00"
      />
      <path
        d="M110.838 26.0127L111.063 27.1377C111.313 27.0252 111.475 26.7627 111.425 26.4877C111.363 26.2002 111.113 26.0252 110.838 26.0127Z"
        fill="#AD6C00"
      />
      <path
        d="M109.7 30.2877C109.712 30.0127 109.55 29.7502 109.275 29.6752C109 29.6002 108.725 29.7377 108.6 29.9877L109.7 30.2877Z"
        fill="#AD6C00"
      />
      <path
        d="M109.637 30.5124L108.525 30.2124C108.512 30.4874 108.675 30.7499 108.95 30.8249C109.237 30.8999 109.512 30.7624 109.637 30.5124Z"
        fill="#AD6C00"
      />
      <path
        opacity="0.38"
        d="M194.475 47.4374L179.938 10.1499H190.912C191.887 10.1499 192.525 10.5124 193.025 11.3499L214.713 47.7749L194.475 47.4374Z"
        fill="#26282B"
      />
      <path
        d="M195.325 47.4374L180.787 10.1499H191.562C192.537 10.1499 193.425 10.6624 193.925 11.4874L215.612 47.9124L195.325 47.4374Z"
        fill="#FCBA18"
      />
      <path
        d="M188.688 12.7998C188.938 12.9123 189.1 13.1748 189.05 13.4498C189 13.7373 188.75 13.9123 188.463 13.9248L188.688 12.7998Z"
        fill="#AD6C00"
      />
      <path
        d="M188.463 12.75L188.237 13.875C187.987 13.7625 187.825 13.5 187.875 13.225C187.938 12.9375 188.188 12.7625 188.463 12.75Z"
        fill="#AD6C00"
      />
      <path
        d="M189.613 17.0873C189.563 16.8123 189.7 16.5373 189.975 16.4248C190.238 16.3248 190.538 16.4248 190.688 16.6623L189.613 17.0873Z"
        fill="#AD6C00"
      />
      <path
        d="M189.7 17.3127L190.762 16.8877C190.812 17.1627 190.675 17.4377 190.4 17.5502C190.137 17.6502 189.85 17.5377 189.7 17.3127Z"
        fill="#AD6C00"
      />
      <path
        d="M200.662 36.8374C200.912 36.9499 201.075 37.2124 201.025 37.4874C200.975 37.7749 200.725 37.9499 200.438 37.9624L200.662 36.8374Z"
        fill="#AD6C00"
      />
      <path
        d="M200.425 36.7998L200.2 37.9248C199.95 37.8123 199.788 37.5498 199.838 37.2748C199.9 36.9873 200.15 36.7998 200.425 36.7998Z"
        fill="#AD6C00"
      />
      <path
        d="M201.787 40.5749C202 40.3999 202.312 40.3874 202.537 40.5749C202.762 40.7624 202.8 41.0624 202.662 41.3124L201.787 40.5749Z"
        fill="#AD6C00"
      />
      <path
        d="M201.637 40.75L202.512 41.4875C202.3 41.6625 201.987 41.675 201.762 41.4875C201.55 41.3 201.512 41 201.637 40.75Z"
        fill="#AD6C00"
      />
      <path
        d="M194.512 24.6499C194.762 24.7624 194.925 25.0249 194.875 25.2999C194.825 25.5874 194.575 25.7624 194.287 25.7749L194.512 24.6499Z"
        fill="#AD6C00"
      />
      <path
        d="M194.287 24.6123L194.062 25.7373C193.812 25.6248 193.65 25.3623 193.7 25.0873C193.762 24.7998 194.012 24.6123 194.287 24.6123Z"
        fill="#AD6C00"
      />
      <path
        d="M195.425 28.8873C195.412 28.6123 195.575 28.3498 195.85 28.2748C196.125 28.1998 196.4 28.3373 196.525 28.5873L195.425 28.8873Z"
        fill="#AD6C00"
      />
      <path
        d="M195.488 29.1125L196.6 28.8125C196.613 29.0875 196.45 29.35 196.175 29.425C195.888 29.5 195.613 29.35 195.488 29.1125Z"
        fill="#AD6C00"
      />
      <path
        d="M98.6125 147.525C78.825 117.65 62.65 132.85 60.6125 143.037C58.575 153.225 82.65 178.212 82.65 178.212L100.425 167.65L98.6125 147.525Z"
        fill="#F69E69"
      />
      <path
        d="M124.75 136.362C124.75 136.362 119.562 136.725 120.825 140.325C122.087 143.925 125.4 147.7 125.4 147.7"
        fill="#F69E69"
      />
      <path
        d="M178.863 136.362C178.863 136.362 184.05 136.725 182.788 140.325C181.525 143.925 178.213 147.7 178.213 147.7"
        fill="#F69E69"
      />
      <path
        d="M174.313 289.537C191.663 289.537 205.738 275.55 205.913 258.2C206.05 243.512 205.6 234.375 203.413 222.087C200.888 207.837 203.3 205.812 211.6 191.487C212.95 189.15 220.113 179.712 222.625 178.225C222.625 178.225 204.213 166.562 206.663 147.525C206.663 147.525 179.325 178.15 152.638 178.512C125.95 178.15 98.6125 147.525 98.6125 147.525C101.063 166.55 82.65 178.225 82.65 178.225C85.1625 179.712 92.325 189.15 93.675 191.487C101.975 205.812 104.388 207.837 101.863 222.087C99.6375 234.662 99.5875 244.312 100.163 259.237C100.813 276.162 114.788 289.525 131.725 289.525H174.313V289.537Z"
        fill="#0797E0"
      />
      <path
        d="M61.9375 150.175C61.675 149.5 56.275 138.063 61.9375 118.363C67.6 98.6626 81.4375 70.4251 85.7 71.7626C89.9625 73.1001 95.55 74.9626 95.025 90.6751C94.4875 106.388 94.55 123.888 87.8625 135.113C81.175 146.338 61.9375 150.175 61.9375 150.175Z"
        fill="#F69E69"
      />
      <path
        d="M243.15 150.175C243.413 149.5 248.813 138.063 243.15 118.363C237.488 98.6626 223.65 70.4251 219.387 71.7626C215.125 73.1001 209.538 74.9626 210.063 90.6751C210.6 106.388 210.538 123.888 217.225 135.113C223.913 146.338 243.15 150.175 243.15 150.175Z"
        fill="#F69E69"
      />
      <path
        d="M131.438 162.275L138.337 179.363L155.087 196.125L168.675 179.2L173.912 160.325L131.438 162.275Z"
        fill="#F69E69"
      />
      <path
        d="M167.75 167.588C167.75 167.588 155.9 183.275 135.425 172.338L131.438 162.288L167.75 167.588Z"
        fill="#26282B"
      />
      <path
        d="M152.213 172.563C137.45 172.563 125.488 160.6 125.488 145.838V131.8C125.488 123.463 132.25 116.7 140.588 116.7H163.838C172.175 116.7 178.938 123.463 178.938 131.8V145.838C178.938 160.6 166.975 172.563 152.213 172.563Z"
        fill="#F69E69"
      />
      <path
        d="M152.212 173.137C137.162 173.137 124.912 160.888 124.912 145.837V131.8C124.912 123.163 131.937 116.125 140.587 116.125H163.837C172.475 116.125 179.512 123.15 179.512 131.8C179.512 132.113 179.262 132.375 178.937 132.375C178.625 132.375 178.362 132.125 178.362 131.8C178.362 123.788 171.837 117.262 163.825 117.262H140.575C132.562 117.262 126.037 123.788 126.037 131.8V145.837C126.037 160.262 137.775 172 152.187 172C158.362 172 164.35 169.813 169.062 165.825C169.3 165.625 169.662 165.65 169.862 165.888C170.062 166.125 170.037 166.488 169.8 166.688C164.912 170.85 158.65 173.137 152.212 173.137Z"
        fill="#26282B"
      />
      <path
        d="M98.6125 148C98.45 148 98.2875 147.912 98.2125 147.762C98.1375 147.625 90.3875 134.325 81.85 133.7C81.5875 133.675 81.4 133.462 81.4125 133.2C81.4375 132.937 81.6625 132.75 81.9125 132.762C90.9375 133.437 98.6875 146.725 99.0125 147.287C99.1375 147.512 99.0625 147.8 98.8375 147.925C98.775 147.975 98.6875 148 98.6125 148Z"
        fill="#26282B"
      />
      <path
        d="M206.663 148C206.575 148 206.488 147.975 206.4 147.925C206.188 147.788 206.125 147.488 206.263 147.275C206.613 146.738 215 134.125 220.7 132.775C220.95 132.713 221.2 132.875 221.263 133.125C221.325 133.375 221.163 133.625 220.913 133.688C215.563 134.95 207.125 147.663 207.038 147.788C206.963 147.925 206.813 148 206.663 148Z"
        fill="#26282B"
      />
      <path
        d="M105.613 215.263C105.413 215.263 105.225 215.138 105.163 214.925C98.25 192.35 82.5 178.713 82.35 178.575C82.15 178.413 82.125 178.113 82.3 177.913C82.4625 177.713 82.7625 177.688 82.9625 177.863C83.125 178 99.075 191.813 106.075 214.65C106.15 214.9 106.013 215.163 105.763 215.238C105.713 215.263 105.663 215.263 105.613 215.263Z"
        fill="#004965"
      />
      <path
        d="M199.725 215.262C199.675 215.262 199.637 215.262 199.587 215.237C199.337 215.162 199.2 214.9 199.275 214.65C206.275 191.812 222.225 178 222.387 177.862C222.587 177.7 222.875 177.712 223.05 177.912C223.212 178.112 223.2 178.4 223 178.575C222.837 178.712 207.087 192.35 200.175 214.925C200.112 215.137 199.925 215.262 199.725 215.262Z"
        fill="#004965"
      />
      <path
        d="M155.088 196.588C154.963 196.588 154.85 196.538 154.75 196.45L137.9 179.575L136.713 177.225C136.6 177 136.688 176.713 136.913 176.6C137.138 176.488 137.425 176.575 137.538 176.8L138.7 179.075L155.413 195.775C155.6 195.963 155.6 196.25 155.413 196.438C155.338 196.55 155.213 196.588 155.088 196.588Z"
        fill="#004965"
      />
      <path
        d="M169.15 214.075C169.15 214.075 179.488 214.988 187.988 214.075L188.725 217.388C188.725 217.388 179.863 219.038 169.15 217.388V214.075Z"
        fill="#004965"
      />
      <path
        d="M170.875 171.263C170.875 171.263 172.125 187.113 167.738 197.85C167.738 197.85 164.75 190.313 159.062 191.175C159.075 191.163 169.562 176.513 170.875 171.263Z"
        fill="#004965"
      />
      <path
        d="M135.375 171.65C135.375 171.65 136.162 187.112 140.55 197.85C140.55 197.85 143.612 188.45 150.087 190.575C150.087 190.575 136.687 176.9 135.375 171.65Z"
        fill="#004965"
      />
      <path
        d="M146.588 153.712C148.163 154.85 150.125 155.45 152.063 155.4C153.163 155.375 154.25 155.15 155.338 154.925C155.488 154.9 155.638 154.862 155.763 154.775C156.125 154.537 156.175 153.987 156.538 153.737C155.388 152.912 154.063 152.275 152.65 152.1C151.238 151.912 149.738 152.212 148.613 153.075"
        fill="#B40000"
      />
      <path
        d="M144.862 137.662C146.064 137.662 147.037 136.689 147.037 135.487C147.037 134.286 146.064 133.312 144.862 133.312C143.661 133.312 142.688 134.286 142.688 135.487C142.688 136.689 143.661 137.662 144.862 137.662Z"
        fill="#26282B"
      />
      <path
        d="M160.025 136.675C161.226 136.675 162.2 135.701 162.2 134.5C162.2 133.299 161.226 132.325 160.025 132.325C158.824 132.325 157.85 133.299 157.85 134.5C157.85 135.701 158.824 136.675 160.025 136.675Z"
        fill="#26282B"
      />
      <path
        d="M150.112 146C149.712 146 149.35 145.938 149.025 145.813C148.175 145.488 147.525 144.738 147.337 143.838C147.15 142.963 147.45 141.988 148.087 141.35C148.4 141.038 148.775 140.825 149.125 140.625C149.35 140.5 149.587 140.363 149.787 140.2C150.325 139.788 150.687 139.138 150.775 138.463C150.8 138.213 151.037 138.025 151.3 138.05C151.562 138.075 151.737 138.313 151.712 138.575C151.6 139.5 151.112 140.363 150.375 140.938C150.137 141.125 149.862 141.275 149.6 141.425C149.287 141.6 148.987 141.775 148.75 142C148.325 142.413 148.137 143.05 148.262 143.625C148.387 144.2 148.812 144.7 149.362 144.913C149.812 145.088 150.387 145.088 151.062 144.913C151.8 144.725 152.425 144.388 152.837 143.95C153.212 143.55 153.8 143.438 154.35 143.663C154.65 143.788 155.012 143.725 155.187 143.513C155.312 143.363 155.35 143.125 155.287 142.888C155.225 142.65 155.075 142.463 154.887 142.4C154.65 142.313 154.512 142.05 154.6 141.8C154.687 141.563 154.95 141.425 155.2 141.513C155.675 141.675 156.05 142.1 156.2 142.638C156.35 143.175 156.237 143.725 155.912 144.113C155.475 144.625 154.7 144.8 154.012 144.513C153.862 144.45 153.662 144.438 153.537 144.575C152.862 145.3 151.962 145.638 151.312 145.813C150.862 145.95 150.475 146 150.112 146Z"
        fill="#26282B"
      />
      <path
        d="M139.625 136.087C139.375 136.087 139.162 135.887 139.162 135.637C139.162 135.6 139.112 131.412 143.787 130.537C144.05 130.487 144.288 130.662 144.338 130.912C144.388 131.162 144.212 131.412 143.962 131.462C140.075 132.187 140.1 135.487 140.1 135.625C140.1 135.862 139.9 136.087 139.625 136.087C139.625 136.087 139.637 136.087 139.625 136.087Z"
        fill="#26282B"
      />
      <path
        d="M165.712 135.113C165.45 135.113 165.237 134.888 165.25 134.638C165.25 134.5 165.275 131.2 161.387 130.475C161.137 130.425 160.962 130.188 161.012 129.925C161.062 129.675 161.3 129.5 161.562 129.55C166.237 130.425 166.187 134.613 166.187 134.65C166.175 134.913 165.975 135.113 165.712 135.113Z"
        fill="#26282B"
      />
      <path
        d="M147.062 149.487C146.175 149.612 145.237 149.75 144.537 150.325C143.525 151.162 143.35 152.612 143.262 153.925C146.587 153.912 149.912 153.887 153.225 153.875C155.975 153.862 158.737 153.85 161.475 153.5C161 152.137 160.375 150.725 159.15 149.95C157.712 149.037 155.95 149.325 154.35 149.087C153.825 149.012 153.312 148.887 152.787 148.862C151.987 148.812 151.187 148.937 150.4 149.05C149.287 149.187 148.175 149.337 147.062 149.487Z"
        fill="#26282B"
      />
      <path
        d="M131.812 130.25C131.562 130.975 131.25 131.713 130.75 132.35C129.562 133.888 127.412 134.788 126.275 136.35C125.312 137.688 125.225 139.288 125.175 140.813C124.55 138.125 124.112 135.413 123.837 132.663C123.712 131.375 123.625 130.088 123.8 128.788C124.225 125.675 126.175 122.55 128.425 120.025C129.225 119.125 130.837 118.788 131.612 119.8C132.075 120.4 132.187 121.313 132.337 122.025C132.512 122.863 132.612 123.7 132.65 124.563C132.725 126.475 132.45 128.4 131.812 130.25Z"
        fill="#26282B"
      />
      <path
        d="M172.487 130.25C172.737 130.975 173.05 131.713 173.55 132.35C174.737 133.888 176.887 134.788 178.025 136.35C178.987 137.688 179.075 139.288 179.125 140.813C179.75 138.125 180.187 135.413 180.462 132.663C180.587 131.375 180.675 130.088 180.5 128.788C180.075 125.675 178.125 122.55 175.875 120.025C175.075 119.125 173.462 118.788 172.687 119.8C172.225 120.4 172.112 121.313 171.962 122.025C171.787 122.863 171.687 123.7 171.65 124.563C171.575 126.475 171.85 128.4 172.487 130.25Z"
        fill="#26282B"
      />
      <path
        d="M141.05 109.738C145.3 107.488 150.262 106.438 154.987 107.288C158.662 107.95 162.037 109.7 165.275 111.563C167.6 112.9 169.9 114.3 172.15 115.763C173.812 116.85 175.675 118.425 175.337 120.388C175.012 122.325 172.787 123.213 170.9 123.75C165.075 125.413 159.037 127.1 153.05 126.225C149.937 125.775 146.962 124.65 143.937 123.763C141.787 123.138 139.612 122.625 137.412 122.238C136.3 122.05 135.187 121.888 134.075 121.75C133.387 121.675 132.712 121.55 132.2 121.025C131.325 120.125 131.525 118.675 131.975 117.613C132.65 116 134.087 114.8 135.375 113.675C137.125 112.163 139.012 110.813 141.05 109.738Z"
        fill="#26282B"
      />
      <path
        opacity="0.38"
        d="M113.363 47.5127L121.288 50.1752H185.388L193.613 47.3252"
        fill="#26282B"
      />
      <path
        d="M95.05 87.6002C95.0375 87.6002 95.0125 87.6002 95 87.6002C94.825 87.5752 94.7125 87.4127 94.75 87.2377C94.7625 87.1502 96.2125 78.6127 96.4 73.6752C96.575 68.9627 98.2875 67.7752 98.6375 67.5877C101.263 65.0252 101.438 62.1002 100.8 60.3752C100.438 59.4127 99.8125 58.7627 99.1875 58.7252C98.7625 58.7002 98.4375 58.8127 98.1875 59.0627C97.55 59.7127 97.625 61.0377 97.625 61.0502C97.65 62.6877 96.7125 64.3127 95.275 65.0877C95.125 65.1752 94.9375 65.1127 94.85 64.9627C94.7625 64.8127 94.825 64.6252 94.975 64.5377C96.2125 63.8752 97.025 62.4752 97 61.0752C97 61.0252 96.9 59.4752 97.7375 58.6252C98.1125 58.2377 98.6125 58.0627 99.2125 58.1002C100.088 58.1502 100.925 58.9377 101.375 60.1502C102.088 62.0627 101.9 65.2877 99.0375 68.0627L98.95 68.1252C98.9375 68.1377 97.2125 69.0002 97.0375 73.7002C96.85 78.6752 95.4 87.2502 95.3875 87.3377C95.3375 87.4877 95.2 87.6002 95.05 87.6002Z"
        fill="#26282B"
      />
      <path
        d="M95.2875 64.0126C94.8375 64.0126 94.3 63.9126 93.7625 63.6001C92.4125 62.8001 91.6125 60.9376 91.375 58.0376C91.3625 57.8626 91.4875 57.7126 91.6625 57.7001C91.8375 57.6876 91.9875 57.8126 92 57.9876C92.2125 60.6251 92.925 62.3751 94.075 63.0626C95.1375 63.7001 96.2375 63.2251 96.2875 63.2001C96.45 63.1376 96.625 63.2001 96.7 63.3626C96.775 63.5251 96.7 63.7001 96.55 63.7751C96.5125 63.7751 96 64.0126 95.2875 64.0126Z"
        fill="#26282B"
      />
      <path
        d="M87.8625 135.562C87.7875 135.562 87.7125 135.55 87.65 135.512C87.425 135.387 87.3375 135.112 87.45 134.875C87.5 134.775 92.75 124.687 93.4875 112.575C93.5 112.312 93.7625 112.125 93.9875 112.137C94.25 112.15 94.4375 112.375 94.425 112.637C93.675 124.962 88.325 135.225 88.275 135.325C88.1875 135.475 88.025 135.562 87.8625 135.562Z"
        fill="#26282B"
      />
      <path
        d="M217.225 135.562C217.05 135.562 216.887 135.462 216.8 135.3C216.55 134.8 210.737 122.987 210.925 115.112C210.925 114.85 211.125 114.687 211.4 114.65C211.662 114.662 211.862 114.875 211.862 115.125C211.675 122.762 217.575 134.75 217.637 134.875C217.75 135.112 217.662 135.387 217.425 135.5C217.375 135.55 217.3 135.562 217.225 135.562Z"
        fill="#26282B"
      />
      <path
        d="M210.088 91.4251C209.925 91.4251 209.788 91.2876 209.775 91.1251C209.7 88.9126 208.225 71.8251 208 70.5751C207.888 69.9501 207.275 68.9376 206.575 67.7626C205.875 66.5876 205.075 65.2626 204.438 63.8126C203.5 61.6751 203.338 59.1751 204.05 57.7126C204.325 57.1376 204.725 56.7751 205.213 56.6626C205.5 56.5876 205.788 56.6501 206.05 56.8251C207.213 57.6126 207.713 60.8251 207.763 61.1876C207.825 61.5376 207.913 61.8751 208.15 62.0126C208.413 62.1501 208.775 62.1751 209.175 62.0751C209.475 62.0001 209.763 61.8751 210.063 61.7376L210.313 61.6251C211.2 61.2376 212 61.0376 212.738 61.0251C213.713 61.0001 214.55 61.2876 215.175 61.8251C215.55 62.1376 215.825 62.5376 216.05 62.8751C216.888 64.1376 217.513 65.5126 217.925 66.9751C217.975 67.1376 217.875 67.3126 217.713 67.3626C217.55 67.4126 217.375 67.3126 217.325 67.1501C216.938 65.7501 216.325 64.4376 215.525 63.2251C215.313 62.9001 215.075 62.5626 214.763 62.3001C214.25 61.8626 213.525 61.6376 212.738 61.6501C212.075 61.6626 211.363 61.8376 210.538 62.2001L210.288 62.3126C209.975 62.4501 209.65 62.6001 209.3 62.6876C208.913 62.7876 208.35 62.8376 207.838 62.5751C207.3 62.2876 207.188 61.6751 207.125 61.3001C206.988 60.3751 206.475 57.8876 205.675 57.3501C205.563 57.2751 205.45 57.2501 205.338 57.2751C204.963 57.3626 204.725 57.7251 204.588 58.0001C204.05 59.1001 204.013 61.3626 204.988 63.5751C205.613 64.9876 206.4 66.3001 207.1 67.4626C207.863 68.7376 208.475 69.7501 208.613 70.4876C208.838 71.7501 210.325 88.9126 210.4 91.1251C210.4 91.2751 210.263 91.4251 210.088 91.4251Z"
        fill="#26282B"
      />
      <path
        d="M148.188 290C147.95 290 147.75 289.825 147.725 289.575C144.738 261.112 145.875 227.337 150.438 209.25C152.275 201.975 154.9 196.025 158.5 191.062C162.613 185.375 167.3 177.7 167.35 177.625C167.488 177.4 167.775 177.337 167.988 177.475C168.213 177.612 168.275 177.9 168.138 178.112C168.088 178.187 163.388 185.887 159.25 191.612C155.725 196.487 153.138 202.337 151.338 209.487C146.8 227.475 145.663 261.125 148.65 289.475C148.675 289.737 148.488 289.962 148.238 289.987C148.225 290 148.2 290 148.188 290Z"
        fill="#004965"
      />
    </svg>
  );
};
export default NoDataSVG;
