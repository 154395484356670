import { Skeleton } from "@mui/material";

interface Props {
  loading?: boolean;
  children?: React.ReactNode;
  align?: "start" | "end";
  minWidth?: any;
  maxWidth?: any;
  variant?: any;
}
export default function ValueSkelton(props: Props) {
  const { loading = true, children, align = "start", minWidth = "100px", maxWidth="150px", variant="text"} = props;
  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: align}}>
        <Skeleton
          sx={{ minWidth: minWidth, maxWidth: maxWidth}}
          animation="wave"
          variant={variant}
        />
      </div>
    );
  }
  return <>{children}</>;
}
