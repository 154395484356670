import React, { useEffect, useMemo } from "react";
import { useFormikContext, Form } from "formik";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { AppDispatch, RootState } from "../../store";
import {
  HelpState,
  closePopup,
  initialState,
  updateFormValues,
} from "./helpSlice";
import Loader from "../../components/Loader";
import { UserDetailsState } from "../../components/UserProfile/userSlice";

const HelpForm = (props: { handleClose: any }) => {
  const { handleClose } = props;
  const { values, errors, touched, handleChange, handleBlur }: any =
    useFormikContext();
  const dispatch: AppDispatch = useDispatch();

  const { loading, error }: HelpState = useSelector(
    (state: RootState) => state.help
  );

  const { response }: UserDetailsState = useSelector(
    (state: RootState) => state.userDetails
  );

  useEffect(() => {
    dispatch(
      updateFormValues({
        ...values,
        mobile: values.mobile || response?.mobileNumber,
      })
    );
  }, [values]);

  useEffect(() => {
    if (response?.mobileNumber)
      dispatch(updateFormValues({ ...values, mobile: response?.mobileNumber }));
  }, [response]);

  const handleCancel = () => {
    handleClose();
    dispatch(
      updateFormValues({ ...initialState, mobile: response?.mobileNumber })
    );
    dispatch(closePopup());
  };

  const descPlaceholder = useMemo(() => {
    if (values.reason === "Feedback") {
      return "Please enter your comment here";
    } else if (values.reason === "Issue") {
      return "Please describe the issue you are encountering";
    } else {
      return "Kindly choose a reason from the options above";
    }
  }, [values.reason]);

  return (
    <Form noValidate>
      <Grid container spacing={2} gap={{ xs: 1, md: 0 }}>
        <Loader loading={loading} />
        {response?.mobileNumber ? (
          ""
        ) : (
          <Grid item xs={12}>
            <TextField
              hidden
              fullWidth
              id="mobile"
              name="mobile"
              label="Phone Number"
              value={values.mobile}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.mobile && Boolean(errors.mobile)}
              helperText={touched.mobile && errors.mobile}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ color: "black" }}>
                    +91{" "}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel
              error={touched.reason && Boolean(errors.reason)}
              id="reason-label"
            >
              Select your Reason
            </InputLabel>
            <Select
              IconComponent={KeyboardArrowDownRoundedIcon}
              labelId="reason-label"
              id="reason"
              name="reason"
              label="Select your Reason"
              placeholder="Please select your reason"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.reason}
              error={touched.reason && Boolean(errors.reason)}
            >
              <MenuItem value={"Issue"}>Issue</MenuItem>
              <MenuItem value={"Feedback"}>Feedback</MenuItem>
            </Select>
            {touched.reason && errors.reason ? (
              <FormHelperText
                sx={{ color: "#bf3333", marginLeft: "16px !important" }}
              >
                {touched.reason && errors.reason}
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            "& .MuiOutlinedInput-input ": {
              overflow: "hidden",
            },
          }}
        >
          <TextField
            fullWidth
            id="desc"
            onChange={handleChange}
            name="desc"
            placeholder={descPlaceholder}
            multiline
            rows={10}
            onBlur={handleBlur}
            error={touched.desc && Boolean(errors.desc)}
            helperText={touched.desc && errors.desc}
            value={values.desc}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {300 - values.desc.length}
                </InputAdornment>
              ),
              style: {
                alignItems: "flex-end",
                justifyContent: "flex-end",
                display: "flex",
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                color="inherit"
                id="login-module-btn"
                variant="text"
                fullWidth
                type="button"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <LoadingButton
                color="primary"
                id="login-module-btn"
                variant="contained"
                fullWidth
                disableElevation={true}
                type="submit"
                loading={loading}
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default HelpForm;
