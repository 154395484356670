import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../api";

export const getFinanceList = createAsyncThunk("user/finance", async (payload: any, { rejectWithValue }) => {
  try {
    const response = await API.post("/order/all", { ...payload })
    return response.data
  } catch (error: any) {
    return rejectWithValue(error.response.data.error);

  }

})

export const getOrderDetails = createAsyncThunk("user/order/details",
  async (payload: any, { rejectWithValue }) => {
    try {
      let response: any;
      if (payload.orderId) {
        response = await API.get(`/order/detail/${payload.orderId}`);
      }
      if (payload.loanId) {
        response = await API.get(`/order/detail/loan/${payload.loanId}`);
      }
      if (response.headers.authorization) {
        localStorage.setItem(
          "token",
          `Bearer ${response.headers.authorization}`
        );
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error);
    }
  }
)

export const getDisbursementDetails = createAsyncThunk("user/order/disbursementDetails",
  async (payload: any, { rejectWithValue }) => {
    try {
      let response: any;
      if (payload.orderId) {
        response = await API.get(`/order/disb/details?loanId=&orderId=${payload.orderId}`);
      }
      if (payload.loanId) {
        response = await API.get(`/order/disb/details?loanId=${payload.loanId}&orderId=`);
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error);
    }
  }
)

export const downloadDisbursementDetails = createAsyncThunk("user/order/disbursementDetails/download",
  async (payload: any, { rejectWithValue }) => {
    try {
      let response: any;
      if (payload.orderId) {
        response = await API.get(`/report/disbursement?orderId=${payload.orderId}`,{
          responseType: 'arraybuffer'
        });
      }
      if (payload.loanId) {
        response = await API.get(`/report/disbursement?loanId=${payload.loanId}`,{
          responseType: 'arraybuffer'
        });
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error);
    }
  }
)
