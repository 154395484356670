import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { getConfig } from './maintenanceApi'

export interface ConfigState {
  loading: boolean,
  error?: string,
  config: any,
}

const initialState: ConfigState = {
  loading: false,
  error: '',
  config: '',
}

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {

  },
  extraReducers: {
    [getConfig.pending.type]: (state, action) => {
      state.loading = true;
      state.error = '';
    },
    [getConfig.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.config = action.payload;
    },
    [getConfig.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = action.payload || 'Something went wrong';
    },
  },
})

// Action creators are generated for each case reducer function

export default configSlice.reducer