import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_API } from "../../../api";

export const getCommodities = createAsyncThunk("get/commodities", async (payload: any, { rejectWithValue }) => {
  try {
    const response = await BASE_API.get("/bijak/commodity/service/api/v1/commodity", { params: payload })
    return response.data.result
  } catch (error: any) {
    return rejectWithValue(error.response.data.error);
  }

})