import { TableCell, styled } from "@mui/material";

const HeadTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  color: theme.palette.info.dark,
  padding: "8px 12px",
}));

const RowTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  padding: "8px 12px",
  fontWeight: 500,
}));

export { HeadTableCell, RowTableCell };
