import {
  Box,
  Grid,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import OrderDivider from "../../../assets/images/order-border.png";
import { formatDate, formatNumber } from "../../../utils";
import { OrderDetailsState } from "./orderDetailsSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { LightTooltip } from "../../reports/ReportsFilter";
export const ORDER_DIVIDER_STYLE = {
  borderStyle: "solid",
  borderImageSource: `url(${OrderDivider})`,
  borderImageRepeat: "repeat",
  borderImageWidth: "0px 0px 2px 0px",
  borderImageSlice: "0 0 4 0",
};

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [` .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.info.main,
    color: "#ffffff",
    boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.16)",
    fontSize: "0.875rem",
    fontWeight: 600,
    lineHeight: "1.25rem",
    padding: "0.375rem 0.75rem",
    borderRadius: "0.25rem",
    marginRight: "8px !important",
  },
}));

export default function OrderDetailsTab() {
  const { currentOrder }: OrderDetailsState = useSelector(
    (state: RootState) => state.orderDetails
  );

  const {
    disburseDate,
    dueDate,
    tenure,
    orderAmount,
    orderAmountPaid,
    outOrderAmount,
    lateFeesPaid,
    outLateFees,
    tdsAmount,
    tcsAmount,
    totalPaidAmount,
    totalOutAmount,
    dueInDays,
    paymentDetails,
    compliance,
    lateFeeTdsAmount,
    totalTdsAmount,
    lateFees
  } = currentOrder;

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Grid
          container
          alignItems="center"
          sx={ORDER_DIVIDER_STYLE}
          padding={"1rem 1rem"}
        >
          <Grid item xs={4}>
            <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
              Disbursement Date
            </Typography>
            <Typography
              sx={{ color: "info.main", fontWeight: 500 }}
              variant="subtitle1"
            >
              {formatDate(disburseDate)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
              Due Date
            </Typography>
            <Typography
              sx={{ color: "info.main", fontWeight: 500 }}
              variant="subtitle1"
            >
              {!dueDate ? "N/A" : formatDate(dueDate)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
              Tenure
            </Typography>
            <Typography
              sx={{ color: "info.main", fontWeight: 500 }}
              variant="subtitle1"
            >
              {tenure ? `${tenure} Days` : "N/A"}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          padding={"1rem 1rem"}
          sx={ORDER_DIVIDER_STYLE}
        >
          <Grid item xs={4}>
            <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
              Order Amount
            </Typography>
            <Typography
              sx={{ color: "info.main", fontWeight: 500 }}
              variant="subtitle1"
            >
              {formatNumber(orderAmount)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
              Order Amount Paid
            </Typography>
            <Typography
              sx={{ color: "info.main", fontWeight: 500 }}
              variant="subtitle1"
            >
              {formatNumber(orderAmountPaid)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
              Order Amount Outstanding
            </Typography>
            <Typography
              sx={{ color: "info.main", fontWeight: 500 }}
              variant="subtitle1"
            >
              {formatNumber(outOrderAmount)}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          padding={"1rem 1rem"}
          sx={ORDER_DIVIDER_STYLE}
        >
          <Grid item xs={4}>
            <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
              Late Fees Charged
            </Typography>
            <Typography
              sx={{
                color: `${
                  lateFees > 0 ? "error.main" : "info.main"
                }`,
                fontWeight: 500,
              }}
              variant="subtitle1"
            >
              {formatNumber(lateFees)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
              Late Fees Paid
            </Typography>
            <Typography
              sx={{ color: "info.main", fontWeight: 500 }}
              variant="subtitle1"
            >
              {formatNumber(lateFeesPaid)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
              Late Fees Outstanding
            </Typography>
            <Typography
              sx={{ color: "info.main", fontWeight: 500 }}
              variant="subtitle1"
            >
              {formatNumber(outLateFees)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center" padding={"1rem 1rem"}>
          {compliance && (
            <Grid item xs={4}>
              <Typography
                sx={{
                  color: "info.dark",
                  display: "flex",
                  alignItems: "center",
                }}
                paddingBottom="8px"
              >
                {`${compliance} Amount`}
                {compliance == "TDS" && (
                  <LightTooltip
                    title={
                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "end",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <Typography
                            sx={{ color: "info.dark", fontSize: "14px" }}
                          >
                            TDS on Order Amount:
                          </Typography>
                          {formatNumber(tdsAmount || tcsAmount || 0)}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "end",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <Typography
                            sx={{ color: "info.dark", fontSize: "14px" }}
                          >
                            TDS on Late Fees:
                          </Typography>
                          {formatNumber(lateFeeTdsAmount || 0)}
                        </div>
                      </div>
                    }
                  >
                    <InfoIcon
                      sx={{
                        cursor: "pointer",
                        fontSize: "20px",
                        marginLeft: "4px",
                      }}
                    />
                  </LightTooltip>
                )}
              </Typography>
              <Typography
                sx={{ color: "info.main", fontWeight: 500 }}
                variant="subtitle1"
              >
                {formatNumber(totalTdsAmount || tcsAmount || 0)}
              </Typography>
            </Grid>
          )}
          <Grid item xs={4}>
            <Typography
              sx={{
                color: "info.dark",
                display: "flex",
                alignItems: "center",
              }}
              paddingBottom="8px"
            >
              Total Paid Till Date
              <LightTooltip title="Total Paid Till Date = Order Amount Paid + Late Fees Paid">
                <InfoIcon
                  sx={{
                    cursor: "pointer",
                    fontSize: "20px",
                    marginLeft: "4px",
                  }}
                />
              </LightTooltip>
            </Typography>
            <Typography
              sx={{ color: "info.main", fontWeight: 500 }}
              variant="subtitle1"
            >
              {formatNumber(totalPaidAmount)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
              Net Amount Outstanding
            </Typography>
            <Typography
              sx={{
                color:
                  totalOutAmount > 0 && dueInDays > 0
                    ? "error.main"
                    : "info.main",
                fontWeight: 500,
              }}
              variant="subtitle1"
            >
              {formatNumber(totalOutAmount)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{ width: 2, bgcolor: "info.contrastText", mr: "-2px" }}
        style={{ paddingTop: 0 }}
      />
      <Grid item xs={4} style={{ paddingLeft: 32, paddingTop: "2rem" }}>
        <Typography variant="subtitle1" color={"info.main"} fontWeight={500}>
          Payment History
        </Typography>
        {paymentDetails == null || paymentDetails.length == 0 ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            height="90%"
          >
            <Grid item xs={12}>
              <Typography textAlign={"center"}>
                Sorry, there are no payment records associated with this order.
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid
              item
              xs={12}
              mt={1}
              sx={{
                maxHeight: 292,
                overflowX: "hidden",
              }}
            >
              {paymentDetails?.map((payment: any) => (
                <Box
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: "secondary.light",
                    mt: 1,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Grid container p={2}>
                    <Grid item paddingBottom="10px" xs={12}>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: "info.dark" }}
                      >
                        {formatDate(payment.txnDate)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      mb="4px"
                      sx={{
                        justifyContent: "space-between",
                        display: "flex",
                      }}
                      xs={12}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ color: "info.dark" }}
                      >
                        {payment.type === "creditNote" ? (
                          <Grid container>
                            <Typography
                              variant="subtitle2"
                              sx={{ color: "info.dark" }}
                            >
                              <InfoIcon
                                fontSize="small"
                                sx={{ marginRight: "4px" }}
                              />
                              Credit Note
                            </Typography>
                          </Grid>
                        ) : (
                          "Payment Received"
                        )}
                      </Typography>
                      <Typography
                        sx={{ color: "info.main", fontWeight: 500 }}
                        variant="subtitle1"
                      >
                        {formatNumber(payment.payinAmount)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        justifyContent: "space-between",
                        display: "flex",
                      }}
                      xs={12}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ color: "info.dark" }}
                      >
                        Amount Settled Against This Order
                      </Typography>
                      <Typography
                        sx={{ color: "info.main", fontWeight: 500 }}
                        variant="subtitle1"
                      >
                        {formatNumber(payment.payinAmountUsed)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
