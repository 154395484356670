import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { CircularProgress, Paper, Typography } from "@mui/material";
import { formatDate, formatNumber } from "../../utils";
import {
  JAMUN_5,
  BLUE,
  JAMUN_80,
  MIRCHI_80,
  MIRCHI_5,
  KALA_80,
} from "../../styles/colors";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { useMemo, useRef, useState } from "react";
import _ from "lodash";
import { HTMLParser } from "../HTMLParser";
import moment from "moment";

interface Props {
  loading: boolean;
  upcomingDueAmount?: number;
  upcomingDueDate?: string;
  overdueAmount?: number;
}

const cheerfulStyle = {
  overdue: {
    borderColor: MIRCHI_80,
    background: MIRCHI_5,
    color: MIRCHI_80,
  },
  upcoming: {
    borderColor: BLUE,
    background: JAMUN_5,
    color: KALA_80,
  },
};
let cheerfulColor = cheerfulStyle.upcoming;

export default function CheerfulReminder(props: Props) {
  const {
    loading,
    upcomingDueAmount = 0,
    upcomingDueDate,
    overdueAmount = 0,
  } = props;
  const { config } = useSelector((state: RootState) => state.config);

  const cheerfulMessage = useMemo(() => {
    if (!config || !(upcomingDueAmount || overdueAmount)) return "";
    const {
      cheerfulNextDueTemplate,
      cheerfulOverDueTemplate,
      cheerfulDueTemplate,
      minAlertAmount,
      minUpcomingOverdueDays,
    } = config;
    if (upcomingDueAmount + overdueAmount < minAlertAmount) return "";
    if (
      cheerfulDueTemplate &&
      upcomingDueAmount &&
      overdueAmount &&
      upcomingDueDate &&
      // upcomingDueAmount + overdueAmount >= minAlertAmount &&
      // moment(upcomingDueDate, "DD-MMM-YYYY").diff(moment(), "days") <=
      //   minUpcomingOverdueDays &&
      upcomingDueDate === moment().format("DD-MMM-YYYY")
    ) {
      cheerfulColor = cheerfulStyle.overdue;
      const template = _.template(cheerfulDueTemplate);
      return template({
        amount: formatNumber(upcomingDueAmount + overdueAmount || 0),
        due_date: formatDate(upcomingDueDate),
      });
    }
    if (
      overdueAmount &&
      overdueAmount >= minAlertAmount &&
      cheerfulOverDueTemplate
    ) {
      const template = _.template(cheerfulOverDueTemplate);
      cheerfulColor = cheerfulStyle.overdue;
      return template({
        amount: formatNumber(overdueAmount || 0),
      });
    }

    if (!cheerfulNextDueTemplate) return "";
    if (
      upcomingDueDate &&
      upcomingDueAmount >= minAlertAmount &&
      moment(upcomingDueDate, "DD-MMM-YYYY").diff(
        moment().startOf("day"),
        "days"
      ) <= minUpcomingOverdueDays
    ) {
      cheerfulColor = cheerfulStyle.upcoming;
      const template = _.template(cheerfulNextDueTemplate);
      return template({
        amount: formatNumber(upcomingDueAmount || 0),
        due_date: formatDate(upcomingDueDate),
      });
    }
    return "";
  }, [config, upcomingDueAmount, upcomingDueDate, overdueAmount]);

  if (!cheerfulMessage) return null;

  return (
    <Paper
      sx={{
        display: "flex",
        padding: "1rem",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "1.25rem",
        borderRadius: "0.5rem",
        border: `2px solid ${cheerfulColor.borderColor}`,
        background: cheerfulColor.background,
        boxShadow: "0px 16px 16px 0px rgba(19, 71, 42, 0.05)",
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          color: cheerfulColor.color,
          fontWeight: 500,
          display: "flex",
          width: "100%",
        }}
      >
        <InfoRoundedIcon sx={{ color: cheerfulColor.borderColor }} />
        {loading ? (
          <CircularProgress size={25} sx={{ marginLeft: "40%" }} />
        ) : (
          <span style={{ marginLeft: "1rem" }}>
            <HTMLParser string={cheerfulMessage} />
          </span>
        )}
      </Typography>
    </Paper>
  );
}
