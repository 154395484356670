import { Fragment } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "../../CustomTable/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, Typography } from "@mui/material";
import ValueSkelton from "../../Skelton/ValueSkelton";
import { ORDER_DIVIDER_STYLE } from "./OrderDetailsTab";
import { HeadTableCell, RowTableCell } from "./CustomComponents";

export const DisbursementDetailsSkelton = () => {
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} paddingRight={"32px"} sx={{ paddingTop: 0 }}>
          <Grid container alignItems="center" padding={"1rem 1rem"}>
            <Grid item xs={3}>
              <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
                Amount Disbursed
              </Typography>
              <Typography
                sx={{ color: "info.main", fontWeight: 500 }}
                variant="subtitle1"
              >
                <ValueSkelton />
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
                Disbursement Pending
              </Typography>
              <Typography
                sx={{ color: "info.main", fontWeight: 500 }}
                variant="subtitle1"
              >
                <ValueSkelton />
              </Typography>
            </Grid>
            {Array.from(Array(2).keys()).map((item, index) => {
              return (
                <Grid item xs={3}>
                  <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
                    <ValueSkelton />
                  </Typography>
                  <Typography
                    sx={{ color: "info.main", fontWeight: 500 }}
                    variant="subtitle1"
                  >
                    <ValueSkelton />
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ border: "3px solid #E4F2FB" }}>
              <TableHead>
                <TableRow
                  sx={{
                    bgcolor: "#E4F2FB",
                    height: "40px",
                  }}
                >
                  <HeadTableCell sx={{}} align="left">
                    Disbursement Date
                  </HeadTableCell>
                  <HeadTableCell sx={{}} align="right">
                    Amount
                  </HeadTableCell>
                  <HeadTableCell sx={{}}>Account Holder Name</HeadTableCell>
                  <HeadTableCell sx={{}}>Account Number</HeadTableCell>
                  <HeadTableCell sx={{}}>IFSC</HeadTableCell>
                  <HeadTableCell sx={{}} align="left">
                    UTR
                  </HeadTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow hover={true}>
                  <RowTableCell align="left" sx={{ fontWeight: 500 }}>
                    <ValueSkelton />
                  </RowTableCell>
                  <RowTableCell sx={{ fontWeight: 500 }}>
                    <ValueSkelton align="end" />
                  </RowTableCell>
                  <RowTableCell align="left" sx={{ fontWeight: 500 }}>
                    <ValueSkelton />
                  </RowTableCell>
                  <RowTableCell align="left" sx={{ fontWeight: 500 }}>
                    <ValueSkelton />
                  </RowTableCell>
                  <RowTableCell align="left" sx={{ fontWeight: 500 }}>
                    <ValueSkelton />
                  </RowTableCell>
                  <RowTableCell align="left" sx={{ fontWeight: 500 }}>
                    <ValueSkelton />
                  </RowTableCell>
                </TableRow>
                <TableRow hover={true}>
                  <RowTableCell align="left" sx={{ fontWeight: 500 }}>
                    <ValueSkelton />
                  </RowTableCell>
                  <RowTableCell sx={{ fontWeight: 500 }}>
                    <ValueSkelton align="end" />
                  </RowTableCell>
                  <RowTableCell align="left" sx={{ fontWeight: 500 }}>
                    <ValueSkelton />
                  </RowTableCell>
                  <RowTableCell align="left" sx={{ fontWeight: 500 }}>
                    <ValueSkelton />
                  </RowTableCell>
                  <RowTableCell align="left" sx={{ fontWeight: 500 }}>
                    <ValueSkelton />
                  </RowTableCell>
                  <RowTableCell align="left" sx={{ fontWeight: 500 }}>
                    <ValueSkelton />
                  </RowTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Fragment>
  );
};
