import {
  Box,
  Button,
  TablePagination,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState, useMemo, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ErrorIcon from "@mui/icons-material/Error";
import { BERRY_70, KARELA_70 } from "../../../styles/colors";
import { getDownloadedLinkReport, getGeneratedReports } from "../ReportsApi";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { JAMUN_80 } from "../../../styles/colors";
import InitialReportData from "../InitialReportsData";
import GeneratedReportTableSkeleton from "./GeneratedReportTableSkeleton";
import { GeneratedReportState, updateFilters } from "./generatedReportSlice";
import { DownloadLinkState } from "./downloadReportSlice";
import { RowTableCell } from "../../Finance/OrderDetails/CustomComponents";
import { trackAnalytics } from "../../../Analytics";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Element, Link as ScrollLink } from "react-scroll";
import { errorMessage } from "../../../utils";

const GeneratedReport = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const reportRef = useRef<HTMLDivElement>(null);
  const [highlightRow, setHighlightRow] = useState(false);

  const {
    loading,
    response,
    error,
    filters,
    count,
    expireMesg,
    reportMesg,
  }: GeneratedReportState = useSelector(
    (state: RootState) => state.generatedReportList
  );

  const handleChangePage = (event: unknown, pageNo: number) => {
    dispatch(updateFilters({ pageNo }));
  };

  const { loading: downloadLinkLoading }: DownloadLinkState = useSelector(
    (state: RootState) => state.downloadReportLink
  );

  const { mobileNumber, response: mobileRes }: any = useSelector(
    (state: RootState) => state.mobileNum
  );

  const currentURL = window.location.href;

  const urlParams = new URLSearchParams(currentURL);
  const query: any = useMemo(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const reportId = urlParams.get("report_id");

  const [filterData, setFilterData] = useState(filters);

  useEffect(() => {
    // Update filters with report_id from URL params
    if (reportId) {
      setFilterData((prevFilters: any) => ({
        ...prevFilters,
        reportId: Number(reportId),
      }));
    }
  }, [reportId, location.search]);

  useEffect(() => {
    query?.tab == "generated" && reportMesg && errorMessage(reportMesg);
  }, [reportMesg]);

  useEffect(() => {
    if (query.report_id != null && response?.length > 0) {
      // setTimeout(() => {
      const element = document.getElementById(`${query.report_id}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      setHighlightRow(true);
      // }, 1000);
    }
  }, [location.pathname, response]);

  useEffect(() => {
    return () => {
      dispatch(updateFilters({ pageNo: 0, pageSize: 100 }));
    };
  }, [dispatch, location]);

  useEffect(() => {
    const newFilters = {
      pageNo: filters.pageNo,
      pageSize: filters.pageSize,
      status: "COMPLETE",
      reportId: null,
    };
    dispatch(updateFilters(newFilters));
    dispatch(getGeneratedReports(newFilters));
  }, [dispatch, filters]);

  useEffect(() => {
    if (query.tab) {
      const newFilters = {
        pageNo: 0,
        pageSize: 100,
        status: "COMPLETE",
        reportId: !!query.tab ? Number(query.report_id) : null,
      };
      dispatch(updateFilters(newFilters));
      dispatch(getGeneratedReports(newFilters));
    }
  }, [dispatch, location, query]);

  // useEffect(() => {
  //   dispatch(getGeneratedReports({ ...filterData }));
  // }, [dispatch, filterData]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      updateFilters({
        pageNo: 0,
        pageSize: parseInt(event.target.value, 10),
      })
    );
  };

  useEffect(() => {
    if (highlightRow) {
      const timer = setTimeout(() => {
        setHighlightRow(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [highlightRow]);

  const handleDownloadClick = async (
    event: React.ChangeEvent<HTMLInputElement>,
    reportId: any
  ) => {
    const res = dispatch(getDownloadedLinkReport({ reportId: reportId }));
    // event.preventDefault();
    //SEGMENT PART
    //when user clicks on the download button of a report
    const pageData = {
      source_page: "reports_tab",
      landing_page: "reports_tab",
      event_type: "click",
      tab: "generated",
      report_id: reportId,
    };

    trackAnalytics("report_download_btn", pageData);
    if ((await res).payload.response_code == 80) {
      window.open((await res).payload.message, "_blank", "noreferrer");
    }
  };

  useEffect(() => {
    const handlePopstate = (event: any) => {
      window.history.go(-1);
    };
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <Box p={2} pt={1}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Typography
            variant="h6"
            noWrap
            sx={{ color: "info.main", fontWeight: 600 }}
            component="div"
          >
            Your Reports
          </Typography>
        </Grid>
        {response?.length > 0 && (
          <Grid item xs={9} display={"flex"} justifyContent={"flex-end"}>
            <Typography
              noWrap
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                color: JAMUN_80,
                alignItems: "center",
                display: "flex",
                gap: "4px",
              }}
              component="div"
            >
              <InfoRoundedIcon fontSize="small" />
              {expireMesg}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          {loading && <GeneratedReportTableSkeleton />}
          {!loading &&
            response.map((row: any, index: number) => {
              return (
                row.status == "Complete" && (
                  <div id={row.reportId}>
                    <Grid
                      // id={row.reportId}
                      container
                      direction="row"
                      sx={{
                        padding: "16px",
                        backgroundColor:
                          query.report_id == row.reportId && highlightRow
                            ? "#E7F6EC"
                            : index % 2 == 0
                            ? "primary.contrastText"
                            : "secondary.light",
                        minHeight: "96px",
                      }}
                    >
                      <Grid item xs={10}>
                        <Grid container spacing={1} direction="column">
                          <Grid item xs={12}>
                            <Typography
                              fontWeight={400}
                              fontSize="14px"
                              color="info.dark"
                            >
                              {row.reportTime}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              fontWeight={700}
                              fontSize={"16px"}
                              color="info.main"
                            >
                              {row.reportName}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              fontWeight={400}
                              fontSize="12px"
                              lineHeight={"1.33"}
                              color="info.dark"
                            >
                              {row.reportType == "LEDGER" &&
                                `Selected Date range: ${row.feInput[0].startDate} - ${row.feInput[0].endDate}`}
                              {row.reportType == "LATEFEES" &&
                                `Selected Invoices: ${row.feInput[0]?.invoiceList?.join(
                                  ", "
                                )}`}
                              {row.reportType == "ORDER" && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                  }}
                                >
                                  <div>
                                    Selected Order Status:{" "}
                                    {row.feInput[0]?.orderStatus == "UNSETTLED"
                                      ? "Unsettled"
                                      : row.feInput[0]?.orderStatus == "SETTLED"
                                      ? "Settled"
                                      : "All"}
                                    {" Orders."}
                                  </div>
                                  <div>
                                    Selected{" "}
                                    {row.feInput[0]?.dateType == "DUEDATE"
                                      ? "Due"
                                      : "Disbursement"}{" "}
                                    Date Range: {row.feInput[0].startDate} -{" "}
                                    {row.feInput[0].endDate}
                                  </div>
                                </div>
                              )}
                              {row.reportType == "PAYMENT" &&
                                `Selected Transaction Date range: ${row.feInput[0].startDate} - ${row.feInput[0].endDate}`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                          gap: "40px",
                        }}
                      >
                        {/* <div
                        style={{
                          display: "inline-flex",
                          gap: 8,
                        }}
                      > */}
                        {row.newIcon && (
                          <div
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              cursor: "default",
                              backgroundColor: "#FAE5EF",
                              borderRadius: "24px",
                              padding: "8px 12px",
                              boxSizing: "content-box",
                              justifyContent: "space-between",
                              gap: "4px",
                            }}
                          >
                            <ErrorIcon
                              fontSize="small"
                              sx={{
                                color: BERRY_70, //acc to condition
                              }}
                            />
                            <Typography
                              fontWeight={600}
                              sx={{
                                color: BERRY_70, //acc to condition
                                fontSize: "12px",
                              }}
                            >
                              New
                            </Typography>
                          </div>
                        )}
                        <Button
                          disableElevation
                          role="link"
                          onClick={(e: any) =>
                            handleDownloadClick(e, row.reportId)
                          }
                          sx={{
                            color: downloadLinkLoading[row.reportId]
                              ? "#FFFFFF"
                              : KARELA_70,
                            backgroundColor: downloadLinkLoading[row.reportId]
                              ? "#A0A4A8"
                              : "#E7F6EC",
                            borderRadius: "4px",
                            padding: "8px",
                            alignItems: "center",
                            minWidth: "40px",
                            height: "40px",
                            "&:hover": {
                              backgroundColor: downloadLinkLoading[row.reportId]
                                ? "#A0A4A8"
                                : "#E7F6EC",
                            },
                          }}
                          disabled={downloadLinkLoading[row.reportId]}
                        >
                          {downloadLinkLoading[row.reportId] ? (
                            <CircularProgress
                              color="inherit"
                              style={{ width: "24px", height: "24px" }}
                            />
                          ) : (
                            <FileDownloadIcon />
                          )}
                        </Button>
                        {/* </div> */}
                      </Grid>
                    </Grid>
                  </div>
                )
              );
            })}
        </Grid>
        {response?.length == 0 && (
          <Grid
            item
            xs={12}
            style={{ height: "70vh", display: "flex", alignItems: "center" }}
          >
            <InitialReportData tab="Generated" />
          </Grid>
        )}
      </Grid>
      {count > 10 && (
        <Box
          sx={{
            backgroundColor: "secondary.light",
            borderRadius: "8px",
            marginTop: "10px",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={filters.pageSize}
            page={filters.pageNo}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </Box>
  );
};

export default GeneratedReport;
