import { Divider, Grid, Typography } from "@mui/material";
import CustomDivider from "./CustomDivider";
const NotificationHeading = () => {
  return (
    <>
      <Grid item xs={12} sx={{ padding: "0.5rem 1rem" }}>
        <Typography
          sx={{
            color: "info.main",
            fontSize: "1.25rem",
            fontWeight: "600",
            lineHeight: "1.75rem",
          }}
        >
          Notifications
        </Typography>
      </Grid>
      <CustomDivider />
    </>
  );
};
export default NotificationHeading;
