import * as React from "react";
import { Dialog, DialogContent, useTheme, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import WriteToUs from "./WriteToUs";
import Feedback from "./Feedback";
import Issue from "./Issue";
import { HelpState, closePopup } from "./helpSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";

interface Props {
  children: React.ReactNode;
}

function HelpModal(props: Props) {
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    dispatch(closePopup());
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { state }: HelpState = useSelector((state: RootState) => state.help);

  const renderPopup = () => {
    switch (state) {
      case 30:
        return <Feedback handleClose={handleClose} />;
      case 20:
        return <Issue handleClose={handleClose} />;
      default:
        return <WriteToUs handleClose={handleClose} />;
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div onClick={() => setOpen(true)}>{props.children}</div>
      <Dialog
        open={open}
        aria-labelledby="help-modal-title"
        aria-describedby="help-modal-description"
        maxWidth={"xs"}
        fullScreen={fullScreen}
        onClose={handleClose}
      >
        <DialogContent style={{ width: "99%" }}>{renderPopup()}</DialogContent>
      </Dialog>
    </div>
  );
}
export default HelpModal;
