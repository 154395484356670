export const REMOVE_FILTER = "Try removing filters to broaden your search.";
export const REQUEST_ASSISTANCE = "Please raise a ticket from the Help Section for further assistance.";
export const QUANTITY_UNITS = [
    {
      id: 3,
      name: "kg",
    },
    {
      id: 2,
      name: "quintal",
    },
    {
      id: 1,
      name: "ton",
    },
    {
      id: 4,
      name: "packet",
    },
    {
      id: 5,
      name: "crate",
    },
    {
      id: 6,
      name: "box",
    },
    {
      id: 7,
      name: "pc",
    },
  ]