import _ from "lodash";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import {
  JAMUN_5,
  JAMUN_5_HOVER,
  JAMUN_80,
  MIRCHI_5,
  MIRCHI_70,
} from "../../styles/colors";
import OrderDetails from "../Finance/OrderDetails/OrderDetails";
import TableRowsLoader from "../Skelton/RowsSkelton";
import { getPaymentHistory } from "./paymentHistoryApi";
import { PaymentState, updateFilters } from "./paymentHistorySlice";
import NoData from "../NoData";
import { REMOVE_FILTER, REQUEST_ASSISTANCE } from "../NoData/constants";
import {
  formatDate,
  formatNumber,
  defaultMessage,
  reportTypeMapping,
} from "../../utils";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DownloadButton from "../DownloadButton";
import { CircularProgress } from "@mui/material";
import { getGeneratePaymentHistoryReport } from "../reports/ReportsApi";
import { PaymentHistoryReportState } from "../reports/PaymentHistoryReport/paymentHistoryReportSlice";
import { ConfigState } from "../../features/maintenance/maintenanceSlice";
import { trackAnalytics } from "../../Analytics";

const TABLE_BODY_STYLE = {
  "&:last-child td, &:last-child th": { border: 0 },
  "&:nth-of-type(odd)": {
    backgroundColor: "primary.contrastText",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "secondary.light",
  },
};

const TABLE_HEAD_STYLE = {
  display: "grid",
  [`& .${tableCellClasses.root}`]: { borderBottom: "none" },
};

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: JAMUN_5,
  color: JAMUN_80,
  boxShadow: "0 2px 5px rgba(0, 0, 0, .3)",
  "&:hover": {
    backgroundColor: JAMUN_5_HOVER,
  },
  borderRadius: "4px",
}));

const NULL_BTN_STYLE = {
  color: `${MIRCHI_70} !important`,
  bgcolor: `${MIRCHI_5} !important`,
  boxShadow: "0 2px 5px rgba(0,0,0,0.3) !important",
  fontWeight: 500,
};

// COMPONENT STARTS-----------xx

const PaymentHistoryTable = ({ dateSelectionBtn }: any) => {
  const dispatch: AppDispatch = useDispatch();
  const [showToast, setShowToast] = useState(false);
  const { list, loading, filters, count }: PaymentState = useSelector(
    (state: RootState) => state.paymentHistory
  );

  const location = useLocation();

  const query: any = useMemo(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const delayedQuery = useCallback(
    _.debounce(async (params: any) => {
      dispatch(getPaymentHistory(params));
    }, 500),
    []
  );

  useEffect(() => {
    delayedQuery(filters);
  }, [dispatch, filters]);

  const handleChangePage = (event: unknown, pageNo: number) => {
    dispatch(updateFilters({ pageNo }));
  };

  const { loading: downloadPaymentHistoryLoading }: PaymentHistoryReportState =
    useSelector((state: RootState) => state.PaymentHistoryReport);

  const { response: generateReportResponse }: any = useSelector(
    (state: RootState) => state.generateReportList
  );

  const { mobileNumber, response: mobileRes }: any = useSelector(
    (state: RootState) => state.mobileNum
  );

  const { config }: ConfigState = useSelector(
    (state: RootState) => state.config
  );

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      updateFilters({ pageNo: 0, pageSize: parseInt(event.target.value, 10) })
    );
  };

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showToast]);

  useEffect(() => {
    if (!showToast) return;
    let message: any =
      "Your request has been submitted. You will be notified when the report is ready.";
    if (filters.startTxnDate === null) {
      message = (
        <>
          Your request has been submitted. The report will be generated from
          <br />
          {generateReportResponse[0].feInput[0].startDate} to
          {" " + generateReportResponse[0].feInput[0].endDate}
        </>
      );
    }
    defaultMessage(message);
  }, [showToast]);

  const handleDownloadClick = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const payload = {
      startDate: filters.startTxnDate,
      endDate: filters.endTxnDate,
      dateType: "TXNDATE",
      sortType: filters.sortType,
      columnName: filters.columnName,
    };
    const pageData = {
      source_page: "payments_history_tab",
      landing_page: "payments_history_tab",
      event_type: "click",
      tab: "",
      report_name: reportTypeMapping("PAYMENT", config),
      payload: {...payload, selected_range_btn: dateSelectionBtn} ,
    };

    trackAnalytics("generate_report_btn", pageData);
    const paymentHistoryResp = dispatch(
      await getGeneratePaymentHistoryReport(payload)
    );
    if ((await paymentHistoryResp).payload.response_code == 80) {
      setShowToast(true);
    }
  };

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      borderRadius: "4px",
      marginTop: "8px !important",
    },
  }));

  const getSettledAgainstVoiceNum = (invoice: {
    invoiceNumber: any;
    loanId: string | number;
  }) => {
    return (
      <OrderDetails
        loanId={invoice.loanId}
        key={invoice.loanId}
        source={"payment_history"}
      >
        {invoice.invoiceNumber === "NA" || !invoice.invoiceNumber ? (
          <LightTooltip
            title="It appears that there isn't an invoice number associated with this order."
            placement="bottom-start"
          >
            <StyledButton>N/A</StyledButton>
          </LightTooltip>
        ) : (
          <StyledButton>{invoice.invoiceNumber}</StyledButton>
        )}
      </OrderDetails>
    );
  };

  const getSettledAgainstCharges = (invoice: { name: any; amount: number }) => {
    return (
      <LightTooltip
        title={`Amount utilized: ${formatNumber(invoice.amount)}`}
        placement="bottom-start"
      >
        <Button
          variant="contained"
          sx={{
            ...NULL_BTN_STYLE,
            color: "info.main",
            bgcolor: "info.contrastText",
            "&:hover": {
              color: "info.main",
              bgcolor: "info.contrastText",
            },
          }}
          disableRipple
          style={{ cursor: "default", borderRadius: "4px" }}
        >
          {invoice.name}
        </Button>
      </LightTooltip>
    );
  };

  const getFormattedRowData = (val: any) => {
    if (typeof val === "number") {
      return formatNumber(val);
    }
    return val ? val : "N/A";
  };

  if (!loading && list?.length === 0) {
    return (
      <NoData
        objKey="utr"
        value={filters.utr}
        updateFilters={updateFilters}
        desc={!filters.startTxnDate ? REQUEST_ASSISTANCE : REMOVE_FILTER}
      />
    );
  }

  return (
    <Box p={2} pt={0}>
      <TableContainer sx={TABLE_HEAD_STYLE}>
        <Table sx={{ minWidth: 350 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ bgcolor: JAMUN_5 }}>
              <TableCell sx={{ color: "info.dark" }}>
                Transaction Date
              </TableCell>
              <TableCell align="right" sx={{ color: "info.dark" }}>
                Amount
              </TableCell>
              <TableCell align="left" sx={{ color: "info.dark" }}>
                Mode
              </TableCell>
              <TableCell align="left" sx={{ color: "info.dark" }}>
                UTR
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "info.dark",
                  alignItems: "center",
                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                <div>Settlement Invoice Number/Charges</div>
                {config.ordersAndPaymentHistoryReportFlag && (
                  <LightTooltip
                    title={"Download results"}
                    placement="bottom-end"
                    style={{ cursor: "pointer" }}
                  >
                    <DownloadButton
                      style={{
                        width: "1.5rem",
                        height: "1.5rem",
                        backgroundColor: downloadPaymentHistoryLoading
                          ? "#A0A4A8"
                          : "#247BC7",
                      }}
                      onClick={(e: any) => handleDownloadClick(e)}
                    >
                      {downloadPaymentHistoryLoading ? (
                        <CircularProgress
                          color="inherit"
                          style={{
                            width: "1rem",
                            height: "1rem",
                            // backgroundColor: "#A0A4A8",
                          }}
                        />
                      ) : (
                        <FileDownloadIcon fontSize="small" />
                      )}
                    </DownloadButton>
                  </LightTooltip>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? <TableRowsLoader rowsNum={10} columnsNum={5} /> : <></>}
            {!loading &&
              list.map((row, index) => (
                <TableRow
                  hover={true}
                  sx={TABLE_BODY_STYLE}
                  key={row.paymentId}
                >
                  <TableCell sx={{ fontWeight: 500, minWidth: "8rem" }}>
                    {formatDate(getFormattedRowData(row.txnDate))}
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 500 }}>
                    {getFormattedRowData(row.amount)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 500 }}>
                    {getFormattedRowData(row.mode)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 500 }}>
                    {getFormattedRowData(row.utr)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 500 }}>
                    <Box display="flex" flexWrap="wrap" gap={2} width="100%">
                      {row.invoices.length === 0 && !row.charges?.length && (
                        <LightTooltip
                          title="The payment is in the process of being settled to clear outstanding invoices."
                          placement="bottom-start"
                        >
                          <Button
                            variant="contained"
                            sx={NULL_BTN_STYLE}
                            disableRipple
                            style={{ cursor: "default", borderRadius: "4px" }}
                          >
                            Awaiting Settlement
                          </Button>
                        </LightTooltip>
                      )}
                      {row.invoices.map(
                        (invoice: {
                          invoiceNumber: any;
                          loanId: string | number;
                        }) => getSettledAgainstVoiceNum(invoice)
                      )}
                      {row.charges?.map(
                        (charge: { name: string; amount: number }) =>
                          getSettledAgainstCharges(charge)
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {count > 10 && (
        <Box
          sx={{
            backgroundColor: "secondary.light",
            borderRadius: "8px",
            marginTop: "10px",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[10, 20]}
            component="div"
            count={count}
            rowsPerPage={filters.pageSize}
            page={filters.pageNo}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </Box>
  );
};

export default PaymentHistoryTable;
