import { Grid, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import feedback from "../../assets/images/feedback.png";
import feedbackThanks from "../../assets/images/feedbackThanks.svg";
import { AppDispatch } from "../../store";
import { useDispatch } from "react-redux";
import { closePopup } from "./helpSlice";

export default function Feedback(props: { handleClose: any }) {
  const dispatch: AppDispatch = useDispatch();
  const { handleClose } = props;
  const handleCancel = () => {
    handleClose();
    dispatch(closePopup());
  };

  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={12} justifyContent="flex-end" display="flex">
        <CloseIcon onClick={handleCancel} />
      </Grid>
      <Grid item xs={12} justifyContent="center" display="flex">
        <img src={feedbackThanks} alt="feedback" />
      </Grid>
      <Grid item xs={12} justifyContent="center" display="flex">
        <Typography variant="h6" color="primary">
          We appreciate your feedback.
        </Typography>
      </Grid>
      <Grid item xs={12} justifyContent="center" display="flex">
        <Typography
          variant="subtitle1"
          color="info.dark"
          style={{ textAlign: "center" }}
        >
          It will help us to improve our service.
        </Typography>
      </Grid>
    </Grid>
  );
}
