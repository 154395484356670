import AuthLayout from "../layouts/auth";

import { useSelector } from "react-redux";
import { RootState } from "../store";
import Loader from "../components/Loader";
import Login from "./Login";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function LoginPage() {
  const { loading } = useSelector((state: RootState) => state.setPassword);

  const { mobileNumber } = useSelector((state: RootState) => state.mobileNum);

  const navigate = useNavigate();

  useEffect(() => {
    if (!mobileNumber) {
      navigate("/");
    }
  }, [mobileNumber]);

  return (
    <AuthLayout>
      <Loader loading={loading} />
      <Login />
    </AuthLayout>
  );
}
export default LoginPage;
