import React from "react";
import { Grid } from "@mui/material";
import bijak from "../../assets/images/logo/bijak.svg";

const BijakLogo = () => {
  return (
    <Grid item xs={12}>
      <img className="bijak-logo" src={bijak} width="156px" />
    </Grid>
  );
};

export default BijakLogo;
