import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { ConfigState } from "./features/maintenance/maintenanceSlice";
import { useSelector } from "react-redux";
import { RootState } from "./store";
import { useEffect, useState } from "react";
import { routes as appRoutes } from "./routes";
import NotFound from "./pages/NotFound";
import Offline from "./components/Offline";
import Loader from "./components/Loader";
import { UserDetailsState } from "./components/UserProfile/userSlice";

export default function AppRoute() {
  const { config, loading, error }: ConfigState = useSelector(
    (state: RootState) => state.config
  );

  const { response }: UserDetailsState = useSelector(
    (state: RootState) => state.userDetails
  );

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (config?.maintenance && location.pathname !== "/maintenance") {
      navigate("/maintenance");
    }
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [config, location]);

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  return (
    <>
      <Loader loading={loading && location.pathname !== "/maintenance"} />
      <Routes>
        {appRoutes.map(
          (route) =>
            (_.isEmpty(config) ||
              _.isEmpty(route.roles) ||
              (config[route.roles?.config] &&
                (_.isEmpty(response)
                  ? true
                  : route.roles?.userDetails
                  ? response[route.roles?.userDetails]
                  : true))) && (
              <Route
                key={route.key}
                path={route.path}
                element={<route.component />}
              />
            )
        )}
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </>
  );
}
