import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import AppLayout from "../layouts/app";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { FinanceTable } from "../components/Finance/FinanceTable";
import { formatNumber } from "../utils";
import OrderAggregates from "../components/OrderAggregates";
import SearchIcon from "@mui/icons-material/Search";
import SearchField from "../components/Finance/SearchInvoiceNumber";
import { useDispatch, useSelector } from "react-redux";
import {
  FinanceState,
  initialState,
  updateFilters,
} from "../components/Finance/financeSlice";
import React, { useState, useEffect } from "react";
import { AppDispatch, RootState } from "../store";
import { useNavigate, useLocation } from "react-router-dom";
import { analytics, trackAnalytics } from "../Analytics";
import queryString from "query-string";

function FinanceList() {
  const navigate = useNavigate();
  const location = useLocation();

  const { list, loading, filters }: FinanceState = useSelector(
    (state: RootState) => state.financeItems
  );

  const { mobileNumber, response }: any = useSelector(
    (state: RootState) => state.mobileNum
  );

  const query: any = React.useMemo(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const dispatch: AppDispatch = useDispatch();

  // window.onpopstate = function () {
  //   const prevRoute = location.state?.prevRoute;
  //   if (filters.orderOrInvoiceNumber != null) {
  //     dispatch(updateFilters({ pageNo: 0, orderOrInvoiceNumber: null }));
  //     navigate(location.pathname, { state: { prevRoute: prevRoute } });
  //   } else if (
  //     initialState.filters.sortKey != filters.sortKey ||
  //     initialState.filters.sortOrder != filters.sortOrder ||
  //     initialState.filters.orderStatus != filters.orderStatus ||
  //     initialState.filters.disburseEndDate != filters.disburseEndDate ||
  //     initialState.filters.dueEndDate != filters.dueEndDate
  //   ) {
  //     navigate(location.pathname, {
  //       state: { reload: true, prevRoute: prevRoute },
  //     });
  //   } else {
  //     navigate(prevRoute, {
  //       state: { reload: true, prevRoute: location.pathname },
  //     });
  //   }
  // };

  //SEGMENT PART
  //when user lands on orders tab
  useEffect(() => {
    if (!mobileNumber || location.state?.trackEvent === false) {
      return;
    }
    const prevRoute = location?.state?.prevRoute;
    let pageData;
    if (query.utm_source) {
      pageData = {
        source_page: "deeplink",
        landing_page: "orders_tab",
        event_type: "pageload",
      };
    } else if (!query.utm_source && query.tab) {
      pageData = {
        source_page: "notification",
        landing_page: "orders_tab",
        event_type: "pageload",
      };
    } else if (prevRoute || mobileNumber) {
      pageData = {
        source_page:
          (prevRoute == "/orders" && "orders_tab") ||
          (prevRoute == "/payments" && "payment_history_tab") ||
          (prevRoute == "/reports" && "reports_tab") ||
          (mobileNumber && "password_input_screen"),
        landing_page: "orders_tab",
        event_type: "pageload",
      };
    } else {
      pageData = {
        source_page: "direct",
        landing_page: "orders_tab",
        event_type: "pageload",
      };
    }
    if (query.tab || location.state) {
      trackAnalytics("orders_tab_pageload", pageData);
    } else if (!query.tab) {
      trackAnalytics("orders_tab_pageload", pageData);
    }
  }, [location, mobileNumber]);

  useEffect(() => {
    if (!mobileNumber) {
      return;
    }
    if (query.utm_source == "email") {
      const pageData = {
        source_page: "email",
        landing_page: "orders_tab",
        event_type: "pageload",
        deeplink_type: query.deeplink_type,
        deeplink_url: `${window.location.href}`,
        order_id: query?.orderId,
      };

      trackAnalytics("deeplink_click", pageData);
    }
  }, [location, mobileNumber]);

  /*const { list, loading, filters }: FinanceState = useSelector(
    (state: RootState) => state.financeItems
  );

  const dispatch: AppDispatch = useDispatch();

  window.onpopstate = function () {
    console.log(location.state?.prevRoute);
    if (filters.orderOrInvoiceNumber != null) {
      dispatch(updateFilters({ pageNo: 0, orderOrInvoiceNumber: null }));
      navigate("/", {state: { prevRoute: location.state?.prevRoute}});
    } else if (
      initialState.filters.sortKey != filters.sortKey ||
      initialState.filters.sortOrder != filters.sortOrder ||
      initialState.filters.orderStatus != filters.orderStatus ||
      initialState.filters.disburseEndDate != filters.disburseEndDate ||
      initialState.filters.dueEndDate != filters.dueEndDate
    ) {
      navigate("/", { state: { reload: true, prevRoute: location.state?.prevRoute}});
    }
  };*/

  return (
    <AppLayout
      window={undefined}
      heading="Finance"
      searchField={<SearchField />}
    >
      <Box sx={{ width: "97%", m: "0 auto", mt: 6, borderRadius: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <OrderAggregates />
          </Grid>
          <Grid item xs={12}>
            <FinanceTable />
          </Grid>
        </Grid>
      </Box>
    </AppLayout>
  );
}
export default FinanceList;
