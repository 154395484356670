import {
  Grid,
  Typography,
  Button,
  styled,
  CircularProgress,
} from "@mui/material";
import { Fragment } from "react";
import React, { useState, useEffect } from "react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import {
  getInvoiceNumber,
  getGeneratePaymentHistoryReport,
  getGenerateLateFeeReport,
  getGenerateReports,
} from "../ReportsApi";
import { KALA_70 } from "../../../styles/colors";
import moment from "moment";
import { PaymentHistoryReportState } from "./paymentHistoryReportSlice";
import HelpIcon from "@mui/icons-material/Help";
import DateRangeReport from "../DateRangeReport";
import { LightTooltip } from "../ReportsFilter";
import { defaultMessage, reportTypeMapping } from "../../../utils";
import { ConfigState } from "../../../features/maintenance/maintenanceSlice";
import { subMonths, format, endOfMonth, startOfMonth } from "date-fns";
import { analytics, trackAnalytics } from "../../../Analytics";

interface Option {
  id: number;
}

function PaymentHistoryFormReport() {
  const dispatch: AppDispatch = useDispatch();
  const [calenderFilterBy, setCalenderFilterBy] = React.useState("");
  const [openDateRange, setOpenDateRange] = React.useState(false);
  const [applied, setApplied] = React.useState(false);
  const [showToast, setShowToast] = useState(false);
  const [dateSelectionBtn, setDateSelectionBtn] = useState("Last Month");

  const { loading }: PaymentHistoryReportState = useSelector(
    (state: RootState) => state.PaymentHistoryReport
  );

  const { config }: ConfigState = useSelector(
    (state: RootState) => state.config
  );

  const [range, setRange] = React.useState([
    {
      startDate: startOfMonth(subMonths(new Date(), 1)),
      endDate: endOfMonth(subMonths(new Date(), 1)),
      key: "selection",
    },
  ]);

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showToast]);

  useEffect(() => {
    if (showToast) {
      defaultMessage(
        "Your request has been submitted. You will be notified when the report is ready."
      );
    }
  }, [showToast]);

  const { mobileNumber, response: mobileRes }: any = useSelector(
    (state: RootState) => state.mobileNum
  );

  const handleGenerateReport = async () => {
    const payload = {
      startDate: moment(range[0].startDate).format("YYYY-MM-DD"),
      endDate: moment(range[0].endDate).format("YYYY-MM-DD"),
      dateType: "TXNDATE",
      sortType: "DESC",
      columnName: "txnDate",
    };
    const pageData = {
      source_page: "reports_tab",
      landing_page: "reports_tab",
      event_type: "click",
      tab: "generate",
      report_name: reportTypeMapping("PAYMENT", config),
      payload: {
        ...payload,
        selected_range_btn: dateSelectionBtn,
      },
    };

    trackAnalytics("generate_report_btn", pageData);
    const paymentHistoryResp = dispatch(
      await getGeneratePaymentHistoryReport(payload)
    );
    if ((await paymentHistoryResp).payload.response_code == 80) {
      setShowToast(true);
    }
  };

  return (
    <Fragment>
      <Grid item>
        <Grid container spacing={1} direction="row" alignItems={"center"}>
          <Grid item xs={9.7}>
            <Grid
              container
              direction="row"
              sx={{ gap: "24px", alignItems: "center" }}
            >
              <Grid item>
                <LightTooltip
                  title={config.Tooltip?.transactionDateReport}
                  placement="bottom-start"
                  style={{ cursor: "pointer" }}
                >
                  <Typography fontWeight={600} color={KALA_70}>
                    Transaction Date{" "}
                    <sup>
                      <HelpIcon
                        sx={{
                          width: "10px",
                          height: "10px",
                          color: KALA_70,
                        }}
                      />
                    </sup>
                  </Typography>
                </LightTooltip>
              </Grid>
              <DateRangeReport
                applied={applied}
                setApplied={setApplied}
                openDateRange={openDateRange}
                setOpenDateRange={setOpenDateRange}
                dateSelectionBtn={dateSelectionBtn}
                setDateSelectionBtn={setDateSelectionBtn}
                range={range}
                setRange={setRange}
                calenderFilterBy={calenderFilterBy}
                setCalenderFilterBy={setCalenderFilterBy}
                reportFilter={"PaymentHistoryReport"}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={2.3}
            justifyContent={"right"}
            alignItems={"center"}
            sx={{
              display: "flex",
              width: "fit-content",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              //   fullWidth
              type="submit"
              onClick={handleGenerateReport}
              id="generate-form-btn"
              style={{
                borderRadius: "8px",
                padding: "12px 16px",
                gap: "8px",
              }}
              disabled={loading}
            >
              Generate Report
              {loading ? (
                <CircularProgress
                  color="inherit"
                  style={{ width: "20px", height: "20px" }}
                />
              ) : (
                <ArrowForwardIcon fontSize="small" />
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default PaymentHistoryFormReport;
