import * as React from "react";

import TermsAndConditions from "./TermsAndConditions";

interface Props {
  children: React.ReactNode;
}

function TermsAndConditionsModal(props: Props) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{width:"100%"}}>
      <div onClick={() => setOpen(true)}>{props.children}</div>
      <TermsAndConditions isOpen={open} handleClose={handleClose} />
    </div>
  );
}
export default TermsAndConditionsModal;
