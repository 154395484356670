export const JAMUN_5 = '#E4F2FB';
export const JAMUN_5_HOVER = '#cdebff';
export const JAMUN_80 = '#114D96';
export const BLUE = "#247BC7";
export const MIRCHI_80 = "#A1131A";
export const MIRCHI_70 = "#BD252B";
export const MIRCHI_5 = "#FCE9EC";
export const KARELA_80 = "#198548";
export const NIMBU_5 = "#FFF0D1";
export const NIMBU_80 = "#C98C10"
export const KARELA_70 = "#219653"
export const KALA_80 = "#25282B"
export const BERRY_5 = "#FAE5EF"
export const BERRY_70 = "#BD276D"
export const KALA_70 = "#52575C"
