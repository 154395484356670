// src/routes.ts

// pages
import LoginPage from "./pages/LoginPage";
import FinanceList from "./pages/FinanceList";
import About from "./pages/About";
import LoginOTPVerification from "./pages/LoginOTPVerification";
import PasswordOTPVerification from "./pages/PasswordOTPVerification"
import MobileLogin from "./pages/MobileLogin"
import CreatePassword from "./pages/CreatePassword";
import ResetPassword from "./pages/ResetPassword";
import PaymentHistoryPage from "./pages/PaymentHistoryPage";

import { IRoute } from './interfaces'
import Maintenance from "./features/maintenance";
import POManagement from "./pages/POManagement";
import ReportsPage from "./pages/ReportsPage";

export const routes: Array<IRoute> = [
    {
        key: 'login-route',
        title: 'Login',
        path: '/',
        enabled: true,
        component: MobileLogin
    },
    {
        key: 'orders-route',
        title: 'Orders List',
        path: '/orders',
        enabled: true,
        component: FinanceList
    },
    {
        key: 'about-route',
        title: 'About',
        path: '/about',
        enabled: true,
        component: About
    },
    {
        key: 'mobile-password-login-route',
        title: 'Mobile / Password Login',
        path: '/login',
        enabled: true,
        component: LoginPage
    },
    {
        key: 'maintenance-route',
        title: 'Maintenance',
        path: '/maintenance',
        enabled: true,
        component: Maintenance
    },
    {
        key: 'payments',
        title: 'Payment History',
        path: '/payments',
        enabled: true,
        component: PaymentHistoryPage
    },
    {
        key: 'purchase-order-management',
        title: 'PO Management',
        path: '/purchase-orders',
        enabled: true,
        component: POManagement,
        roles: { config: 'poFlag', 'userDetails': 'poViewAccess' }
    },
    {
        key: 'reports',
        title: 'Reports',
        path: '/reports',
        enabled: true,
        component: ReportsPage,
        roles: { config: 'reportsFlag' }
    }
]