const Ellipse2 = () => {
  return (
    <>
      <svg id = 'Ellipse2'
        width="16"
        height="16"
        viewBox="0 0 16 16"
              fill="none"
              
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle id="Ellipse 2" cx="8" cy="8" r="8" fill="#BD252B" />
      </svg>
    </>
  );
};

export default Ellipse2;
