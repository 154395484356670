import * as React from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { logoutUser } from "./logoutApi";
import { LogoutSate } from "./logoutSlice";
import Loader from "../Loader";
import { analytics } from "../../Analytics";

interface Props {
  children: React.ReactNode;
}

function Logout(props: Props) {
  const theme = useTheme();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  const { loading, response }: LogoutSate = useSelector(
    (state: RootState) => state.logout
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    const resp = await dispatch(logoutUser(""));
    if (resp) {
      analytics.reset();
      localStorage.removeItem("token");
      setOpen(false);
      navigate("/");
    }
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div style={{ width: "100%" }}>
      <Loader loading={loading} />
      <div onClick={() => setOpen(true)}>{props.children}</div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
        fullScreen={fullScreen}
        sx={{ boxShadow: "0px 0px 12px 0px #00000029" }}
        onClose={handleClose}
      >
        <Box sx={{ borderRadius: "16px" }}>
          <DialogTitle id="alert-dialog-title">
            <Grid
              container
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Grid item>
                <Typography color="info.main" variant="h3">
                  Logout
                </Typography>
              </Grid>
              <Grid item xs={12} marginBottom={"15px"}>
                <Typography variant="body2" color="info.light">
                  Are you sure you want to logout?
                </Typography>
              </Grid>
              {/* <Grid item>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid> */}
            </Grid>
          </DialogTitle>
          <DialogContent style={{ width: "99%" }}>
            <DialogContentText id="alert-dialog-description">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={3}
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <Grid item xs={6}>
                      <Button
                        sx={{
                          color: "info.dark",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                        onClick={handleClose}
                        fullWidth
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleLogout}
                        fullWidth
                      >
                        Logout
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContentText>
            {/* <DialogActions></DialogActions> */}
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
}
export default Logout;
