import { createSlice, original } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { getVarieties } from './varietyApi';
import { errorMessage } from '../../../utils';



export interface VarietyState {
  loading: boolean,
  response: any,
  error: string | null,
  search: string,
}

export const initialState: VarietyState = {
  loading: true,
  error: null,
  response: [],
  search: "",
}

export const varietiesSlice = createSlice({
  name: 'varietyList',
  initialState,
  reducers: {
    updateFilters: (state, action: PayloadAction<any>) => {
      if (!_.isEqual(state.search, state.search || action.payload)) {
        state.search = action.payload
      }
    },
  },
  extraReducers: {
    [getVarieties.pending.type]: (state, action) => {
      state.loading = true;
    },
    [getVarieties.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.response = action.payload.data;
    },
    [getVarieties.rejected.type]: (state, action) => {
      state.loading = false;
      state.response = [];
      state.error = action.payload || 'Something went wrong';
      errorMessage(action.payload || 'Something went wrong')
    },
  }
})

// Action creators are generated for each case reducer function
export const { updateFilters } = varietiesSlice.actions



export default varietiesSlice.reducer