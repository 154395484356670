import * as React from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./TermsAndConditions.css";

function TermsAndConditions({ isOpen, handleClose }: any) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"md"}
      fullScreen={fullScreen}
      sx={{ borderRadius: "16px" }}
      onClose={handleClose}
    >
      <DialogTitle className="terms-conditions" id="alert-dialog-title">
        <Grid
          container
          style={{ flexDirection: "row", justifyContent: "space-between" }}
        >
          <Grid item>
            <Typography color="primary.main" variant="h3">
              Terms and Conditions
            </Typography>
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ width: "99%" }}>
        <DialogContentText id="alert-dialog-description">
          <ol>
            <li>
              <strong>INTRODUCTION</strong>
              <ol>
                <li>
                  This document is an electronic record in terms of Information
                  Technology Act, 2000 and rules there under as applicable and
                  the amended provisions pertaining to electronic records in
                  various statutes as amended by the Information Technology Act,
                  2000. This electronic record is generated by a computer system
                  and does not require any physical or digital signatures. This
                  document is published in accordance with the provisions of
                  Rule 3 (1) of the Information Technology (Intermediaries
                  guidelines) Rules, 2011 that require publishing the rules and
                  regulations, privacy policy and Terms &amp; Conditions.
                  <br />
                  <br />
                  Krishiacharya Technologies India Private Limited (the
                  “Company” or “Bijak”) a Company incorporated under Companies
                  Act, 2013, having its registered office at 90B, Delhi Jaipur,
                  Expressway, Udyog Vihar, Sector -18 Gurugram, Haryana –
                  122008, maintains this website (
                  <a
                    href="https://www.bijak.in/"
                    style={{
                      textDecoration: "underline",
                      color: "#198548",
                    }}
                  >
                    www.bijak.in
                  </a>
                  ) and mobile apps – Bijak on Google Android Playstore (
                  <a
                    href="https://play.google.com/store/apps/details?id=com.bedwal.bijak.mvp&amp;hl=en"
                    style={{ color: "#198548", textDecoration: "underline" }}
                  >
                    https://play.google.com/store/apps/details?id=com.bedwal.bijak.mvp&amp;hl=en
                  </a>
                  ) &amp; Bijak on Apple App Store (
                  <a
                    href="https://itunes.apple.com/app/id1568697202"
                    style={{ color: "#198548", textDecoration: "underline" }}
                  >
                    https://itunes.apple.com/app/id1568697202
                  </a>
                  ) mobile-optimized versions of the Website, digital
                  applications, and other media formats for use and promotion of
                  the Company (collectively and individually, the
                  <strong>"Platform"</strong>) which acts as an “intermediary”
                  in accordance with the meaning of “Intermediary” envisaged
                  under Section 2(w) of the Information Technology Act, 2000, as
                  amended from time to time (“Intermediary”). The Platform is an
                  online listing and discovery medium to facilitate
                  business-to-business transactions by providing buyers and
                  sellers better prices, increased working capital and optimised
                  logistics. Access and use of this Platform by you are governed
                  exclusively by these terms and conditions including the
                  applicable policies which are incorporated herein by way of
                  reference <strong>("Terms and Conditions").</strong>
                  <br />
                  <br />
                  Transactions done under this platform are subject to the
                  terms/policies that are mentioned in the Platform . By mere
                  use of the Platform, You shall be contracting with
                  Krishiacharya Technologies Pvt. Ltd. (“Bijak”) and these terms
                  and conditions including the policies shall be binding on the
                  User.
                  <br />
                  <br />
                  For the purpose of these Terms and Conditions, wherever the
                  context so requires "You" or "User" shall mean any natural or
                  legal person who has agreed to become a buyer on the Platform
                  by providing Registration Data while registering on the
                  Platform as Registered User using the computer systems or any
                  other electronic medium.
                  <br />
                  <br />
                  User understands and accepts that the Company maintains the
                  Platform to provide visitors with information about the
                  Company, its Services and products. any use of the Platform
                  constitutes your acceptance and agreement to be bound by such
                  terms, and the changes made to this Terms and Conditions from
                  time to time, relating to your usage of the Platform as made
                  available on the Company's Platform.
                  <br />
                  <br />
                  Upon the usage of any service on the Platform, you will be
                  subject to the rules, guidelines, policies, terms, and
                  conditions applicable to such service, and they shall be
                  deemed to be incorporated into this Terms and Conditions and
                  shall be considered as part and parcel of these Terms and
                  Conditions. Bijak reserves the right, at it’s sole discretion,
                  to change, modify, add or remove portions of these Terms and
                  Conditions, at any time without any prior written notice. It
                  is the responsibility of the User to review these Terms and
                  Conditions periodically for updates / changes and shall adhere
                  to the same.. As long as You comply with these Terms and
                  Conditions, we grant You a personal, non-exclusive,
                  non-transferable, limited privilege to enter and use the
                  Platform.
                  <br />
                  ACCESSING, BROWSING OR OTHERWISE USING THE PLATFORM INDICATES
                  YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS, KINDLY READ
                  THE TERMS AND CONDITIONS CAREFULLY BEFORE PROCEEDING.
                </li>
              </ol>
            </li>
            <br />
            <br />
            <li>
              <strong>DEFINITIONS AND INTERPRETATION</strong>
              <ol>
                <li>
                  Unless the context otherwise requires, for these Terms and
                  Conditions, the following terms shall have the meaning
                  ascribed to them hereunder:
                  <ol type="1">
                    <li>
                      <strong>"Applicable Law"</strong> means all laws,
                      ordinances, statutes, rules, orders, decrees, injunctions,
                      licenses, permits, approvals, authorizations, consents,
                      waivers, privileges, agreements, and regulations of any
                      governmental authority/court of law having jurisdiction
                      over the relevant matter including any interpretations
                      thereof, in effect;
                    </li>
                    <br />
                    <li>
                      <strong>"Intellectual Property Rights"</strong> means all
                      patents, designs and drawings, trademarks, service marks,
                      logos, domain names, and utility models, copyrights,
                      inventions, brand names, and business names, and any
                      similar rights and the benefit (subject to the burden) of
                      any of the foregoing (in each case whether registered or
                      otherwise, and includes applications for the grant of any
                      of the foregoing and the right to apply for any of the
                      foregoing in any part of the world);
                    </li>
                    <br />
                    <li>
                      <strong>“Privacy Policy”</strong> shall mean the Privacy
                      Policy available on the Platform and mentioned in clause 6
                      of this document;
                    </li>
                    <br />
                    <li>
                      <strong>"Services"</strong> means and includes disbursal
                      of loans to buyers, enabling immediate payment to
                      suppliers, buyer and seller ratings to bring
                      accountability, incentivize good behavior and address
                      trust deficit, easy and convenient bookkeeping, managing
                      large value transactions, access to the largest network of
                      verified buyers and sellers with accurate price data and
                      aggregation to reduce cost, eliminate wastage and cater to
                      partial truckload requirements. <br /> Company on and/or
                      from the Platform;
                    </li>
                    <br />
                    <li>
                      <strong>“Transaction Documents”</strong> means the various
                      documents required to be executed by the User to obtain
                      the services of the Company on and/or from the Platform;
                    </li>
                    <br />
                    <li>
                      <strong>“User Account”</strong> means the personal online
                      account created by the User to gain access and use the
                      Platform.
                    </li>
                    <br />
                  </ol>
                </li>
                <li>
                  Accordingly, the terms <strong>“Krishiacharya”</strong>,{" "}
                  <strong>“Bijak”</strong>,<strong>“Platform”</strong>,{" "}
                  <strong>“we”</strong>, <strong>“our”</strong> and
                  <strong>“us”</strong> in these Terms and Conditions refer to
                  the Company.
                </li>
              </ol>
            </li>
            <br />
            <br />
            <li>
              <strong>PROPRIETARY RIGHTS</strong>
              <ol>
                <li>
                  User acknowledges and agrees that the Company owns all legal
                  right, title, and interest in and to the Services, including
                  any Intellectual Property Rights which subsist in the Services
                  (whether those rights happen to be registered or not, and
                  wherever in the world those rights may exist). User further
                  acknowledges that the Services may contain information which
                  is designated confidential by the Company and that User shall
                  not disclose such information without the Company's prior
                  written consent.
                </li>
                <br />
                <li>
                  Unless the User has agreed otherwise in writing with the
                  Company, nothing in these Terms and Conditions gives the User
                  a right to use any of the Company's trade names, trademarks,
                  service marks, logos, domain names, and other distinctive
                  brand features.
                </li>
                <br />
                <li>
                  Unless you have been expressly authorized to do so in writing
                  by the Company, User agrees that in using the Services, User
                  will not use any trademark, service mark, trade name, the logo
                  of any company or organization in a way that is likely or
                  intended to confuse the owner or authorized user of such
                  marks, names or logos.
                </li>
              </ol>
            </li>
            <br />
            <br />
            <li>
              <strong>USER ACCOUNTS</strong>
              <ol>
                <li>
                  Transaction on the Platform is available only to persons who
                  can enter into legally binding contracts under Indian Contract
                  Act, 1872. Persons who are "incompetent to contract" within
                  the meaning of the Indian Contract Act, 1872 including
                  un-discharged insolvents etc. are not eligible to use the
                  Platform. If you are a minor i.e. under the age of 18 years,
                  you may use the Platform or access content on the Platform
                  only under the supervision and prior consent/ permission of a
                  parent or legal guardian.
                  <br />
                  As a minor if you wish to transact on the Platform, such
                  transaction on the Platform may be made by your legal guardian
                  or parents. Bijak reserves the right to terminate your
                  membership and / or refuse to provide you with access to the
                  Platform if it is brought to Bijak's notice or if it is
                  discovered that You are under the age of 18 years and
                  transacting on the Platform.
                </li>
                <br />
                <li>
                  A User must first register and create his distinct Account on
                  the Platform ("User Account") to access the services available
                  on the Platform. By registering an account with us
                  ("Registered User"), the User represents and warrants that he
                  has complied with eligibility guidelines as detailed in Clause
                  4.1 herein above. All information that you submit is true,
                  accurate, and complete and you shall comply with these Terms
                  and Conditions. User further represents and warrants that: (a)
                  all required registration information submitted by User is
                  truthful, accurate, and complete; and that (b) User will
                  maintain the accuracy of such information.
                </li>
                <br />
                <li>
                  You agree that if You provide any information that is untrue,
                  inaccurate, not current or incomplete or We have reasonable
                  grounds to suspect that such information is untrue,
                  inaccurate, not current or incomplete, or not in accordance
                  with the this Terms and Conditions, We shall have the right to
                  indefinitely suspend or terminate or block access of your
                  membership on the Platform and refuse to provide You with
                  access to the Platform.
                </li>
                <br />
                <li>
                  You agree that Bijak shall not be liable or responsible for
                  the activities or consequences of use or misuse of any
                  information that occurs under your Account in cases,
                  including, where You have failed to update Your revised mobile
                  phone number and/or e-mail address on the Platform.
                </li>
                <br />
                <li>
                  When You use the Platform or send emails or other data,
                  information or communication to us, You agree and understand
                  that You are communicating with Us through electronic records
                  and You consent to receive communications via electronic
                  records from Us periodically and as and when required. We may
                  communicate with you by email or by such other mode of
                  communication, electronic or otherwise.
                </li>
                <br />
                <li>
                  <strong>Confidentiality Obligation:</strong> User will be
                  solely responsible for maintaining the confidentiality of
                  their User Account and login details, and we will not be
                  responsible for misuse of your User Account by any third
                  party, whether authorized by you or not. User may never use
                  another User's account without due permissions of such User
                  and us. User is also prohibited from sharing their log-in
                  details or allowing anyone to access their account or do
                  anything that might put their User Account at risk. However,
                  if Company is retaining any data under this or any other
                  policy, it will be under legal obligation to maintain its
                  confidentiality, as per applicable laws, and the
                  Buyers/Sellers on the platform provide consent that such data
                  as per applicable laws, can be retained by the Company (if
                  required). However, if required, Company is entitled to
                  provide the data to any authority as may be required under
                  applicable laws or court orders.
                </li>
                <br />
                <li>
                  Users are responsible for any activity on the Platform arising
                  out of any failure to keep their User Account information
                  confidential and shall be liable for any losses arising out of
                  such a failure. User agrees to notify us immediately in case
                  of any unauthorized/suspicious activity in their Account or
                  any other breach of security.
                </li>
                <br />
                <li>
                  For a User to be listed as a trader on the Platform, the User
                  will be required to provide copies of their KYC documents and
                  other business registrations to the Company and maintain their
                  registration as a trader under the applicable State/ Union
                  Territory specific Agricultural Produce Marketing Committee
                  (APMC) laws ("APMC Laws") and / or have relevant license from
                  the local mandis in accordance with APMC Laws. For the
                  purposes of listing and maintaining its status as a trader on
                  the Platform, the User hereby represents to the Company that
                  prior to and during the subsistence of its listing on the
                  Platform, all necessary mandi cess/fees/ charges are paid by
                  the User with respect to commodities under the applicable APMC
                  Laws and/or that such User is expressly permitted under an
                  express exception under the relevant APMC Laws.
                </li>
                <br />
                <li>
                  The Company reserves the right to refuse to offer access to or
                  use of the Platform to any person or entity at the Company's
                  sole discretion including by changing its eligibility criteria
                  at any time.
                </li>
                <br />
                <li>
                  <strong>DELETE MY ACCOUNT:</strong>
                  <br />
                  You may choose to deactivate your account at any point through
                  your app. Rest assured, we do not share your data externally.
                  However, we keep your personal data as long as we need it for
                  its intended purpose. Once that purpose is fulfilled, we
                  securely dispose of it, unless specific laws require us to
                  retain transaction logs for extended periods even after an
                  account deactivation. Additionally, if there are ongoing legal
                  or regulatory proceedings or if we receive specific legal or
                  regulatory directives, we may be obligated to retain your
                  personal data for extended durations as mandated by local
                  laws.
                  <br />
                  <br />
                  <b>Steps to deactivate account in Bijak:</b>
                  <br />
                  <br />
                  <b>
                    My Profile --&gt; Edit Profile --&gt; Request to Deactivate
                    (at the bottom) --&gt; Select your reason for deactivation
                  </b>
                  <br />
                  <br />
                  <b>*</b>Deleting your account is an irreversible process,
                  which we can't revert even if you perform it by accident.
                </li>
                <br />
                <li>
                  <strong>TDS DEDUCTION ON REWARDS:</strong> During the process
                  of adding or updating information, it's essential to ensure
                  that the PAN provided belongs to the user registered with
                  Bijak.
                </li>
                <br />
                <li>
                  When adding or updating bank account information, it is
                  important to note that bank account provided will be
                  considered the user's account for any incoming or outgoing
                  payments, including rewards.
                </li>
                <br />
                <li>
                  The user must be engaged in agricultural or related businesses
                  and should exclusively utilise the application's services for
                  agricultural, allied, and trade-related purposes.
                </li>
              </ol>
            </li>
            <br />
            <br />
            <li>
              <strong>SERVICES PROVIDED ON THE PLATFORM</strong>
              <ol>
                <li>
                  The Company provides a digital marketplace through its
                  Platform, whereby it connects users for the purpose of trade,
                  thereby facilitating smooth trade amongst them.{" "}
                </li>
                <br />
                <li>
                  The Platform also provides a ready reckoner of prevailing
                  market prices of goods in various Mandis in the Country.
                </li>
                <br />
                <li>
                  The Company also provides a trade credit facility to
                  commission agents which the agents can use to make payments to
                  the suppliers. As the Company is an intermediary, therefore,
                  loan/ advances shall never be directly provided by the
                  Company.
                </li>
              </ol>
            </li>
            <br />
            <br />
            <li>
              <strong>PRIVACY POLICY</strong>
              <br />
              <p>
                This clause along with privacy policy mentioned on the website
                (the “Privacy Policy”) describes how and why the Bijak Platform
                collects, uses, transfers and discloses the information provided
                by the users. All users shall be bound by this Privacy Policy
                and the Company shall not use any information supplied by Users,
                except in accordance with this Privacy Policy. Protection of
                Your privacy is our utmost responsibility. We store and process
                Your Information including any sensitive financial information
                collected (as defined under the Information Technology Act,
                2000), if any, on computers that may be protected by physical as
                well as reasonable technological security measures and
                procedures in accordance with Information Technology Act 2000
                and Rules there under.
                <br />
                <br />
                We may share personal information with our other corporate
                entities and affiliates or to any third party without seeking
                your consent to facilitate our marketing and advertising
                activities, or to prevent, detect, mitigate, and investigate
                fraudulent or illegal activities related to our Services. We do
                not disclose your personal information to third parties for
                their marketing and advertising purposes.
                <br />
                <br />
                We may disclose personal information if required to do so by law
                or in the good faith belief that such disclosure is reasonably
                necessary to respond to subpoenas, court orders, or other legal
                processes. We may disclose personal information to law
                enforcement offices, third party rights owners, or others in the
                good faith belief that such disclosure is reasonably necessary
                to: enforce our Terms or Privacy Policy; respond to claims that
                an advertisement, posting or other content violates the rights
                of a third party; or protect the rights, property or personal
                safety of our users or the general public.
                <br />
                <br />
                Bijak reserves the right to modify this Privacy Policy at any
                time, so please review it frequently. Changes and clarifications
                will take effect immediately upon their posting on the Platform.
                If the Company makes any material changes to this Privacy
                Policy, the Company will notify Users that the policy has been
                updated, so that Users are aware of what information is
                collected by the Company, how it is used, and under what
                circumstances, if any, can the Company use and/or disclose it.
              </p>
            </li>
            <br />
            <br />
            <li>
              <strong>DISCLAIMER</strong>
              <ol>
                <li>
                  User agrees that their use of the Platform shall be at their
                  own risk. To the fullest extent permitted by law, the company
                  and its officers, managers, members, directors, employees,
                  successors, assigns, affiliates, suppliers, and agents
                  disclaim all warranties, express, implied, statutory or
                  otherwise, and make no warranties, representations, or
                  guarantees in connection with the Platform, the services
                  offered on or through the Platform, any data, materials,
                  content, relating to the quality, suitability, truth, accuracy
                  or completeness of any information or material contained or
                  presented on the Platform, including without limitation the
                  materials, data and submitted content of other users of this
                  site or other third parties. Unless otherwise explicitly
                  stated, to the maximum extent permitted by applicable law, the
                  Platform, the services offered on or through the Platform,
                  data, materials, submitted content, and any information or
                  material contained or presented on the Platform is provided to
                  you on an "as is", "as available" and "where-is" basis with no
                  warranty, express or implied, of merchantability, fitness for
                  a particular purpose, or non-infringement of third-party
                  rights.
                </li>
                <br />
                <li>
                  The Company does not provide any warranties against errors,
                  mistakes, or inaccuracies of data, content, information,
                  materials, the substance of the Platform, postings, feedback
                  or content, any unauthorized access to or use of our secure
                  servers, and/or any personal information and/or financial
                  information stored therein, any bugs, viruses, trojan horses,
                  or the like which may be transmitted to or through the
                  Platform, any interruption or cessation of transmission to or
                  from the Platform, any defamatory, offensive, or illegal
                  conduct of any third party or user, or any loss or damage of
                  any kind incurred as a result of the use of any data, content,
                  information, materials, the substance of the Platform or
                  content posted, emailed, transmitted, or otherwise made
                  available via the Platform.
                </li>
                <br />
                <li>
                  The Company does not endorse, warrant, guarantee, or assume
                  responsibility for any product or service advertised or
                  offered by a third party through the Platform or any
                  hyperlinked site or featured in any banner or other
                  advertisement. The company will not be a party to or in any
                  way be responsible for monitoring any transaction between you
                  and any party, including third-party providers of products or
                  services. As with the use of any product or service, and the
                  publishing or posting of any material through any medium or in
                  any environment, you should use your best judgment and
                  exercise caution where appropriate.
                </li>
                <br />
                <li>
                  When registering with a mobile number on Bijak App, the User
                  authorizes and gives consent to the Company or an any
                  third-party service provider, to send, from time to time,
                  various information/alerts/SMS/WhatsApp Messages/Calls or
                  commercial communication, and other services on the aforesaid
                  listed telephone/mobile numbers, whether these numbers are
                  registered with National Do Not Call Registry/listed in
                  National Customer Preference Register or not. User cannot hold
                  the Company or its third-party service provider
                  liable/institute complaint under the Telecom Commercial
                  Communications Customer Preference (TRAI) Regulations, 2010 or
                  such other applicable regulations including amendment thereof,
                  as may be applicable from time to time.
                  <p>
                    Users can stop this service, by writing an email to{" "}
                    <a
                      href="mailto:contact@bijak.in"
                      style={{ color: "#198548" }}
                    >
                      contact@bijak.in
                    </a>{" "}
                    or calling customer support number{" "}
                    <a href="tel:+91-8588999944" style={{ color: "#198548" }}>
                      +91-8588999944
                    </a>
                    .
                  </p>
                </li>
                <br />
                <li>
                  We do not endorse, warrant, guarantee, validate or assume
                  responsibility for the descriptions, images, appearance,
                  nature, quality, purpose, general features, and other content
                  of goods or services on the platform posted by the seller.
                </li>
                <br />
                <li>
                  User agrees that this agreement or any other related policy
                  may be updated from time to time and shall be binding on the
                  users.
                </li>
                <br />
                <li>
                  User acknowledges the obligation to pay the agreed commission
                  to the Company in compliance with Section 186(7) of the
                  Companies Act.
                </li>
                <br />
                <li>
                  Bijak is not responsible for any non-performance or breach of
                  terms of any contract or transactions entered into between
                  Users. Bijak cannot and does not guarantee that the concerned
                  Users acting in the capacity of Buyers or Sellers will perform
                  any transaction concluded on the Platform. Bijak shall not and
                  is not required to mediate or resolve any dispute or
                  disagreement between Buyers and Sellers.
                </li>
                <br />
                <li>
                  Bijak does not make any representation or warranty as to the
                  item-specifics (such as legal title, creditworthiness,
                  identity, etc) of any of its Users. You are advised to
                  independently verify the bona fides of any particular User
                  that You choose to deal with on the Platform and use Your best
                  judgment in that behalf.
                </li>
                <br />
                <li>
                  You release and indemnify Bijak and/or any of its officers and
                  representatives from any cost, damage, liability or other
                  consequence of any of the actions of the Users of the Platform
                  and specifically waive any claims that you may have in this
                  behalf under any applicable law. Notwithstanding its
                  reasonable efforts in that behalf, Bijak cannot take
                  responsibility or control the information provided by other
                  Users which is made available on the Platform. You may find
                  other User's information to be offensive, harmful,
                  inconsistent, inaccurate, or deceptive. Please use caution and
                  practice safe trading when using the Platform.
                </li>
                <br />
                <li>
                  Please note that there could be risks in dealing with underage
                  persons or people acting under false pretence.
                </li>
              </ol>
            </li>
            <br />
            <br />
            <li>
              <strong>UPDATION/ AMENDMENT</strong>
              <ol>
                <li>
                  The Company can update this or any other related policy by
                  uploading an updated version of this policy on the Platform or
                  any click-through format which would be considered a deemed
                  consent of user.
                </li>
                <br />
                <li>
                  The most recent version of this or any other policy will
                  govern the use of the information (including Personal
                  Information) on the Platform. We may revise or amend this from
                  time to time. If we decide to change this or the Privacy
                  Policy or any other related policy, we will inform you by
                  posting the revised policies on the platform. Company may
                  also, but is not required to, notify you of changes to the
                  policies via email to the email address associated with your
                  account. If you object to any changes in this or any other
                  related policy, you should immediately stop using the platform
                  and/or Services and close any related accounts. By continuing
                  to access or use the platform and/or Services after changes
                  have become effective, you agree to be bound by the revised
                  amendments.
                </li>
              </ol>
            </li>
            <br />
            <br />
            <li>
              <strong>LIMITATIONS OF LIABILITY</strong>
              <ol>
                <li>
                  In no event shall the Company, its affiliates or its
                  respective officers, managers, members, directors, employees,
                  successors, assigns, subsidiaries, suppliers, attorneys or
                  agents, be liable to a user for any direct, indirect,
                  incidental, special, punitive, consequential or exemplary
                  damages (including but not limited to loss of business,
                  revenue, profits, use, data or other economic advantage)
                  whatsoever resulting from any (i) access to or use of the
                  Platform; (ii) errors or omissions in any data, content,
                  information, materials or substance of the Platform or
                  content; (vii) any failed negotiations for purchase of
                  fractional ownership of any listed property on the Platform,
                  any disputes that arise during or after the negotiation for
                  purchase of fractional ownership of any listed property on the
                  Platform, or any other dispute that arises between users of
                  the Platform; (viii) any defamatory, offensive, or illegal
                  conduct of any third party or user;
                </li>
                <br />
                <li>
                  The foregoing limitation of liability shall apply to the
                  fullest extent permitted by law in the applicable
                  jurisdiction.
                </li>
                <br />
                <li>
                  In no event shall the total aggregate liability of the
                  company, or any of the above-referenced respective parties,
                  arising from or relating to the Platform, exceed the total
                  amount paid to the Company by a User for the Services under
                  which such liability arose.
                </li>
                <br />
                <li>
                  The Platform may contain links to third-party Platforms that
                  are not owned or controlled by the Company. The Company does
                  not have any control over and assumes no responsibility for,
                  the content, privacy policies, Terms and Conditions, or
                  practices of any third-party Platforms. In addition, the
                  Company will not and cannot censor or edit the content of any
                  third-party site. By using third-party Platforms, you
                  expressly relieve the company from any liability arising from
                  your use of any third-party Platform. Accordingly, please be
                  advised to read the terms and conditions and privacy policy of
                  each third-party Platform that you visit, including those
                  directed by the links contained on the Platform.
                </li>
              </ol>
            </li>
            <br />
            <br />
            <li>
              <strong>INDEMNIFICATION</strong>
              <ol>
                <li>
                  You agree to defend, indemnify and hold harmless the Company,
                  its affiliates, and its respective officers, managers,
                  members, directors, employees, successors, assigns,
                  subsidiaries, affiliates, suppliers, and agents, from and
                  against any claims, damages, obligations, losses, liabilities,
                  costs or debt, and expenses (including but not limited to
                  attorneys' fees) arising from your use of, access to, and
                  participation on the Platform; your violation of any provision
                  of the Terms and Conditions, including the privacy policy;
                  your violation of any third-party right, including without
                  limitation any copyright, property, proprietary, Intellectual
                  Property Rights, or breach of your express or implied
                  representations and warranties. This defense and
                  indemnification obligation will survive these terms and
                  conditions and your use of the Platform.
                </li>
                <br />
                <li>
                  The Company is an intermediary which acts as a mere
                  facilitator between the buyers and the sellers, hence, shall
                  not be held accountable for any reasons whatsoever.
                </li>
              </ol>
            </li>
            <br />
            <br />
            <li>
              <strong>BREACH OF TERMS AND CONDITIONS</strong>
              <ol>
                <li>
                  Without prejudice to the Company's other rights under the
                  Terms and Conditions, if a User breaches any provisions of
                  these Terms and Conditions in any way, or if the Company
                  reasonably suspects that a User has breached these Terms.
                  <br />
                  <br />
                  In no event shall the Company, its affiliates or its
                  respective officers, managers, members, directors, employees,
                  successors, assigns, subsidiaries, suppliers, attorneys or
                  agents, be liable to a user for any direct, indirect,
                  incidental, special, punitive, consequential or exemplary
                  damages (including but not limited to loss of business,
                  revenue, profits, use, data or other economic advantage)
                  whatsoever resulting from any (i) access to or use of the
                  Platform; (ii) errors, mistakes, or inaccuracies of data,
                  Intellectual Property Rights, content, information, materials
                  or substance of the Platform; (iii) any unauthorized access to
                  or use of our secure servers and / or any and all personal
                  information and / or financial information stored therein;
                  (iv) any bugs, viruses, trojan horses, or the like which may
                  be transmitted to or and Conditions in any way, the Company
                  may take such action as it deems appropriate to deal with the
                  breach, including (a) temporarily suspend such User's access
                  to the Platform, (b) permanently prohibit such User from
                  accessing the Platform, (c) blocking computers/devices using
                  such User's IP address from accessing the Platform, (d)
                  contacting any or all of such User's internet service
                  providers and request that they block such User's access to
                  the Platform, (e) commence legal action against such User,
                  whether for breach of contract or otherwise; and/or (f)
                  suspend or delete such User's User Account.
                </li>
              </ol>
            </li>
            <br />
            <br />
            <li>
              <strong>MODIFICATIONS TO OR TERMINATION OF THE PLATFORM</strong>
              <ol>
                <li>
                  <strong>Modification or Cessation of Platform</strong>
                  <p>
                    The Company reserves the right to, at any time and from time
                    to time, modify or discontinue, temporarily or permanently,
                    the Platform (or any part thereof) with or without notice
                    and in its sole discretion. Users agree that the Company
                    shall not be liable to the User or any third party for any
                    modification, suspension, or discontinuance of the services
                    offered by the Company.
                    <br />
                    User hereby acknowledges and agree that the Company, in its
                    sole and absolute discretion, has the right (but not the
                    obligation) to delete, terminate, or deactivate User
                    Account, block User's email or <b>IP</b> address, cancel the
                    Platform or otherwise terminate User's access to or
                    participation in the use of the Platform (or any part
                    thereof), or remove and discard any content submitted by
                    User on the Platform, immediately and without notice, for
                    any reason, including without limitation, User Account
                    inactivity or if the Company believes or has reason to
                    believe that the User has violated any provision of the
                    Terms and Conditions.
                  </p>
                </li>
                <br />
                <li>
                  <strong>Effect of Termination</strong>
                  <p>
                    Upon termination of the User Account, User's right to
                    participate in the Platform, including, but not limited to,
                    User's right to receive any rental shall automatically
                    terminate. User acknowledges and agrees that right to
                    receive any rental accrued for the period before termination
                    is conditional upon User having (i) properly used the
                    Platform, (ii) adhered to the Terms and Conditions, (iii)
                    maintained continuous activation of User Account, and (iv)
                    participated on the Platform. In the event of termination,
                    the User Account will be disabled and the User may not be
                    granted access to the User Account or any files or other
                    data contained in the User Account. Notwithstanding the
                    foregoing, residual data may remain in the Company's system.
                    <br />
                    <br />
                    Upon termination of Service, the User's access to the
                    Platform shall be immediately revoked. The provisions of
                    these Terms and Conditions which by their very nature are
                    intended to survive termination shall survive expiration or
                    termination of the Platform or User Account.
                  </p>
                </li>
              </ol>
            </li>
            <br />
            <br />
            <li>
              <strong>REFUND POLICY</strong>
              <ol>
                <li>
                  Refund queries will be catered in case of frauds and disputes
                  only. A user has to inform about any such activity by dropping
                  an email to{" "}
                  <a
                    href="mailto:support@bijak.in"
                    style={{ color: "#198548" }}
                  >
                    support@bijak.in
                  </a>{" "}
                  within 48 hours of learning/ascertaining about the same. The
                  Dedicated team will then contact the user within 48 hours to
                  understand and analyse the incident. The refunds will be
                  issued after complete verification and due diligence by our
                  team. In case the concern is not addressed within 48 hours,
                  the user can reach out to the Grievances Officer on{" "}
                  <a
                    href="mailto:grievanceofficer@bijak.in"
                    style={{ color: "#198548" }}
                  >
                    grievanceofficer@bijak.in
                  </a>
                  . The refund will be transferred into the same account from
                  which the money was debited subject to successful completion
                  of the verification and due diligence process by our dedicated
                  team.
                </li>
              </ol>
            </li>
            <br />
            <br />
            <li>
              <strong>MISCELLANEOUS</strong>
              <ol>
                <li>
                  <strong>Charges for Services</strong>
                  <ol type="1">
                    <li>
                      The Company may charge a commission/fees for its Services,
                      on a case to case basis.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  <strong>Governing Law and Jurisdiction</strong>
                  <ol type="1">
                    <li>
                      <strong>Arbitration</strong>
                      <p>
                        Any dispute, controversy, difference or claim arising
                        out of or relating to the use of Bijak App or Bijak
                        Platform including the existence, validity,
                        interpretation, performance, breach of any part of the
                        terms and conditions, or any dispute regarding
                        non-contractual obligations arising out of or relating
                        to it shall be referred to and finally resolved by a
                        Sole arbitrator in terms of the provisions of
                        Arbitration and Conciliation Act, 1996 as amended from
                        time to time and the place of arbitration will be at New
                        Delhi, India.
                      </p>
                    </li>
                    <br />
                    <li>
                      <strong>Jurisdiction of Courts</strong>
                      <p>
                        All disputes arising out Terms and Conditions shall be
                        governed in all respects by the laws of India and any
                        legal proceeding arising thereof shall be construed,
                        interpreted and initiated exclusively in the courts
                        located in Gurugram, Haryana, India.
                      </p>
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  <strong>Advertisements</strong>
                  <ol type="1">
                    <li>
                      The Company may display advertisements and promotions on
                      the Platform. The manner, mode, and extent of advertising
                      by the Company on the Platform are subject to change and
                      the appearance of advertisements on the Platform does not
                      imply endorsement by the Company of any advertised
                      products or services. The User agrees that the Company
                      shall not be responsible or liable for any loss or damage
                      of any sort incurred by the User as a result of any such
                      dealings or as the result of the presence of such
                      advertisers on the Platform.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  <strong>Use of Cookies</strong>
                  <ol type="1">
                    <li>
                      The Company uses cookies to aggregate information about
                      the User's sessions, track information, store certain User
                      preferences, analyze web page flow, etc. so that the
                      Company can update and redesign the Company Website/App,
                      as necessary, to provide User with the most useful
                      information.
                    </li>
                    <br />
                    <li>
                      Users will have the option to either accept or decline the
                      use of cookies on their computer/device, whether they are
                      registered or not. However, a User's experience of the
                      Website shall be limited and certain features of the
                      Website will not be available if the User declines the use
                      of Cookies.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  <strong>Assignment</strong>
                  <ol type="1">
                    <li>
                      The Terms and Conditions, and any rights and licenses
                      granted hereunder, may not be transferred or assigned by
                      the User but may be assigned by the Company without
                      restriction.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  <strong>No Agency or Partnership</strong>
                  <ol type="1">
                    <li>
                      No agency, partnership, joint venture, or employment is
                      created as a result of the Terms and Conditions or User’s
                      use of any part of the Platform, including without
                      limitation, the contract between the User and The Company.
                      The user does not have any authority whatsoever to bind
                      the Company in any respect. Neither the Company nor any
                      User of the Platform may direct or control the day-to-day
                      activities of the other or create or assume any obligation
                      on behalf of the other.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  <strong>Force Majeure</strong>
                  <ol type="1">
                    <li>
                      Neither the Company nor the User shall be liable to the
                      other for any delay or failure in performance under the
                      Terms and Conditions, other than payment obligations,
                      arising out of a cause beyond its control and without its
                      fault or negligence. Such causes may include but are not
                      limited to fires, floods, earthquakes, strikes,
                      unavailability of necessary utilities,lockdown, blackouts,
                      acts of God, acts of declared or undeclared war, acts of
                      regulatory agencies, or national disasters.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  <strong>No Third-party Beneficiaries</strong>
                  <ol type="1">
                    <li>
                      User agrees that, except as otherwise expressly provided
                      in the Terms and Conditions, there shall be no third-party
                      beneficiaries to the Terms and Conditions.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  <strong>Notice</strong>
                  <ol type="1">
                    <li>
                      User agrees that The Company may provide the User with
                      notices, including those regarding changes to the Terms
                      and Conditions, by email, regular mail, or postings on the
                      Platform.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  <strong>Entire Agreement</strong>
                  <ol type="1">
                    <li>
                      The Terms and Conditions, together with the Privacy
                      Policy, and any other legal notices or any additional
                      policies published by the Company on the Platform, shall
                      constitute the entire agreement between User and the
                      Company concerning the Platform. Please note, however,
                      that other aspects of your use of Services may be governed
                      by additional agreements.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  <strong>Severability</strong>
                  <ol type="1">
                    <li>
                      If any provision of the Terms and Conditions is deemed
                      invalid by a court of competent jurisdiction, the
                      invalidity of such provision shall not affect the validity
                      of the remaining provisions of the Terms and Conditions,
                      which shall remain in full force and effect.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  <strong>No Waiver</strong>
                  <ol type="1">
                    <li>
                      No waiver of any provision of these Terms and Conditions
                      shall be deemed a further or continuing waiver of such
                      term or any other term, and The Company’s failure to
                      assert any right or provision under these Terms and
                      Conditions shall not constitute a waiver of such right or
                      provision.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  <strong>Complaint &amp; Grievance Mechanism:</strong>
                  <br />
                  <p>
                    To Address the queries/concerns of the users we have defined
                    Complaint &amp; Grievance Mechanism.
                  </p>
                  <p>
                    {" "}
                    <strong> Level 1:</strong> Users can reach us through the
                    “Help via Call” feature on mob application or can write to
                    us at{" "}
                    <a
                      href="mailto:support@bijak.in"
                      style={{ color: "#198548" }}
                    >
                      support@bijak.in
                    </a>
                    .
                  </p>
                  <p>
                    {" "}
                    <strong> Level 2:</strong> If the user query/concerns are
                    not addressed to the expected levels, then they can reach
                    the Grievance Officer- Mr. Jitender Bedwal. Email id:{" "}
                    <a
                      href="mailto:grievanceofficer@bijak.in"
                      style={{ color: "#198548" }}
                    >
                      grievanceofficer@bijak.in
                    </a>
                    ; Grievance officer acknowledges the receipt of any user
                    query/concerns within 48 hours and redresses the complaint
                    within 1 month from the date of receipt of the complaint.
                  </p>
                  <p>
                    <strong> Level 3:</strong> In the unlikely event that your
                    issue remains unresolved to your satisfaction despite
                    escalating to our Grievance Officer, you can reach out to
                    our Nodal Officer Mr. Nukul Upadhye –{" "}
                    <a
                      href="mailto:grievanceofficer@bijak.in"
                      style={{ color: "#198548" }}
                    >
                      grievanceofficer@bijak.in
                    </a>
                    . We will respond within 3 business days from the date of
                    receipt of your email.
                  </p>
                  <p></p>
                </li>
              </ol>
            </li>
          </ol>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
export default TermsAndConditions;
