import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { FinanceState, updateFilters, initialState } from "./financeSlice";
import React from "react";
import { trackAnalytics } from "../../Analytics";

const SearchField = () => {
  const { loading, filters }: FinanceState = useSelector(
    (state: RootState) => state.financeItems
  );
  const [searchText, setSearchText] = React.useState<string>(
    filters.orderOrInvoiceNumber || ""
  );

  const { mobileNumber, response }: any = useSelector(
    (state: RootState) => state.mobileNum
  );

  const dispatch: AppDispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleClick = () => {
    //SEGMENT PART
    //when user search invoice on orders tab
    const pageData = {
      source_page: "orders_tab",
      landing_page: "orders_tab",
      event_type: "click",
      invoice_number: searchText,
    };

    trackAnalytics("invoice_search", pageData);

    if (searchText !== filters.orderOrInvoiceNumber) {
      dispatch(
        updateFilters({ pageNo: 0, orderOrInvoiceNumber: searchText || null })
      );
    }
  };

  const handleClose = () => {
    setSearchText("");
    const pageData = {
      source_page: "orders_tab",
      landing_page: "orders_tab",
      event_type: "click",
    };

    trackAnalytics("invoice_search_clear", pageData);
    dispatch(updateFilters({ pageNo: 0, orderOrInvoiceNumber: null }));
  };

  const handleSearch = (event: any) => {
    if (event.key === "Enter" && searchText !== filters.orderOrInvoiceNumber) {
      //SEGMENT PART
      //when user search invoice on orders tab
      const pageData = {
        source_page: "orders_tab",
        landing_page: "orders_tab",
        event_type: "click",
        invoice_number: searchText,
      };

      trackAnalytics("invoice_search", pageData);
      dispatch(
        updateFilters({ pageNo: 0, orderOrInvoiceNumber: searchText || null })
      );
    }
  };

  React.useEffect(() => {
    if (filters.orderOrInvoiceNumber == null && searchText) {
      setSearchText("");
    }
  }, [filters.orderOrInvoiceNumber]);

  React.useEffect(() => {
    return () => {
      setSearchText("");
      dispatch(updateFilters({ ...initialState.filters }));
    };
  }, []);

  return (
    <TextField
      disabled={loading}
      variant="outlined"
      placeholder="Search Invoice Number"
      fullWidth
      autoComplete="off"
      name="tech_id"
      value={searchText}
      onChange={handleChange}
      sx={{
        bgcolor: "white",
        maxWidth: "360px",
      }}
      onKeyDown={handleSearch}
      onKeyUp={handleSearch}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {filters?.orderOrInvoiceNumber &&
            filters?.orderOrInvoiceNumber === searchText ? (
              <CloseIcon
                onClick={handleClose}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    color: "primary.main",
                  },
                  "&:clicked": {
                    color: "primary.main",
                  },
                }}
              />
            ) : (
              <SearchIcon
                onClick={handleClick}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    color: "primary.main",
                  },
                  "&:clicked": {
                    color: "primary.main",
                  },
                }}
              />
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchField;
