import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useFormikContext, Form } from "formik";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Grid, Typography, InputAdornment } from "@mui/material";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";

const LoginModule = () => {
  const { values, errors, touched, handleChange, handleBlur }: any =
    useFormikContext();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Form noValidate>
      <Grid container spacing={2} gap={{ xs: 1, md: 0 }}>
        <Grid item xs={12} mb={2}>
          <TextField
            fullWidth
            id="phoneNumber"
            name="phoneNumber"
            label="Enter Mobile Number"
            value={values.phoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.phoneNumber && Boolean(errors.phoneNumber)}
            helperText={touched.phoneNumber && errors.phoneNumber}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ color: "black" }}>
                  +91{" "}
                </InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 10,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            id="login-module-btn"
            variant="contained"
            fullWidth
            type="submit"
            disabled={!values.phoneNumber || Boolean(errors.phoneNumber)}
            style={{padding: "12px 0"}}
          >
            Continue
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography
            className="font-bold"
            color="info.light"
            variant="body2"
            style={{ userSelect: "none" }}
          >
            By clicking continue, you agree to our{" "}
            <a
              style={{
                color: "#359AE7",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={openModal}
            >
              Terms and Conditions
            </a>
          </Typography>
        </Grid>
      </Grid>
      <TermsAndConditions isOpen={isModalOpen} handleClose={closeModal} />
    </Form>
  );
};

export default LoginModule;
