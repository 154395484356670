interface Props {
  width?: string;
  height?: string;
  style?: React.CSSProperties;
}

const RupeePayment = (props: Props) => {
  const { width = 24, height = 24, style = {} } = props;
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 1C5.9 1 1 5.9 1 12C1 18.1 5.9 23 12 23C18.1 23 23 18.1 23 12C23 5.9 18.1 1 12 1ZM13.9 9.5H15C15.5 9.5 16 9.9 16 10.5C16 11.1 15.5 11.5 15 11.5H13.5C13.3 12.1 13 12.6 12.5 13.1C12 13.6 11.3 14 10.6 14.2C10.6 14.2 10.5 14.3 10.6 14.3L14.6 17.2C15 17.5 15.1 18.1 14.8 18.5C14.5 18.9 14 19 13.5 18.7L8.2 14.9C7.9 14.7 7.7 14.3 7.7 13.8C7.7 13.2 8.2 12.7 8.8 12.7C9.6 12.6 10.7 12.4 11.2 12C11.3 11.9 11.4 11.8 11.6 11.6H8.1C7.6 11.6 7.1 11.2 7.1 10.6C7.1 10 7.6 9.6 8.1 9.6H12.2C12.2 9.2 12.2 8.8 12.2 8.6H8.1C7.6 8.6 7.1 8.2 7.1 7.6C7.1 7.1 7.6 6.6 8.1 6.6H15.1C15.6 6.6 16.1 7.1 16.1 7.6C16.1 8.2 15.6 8.6 15.1 8.6H14C14 8.7 14 9.1 13.9 9.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default RupeePayment;
