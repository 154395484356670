import { Autocomplete, TextField } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { CommodityState, updateFilters } from "./commoditySlice";
import { getCommodities } from "./commodityApi";
import { getVarieties } from "../variety/varietyApi";

const CommodityDropdown = ({
  label = "Select commodity",
  name = "commodity_name",
  required = true,
  disabled = false,
  isMulti = false,
  classes = "",
  error = false,
  variant = "outlined",
  onChange = (args?: any) => {},
  defaultValue = "",
  id = "commodity_dropdown",
  fullWidth = false,
  ...props
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");

  const {
    response,
    loading,
    error: serverError,
  }: CommodityState = useSelector((state: RootState) => state.commodityList);

  useEffect(() => {
    setInputValue(defaultValue);
    // dispatch(updateFilters(defaultValue));
  }, [defaultValue]);

  const handleInputChange = (event: any) => {
    const value = event?.target?.value || "";
    setInputValue(value);

    value.length === 0 && onChange();
    if (value.length >= 3) {
      dispatch(updateFilters(value));
      dispatch(getCommodities({ search: value }));
    }
  };
  const handleSelectChange = (_e: any, option: any) => {
    if (option) {
      dispatch(updateFilters(option));
    } else {
      dispatch(updateFilters(defaultValue));
    }
    onChange && onChange(option);
  };

  return (
    <Autocomplete
      fullWidth={fullWidth}
      loading={loading}
      multiple={isMulti}
      disabled={disabled}
      options={[...(response || [])]}
      filterOptions={(options) => options}
      getOptionLabel={(commodity: any) => commodity?.name}
      inputValue={!isMulti ? inputValue : undefined}
      defaultValue={isMulti ? inputValue || [] : undefined}
      style={{ marginBottom: "16px" }}
      onChange={handleSelectChange}
      className={classes}
      id={id}
      renderInput={(params) => (
        <TextField
          helperText={props.helperText}
          error={error}
          {...params}
          label={label}
          // variant={variant}
          onChange={handleInputChange}
        />
      )}
    />
  );
};

export const FormikCommodityDropdown = ({ name, ...props }: any) => {
  const { errors, touched, getFieldProps, setFieldValue, values }: any =
    useFormikContext();
  const error = getIn(errors, name);
  const hasError = getIn(touched, name) && error ? true : false;
  return (
    <CommodityDropdown
      {...props}
      variant="outlined"
      name={name}
      error={hasError}
      helperText={hasError ? error : ""}
      {...getFieldProps(name)}
      defaultValue={values?.[name]?.name || ""}
      onChange={(value: any) => setFieldValue(name, value)}
    />
  );
};

export default CommodityDropdown;
