interface Props {
  width?: string;
  height?: string;
  style?: React.CSSProperties;
}

const ErrorSVG = (props: Props) => {
  const { width = 24, height = 24, style = {} } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id=" error fill">
        <path
          id="Vector"
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 13C11.45 13 11 12.55 11 12V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V12C13 12.55 12.55 13 12 13ZM13 17H11V15H13V17Z"
          fill="#BD252B"
        />
      </g>
    </svg>
  );
};
export default ErrorSVG;
