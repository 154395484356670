import { Button, Grid, Typography } from "@mui/material";
import InitialReport from "../SVG/InitialReport";

function InitialReportData({tab}: any) {

  return (
    <Grid
      container
      spacing={2}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "theme.palette.primary.light",
        padding: "2rem 0",
      }}
    >
      <Grid item xs={12}>
        <InitialReport />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          color="info.main"
          sx={{ fontWeight: "500", fontSize: "1rem" }}
        >
          {tab == "Generation"
            ? "The reports currently in progress will be displayed here"
            : "Your requested reports will be shown here upon generation."}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default InitialReportData;
