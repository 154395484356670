import { createSlice } from "@reduxjs/toolkit";
import { getUserDetails } from "./userApi";
import { logoutUser } from "../Logout/logoutApi";

export interface UserDetailsState {
  response: any;
  loading: boolean;
  error?: string;
}

export const initialState: UserDetailsState = {
  response: {},
  loading: false,
  error: "",
};

export const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    logOut: (state) => {
      state.response = {}
    }
  },
  extraReducers: {
    [getUserDetails.pending.type]: (state, action) => {
      state.loading = true;
      state.error = "";
    },
    [getUserDetails.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.response = action.payload;
    },
    [getUserDetails.rejected.type]: (state, action) => {
      state.loading = false;
      state.response = {}
      state.error = action.payload || "Something went wrong";
    },
    [logoutUser.fulfilled.type]: (state, action) => {
      state.response = {}
    }
  },
});
export const { logOut } = userDetailsSlice.actions

export default userDetailsSlice.reducer;
