import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import {
  Button,
  Checkbox,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  Typography,
  Box,
  Radio,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import {
  addDays,
  addMonths,
  subMonths,
  subDays,
  addYears,
  format,
  endOfMonth,
  startOfMonth,
} from "date-fns";
import { DateRange, Calendar } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./CustomDateRangePicker.css";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { updateFilters } from "./financeSlice";
import { differenceInDays } from "date-fns";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
  },
}));

interface CalenderMenuProps {
  buttonName: string;
  futureOptions: boolean;
  filterDispatchCall: (
    startDate: string | null,
    endDate: string | null
  ) => void;
  filterBy: string;
  setFilterBy: React.Dispatch<React.SetStateAction<string>>;
}

export function DateDropDownMenu(prop: CalenderMenuProps) {
  const {
    buttonName,
    futureOptions,
    filterDispatchCall,
    filterBy,
    setFilterBy,
  } = prop;

  const [range, setRange] = React.useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [open, setOpen] = React.useState(false);

  const [anchorElOrder, setAnchorElOrder] = React.useState<null | HTMLElement>(
    null
  );

  const dropDownNames = [
    "Last 7 Days (Including Today)",
    "Current Month",
    "Last Month",
    "Custom Date Range",
  ];

  const dropDownNamesFuture = [
    "Next 7 Days (Including Today)",
    "Current Month",
    "Next Month",
    "Custom Date Range",
  ];

  const openOrderStatus = Boolean(anchorElOrder);

  const openOrderMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (filterBy !== "Custom Date Range") {
      setAnchorElOrder(event.currentTarget);
    } else {
      setApplied(false);
      setOpen(true);
    }
  };

  const closeOrderMenu = () => {
    setOpen(false);
    setAnchorElOrder(null);
  };

  const closeButtonclicked = (event: any) => {
    event.stopPropagation();
    setFilterBy("");
    setOpen(false);
    setAnchorElOrder(null);
  };

  const [applied, setApplied] = React.useState(false);

  const checkboxSelected = (value: string) => {
    if (value === "Last 7 Days (Including Today)") {
      setRange([
        {
          startDate: subDays(new Date(), 6),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    } else if (value === "Last Month") {
      setRange([
        {
          startDate: startOfMonth(subMonths(new Date(), 1)),
          endDate: endOfMonth(subMonths(new Date(), 1)),
          key: "selection",
        },
      ]);
    }  else if (value === "Custom Date Range") {
      if (filterBy != "Custom Date Range") {
        setRange([
          {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          },
        ]);
        setApplied(false);
        setOpen(true);
      }
    } else if (value === "Next 7 Days (Including Today)") {
      setRange([
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 6),
          key: "selection",
        },
      ]);
    } else if (value === "Current Month") {
      if (futureOptions) {
        setRange([
          {
            startDate: startOfMonth(new Date()),
            endDate: endOfMonth(new Date()),
            key: "selection",
          },
        ]);
      } else {
        setRange([
          {
            startDate: startOfMonth(new Date()),
            endDate: (new Date()),
            key: "selection",
          },
        ]);
      }
    } else if (value === "Next Month") {
      setRange([
        {
          startDate: startOfMonth(addMonths(new Date(), 1)),
          endDate: endOfMonth(addMonths(new Date(), 1)),
          key: "selection",
        },
      ]);
    }
    filterBy == value ? setFilterBy("") : setFilterBy(value);
    setAnchorElOrder(null);
  };

  React.useEffect(() => {
    const startDate = format(range[0].startDate, "yyyy-MM-dd");
    const endDate = format(range[0].endDate, "yyyy-MM-dd");

    if (filterBy !== "") {
      if (filterBy == "Custom Date Range" && applied) {
        filterDispatchCall(startDate, endDate);
      } else if (filterBy != "Custom Date Range") {
        filterDispatchCall(startDate, endDate);
      }
    } else if (filterBy == "") {
      filterDispatchCall(null, null);
    }
  }, [filterBy, applied]);

  return (
    <div>
      <Button
        id="order-status-dropdown"
        aria-controls={openOrderStatus ? "order-status-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openOrderStatus ? "true" : undefined}
        variant="outlined"
        disableElevation
        onClick={openOrderMenu}
        endIcon={
          filterBy == "" ? (
            <KeyboardArrowDownRoundedIcon />
          ) : (
            <CloseRoundedIcon
              onClick={closeButtonclicked}
              sx={{
                color: "primary.main",
                backgroundColor: "white",
                borderRadius: "25px",
              }}
            />
          )
        }
        sx={{
          color: filterBy == "" ? "info.dark" : "white",
          justifyContent: "space-between",
          borderRadius: "25px",
          backgroundColor: filterBy == "" ? "" : "primary.main",
          "&.MuiButtonBase-root:hover": {
            bgcolor: filterBy == "" ? "" : "primary.main",
          },
        }}
        color="info"
      >
        {filterBy == ""
          ? buttonName
          : `${buttonName}: ${
              filterBy == "Custom Date Range" && !open && applied
                ? `${format(range[0].startDate, "dd MMM yyyy")} - ${format(
                    range[0].endDate,
                    "dd MMM yyyy"
                  )}`
                : filterBy
            }`}
      </Button>
      <StyledMenu
        id="order-status-menu"
        MenuListProps={{
          "aria-labelledby": "order-status-dropdown",
        }}
        anchorEl={anchorElOrder}
        open={openOrderStatus}
        onClose={closeOrderMenu}
      >
        {(futureOptions ? dropDownNamesFuture : dropDownNames).map(
          (item: string, index: number) => (
            <MenuItem key={index} onClick={(e) => checkboxSelected(item)}>
              <Radio
                checked={filterBy == item}
                value="a"
                name="radio-buttons"
                inputProps={{ "aria-label": "a" }}
              />
              <ListItemText primary={item} />
            </MenuItem>
          )
        )}
      </StyledMenu>
      <DateRangeSelector
        open={open}
        setFilterBy={setFilterBy}
        setOpen={setOpen}
        range={range}
        setRange={setRange}
        setApplied={setApplied}
      />
    </div>
  );
}

interface calenderProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  range: {
    startDate: Date;
    endDate: Date;
    key: string;
  }[];
  setRange: any;
  setApplied: any;
  setFilterBy: React.Dispatch<React.SetStateAction<string>>;
  centerAlign?: boolean;
  daysLimit?: boolean;
}

export default function DateRangeSelector(prop: calenderProps) {
  const { open, setOpen, range, setRange, setApplied, setFilterBy, centerAlign, daysLimit=false} = prop;

  const refOne = React.useRef<HTMLDivElement>(null);

  const customAriaLabels = {
    dateRange: "Select a date range",
    nextButton: "Next Month",
    prevButton: "Previous Month",
  };

  React.useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  // hide dropdown on ESC press
  const hideOnEscape = (e: any) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  // Hide on outside click
  const hideOnClickOutside = (e: any) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      if (open) {
        setFilterBy("");
      }
      setOpen(false);
    }
  };

  function renderMonthAndYear(
    focusedDate: Date,
    changeShownDate: any,
    props: any
  ) {
    const {
      showMonthArrow,
      minDate,
      maxDate,
      showMonthAndYearPickers,
      ariaLabels,
    } = props;
    const upperYearLimit = addYears(new Date(), 20).getFullYear();
    const lowerYearLimit = addYears(new Date(), -100).getFullYear();
    let monthStep = addMonths(focusedDate, 1);
    return (
      <Box>
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <Button
              onClick={() => changeShownDate(-1, "monthOffset")}
              aria-label={ariaLabels.prevButton}
              color="info"
            >
              <KeyboardArrowLeftRoundedIcon />
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Typography textAlign={"center"}>
              {format(focusedDate, "MMMM, yyyy")}
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography textAlign={"center"}>
              {format(monthStep, "MMMM, yyyy")}
            </Typography>{" "}
          </Grid>
          <Grid item xs={1}>
            <Button
              onClick={() => changeShownDate(+1, "monthOffset")}
              aria-label={ariaLabels.prevButton}
              color="info"
            >
              <KeyboardArrowRightRoundedIcon />
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }

  const handleDateRangeChange = (selectedRange: any) => {
    const selectedStartDate = selectedRange.selection.startDate;
    const selectedEndDate = selectedRange.selection.endDate;

    const daysDifference = differenceInDays(selectedEndDate, selectedStartDate);
    if (daysDifference <= 365) {
      setRange([selectedRange.selection]);
    }
  };

  return (
    <div ref={refOne}>
      {open && (
        <Paper
          className="calendarWrap "
          elevation={2}
          sx={{
            padding: "1.5rem 2rem 1rem 2rem",
            left: centerAlign ? "50%" : null,
          }}
        >
          <Grid spacing={2}>
            <div>
              <DateRange
                onChange={(item) =>
                  daysLimit
                    ? handleDateRangeChange(item)
                    : setRange([item.selection])
                }
                moveRangeOnFirstSelection={false}
                ranges={range}
                months={2}
                rangeColors={["#E7F6EC"]}
                direction="horizontal"
                showMonthArrow={false}
                showMonthAndYearPickers={false}
                fixedHeight={true}
                ariaLabels={customAriaLabels}
                className="calendarElement"
                navigatorRenderer={renderMonthAndYear}
              />
            </div>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            padding={2}
          >
            <Grid item>
              <Typography sx={{ fontWeight: "bold" }}>{`${format(
                range[0].startDate,
                "dd MMM, yyyy"
              )} - ${format(range[0].endDate, "dd MMM, yyyy")}`}</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="text"
                sx={{ minWidth: "150px", marginRight: "5px" }}
                onClick={() => {
                  setOpen(false);
                  setApplied(false);
                  setFilterBy("");
                }}
                color="info"
              >
                <Typography>Cancel</Typography>
              </Button>
              <Button
                onClick={() => {
                  setOpen(false);
                  setApplied(true);
                }}
                variant="contained"
                sx={{ minWidth: "150px" }}
              >
                Apply Filter
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  );
}
