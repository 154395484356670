import * as React from "react";
import _ from "lodash";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";

import LogoutIcon from "@mui/icons-material/Logout";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ReceiptLongRoundedIcon from "@mui/icons-material/ReceiptLongRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import FeedIcon from "@mui/icons-material/Feed";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import BijakLogo from "../../components/SVG/BijakLogo";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useLocation, useNavigate } from "react-router-dom";
import Logout from "../../components/Logout";
import TermsAndConditionsModal from "../../components/TermsAndConditions";
import { MiniDrawer, DrawerHeader } from "../Drawer";
import LogoMark from "../../components/SVG/LogoMark";
import HelpModal from "../../features/Help/HelpModal";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { AppState } from "./appSlice";
import UserProfile from "../../components/UserProfile";
import Loader from "../../components/Loader";
import { getUserDetails } from "../../components/UserProfile/userApi";
import RupeePayment from "../../components/SVG/RupeePayment";
import Reports from "../../components/SVG/Reports";
import { getToastNotifications, markAsReadToastNotifications } from "./appAPI";
import { toast } from "react-toastify";
import SuccessSVG from "../../components/SVG/SuccessSVG";
import ErrorSVG from "../../components/SVG/ErrorSVG";
import InfoSVG from "../../components/SVG/InfoSVG";
const toastMapping: any = {
  COMPLETE: {
    name: "success",
    color: "#219653",
    progressStyle: {
      backgroundColor: "#219653",
    },
    icon: <SuccessSVG />,
  },
  ERROR: {
    name: "error",
    color: "#BD252B",
    progressStyle: {
      backgroundColor: "#BD252B",
    },
    icon: <ErrorSVG />,
  },
  INFO: {
    name: "info",
    color: "#247BC7",
    progressStyle: {
      backgroundColor: "#247BC7",
    },
    icon: <InfoSVG />,
  },
};

const drawerWidth = 324;

interface Props {
  children: React.ReactNode;
  window?: () => Window;
  heading: string;
  searchField?: React.ReactNode;
}

export default function AppLayout(props: Props) {
  const dispatch: AppDispatch = useDispatch();

  const { loading, response }: any = useSelector(
    (state: RootState) => state.userDetails
  );
  const { config }: any = useSelector((state: RootState) => state.config);
  React.useEffect(() => {
    if (!response?.businessName) {
      dispatch(getUserDetails(""));
    }
  }, []);

  const { minimizeSidebar, notificationResponse }: AppState = useSelector(
    (state: RootState) => state.app
  );
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();

  const toastMapping: any = {
    SUCCESS: {
      name: "success",
      style: {
        color: theme.palette.info.main,
        fontWeight: 500,
        lineHeight: 1.25,
      },
      progressStyle: {
        backgroundColor: "#219653",
      },
      icon: <SuccessSVG />,
    },
    ERROR: {
      name: "error",
      style: {
        color: theme.palette.info.main,
        fontWeight: 500,
        lineHeight: 1.25,
      },
      progressStyle: {
        backgroundColor: "#BD252B",
      },
      icon: <ErrorSVG />,
    },
    INFO: {
      name: "info",
      style: {
        color: theme.palette.info.main,
        fontWeight: 500,
        lineHeight: 1.25,
      },
      progressStyle: {
        backgroundColor: "#247BC7",
      },
      icon: <InfoSVG />,
    },
  };
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [open, setOpen]: any = React.useState({ 0: true });

  const handleClick = (index: any) => {
    setOpen({ [index]: !open[index] });
  };

  const handleNavigation = (uri: string) => {
    navigate(uri, { state: { reload: true, prevRoute: location.pathname } });
  };
  const financeMenuItems: any = React.useMemo(() => {
    const menuItems: any = {
      ORDERS: [
        { name: "Orders", icon: <ReceiptLongRoundedIcon />, href: "/orders" },
        { name: "Payment History", icon: <RupeePayment />, href: "/payments" },
        { name: "Reports", icon: <Reports />, href: "/reports" },
      ],
    };
    if (response?.poViewAccess && config.poFlag) {
      menuItems["PO MANAGEMENT"] = [
        {
          name: "Purchase Orders",
          icon: <ReceiptLongRoundedIcon />,
          href: "/purchase-orders",
        },
      ];
    } else {
      delete menuItems["PO MANAGEMENT"];
    }
    if (
      config.reportsFlag &&
      !_.find(menuItems["ORDERS"], { name: "Reports" })
    ) {
      menuItems["ORDERS"].push({
        name: "Reports",
        icon: <Reports />,
        href: "/reports",
      });
    } else if (!config.reportsFlag) {
      const index = menuItems["ORDERS"].findIndex(
        (item: any) => item.name === "Reports"
      );
      menuItems["ORDERS"].splice(index, 1);
    }
    return menuItems;
  }, [response, config]);

  const CloseButton = ({ closeToast }: any) => {
    const handleClick = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      closeToast();
    };
    return (
      <CloseIcon
        style={{ marginTop: "0.3rem", zIndex: 999999 }}
        onClick={handleClick}
      />
    );
  };

  const toastCallback = (notifications: any) => {
    if (!notifications?.dataList?.length) return;
    const { dataList } = notifications;
    const url =
      "https://buyer-dashboard-assets.s3.ap-south-1.amazonaws.com/website/notification.wav";
    const audio = new Audio(url);
    audio.play();
    dataList.forEach((notification: any) => {
      const toastType = toastMapping[notification.infoIcon];
      toast(notification.text, {
        autoClose: config.toastAutoClose,
        type: toastType.name,
        onClick: () => handleToastClick(notification),
        icon: toastType.icon,
        style: { ...toastType.style },
        progressStyle: toastType.progressStyle,
        closeButton: CloseButton,
      });
    });
  };

  React.useEffect(() => {
    // call API after interval of 1 minute
    if (!response?.businessName) return;
    const interval = setInterval(() => {
      dispatch(getToastNotifications({ callback: toastCallback }));
    }, config.toastAPIInterval || 60000);
    return () => clearInterval(interval);
  }, [response]);

  const handleToastClick = async (notification: any) => {
    await dispatch(
      markAsReadToastNotifications(notification?.notificationRefId)
    );
    if (notification?.link) {
      handleNavigation(`/${notification.link}`);
    }
  };

  const handleSearch = ({ event }: any) => {
    const searchQuery = event.target.value;
  };
  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      display: minimizeSidebar ? "none" : "block",
      marginLeft: "8px !important",
    },
  }));

  const handleDrawerClose = () => {
    dispatch({ type: "app/toggleSideBar" });
  };
  const drawer = (
    <Box
      className={minimizeSidebar ? "" : "collapsed"}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflow: "auto",
        height: "87vh",
        overflowX: "hidden",
      }}
    >
      <List>
        {Object.entries(financeMenuItems).map((item, index) => {
          const [menu, submenus]: any = item;
          return (
            <>
              <ListItem>
                <ListItemButton disabled style={{ padding: 0 }}>
                  <ListItemText
                    primary={menu}
                    sx={{ visibility: minimizeSidebar ? "visible" : "hidden" }}
                  />
                </ListItemButton>
              </ListItem>
              {submenus.map((menu: any, menuIndex: number) => (
                <LightTooltip title={menu.name} placement="right">
                  <ListItem disablePadding key={`${menu.name}-${menuIndex}`}>
                    <ListItemButton
                      onClick={() => handleNavigation(menu.href)}
                      selected={location.pathname.includes(menu.href)}
                    >
                      <ListItemIcon>{menu.icon}</ListItemIcon>
                      <ListItemText primary={menu.name} />
                    </ListItemButton>
                  </ListItem>
                </LightTooltip>
              ))}
            </>
          );
        })}
      </List>
      <List>
        {isSmall ? (
          <></>
        ) : (
          <LightTooltip title="Expand Menu" placement="right">
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                onClick={handleDrawerClose}
              >
                <ListItemText
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <span
                    style={{
                      marginRight: "-15px",
                      padding: "10px",
                      borderRadius: "8px 0 0 8px",
                      boxShadow: "0px 0px 12px 0px #00000029",
                    }}
                  >
                    {minimizeSidebar ? (
                      <ChevronLeftIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </span>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </LightTooltip>
        )}
        <LightTooltip title="Need Help?" placement="right">
          <ListItem disablePadding>
            <HelpModal>
              <ListItemButton>
                <ListItemIcon>
                  <HelpIcon sx={{ color: "info.dark" }} />
                </ListItemIcon>
                <ListItemText primary={"Need Help?"} />
              </ListItemButton>
            </HelpModal>
          </ListItem>
        </LightTooltip>

        <LightTooltip title="Terms and Conditions" placement="right">
          <ListItem disablePadding>
            <TermsAndConditionsModal>
              <ListItemButton>
                <ListItemIcon>
                  <AccountBalanceRoundedIcon />
                </ListItemIcon>
                <ListItemText primary={"Terms and Conditions"} />
              </ListItemButton>
            </TermsAndConditionsModal>
          </ListItem>
        </LightTooltip>
        <LightTooltip title="Log Out" placement="right">
          <ListItem disablePadding>
            <Logout>
              <ListItemButton>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={"Log Out"} />
              </ListItemButton>
            </Logout>
          </ListItem>
        </LightTooltip>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  if (!response?.businessName) {
    return <Loader loading />;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: "none",
          backgroundColor: "secondary.light",
        }}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              display: { sm: "none" },
              color: "primary.main",
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              marginTop: "1rem",
              marginBottom: "1rem",
              marginLeft: minimizeSidebar ? "324px" : "80px",
              width: `calc(100% - 0px)`,
              display: { xs: "none", md: "block" },
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item>
                <Typography
                  variant="h6"
                  noWrap
                  fontWeight={600}
                  sx={{
                    color: "info.main",
                    fontSize: 28,
                    lineHeight: 1.25,
                  }}
                  component="div"
                >
                  {props.heading}
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  minWidth: "360px",
                  maxHeight: "48px",
                  ".MuiInputBase-root": {
                    maxHeight: "48px",
                  },
                }}
              >
                {props.searchField ? props.searchField : <></>}
              </Grid>
              <Grid item>
                <UserProfile />
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <MiniDrawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
          }}
          open={minimizeSidebar}
        >
          <DrawerHeader>
            <ListItem disablePadding key={"logo"}>
              <ListItemButton
                onClick={() => handleNavigation("/orders")}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <ListItemIcon sx={{ minWidth: "46px" }}>
                  <LogoMark
                    style={{ marginLeft: "-20px" }}
                    height="48"
                    width="48"
                  />
                </ListItemIcon>
                <BijakLogo style={{ margin: 0 }} />
              </ListItemButton>
            </ListItem>
          </DrawerHeader>
          {drawer}
        </MiniDrawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
}
