import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../api";


export const getOrderAggregates = createAsyncThunk("order/aggregates",
  async (_payload: any, { rejectWithValue }) => {
    try {
      const response = await API.get("/order/total/limit")

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error);
    }
  }
)
