import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/orders");
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "theme.palette.primary.light",
      }}
    >
      <Typography variant="h1">404</Typography>
      <Typography variant="h6">
        The page you’re looking for doesn’t exist.
      </Typography>
      <Button
        onClick={handleClick}
        variant="contained"
        sx={{ marginTop: "1rem" }}
      >
        Back Home
      </Button>
    </Box>
  );
}
export default NotFound;
