import { Grid, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import issue from "../../assets/images/issue.svg";
import { AppDispatch } from "../../store";
import { useDispatch } from "react-redux";
import { closePopup } from "./helpSlice";

export default function Issue(props: { handleClose: any }) {
  const dispatch: AppDispatch = useDispatch();
  const { handleClose } = props;
  const handleCancel = () => {
    handleClose();
    dispatch(closePopup());
  };

  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={12} justifyContent="flex-end" display="flex">
        <CloseIcon onClick={handleCancel} />
      </Grid>
      <Grid item xs={12} justifyContent="center" display="flex">
        <img src={issue} alt="issue" />
      </Grid>
      <Grid item xs={12} justifyContent="center" display="flex">
        <Typography variant="h3" color="primary">
          Thank you for reporting the issue.
        </Typography>
      </Grid>
      <Grid item xs={12} justifyContent="center" display="flex">
        <Typography
          variant="subtitle1"
          color="info.dark"
          style={{ textAlign: "center" }}
        >
          Our team will review it and take necessary action.
        </Typography>
      </Grid>
    </Grid>
  );
}
