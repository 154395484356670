import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useFormikContext, Form } from "formik";
import { Grid, Button, Typography } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import moment from "moment";
import { getLocalStorageItem, setLocalStorageItem } from "../LocalStorage";
import { resendOTP } from "./loginApi";
import { errorMessage } from "../../utils";

const VerifyOTP = () => {
  const { values, errors, touched, handleChange, getFieldProps }: any =
    useFormikContext();

  const dispatch: AppDispatch = useDispatch();

  const { mobileNumber }: any = useSelector(
    (state: RootState) => state.mobileNum
  );

  const { resentOtpResponseCode }: any = useSelector(
    (state: RootState) => state.login
  );

  const { error, otpVerifyResponseCode }: any = useSelector(
    (state: RootState) => state.loginOtpVerify
  );

  const [timer, setTimer] = useState(30);

  const updateTimer = () => {
    if (timer > 0) {
      setTimer(timer - 1);
      setLocalStorageItem("timer", timer - 1);
    }
  };

  useEffect(() => {
    if (timer > 0) {
      const timerInterval = setInterval(updateTimer, 1000);
      return () => clearInterval(timerInterval);
    }
  }, [timer]);

  const handleResendOtp = () => {
    dispatch(resendOTP(mobileNumber)).then((res) => {
      if (res.meta.requestStatus != "rejected") {
        setTimer(30);
        setLocalStorageItem("timer", 30);
        values.otp = "";
      }
    });
  };

  return (
    <Form noValidate>
      <Grid container spacing={2} gap={{ xs: 1, md: 0 }}>
        <Grid
          item
          xs={12}
          marginBottom={2}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
              border: error ? "1px solid red" : "1px solid #CACCCF",
              ":hover": {
                border: error ? "1px solid red" : "1px solid black",
              },
            },
            "& .Mui-focused": {
              border: "1px solid green",
            },
            "& .MuiOutlinedInput-notchedOutline ": {
              border: "none",
            },
          }}
        >
          <MuiOtpInput
            {...getFieldProps("otp")}
            length={6}
            value={values.otp}
            id="otp-input-box"
            onChange={handleChange("otp")}
          />
          {/* {errors.otp && (
            <Typography
              className="error"
              variant="body2"
              style={{ color: "red", marginTop: "8px" }}
            >
              {errors.otp}
            </Typography>
          )} */}
          {error && (
            <Typography
              className="error"
              variant="body2"
              style={{ color: "red", marginTop: "8px" }}
            >
              {error}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} marginBottom={2}>
          <Typography variant="body2">
            {resentOtpResponseCode != 60 ? (
              otpVerifyResponseCode != 60 ? (
                timer > 0 ? (
                  `Resend in ${getLocalStorageItem("timer") || timer}s`
                ) : (
                  <Grid spacing={1}>
                    <Grid item>
                      <a
                        style={{
                          color: "green",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={handleResendOtp}
                      >
                        Resend OTP
                      </a>
                    </Grid>
                  </Grid>
                )
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            style={{ padding: "12px 0" }}
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            id="otp-verify-btn"
            disabled={
              !values.otp || Boolean(errors.otp) || otpVerifyResponseCode == 60
            }
          >
            Verify OTP
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default VerifyOTP;
