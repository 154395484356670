import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Skeleton from "@mui/material/Skeleton";

type TableRowType = typeof TableRow;

interface TableRowsLoaderType extends Array<TableRowType> {}

interface Props {
  rowsNum: number;
  columnsNum: number;
}
const TableRowsLoader = ({ rowsNum, columnsNum }: Props) => {
  return (
    <>
      {[...Array(rowsNum)].map((_row, index) => (
        <TableRow key={index}>
          {[...Array(columnsNum)].map((_column, colIndex) => (
            <TableCell key={colIndex}>
              <Skeleton animation="wave" variant="text" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};
export default TableRowsLoader;
