import * as React from "react";
import "./";
import { Box, Grid, ListItem, Typography } from "@mui/material";
import UserAvatar from "../SVG/UserAvatar";
import { AppDispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUserDetails } from "./userApi";
import UserNotifications from "../Notifications";

function UserProfile() {
  const dispatch: AppDispatch = useDispatch();
  const { loading, response }: any = useSelector(
    (state: RootState) => state.userDetails
  );
  return (
    <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
      <Grid
        container
        spacing={0}
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "right",
          marginRight: "12px",
        }}
      >
        <Grid item>
          <Typography
            variant="h6"
            noWrap
            sx={{ color: "info.main", fontSize: "1rem" }}
          >
            {response?.businessName}
          </Typography>
        </Grid>
        <Grid item>
          <Typography noWrap sx={{ color: "info.light" }} component="div">
            +91 {response?.mobileNumber}
          </Typography>
        </Grid>
      </Grid>
      <UserAvatar />
      <UserNotifications />
    </Box>
  );
}

export default UserProfile;
