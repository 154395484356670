import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Loader from "../components/Loader";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { uploadData } from "aws-amplify/storage";
import { getIn, useFormikContext } from "formik";
import {
  errorMessage,
  getBucketName,
  getContentType,
  validateFileFormatSize,
} from "../utils";
import moment from "moment";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface Props {
  name: string;
  accept?: string;
  filePrefix?: string;
  bucket?: string;
  maxFileSize?: number;
  children?: React.ReactNode;
}

export default function InputFileUpload(props: Props) {
  const [imageUploadState, setImageUploadState] = React.useState({
    selectedFileName: "",
    isFileLoading: false,
    file: "",
  });
  const {
    name,
    accept = "image/jpg,image/png,application/pdf",
    filePrefix,
  } = props;
  const { errors, touched, getFieldProps, setFieldValue, values }: any =
    useFormikContext();
  const error = getIn(errors, name);
  const hasError = getIn(touched, name) && error ? true : false;

  const fileChangedHandler = async (event: any) => {
    let { selectedFileName, isFileLoading, file } = imageUploadState;

    const file1: File = (event.currentTarget.files as FileList)[0];
    const isInValid = validateFileFormatSize(file1, props.maxFileSize);
    if (isInValid) {
      errorMessage(isInValid);
      return;
    }

    const bucket = props.bucket || getBucketName();

    selectedFileName = file1
      ? file1.name
          .replaceAll(new RegExp(/\s/, "g"), "")
          .replaceAll("+", "")
          .replaceAll("(", "")
          .replaceAll(")", "")
      : "";
    isFileLoading = !file1 ? false : true;
    setImageUploadState({ selectedFileName, isFileLoading, file });
    const filename = `${filePrefix}/${moment().valueOf()}-${selectedFileName}`;

    try {
      const result: any = await uploadData({
        key: filename,
        data: file1,
        options: {
          contentType: getContentType(filename),
          onProgress: ({ transferredBytes, totalBytes }) => {
            if (totalBytes) {
              console.log(
                `Upload progress ${
                  Math.round(transferredBytes / totalBytes) * 100
                } %`
              );
            }
          },
        },
      }).result;

      setImageUploadState({ selectedFileName, isFileLoading: false, file });

      setFieldValue(name, [
        ...(values[name] || []),
        `https://${bucket}.s3.ap-south-1.amazonaws.com/public/${filename}`,
      ]);
    } catch (error) {
      console.log("Error : ", error);
    }
  };
  console.log("error", error);
  return (
    <>
      <Loader loading={imageUploadState.isFileLoading} />
      <Button
        component="label"
        startIcon={<AddCircleIcon sx={{ width: "100px", height: "40px" }} />}
        sx={{
          // width: "132px",
          height: "140px",
          radius: "4px",
          border: "1px dashed",
          backgroundColor: "#E7F6EC",
          display: "flex",
          flexDirection: "column",
          borderColor: error ? "red" : "",
        }}
      >
        <div style={{ textAlign: "center", fontWeight: "500" }}>
          {props.children}
        </div>
        <VisuallyHiddenInput
          name={name}
          onChange={fileChangedHandler}
          accept={accept}
          type="file"
        />
      </Button>
    </>
  );
}
