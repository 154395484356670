import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../api";
import sendGtmEvent from "../../../sendEvents";

export const postLoginMobileNumber = createAsyncThunk(
  "data/postMobileNumber",
  async (mobileNumber: any, { rejectWithValue }) => {
    try {
      const response = await API.post("/login/otp", { mobile: mobileNumber });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const postLoginResetPasswordOtp = createAsyncThunk(
  "data/postLoginResetPasswordOtp",
  async (mobileNumber: any, { rejectWithValue }) => {
    try {
      const response = await API.post("/login/otp", {
        mobile: mobileNumber,
        resend: true,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const postLoginOtp = createAsyncThunk(
  "data/postOtpForLogin",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await API.post("login/verify/otp", {
        mobile: payload.mobileNumber,
        otp: payload.otp,
        buyerId: payload.buyerId,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postLoginCreatePassword = createAsyncThunk(
  "data/postLoginCreatePassword",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await API.post("login/update/password", {
        mobile: payload.mobileNumber,
        password: payload.password,
        secureUuid: payload.secureUuid,
        buyerId: payload.buyerId,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const postLoginResetPassword = createAsyncThunk(
  "data/postLoginResetPassword",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await API.post("login/update/password", {
        mobile: payload.mobileNumber,
        password: payload.password,
        secureUuid: payload.secureUuid,
        buyerId: payload.buyerId,
        reset: true,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const postLoginWithPassword = createAsyncThunk(
  "data/postLoginWithPassword",
  async (payload: any, { rejectWithValue }) => {
    try {
      sendGtmEvent("user_login", {
        mobile_number: payload.mobileNumber,
        user_id: payload.mobileNumber,
        buyer_id: payload.buyerId,
      });
      const response = await API.post("login/dashboard", {
        username: payload.mobileNumber,
        password: payload.password,
        buyerId: payload.buyerId,
      });
      if (response.headers.authorization) {
        localStorage.setItem(
          "token",
          `Bearer ${response.headers.authorization}`
        );
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const resendOTP = createAsyncThunk(
  "data/resendOTP",
  async (mobileNumber: string, { rejectWithValue }) => {
    try {
      const response = await API.post("login/otp", {
        mobile: mobileNumber,
        resend: true,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
