import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../api";


export const getNotifications = createAsyncThunk("user/notifications/all",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await API.post("/notification/list?=null", payload)

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error);
    }
  }
)
