import { Box, TablePagination, Typography, Grid, styled } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import InitialReportData from "../InitialReportsData";
import {
  JAMUN_80,
  KARELA_80,
  MIRCHI_80,
  NIMBU_80,
} from "../../../styles/colors";
import AccessTimeFilledOutlinedIcon from "@mui/icons-material/AccessTimeFilledOutlined";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { getGenerateReports } from "../ReportsApi";
import ErrorIcon from "@mui/icons-material/Error";
import GenerateReportTableSkeleton from "./GenerateReportTableSkeleton";
import { GenerateReportState, updateFilters } from "./generateReportSlice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { ConfigState } from "../../../features/maintenance/maintenanceSlice";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { errorMessage } from "../../../utils";
import { replace } from "lodash";

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px",
    marginTop: "8px !important",
    display: "flex",
    textAlign: "center",
  },
}));

const GenerateReportTable = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [highlightRow, setHighlightRow] = useState(false);
  const query: any = useMemo(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const {
    loading,
    response,
    error,
    filters,
    count,
    reportMesg,
  }: GenerateReportState = useSelector(
    (state: RootState) => state.generateReportList
  );

  const handleChangePage = (event: unknown, pageNo: number) => {
    dispatch(updateFilters({ pageNo }));
  };

  const { config }: ConfigState = useSelector(
    (state: RootState) => state.config
  );

  // useEffect(() => {
  //   dispatch(getGenerateReports({ ...filters }));
  // }, [dispatch, filters]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      updateFilters({
        pageNo: 0,
        pageSize: parseInt(event.target.value, 10),
      })
    );
  };

  const ReportStatus = (row: any) => {
    return (
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          cursor: "default",
          backgroundColor:
            row.status == "Generating"
              ? "#FFF0D1"
              : row.status == "Complete"
              ? "#E7F6EC"
              : "#FCE9EC",
          borderRadius: "24px",
          padding: "4px 8px",
          boxSizing: "content-box",
          justifyContent: "space-between",
          gap: "4px",
        }}
      >
        {row.status == "Generating" ? (
          <AccessTimeFilledOutlinedIcon
            fontSize="small"
            sx={{
              color: NIMBU_80,
            }}
          />
        ) : row.status == "Complete" ? (
          <CheckCircleIcon
            fontSize="small"
            sx={{
              color: KARELA_80,
            }}
          />
        ) : (
          <ErrorIcon
            fontSize="small"
            sx={{
              color: MIRCHI_80,
            }}
          />
        )}
        <Typography
          fontWeight={600}
          sx={{
            color:
              row.status == "Generating"
                ? NIMBU_80
                : row.status == "Complete"
                ? KARELA_80
                : MIRCHI_80,
            fontSize: "12px",
          }}
        >
          {row.status == "Error"
            ? "Failed"
            : row.status == "Complete"
            ? "Generated"
            : row.status}
        </Typography>
      </div>
    );
  };

  useEffect(() => {
    if (highlightRow) {
      const timer = setTimeout(() => {
        setHighlightRow(false);
        // navigate(location.pathname, {replace: true})
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [highlightRow]);

  useEffect(() => {
    if (query.report_id != null && response?.length > 0) {
      // setTimeout(() => {
      const element = document.getElementById(`${query.report_id}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      setHighlightRow(true);
      // }, 1000);
    }
  }, [location.pathname, response]);

  useEffect(() => {
    query?.tab == "generate" && reportMesg && errorMessage(reportMesg);
  }, [reportMesg]);

  useEffect(() => {
    return () => {
      dispatch(updateFilters({ pageNo: 0, pageSize: 100 }));
    };
  }, [dispatch, location]);

  useEffect(() => {
    const newFilters = {
      pageNo: filters.pageNo,
      pageSize: filters.pageSize,
      reportId: null,
    };
    dispatch(updateFilters(newFilters));
    dispatch(getGenerateReports(newFilters));
  }, [dispatch, filters]);

  useEffect(() => {
    if (query.tab) {
      const newFilters = {
        pageNo: 0,
        pageSize: 100,
        reportId: !!query.tab ? Number(query.report_id) : null,
      };
      dispatch(updateFilters(newFilters));
      dispatch(getGenerateReports(newFilters));
    }
  }, [dispatch, location, query]);

  useEffect(() => {
    const handlePopstate = (event: any) => {
      window.history.go(-1);
    };
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <Box p={2} pt={1}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            noWrap
            sx={{ color: "info.main", fontWeight: 600 }}
            component="div"
          >
            Reports being generated
          </Typography>
        </Grid>
        {response?.length > 0 && (
          <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
            <Typography
              noWrap
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                color: JAMUN_80,
                alignItems: "center",
                display: "flex",
                gap: "4px",
              }}
              component="div"
            >
              <InfoRoundedIcon fontSize="small" />
              You will be notified when reports finish generating
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          {loading && <GenerateReportTableSkeleton />}
          {!loading &&
            response.map((row: any, index: number) => {
              return (
                <div id={row.reportId}>
                  <Grid
                    container
                    direction="row"
                    sx={{
                      padding: "16px",
                      backgroundColor:
                        query.report_id == row.reportId && highlightRow
                          ? "#FCE9EC"
                          : index % 2 == 0
                          ? "primary.contrastText"
                          : "secondary.light",
                      borderRadius: "4px",
                      minHeight: "96px",
                    }}
                  >
                    <Grid item xs={10}>
                      <Grid container spacing={1} direction="column">
                        <Grid item xs={12}>
                          <Typography
                            fontWeight={400}
                            fontSize="14px"
                            color="info.dark"
                          >
                            {row.reportTime}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            fontWeight={700}
                            fontSize={"16px"}
                            color="info.main"
                          >
                            {row.reportName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            fontWeight={400}
                            fontSize="12px"
                            color="info.dark"
                            lineHeight={"1.33"}
                          >
                            {row.reportType == "LEDGER" &&
                              `Selected Date range: ${row.feInput[0].startDate} - ${row.feInput[0].endDate}`}
                            {row.reportType == "LATEFEES" &&
                              `Selected Invoices: ${row.feInput[0]?.invoiceList?.join(
                                ", "
                              )}`}
                            {row.reportType == "ORDER" && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "4px",
                                }}
                              >
                                <div>
                                  Selected Order Status:{" "}
                                  {row.feInput[0]?.orderStatus == "UNSETTLED"
                                    ? "Unsettled"
                                    : row.feInput[0]?.orderStatus == "SETTLED"
                                    ? "Settled"
                                    : "All"}
                                  {" Orders."}
                                </div>
                                <div>
                                  Selected{" "}
                                  {row.feInput[0]?.dateType == "DUEDATE"
                                    ? "Due"
                                    : "Disbursement"}{" "}
                                  Date Range: {row.feInput[0].startDate} -{" "}
                                  {row.feInput[0].endDate}
                                </div>
                              </div>
                            )}
                            {row.reportType == "PAYMENT" &&
                              `Selected Transaction Date range: ${row.feInput[0].startDate} - ${row.feInput[0].endDate}`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "right",
                      }}
                    >
                      {/* {row.status === "Generating" ? ( */}
                      <LightTooltip
                        title={
                          row.status === "Generating"
                            ? config.Tooltip?.reportGeneration
                            : row.status == "Error"
                            ? config.Tooltip?.reportFailed
                            : config.Tooltip?.reportGenerated
                        }
                      >
                        {ReportStatus(row)}
                      </LightTooltip>
                      {/* ) : (
                      ReportStatus(row)
                    )} */}
                    </Grid>
                  </Grid>
                </div>
              );
            })}
        </Grid>
        {response?.length == 0 && (
          <Grid
            item
            xs={12}
            style={{ height: "70vh", display: "flex", alignItems: "center" }}
          >
            <InitialReportData tab="Generation" />
          </Grid>
        )}
      </Grid>
      {count > 10 && (
        <Box
          sx={{
            backgroundColor: "secondary.light",
            borderRadius: "8px",
            marginTop: "10px",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={filters.pageSize}
            page={filters.pageNo}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </Box>
  );
};

export default GenerateReportTable;
