import { createSlice, original } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { errorMessage } from "../../../utils";
import { getDownloadedLinkReport, getGeneratedReports } from "../ReportsApi";

export interface GeneratedReportState {
  loading: boolean;
  response: any;
  error: string | null;
  count: number;
  filters: {
    pageNo: number;
    pageSize: number;
    status: string;
  };
  expireMesg: string | null;
  reportMesg: string | null;
}

export const initialState: GeneratedReportState = {
  loading: true,
  error: null,
  response: [],
  count: 0,
  filters: {
    pageNo: 0,
    pageSize: 100,
    status: "COMPLETE",
  },
  expireMesg: null,
  reportMesg: null
};

export const generatedReportSlice = createSlice({
  name: "generatedReportList",
  initialState,
  reducers: {
    updateFilters: (state, action: PayloadAction<any>) => {
      if (
        !_.isEqual(original(state.filters), {
          ...state.filters,
          ...action.payload,
        })
      ) {
        state.filters = { ...state.filters, ...action.payload };
      }
    },
  },
  extraReducers: {
    [getGeneratedReports.pending.type]: (state, action) => {
      state.loading = true;
    },
    [getGeneratedReports.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.response = action.payload.reportData || [];
      state.count = action.payload.totalCount || 0;
      state.expireMesg = action.payload.expireMsg;
      state.reportMesg = action.payload.reportMsg;
    },
    [getGeneratedReports.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = action.payload || "Something went wrong";
      errorMessage(state.error || "");
    },
    [getDownloadedLinkReport.fulfilled.type]: (state, action) => {
      const index = state.response.findIndex(
        (item: any) => item.reportId == action.meta.arg.reportId
      );
      state.response[index] = { ...state.response[index], newIcon: false };
    },
  },
});

export const { updateFilters } = generatedReportSlice.actions;

export default generatedReportSlice.reducer;
