import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "../CustomTable/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { Typography } from "@mui/material";
import { formatDate, formatNumber, reportTypeMapping } from "../../utils";
import OrderDetails from "./OrderDetails/OrderDetails";
import { useDispatch, useSelector } from "react-redux";
import { FinanceState, initialState, updateFilters } from "./financeSlice";
import { AppDispatch, RootState } from "../../store";
import TableRowsLoader from "../Skelton/RowsSkelton";
import { getFinanceList } from "./financeApi";
import TablePagination from "@mui/material/TablePagination";
import { FinanceFilters } from "./FinanceTableTitle";
import { ConfigState } from "../../features/maintenance/maintenanceSlice";
import NoData from "../NoData";
import { REMOVE_FILTER, REQUEST_ASSISTANCE } from "../NoData/constants";
import moment from "moment";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DownloadButton from "../DownloadButton";
import { CircularProgress } from "@mui/material";
import { Button } from "@mui/material";
import { getGenerateOrdersReport } from "../reports/ReportsApi";
import { KALA_80 } from "../../styles/colors";
import { defaultMessage } from "../../utils";
import { OrdersReportState } from "../reports/OrdersReport/ordersReportSice";
import { LightTooltip } from "../reports/GenerateReports/GenerateReportTable";
import { trackAnalytics } from "../../Analytics";

export const FinanceTable: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const [showToast, setShowToast] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [dateSelectionBtn, setDateSelectionBtn] = React.useState("");
  const query = React.useMemo(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  React.useEffect(() => {
    if (query.orderId) {
      handleShowOrderModal(query.orderId);
    }
  }, [query]);
  const { list, loading, filters, count }: FinanceState = useSelector(
    (state: RootState) => state.financeItems
  );
  const { config }: ConfigState = useSelector(
    (state: RootState) => state.config
  );
  React.useEffect(() => {
    dispatch(getFinanceList(filters));
  }, [dispatch, filters]);
  const handleChangePage = (event: unknown, pageNo: number) => {
    dispatch(updateFilters({ pageNo }));
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      updateFilters({ pageNo: 0, pageSize: parseInt(event.target.value, 10) })
    );
  };
  function dueDateUrgencyColors(payload: any) {
    const rowDate = moment(payload.dueDate, "DD-MMM-YYYY").endOf("day");
    const today = moment().endOf("day");
    const configDate = moment()
      .add(config.dueDateDays || 0, "days")
      .endOf("day");
    if (payload.outAmount > 0) {
      if (moment(rowDate).isSame(today)) {
        return "#C98C10";
      } else if (rowDate < today) {
        return "#A1131A";
      } else if (
        moment(rowDate).diff(today, "days") <= (config.dueDateDays || 0)
      ) {
        return "#C98C10";
      }
    }

    return "#198548";
  }

  const [showOrderModal, setShowOrderModal] = React.useState(false);
  const [orderId, setOrderId] = React.useState("");

  const { loading: downloadOrderLoading }: OrdersReportState = useSelector(
    (state: RootState) => state.OrdersReport
  );
  const { response: generateReportResponse }: any = useSelector(
    (state: RootState) => state.generateReportList
  );

  const handleShowOrderModal = (orderId: any) => {
    setShowOrderModal(true);
    setOrderId(orderId);
  };

  const handleOrderModalClose = () => {
    setShowOrderModal(false);
    navigate(location.pathname, { state: { trackEvent: false } });
    setOrderId("");
  };

  React.useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showToast]);

  React.useEffect(() => {
    if (!showToast) return;
    let message: any =
      "Your request has been submitted. You will be notified when the report is ready.";
    if (filters.disburseStartDate == null && filters.dueStartDate == null) {
      message = (
        <>
          Your request has been submitted. The report will be generated from
          <br />
          {generateReportResponse[0].feInput[0].startDate} to
          {" " + generateReportResponse[0].feInput[0].endDate}
        </>
      );
    }
    defaultMessage(message, KALA_80);
  }, [showToast]);

  const statusMappings: any = {
    S: "SETTLED",
    U: "UNSETTLED",
  };

  const { mobileNumber, response: mobileRes }: any = useSelector(
    (state: RootState) => state.mobileNum
  );

  const handleDownloadClick = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const payload = {
      startDate:
        filters.dueStartDate != null
          ? filters.dueStartDate
          : filters.disburseStartDate,
      endDate:
        filters.dueEndDate != null
          ? filters.dueEndDate
          : filters.disburseEndDate,
      ordersType: statusMappings[filters.orderStatus as string] || "ALL",
      dateType: filters.disburseStartDate != null ? "PAYOUTDATE" : "DUEDATE",
      sortType: filters.sortOrder == "asc" ? "ASC" : "DESC",
      columnName: filters.sortKey == "Disburs" ? "payoutDate" : "dueDate",
    };
    const pageData = {
      source_page: "orders_tab",
      landing_page: "orders_tab",
      event_type: "click",
      tab: "",
      report_name: reportTypeMapping("ORDER", config),
      payload: { ...payload, selected_range_btn: dateSelectionBtn },
    };

    trackAnalytics("generate_report_btn", pageData);
    const ordersResponse = dispatch(await getGenerateOrdersReport(payload));
    if ((await ordersResponse).payload.response_code == 80) {
      setShowToast(true);
    }
  };

  return (
    <Box
      component={Paper}
      sx={{
        backgroundColor: "primary.contrastText",
        borderRadius: "16px",
        marginBottom: "20px",
        p: 2,
        // minHeight: "67vh",
      }}
    >
      <FinanceFilters setDateSelectionBtn={setDateSelectionBtn} />
      {!loading && count === 0 ? (
        <NoData
          objKey="orderOrInvoiceNumber"
          value={filters.orderOrInvoiceNumber}
          updateFilters={updateFilters}
          desc={
            !filters.disburseStartDate &&
            !filters.dueStartDate &&
            !filters.orderStatus
              ? REQUEST_ASSISTANCE
              : REMOVE_FILTER
          }
        />
      ) : (
        <>
          <TableContainer
            sx={{
              display: "grid",
              [`& .${tableCellClasses.root}`]: { borderBottom: "none" },
            }}
          >
            <Table sx={{ minWidth: 350 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ bgcolor: "#E4F2FB" }}>
                  <TableCell sx={{ color: "info.dark" }}>
                    Invoice Number
                  </TableCell>
                  <TableCell align="left" sx={{ color: "info.dark" }}>
                    Order ID
                  </TableCell>
                  <TableCell align="right" sx={{ color: "info.dark" }}>
                    Order Amount
                  </TableCell>
                  <TableCell align="right" sx={{ color: "info.dark" }}>
                    Amount Outstanding
                  </TableCell>
                  <TableCell align="left" sx={{ color: "info.dark" }}>
                    Disbursement Date
                  </TableCell>
                  <TableCell align="left" sx={{ color: "info.dark" }}>
                    Due Date
                  </TableCell>
                  <TableCell align="left">
                    {config.ordersAndPaymentHistoryReportFlag && (
                      <LightTooltip
                        title={"Download results"}
                        placement="bottom-end"
                        style={{ cursor: "pointer" }}
                      >
                        <DownloadButton
                          style={{
                            width: "1.5rem",
                            height: "1.5rem",
                            backgroundColor: downloadOrderLoading
                              ? "#A0A4A8"
                              : "#247BC7",
                          }}
                          onClick={(e: any) => handleDownloadClick(e)}
                        >
                          {downloadOrderLoading ? (
                            <CircularProgress
                              color="inherit"
                              style={{
                                width: "1rem",
                                height: "1rem",
                                // backgroundColor: "#A0A4A8",
                              }}
                            />
                          ) : (
                            <FileDownloadIcon fontSize="small" />
                          )}
                        </DownloadButton>
                      </LightTooltip>
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRowsLoader rowsNum={10} columnsNum={7} />
                ) : (
                  <></>
                )}
                {!loading &&
                  list?.map((row) => (
                    <TableRow
                      onClick={() => handleShowOrderModal(row.orderId)}
                      hover={true}
                      key={`${row.buyerInvoiceNumber}-${row.orderId}}`}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:nth-of-type(odd)": {
                          backgroundColor: "primary.contrastText",
                        },
                        "&:nth-of-type(even)": {
                          backgroundColor: "secondary.light",
                        },
                        cursor: "pointer",
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ fontWeight: 500 }}
                      >
                        {row.buyerInvoiceNumber == "NA" ||
                        !row.buyerInvoiceNumber
                          ? "N/A"
                          : row.buyerInvoiceNumber}
                      </TableCell>
                      <TableCell align="left" sx={{ fontWeight: 500 }}>
                        {row.orderId}
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: 500 }}>
                        {formatNumber(row.orderAmount)}
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: 500 }}>
                        {formatNumber(row.outAmount)}
                      </TableCell>
                      <TableCell align="left" sx={{ fontWeight: 500 }}>
                        {formatDate(row.disburseDate)}
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            bgcolor: dueDateUrgencyColors(row),
                            borderRadius: "32px",
                            color: "#FFFFFF",
                            padding: "3px 0",
                            width: "fit-content",
                            minWidth: "120px",
                          }}
                        >
                          <Typography
                            variant="body2"
                            fontWeight={700}
                            padding="2px 12px"
                            color={
                              formatDate(row.dueDate) !== "-"
                                ? "inherit"
                                : "info.dark"
                            }
                          >
                            {formatDate(row.dueDate) == "NA"
                              ? "N/A"
                              : formatDate(row.dueDate)}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        {/* <OrderDetails orderId={row.orderId}> */}
                        <ArrowForwardIosRoundedIcon
                          color="primary"
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#E7F6EC",
                            borderRadius: "6px",
                            padding: "4px",
                            boxSizing: "content-box",
                            height: "24px",
                            width: "15px",
                          }}
                        />
                        {/* </OrderDetails> */}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {count > 10 && (
            <Box
              sx={{
                backgroundColor: "secondary.light",
                borderRadius: "8px",
                marginTop: "10px",
              }}
            >
              <TablePagination
                rowsPerPageOptions={[10, 20]}
                component="div"
                count={count}
                rowsPerPage={filters.pageSize || 0}
                page={filters.pageNo || 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          )}
        </>
      )}
      {showOrderModal && (
        <OrderDetails
          orderId={orderId}
          children={undefined}
          defaultState={Boolean(orderId)}
          onClose={handleOrderModalClose}
          source={"orders_tab"}
        />
      )}
    </Box>
  );
};
