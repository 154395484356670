import React, { FC, ReactElement } from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import { RootState } from "../store";
import { useSelector } from "react-redux";

export const Footer: FC = (): ReactElement => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { config } = useSelector((state: RootState) => state.config);

  return (
    <Typography
      className="footer font-bold"
      color="info.light"
      variant="body2"
      justifyContent={"center"}
      id="footer"
      style={{ userSelect: "none" }}
    >
      <Grid container spacing={1} style={{ textAlign: "center" }}>
        <Grid item xs={6}>
          <Grid item style={{ textAlign: "right" }}>
            {isSmall ? "" : "Contact: "}
            <CallIcon />{" "}
            <a href={`tel:${config.SupportNumber}`}>{config.SupportNumber}</a>
          </Grid>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "left" }}>
          <EmailIcon style={{ marginRight: "4px" }} />
          <a
            href={`mailto:${config.SupportEmail}`}
            style={{ textDecoration: "underline" }}
          >
            {config.SupportEmail}
          </a>
        </Grid>
        <Grid item xs={12}>
          © 2023 Bijak|Krishiacharya Technologies Private Limited. All Rights
          Reserved
        </Grid>
      </Grid>
      <Grid style={{ textAlign: "center" }}></Grid>
    </Typography>
  );
};

export default Footer;
