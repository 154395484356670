import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { getInvoiceNumber } from "./ReportsApi";

export interface InvoiceNumberState {
  loading: boolean,
  response: any,
  error: string | null,
}

export const initialState: InvoiceNumberState = {
  loading: false,
  error: null,
  response: [],
}

export const CheckInvoiceNumberSlice = createSlice({
  name: 'checkInvoiceNumberSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [getInvoiceNumber.pending.type]: (state, action) => {
      state.loading = true;
    },
    [getInvoiceNumber.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.response = action.payload;
    },
    [getInvoiceNumber.rejected.type]: (state, action) => {
      state.loading = false;
      state.response = [];
      state.error = action.payload || 'Something went wrong';
    },
  }
})

export default CheckInvoiceNumberSlice.reducer