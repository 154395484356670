import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import {
  Button,
  Checkbox,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { DateDropDownMenu } from "../Finance/CustomCalender";
import PaymentSort from "./PaymentSort";
import {
  PaymentState,
  initialState,
  updateFilters,
} from "./paymentHistorySlice";
import { useLocation } from "react-router-dom";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
  },
}));

function PaymentFilters({setDateSelectionBtn}: any) {
  const location = useLocation();

  React.useEffect(() => {
    if (location.state?.reload) {
      dispatch(updateFilters({ ...initialState.filters, utr: null }));
      setCalenderFilterBy("");
      setTransactionDate(true);
    }
  }, [location]);

  const [transactionDate, setTransactionDate] = React.useState(true);
  const [calenderFilterBy, setCalenderFilterBy] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch: AppDispatch = useDispatch();
  const { filters, count }: PaymentState = useSelector(
    (state: RootState) => state.paymentHistory
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterTrnxDate = (startDate: string | null, endDate: string | null) => {
    dispatch(
      updateFilters({
        startTxnDate:
          startDate === null ? null : moment(startDate).format("YYYY-MM-DD"),
        endTxnDate:
          endDate === null ? null : moment(endDate).format("YYYY-MM-DD"),
        pageNo: 0,
      })
    );
  };

  useEffect(()=>{
    setDateSelectionBtn(calenderFilterBy);
  }, [calenderFilterBy])

  useEffect(() => {
    if (filters.utr != null) {
      clearFilters();
    } else {
      setTransactionDate(true);
      setCalenderFilterBy("");
    }
  }, [filters.utr]);

  const clearFilters = () => {
    setTransactionDate(false);
    setCalenderFilterBy("");
    dispatch(
      updateFilters({
        ...filters,
        startTxnDate: null,
        endTxnDate: null,
        pageNo: 0,
      })
    );
  };

  const handleCheckbox = () => {
    if (transactionDate) {
      setCalenderFilterBy("");
      dispatch(
        updateFilters({
          ...filters,
          startTxnDate: null,
          endTxnDate: null,
          pageNo: 0,
        })
      );
    }
    setTransactionDate(!transactionDate);
  };

  return (
    <div>
      <Grid
        width="100%"
        container
        alignItems="center"
        my={2}
        px={4}
        justifyContent="space-between"
      >
        <Grid item style={{ padding: 0 }}>
          <Typography variant="h6" sx={{ color: "info.main", fontWeight: 600 }}>
            Payment History
          </Typography>
        </Grid>
        {filters.utr === null ? (
          <Grid item style={{ padding: 0 }}>
            <div style={{ display: "flex", columnGap: 16 }}>
              <PaymentSort
                disabled={
                  filters.utr === null && !transactionDate && count === 0
                }
              />
              <Button
                variant="outlined"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownRoundedIcon />}
                color="info"
                disabled={
                  filters.utr === null && !transactionDate && count === 0
                }
                sx={{
                  color: "info.dark",
                  minWidth: "180px",
                  justifyContent: "space-between",
                }}
              >
                <Grid container>
                  <Grid item paddingRight="6px">
                    <FilterAltRoundedIcon />
                  </Grid>
                  <Grid item>Filters</Grid>
                </Grid>
              </Button>
              <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={handleCheckbox}>
                  <Checkbox checked={transactionDate} />
                  <ListItemText primary={"Transaction Date"} />
                </MenuItem>
              </StyledMenu>
            </div>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      {filters.utr === null && transactionDate ? (
        <Grid container alignItems="center" px={4} mb={2} width="100%">
          <Grid item xs={9}>
            <Grid container alignItems="center" spacing={3}>
              <Grid item>
                <Typography>Selected Filters:</Typography>
              </Grid>
              <Grid item>
                <DateDropDownMenu
                  buttonName="Transaction Date"
                  futureOptions={false}
                  filterDispatchCall={filterTrnxDate}
                  filterBy={calenderFilterBy}
                  setFilterBy={setCalenderFilterBy}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} textAlign="right">
            <Button
              variant="text"
              onClick={clearFilters}
              sx={{ columnGap: 1, color: "info.dark" }}
            >
              <CloseRoundedIcon />
              <Typography variant="subtitle2">Clear All Filters</Typography>
            </Button>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </div>
  );
}

export default PaymentFilters;
