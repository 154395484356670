// Analytics.js

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Analytics from "analytics";
import segmentPlugin from "@analytics/segment";
import { store } from "./store";

export const analytics = Analytics({
  app: import.meta.env.VITE_APP_SEGMENT_APP,
  plugins: [
    segmentPlugin({
      writeKey: import.meta.env.VITE_APP_SEGMENT_KEY, // Your Segment write key
    }),
  ],
});

export const trackAnalytics = (event_name: string, payload: any) => {
  const responseUser: any = store.getState().mobileNum;
  let user: any = {
    Mobile_Number: responseUser?.mobileNumber || payload?.Mobile_Number,
  };
  if (responseUser?.response?.buyerId) {
    user.buyer_ptr_id = responseUser?.response?.buyerId;
  }
  analytics.track(event_name, { ...user, ...payload });
};
