import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import {
  PaymentState,
  updateFilters,
  initialState,
} from "./paymentHistorySlice";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { trackAnalytics } from "../../Analytics";

const SearchField = () => {
  const location = useLocation();
  const query: any = React.useMemo(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const { loading, filters }: PaymentState = useSelector(
    (state: RootState) => state.paymentHistory
  );
  const [searchText, setSearchText] = React.useState<string>(
    filters.utr || query.utr
  );
  const dispatch: AppDispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const { mobileNumber, response: mobileRes }: any = useSelector(
    (state: RootState) => state.mobileNum
  );

  const handleClick = () => {
    //SEGMENT PART
    //when user search UTR on payment history tab
    const pageData = {
      source_page: "payment_history_tab",
      landing_page: "payment_history_tab",
      event_type: "click",
      utr: searchText,
    };
    trackAnalytics("utr_search", pageData);

    if (searchText !== filters.utr) {
      dispatch(updateFilters({ pageNo: 0, utr: searchText || null }));
    }
  };

  const handleClose = () => {
    setSearchText("");
    const pageData = {
      source_page: "payment_history_tab",
      landing_page: "payment_history_tab",
      event_type: "click",
    };
    trackAnalytics("utr_search_clear", pageData);
    dispatch(updateFilters({ pageNo: 0, utr: null }));
  };

  const handleSearch = (event: any) => {
    if (event.key === "Enter" && searchText !== filters.utr) {
      //SEGMENT PART
      //when user search UTR on payment history tab
      const pageData = {
        source_page: "payment_history_tab",
        landing_page: "payment_history_tab",
        event_type: "click",
        utr: searchText,
      };

      trackAnalytics("utr_search", pageData);
      dispatch(updateFilters({ pageNo: 0, utr: searchText || null }));
    }
  };

  React.useEffect(() => {
    if (filters.utr == null && searchText) {
      setSearchText("");
    }
  }, [filters.utr]);

  React.useEffect(() => {
    return () => {
      setSearchText("");
      dispatch(updateFilters({ ...initialState.filters }));
    };
  }, []);

  React.useEffect(() => {
    setSearchText(query.utr || "");
    dispatch(
      updateFilters({
        ...initialState.filters,
        utr: query.utr || null,
      })
    );
  }, [query]);

  return (
    <TextField
      disabled={loading}
      variant="outlined"
      placeholder="Search UTR"
      fullWidth
      value={searchText}
      autoComplete="off"
      name="tech_id_utr"
      onChange={handleChange}
      sx={{
        bgcolor: "white",
        maxWidth: "360px",
      }}
      onKeyDown={handleSearch}
      onKeyUp={handleSearch}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {filters.utr && filters?.utr === searchText ? (
              <CloseIcon
                onClick={handleClose}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    color: "primary.main",
                  },
                  "&:clicked": {
                    color: "primary.main",
                  },
                }}
              />
            ) : (
              <SearchIcon
                onClick={handleClick}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    color: "primary.main",
                  },
                  "&:clicked": {
                    color: "primary.main",
                  },
                }}
              />
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchField;
