import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_API } from "../../../api";

export const getVarieties = createAsyncThunk("get/varieties", async (payload: any, { rejectWithValue }) => {
  try {
    const response = await BASE_API.get("bijak/commodity/service/api/v1/dashboard/variety", { params: payload })
    return response.data.result
  } catch (error: any) {
    return rejectWithValue(error.response.data.error);
  }

})