import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { postHelp } from './helpAPI'
import { errorMessage } from '../../utils'
import { getUserDetails } from '../../components/UserProfile/userApi'
import { logoutUser } from '../../components/Logout/logoutApi'

export interface HelpState {
  state: number,
  reason: string,
  mobile: string,
  desc: string,
  loading: boolean,
  error?: string,
}

export const initialState: HelpState = {
  state: 10,
  reason: '',
  desc: '',
  loading: false,
  error: '',
  mobile: '',
}

export const helpSlice = createSlice({
  name: 'help',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.state += 1
    },
    decrement: (state) => {
      state.state -= 1
    },
    updateFormValues: (state, action: PayloadAction<any>) => {
      state.reason = action.payload.reason
      state.desc = action.payload.desc
      state.mobile = action.payload.mobile
    },
    closePopup: (state) => {
      state.state = 10
    },
  },
  extraReducers: {
    [postHelp.pending.type]: (state, action) => {
      state.loading = true;
      state.error = '';
    },
    [postHelp.fulfilled.type]: (state, action) => {
      state.loading = false;
      if (state.reason === 'Issue') {
        state.state = 20;
      } else {
        state.state = 30;
      }
      state.reason = ''
      state.desc = ''
      state.mobile = ''
    },
    [postHelp.rejected.type]: (state, action) => {
      errorMessage(action.payload || 'Something went wrong');
      state.loading = false;
      state.error = action.payload || 'Something went wrong';
    },
    [getUserDetails.fulfilled.type]: (state, action) => {
      state.mobile = action.payload.mobileNumber
    },
    [logoutUser.fulfilled.type]: (state, action) => {
      state.mobile = ''
      state.desc = ''
      state.reason = ''
    }
  },
})

// Action creators are generated for each case reducer function
export const { closePopup, decrement, updateFormValues } = helpSlice.actions

export default helpSlice.reducer