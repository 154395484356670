import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { postLoginOtp, postLoginResetPassword, postLoginResetPasswordOtp, resendOTP } from "./loginApi";
import { AuthState } from "./LoginModuleSlice";
import { errorMessage } from "../../utils";

interface LoginVerifyOtpState {
  loading: boolean,
  error: any,
  state: AuthState
  otp: any,
  response?: any,
  otpVerifyResponseCode: number
}

const initialState: LoginVerifyOtpState = {
  state: AuthState.VerifyOtp,
  loading: false,
  error: null,
  otp: null,
  response: '',
  otpVerifyResponseCode: 0
};

export const LoginOtpSlice = createSlice({
  name: "LoginVerifyOtp",
  initialState,
  reducers: {
    updateAuthState: (state) => {
      state.state = AuthState.VerifyOtp;
      state.error ='';
    },
  },
  extraReducers: {
    [postLoginOtp.pending.type]: (state, action) => {
      state.loading = true;
      state.otp = action.meta.arg.otp;
      state.error = '';
    },
    [postLoginOtp.fulfilled.type]: (state, action) => {
      state.loading = false;
      if (action.payload.response_code === 80) {
        state.state = AuthState.SetPassword;
      }
      state.response = action.payload;
      state.error ='';
    },
    [postLoginOtp.rejected.type]: (state, action) => {
      state.loading = false;
      state.response = '';
      state.otpVerifyResponseCode = action?.payload?.response_code;
      state.error = action?.payload?.error || "Something went wrong";
      state.state = AuthState.VerifyOtp;
      errorMessage(state.error);
    },
    [postLoginResetPassword.fulfilled.type]: (state, action) => {
      state.state = AuthState.VerifyOtp;
    },
    [postLoginResetPasswordOtp.fulfilled.type]: (state, action) => {
      state.state = AuthState.VerifyOtp;
      state.error='';
    },
    [resendOTP.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.error='';
    },
    [resendOTP.rejected.type]: (state, action) => {
      state.loading = false;
      state.error='';
    }
  },
});
export const { updateAuthState } = LoginOtpSlice.actions
export default LoginOtpSlice.reducer;
