import { Button, styled } from "@mui/material";

const DownloadButton = styled(Button)(({ theme }) => ({
  fontSize: "0.75rem",
  fontWeight: 600,
  lineHeight: "1rem",
  padding: "0.25rem ",
  borderRadius: "0.25rem",
  background: "#247BC7",
  color: "#FFFFFF",
  minWidth: "auto",
  "&: hover": {
    background: "#247BC7",
    color: "#FFFFFF",
  },
}));
export default DownloadButton;
