import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch } from "react-redux";
import { redirectToLogin } from "./LoginModuleSlice";

const ArrowBack = ({ url = "" }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = () => {
    if (url) {
      navigate(url);
    } else {
      if (location.pathname === "/login") {
        navigate("/");
      }
      dispatch(redirectToLogin());
    }
  };

  return (
    <ArrowBackIcon
      style={{ color: "primary.main", cursor: "pointer", marginRight: "5px" }}
      onClick={handleNavigation}
      id="arrowBackIcon"
    />
  );
};

export default ArrowBack;
