import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import {
  JAMUN_5,
  JAMUN_5_HOVER,
  JAMUN_80,
  MIRCHI_5,
  MIRCHI_70,
  KARELA_80,
} from "../../styles/colors";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import TableRowsLoader from "../Skelton/RowsSkelton";
import { POState, updateFilters } from "./poManagementSlice";
import NoData from "../NoData";
import { REMOVE_FILTER, REQUEST_ASSISTANCE } from "../NoData/constants";
import { formatDate } from "../../utils";
import { getPurchaseOrder } from "./poManagementApi";

const TABLE_BODY_STYLE = {
  "&:last-child td, &:last-child th": { border: 0 },
  "&:nth-of-type(odd)": {
    backgroundColor: "primary.contrastText",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "secondary.light",
  },
};

const TABLE_HEAD_STYLE = {
  display: "grid",
  [`& .${tableCellClasses.root}`]: { borderBottom: "none" },
};

const POManagementTable = () => {
  const dispatch: AppDispatch = useDispatch();

  const { list, loading, filters, count }: POState = useSelector(
    (state: RootState) => state.purchaseOrder
  );

  useEffect(() => {
    dispatch(getPurchaseOrder(filters));
  }, [dispatch, filters]);

  const handleChangePage = (event: unknown, pageNo: number) => {
    dispatch(updateFilters({ pageNo }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      updateFilters({ pageNo: 0, pageSize: parseInt(event.target.value, 10) })
    );
  };

  if (!loading && list?.length === 0) {
    return (
      <NoData
        objKey="purchaseOrderNumber"
        value={filters.purchaseOrderNumber}
        updateFilters={updateFilters}
        desc={!filters.startCreateDate ? REQUEST_ASSISTANCE : REMOVE_FILTER}
      />
    );
  }

  return (
    <Box p={2} pt={0}>
      <TableContainer sx={TABLE_HEAD_STYLE}>
        <Table sx={{ minWidth: 350 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ bgcolor: JAMUN_5 }}>
              <TableCell sx={{ color: "info.dark" }}>PO Number</TableCell>
              <TableCell align="left" sx={{ color: "info.dark" }}>
                Created Date
              </TableCell>
              <TableCell align="left" sx={{ color: "info.dark" }}>
                Reference ID
              </TableCell>
              <TableCell align="left" sx={{ color: "info.dark" }}>
                Commodity
              </TableCell>
              <TableCell align="left" sx={{ color: "info.dark" }}>
                Rate
              </TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? <TableRowsLoader rowsNum={10} columnsNum={5} /> : <></>}
            {!loading &&
              list.map((row, index) => (
                <TableRow
                  hover={true}
                  sx={TABLE_BODY_STYLE}
                  key={row.purchaseOrderNumber}
                >
                  <TableCell sx={{ fontWeight: 500, minWidth: "8rem" }}>
                    {row.purchaseOrderNumber}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 500 }}>
                    {formatDate(row.createdDate)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 500 }}>
                    {row.referenceId}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 500 }}>
                    {row.commodityName ? row.commodityName : "-"}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 500 }}>
                    {row.price ? `₹${row.price}/${row.priceUnit}` : "-"}
                  </TableCell>
                  <TableCell align="right">
                    <div
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        cursor: "pointer",
                        backgroundColor:
                          row.status === "Rejected" ? "#FCE9EC" : "#E7F6EC",
                        borderRadius: "8px",
                        padding: "4px 8px",
                        boxSizing: "content-box",
                        // height: "24px",
                      }}
                    >
                      <Typography
                        fontWeight={600}
                        sx={{
                          color:
                            row.status === "Rejected" ? MIRCHI_70 : KARELA_80,
                          fontSize: "12px",
                        }}
                      >
                        {row.status}
                      </Typography>
                      <ArrowForwardIosRoundedIcon
                        fontSize="small"
                        sx={{
                          color:
                            row.status === "Rejected" ? MIRCHI_70 : KARELA_80,
                        }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {count > 10 && (
        <Box
          sx={{
            backgroundColor: "secondary.light",
            borderRadius: "8px",
            marginTop: "10px",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[10, 20]}
            component="div"
            count={count}
            rowsPerPage={filters.pageSize}
            page={filters.pageNo}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </Box>
  );
};

export default POManagementTable;
