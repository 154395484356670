import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
// import userReducer from '../components/Login/userSlice';
import financeListReducer from '../components/Finance/financeSlice';
import mobileNumberVerifyReducer from '../components/Login/LoginModuleSlice';
import loginOtpVerifyReducer from '../components/Login/LoginVerifyOtpSlice';
import PasswordReducer from '../components/Login/PasswordSlice';
import LoginViaPasswordReducer from '../components/Login/LoginSlice';
import helpSliceReducer from '../features/Help/helpSlice';
import configSliceReducer from '../features/maintenance/maintenanceSlice';
import orderDetailsSliceReducer from '../components/Finance/OrderDetails/orderDetailsSlice';
import orderAggregateSliceReducer from '../components/OrderAggregates/orderAggregateSlice';
import paymentHistoryReducer from '../components/PaymentHistory/paymentHistorySlice';
import appSliceReducer from '../layouts/app/appSlice';
import userReducer from '../components/UserProfile/userSlice'
import logoutSlice from '../components/Logout/logoutSlice';
import poManagementReducer from '../components/POManagement/poManagementSlice';
import commoditiesSlice from '../components/dropDowns/commodity/commoditySlice';
import poAddReducer from '../components/AddPO/poSlice';
import varietiesSlice from '../components/dropDowns/variety/varietySlice';
import duplicatePONumberStateReducer from '../components/AddPO/duplicatePoSlice';
import disbursementDetailsReducer from '../components/Finance/OrderDetails/disbursementDetailsSlice';
import CheckInvoiceNumberReducer from '../components/reports/invoiceCheckSlice';
import LedgerReportReducer from '../components/reports/ledgerReportSlice';
import LateFeeReportReducer from '../components/reports/lateFeeReportsSlice';
import generateReportReducer from '../components/reports/GenerateReports/generateReportSlice';
import generatedReportReducer from '../components/reports/GeneratedReports/generatedReportSlice';
import notificationSliceReducer from '../components/Notifications/notificationSlice';
import downloadReportSliceReducer from '../components/reports/GeneratedReports/downloadReportSlice';
import paymentHistoryReportReducer from '../components/reports/PaymentHistoryReport/paymentHistoryReportSlice';
import ordersReportReducer from '../components/reports/OrdersReport/ordersReportSice';
import DownloadDisbursementReportReducer from '../components/Finance/OrderDetails/disbursementReportSlice';

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        // user: userReducer,
        financeItems: financeListReducer,
        mobileNum: mobileNumberVerifyReducer,
        loginOtpVerify: loginOtpVerifyReducer,
        setPassword: PasswordReducer,
        help: helpSliceReducer,
        config: configSliceReducer,
        login: LoginViaPasswordReducer,
        orderDetails: orderDetailsSliceReducer,
        orderAggregates: orderAggregateSliceReducer,
        paymentHistory: paymentHistoryReducer,
        app: appSliceReducer,
        userDetails: userReducer,
        logout: logoutSlice,
        purchaseOrder: poManagementReducer,
        commodityList: commoditiesSlice,
        addPurchaseOrder: poAddReducer,
        varietyList: varietiesSlice,
        duplicatePONumber: duplicatePONumberStateReducer,
        disbursementDetails: disbursementDetailsReducer,
        checkInvoiceNumber: CheckInvoiceNumberReducer,
        LedgerReport: LedgerReportReducer,
        LateFeeBreakdownReport: LateFeeReportReducer,
        generateReportList: generateReportReducer,
        generatedReportList: generatedReportReducer,
        notifications: notificationSliceReducer,
        downloadReportLink: downloadReportSliceReducer,
        PaymentHistoryReport: paymentHistoryReportReducer,
        OrdersReport: ordersReportReducer,
        downloadDisbursementReport: DownloadDisbursementReportReducer
    },
    devTools: true
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;