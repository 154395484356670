import { Grid, Button } from "@mui/material";
import { Fragment } from "react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import DateRangeSelector from "./../Finance/CustomCalender";
import TodayIcon from "@mui/icons-material/Today";
import Reports from "./../SVG/Reports";
import {
  subMonths,
  format,
  endOfMonth,
  startOfMonth,
  addMonths,
  addDays,
  subDays
} from "date-fns";
import { KALA_70 } from "./../../styles/colors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React, { useEffect, useState} from "react";
import { LightTooltip } from "./ReportsFilter";

function DateRangeReport({
  applied,
  setApplied,
  openDateRange,
  setOpenDateRange,
  dateSelectionBtn,
  setDateSelectionBtn,
  range,
  setRange,
  calenderFilterBy,
  setCalenderFilterBy,
  reportFilter,
}: any) {

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      dateSelectionBtn == "Date Range" &&
      !applied &&
      !openDateRange &&
      calenderFilterBy == ""
    ) {
      setRange([
        {
          startDate: startOfMonth(subMonths(new Date(), 3)),
          endDate: endOfMonth(subMonths(new Date(), 1)),
          key: "selection",
        },
      ]);
      if (reportFilter == "DueReport" || reportFilter == "DisbursementReport") {
        setDateSelectionBtn("");
      } else if (reportFilter == "PaymentHistoryReport") {
        setDateSelectionBtn("Last Month");
      } else {
        setDateSelectionBtn("Last 3 Months");
      }
    }
  }, [
    openDateRange,
    dateSelectionBtn,
    applied,
    openDateRange,
    calenderFilterBy,
    reportFilter
  ]);

  const handleLedgerDate = (value: string) => {
    if (value == "Date Range") {
      if (!applied && !openDateRange && calenderFilterBy == "") {
        setRange([
          {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          },
        ]);
        setDateSelectionBtn("Last 3 Months");
      }
      setOpenDateRange(true);
    } else if (value == "Last Month") {
      setApplied(false);
      setRange([
        {
          startDate: startOfMonth(subMonths(new Date(), 1)),
          endDate: endOfMonth(subMonths(new Date(), 1)),
          key: "selection",
        },
      ]);
    } else if (value == "Last 3 Months") {
      setApplied(false);
      setRange([
        {
          startDate: startOfMonth(subMonths(new Date(), 3)),
          endDate: endOfMonth(subMonths(new Date(), 1)),
          key: "selection",
        },
      ]);
    } else if (value == "Last 6 Months") {
      setApplied(false);
      setRange([
        {
          startDate: startOfMonth(subMonths(new Date(), 6)),
          endDate: endOfMonth(subMonths(new Date(), 1)),
          key: "selection",
        },
      ]);
    } else if (value == "Current Month") {
      setApplied(false);
      setRange([
        {
          startDate: startOfMonth(subMonths(new Date(), 0)),
          endDate: endOfMonth(subMonths(new Date(), 0)),
          key: "selection",
        },
      ]);
    } else if (value == "Next Month") {
      setApplied(false);
      setRange([
        {
          startDate: startOfMonth(addMonths(new Date(), 1)),
          endDate: endOfMonth(addMonths(new Date(), 1)),
          key: "selection",
        },
      ]);
    } else if (value == "Next 7 Days") {
      setApplied(false);
      setRange([
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 6),
          key: "selection",
        },
      ]);
    } else if (value == "Last 7 Days") {
      setApplied(false);
      setRange([
        {
          startDate: subDays(new Date(), 6),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    }
    setDateSelectionBtn(value);
  };

  const closeButtonclicked = (event: any) => {
    event.stopPropagation();
    if (reportFilter == "DueReport" || reportFilter == "DisbursementReport") {
      setDateSelectionBtn("");
    } else if (reportFilter == "PaymentHistoryReport") {
      setDateSelectionBtn("Last Month");
    } else {
      setDateSelectionBtn("Last 3 Months");
    }
    setOpenDateRange(false);
    setApplied(false);
    setRange([
      {
        startDate: startOfMonth(subMonths(new Date(), 3)),
        endDate: endOfMonth(subMonths(new Date(), 1)),
        key: "selection",
      },
    ]);
  };

  return (
    <Fragment>
      <Grid
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "12px",
        }}
      >
        {(reportFilter == "DisbursementReport" ||
          reportFilter == "PaymentHistoryReport") && (
          <Grid item>
            <LightTooltip
              title={"Including Today"}
              placement="bottom-start"
              style={{ cursor: "pointer" }}
            >
              <Button
                color="primary"
                variant={
                  dateSelectionBtn == "Last 7 Days" ? "contained" : "outlined"
                }
                onClick={() => handleLedgerDate("Last 7 Days")}
                startIcon={
                  dateSelectionBtn == "Last 7 Days" && <CheckCircleIcon />
                }
                sx={{
                  color: dateSelectionBtn == "Last 7 Days" ? "white" : KALA_70,
                  // minWidth: "400px",
                  fontWeight: 500,
                  borderColor: "#CACCCF",
                  "&:hover": {
                    borderColor: "#CACCCF",
                  },
                  height: "36px",
                  padding: "8px 12px",
                  borderRadius: "24px",
                }}
              >
                Last 7 Days
              </Button>
            </LightTooltip>
          </Grid>
        )}
        {reportFilter == "DueReport" && (
          <Grid item>
            <LightTooltip
              title={"Including Today"}
              placement="bottom-start"
              style={{ cursor: "pointer" }}
            >
              <Button
                color="primary"
                variant={
                  dateSelectionBtn == "Next 7 Days" ? "contained" : "outlined"
                }
                onClick={() => handleLedgerDate("Next 7 Days")}
                startIcon={
                  dateSelectionBtn == "Next 7 Days" && <CheckCircleIcon />
                }
                sx={{
                  color: dateSelectionBtn == "Next 7 Days" ? "white" : KALA_70,
                  // minWidth: "400px",
                  fontWeight: 500,
                  borderColor: "#CACCCF",
                  "&:hover": {
                    borderColor: "#CACCCF",
                  },
                  height: "36px",
                  padding: "8px 12px",
                  borderRadius: "24px",
                }}
              >
                Next 7 Days
              </Button>
            </LightTooltip>
          </Grid>
        )}
        {(reportFilter == "LateFeeReport" ||
          reportFilter == "PaymentHistoryReport" ||
          reportFilter == "DueReport" ||
          reportFilter == "DisbursementReport") && (
          <Grid item>
            <Button
              color="primary"
              variant={
                dateSelectionBtn == "Current Month" ? "contained" : "outlined"
              }
              onClick={() => handleLedgerDate("Current Month")}
              startIcon={
                dateSelectionBtn == "Current Month" && <CheckCircleIcon />
              }
              sx={{
                color: dateSelectionBtn == "Current Month" ? "white" : KALA_70,
                // minWidth: "400px",
                fontWeight: 500,
                borderColor: "#CACCCF",
                "&:hover": {
                  borderColor: "#CACCCF",
                },
                height: "36px",
                padding: "8px 12px",
                borderRadius: "24px",
              }}
            >
              Current Month
            </Button>
          </Grid>
        )}
        {((reportFilter == "DisbursementReport" && windowSize.width > 1460) ||
          (reportFilter == "DisbursementReport" &&
            !applied &&
            windowSize.width <= 1460) ||
          (reportFilter == "PaymentHistoryReport" && windowSize.width > 1460) ||
          (reportFilter == "PaymentHistoryReport" &&
            !applied &&
            windowSize.width <= 1460) ||
          reportFilter == "LateFeeReport" ||
          reportFilter == "LedgerReport") && (
          <Grid item>
            <Button
              color="primary"
              variant={
                dateSelectionBtn == "Last Month" ? "contained" : "outlined"
              }
              onClick={() => handleLedgerDate("Last Month")}
              startIcon={
                dateSelectionBtn == "Last Month" && <CheckCircleIcon />
              }
              sx={{
                color: dateSelectionBtn == "Last Month" ? "white" : KALA_70,
                // minWidth: "400px",
                fontWeight: 500,
                borderColor: "#CACCCF",
                "&:hover": {
                  borderColor: "#CACCCF",
                },
                height: "36px",
                padding: "8px 12px",
                borderRadius: "24px",
              }}
            >
              Last Month
            </Button>
          </Grid>
        )}
        {reportFilter != "DueReport" &&
          reportFilter != "DisbursementReport" &&
          reportFilter != "PaymentHistoryReport" && (
            <Grid item>
              <Button
                color="primary"
                variant={
                  dateSelectionBtn == "Last 3 Months" ? "contained" : "outlined"
                }
                onClick={() => handleLedgerDate("Last 3 Months")}
                startIcon={
                  dateSelectionBtn == "Last 3 Months" && <CheckCircleIcon />
                }
                sx={{
                  color:
                    dateSelectionBtn == "Last 3 Months" ? "white" : KALA_70,
                  // minWidth: "400px",
                  fontWeight: 500,
                  borderColor: "#CACCCF",
                  "&:hover": {
                    borderColor: "#CACCCF",
                  },
                  height: "36px",
                  padding: "8px 12px",
                  borderRadius: "24px",
                }}
              >
                Last 3 Months
              </Button>
            </Grid>
          )}
        {((reportFilter == "LedgerReport" && windowSize.width > 1460) ||
          (reportFilter == "LedgerReport" &&
            !applied &&
            windowSize.width <= 1460)) && (
          <Grid item>
            <Button
              color="primary"
              variant={
                dateSelectionBtn == "Last 6 Months" ? "contained" : "outlined"
              }
              onClick={() => handleLedgerDate("Last 6 Months")}
              startIcon={
                dateSelectionBtn == "Last 6 Months" && <CheckCircleIcon />
              }
              sx={{
                color: dateSelectionBtn == "Last 6 Months" ? "white" : KALA_70,
                // minWidth: "400px",
                fontWeight: 500,
                borderColor: "#CACCCF",
                "&:hover": {
                  borderColor: "#CACCCF",
                },
                height: "36px",
                padding: "8px 12px",
                borderRadius: "24px",
              }}
            >
              Last 6 Months
            </Button>
          </Grid>
        )}
        {((reportFilter == "DueReport" && windowSize.width > 1460) ||
          (reportFilter == "DueReport" &&
            !applied &&
            windowSize.width <= 1460)) && (
          <Grid item>
            <Button
              color="primary"
              variant={
                dateSelectionBtn == "Next Month" ? "contained" : "outlined"
              }
              onClick={() => handleLedgerDate("Next Month")}
              startIcon={
                dateSelectionBtn == "Next Month" && <CheckCircleIcon />
              }
              sx={{
                color: dateSelectionBtn == "Next Month" ? "white" : KALA_70,
                // minWidth: "400px",
                fontWeight: 500,
                borderColor: "#CACCCF",
                "&:hover": {
                  borderColor: "#CACCCF",
                },
                height: "36px",
                padding: "8px 12px",
                borderRadius: "24px",
              }}
            >
              Next Month
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button
            color="primary"
            variant={
              dateSelectionBtn == "Date Range" && applied
                ? "contained"
                : "outlined"
            }
            onClick={() => handleLedgerDate("Date Range")}
            startIcon={
              dateSelectionBtn == "Date Range" &&
              applied && (
                <CheckCircleIcon style={{ width: "20px", height: "20px" }} />
              )
            }
            endIcon={
              applied ? (
                <CloseRoundedIcon
                  onClick={closeButtonclicked}
                  sx={{
                    color: "primary.main",
                    backgroundColor: "white",
                    borderRadius: "25px",
                    width: "16.6px",
                    height: "16.6px",
                  }}
                />
              ) : (
                <KeyboardArrowDownRoundedIcon />
              )
            }
            sx={{
              color:
                dateSelectionBtn == "Date Range" && applied ? "white" : KALA_70,
              fontWeight: 500,
              borderColor: "#CACCCF",
              "&:hover": {
                borderColor: "#CACCCF",
              },
              height: "36px",
              padding: "8px 12px",
              borderRadius: "24px",
            }}
          >
            {applied
              ? `${format(range[0].startDate, "dd MMM yyyy")} - ${format(
                  range[0].endDate,
                  "dd MMM yyyy"
                )}`
              : "Date Range"}
          </Button>
          <DateRangeSelector
            open={openDateRange}
            setFilterBy={setCalenderFilterBy}
            setOpen={setOpenDateRange}
            range={range}
            setRange={setRange}
            setApplied={setApplied}
            centerAlign={true}
            daysLimit={true}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default DateRangeReport;
