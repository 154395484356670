import {
  Grid,
  Paper,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import Footer from "../../components/Footer";
import { styled } from "@mui/system";
import Help from "../../features/Help";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { getUserDetails } from "../../components/UserProfile/userApi";
import { useNavigate } from "react-router-dom";
import { UserDetailsState } from "../../components/UserProfile/userSlice";
import Loader from "../../components/Loader";

const ResponsiveLogin = styled(Grid)`
  .auth-container {
    padding: 32px;
  }
  .auth-box-container {
    border-radius: 32px;
  }
  .auth-box {
    padding: 56px 30px;
  }
  // ${(props) => props.theme.breakpoints.down("lg")} {
  //   position: absolute;
  //   bottom: 20px;
  //   // top: 20px;
  //   width: 490px;
  //   left: 50px;
  //   .auth-box {
  //     padding: 24px;
  //   }
  //   .auth-box-container {
  //     border-radius: 0;
  //   }
  //   .auth-container {
  //     padding: 16px;
  //   }
  //   .bijak-logo {
  //     height: 32px;
  //   }
  //   .footer {
  //     margin-top: 20px;
  //     color: ${(props) => props.theme.palette.primary.contrastText};
  //   }
  // }

  ${(props) => props.theme.breakpoints.down("sm")} {
    position: absolute;
    bottom: 20px;
    width: 90%;
    left: 4%;
    .footer {
      margin-top: 20px;
      color: ${(props) => props.theme.palette.primary.contrastText};
    }
  }
`;

const ResponsiveBox = styled(Box)`
  .auth-container {
    padding: 32px;
  }
  &.auth-box-container {
    border-radius: 32px;
    height: 96vh;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.8) 0%,
        rgba(25, 133, 72, 0.8) 0.01%,
        rgba(25, 133, 72, 0.4) 100%
      ),
      url(/banner.jpg);
    background-position: "center";
    background-repeat: "no-repeat";
    background-size: "cover";
  }
  .auth-box {
    padding: 56px 30px;
  }
`;

function AuthLayout({ children }: any) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  const { response, loading }: UserDetailsState = useSelector(
    (state: RootState) => state.userDetails
  );

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (!response?.mobileNumber && localStorage.getItem("token")) {
      dispatch(getUserDetails(""));
    }
  }, []);

  useEffect(() => {
    if (response?.mobileNumber) {
      navigate("/orders");
    }
  }, [response]);

  if ((_.isEmpty(response) && loading) || response?.mobileNumber) {
    return <Loader loading />;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        position: "relative",
        maxWidth: "1440px",
        margin: "0 auto",
        padding: isSmall ? 0 : "1%",
        height: "100vh",
      }}
    >
      <Paper elevation={0} sx={{ backgroundColor: "secondary.light" }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            // md={12}
            sm={6}
            xl={6}
          >
            <ResponsiveBox className="auth-box-container" style={{}}>
              <Box className="auth-box" style={{}}>
                <Grid container spacing={isSmall ? 0 : 2}>
                  <Grid item xs={12}>
                    <Typography
                      color="primary.contrastText"
                      variant={"poster"}
                      sx={{
                        float: "left",
                      }}
                    >
                      Welcome to Bijak
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      color="primary.contrastText"
                      variant="h1"
                      className="font-normal"
                      style={{
                        // fontSize: "2rem",
                        // fontWeight: 400,
                        // lineHeight: "40px",
                        float: "left",
                      }}
                    >
                      Empowering Your Trade Experience
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Typography
                      color="primary.contrastText"
                      variant="h6"
                      style={{
                        // fontSize: "32px",
                        // fontWeight: 400,
                        float: "left",
                        lineHeight: "40px",
                      }}
                    >
                      Trade Experience
                    </Typography>
                  </Grid> */}
                </Grid>
              </Box>
            </ResponsiveBox>
          </Grid>
          <ResponsiveLogin
            item
            xs={12}
            // sm={12}
            // md={12}
            sm={6}
            xl={6}
            display={"flex"}
            flexDirection={"column"}
          >
            <Box
              sx={{
                justifyContent: "space-between",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid
                container
                spacing={0}
                justifyContent={"center"}
                alignItems={"center"}
                flex={1}
                sx={{ zIndex: 0 }}
              >
                <Grid item xs={12} md={10} lg={10} xl={10}>
                  <Paper
                    className="auth-container"
                    style={{ borderRadius: "16px" }}
                  >
                    {children}
                  </Paper>
                </Grid>
              </Grid>
              <Footer />
            </Box>
          </ResponsiveLogin>
        </Grid>
        <Help />
      </Paper>
    </Box>
  );
}
export default AuthLayout;
