import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getOrderAggregates } from "./orderAggregateApi";

interface OrderAggregateResponse {
  outstandingAmount?: number;
  overdueAmount?: number;
  creditAmount?: number;
  creditAssigned?: number;
  upcomingDueAmount?: number;
  upcomingDueDate?: string;
}

export interface OrderAggregateState {
  response: OrderAggregateResponse;
  loading: boolean;
  error?: string;
}

export const initialState: OrderAggregateState = {
  loading: false,
  error: "",
  response: {},
};

export const orderAggregateSlice = createSlice({
  name: "help",
  initialState,
  reducers: {},
  extraReducers: {
    [getOrderAggregates.pending.type]: (state) => {
      state.loading = true;
      state.error = "";
      state.response = {};
    },
    [getOrderAggregates.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.response = action.payload;
    },
    [getOrderAggregates.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = action.payload || "Something went wrong";
    },
  },
});

// Action creators are generated for each case reducer function

export default orderAggregateSlice.reducer;
