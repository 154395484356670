import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { Fragment } from "react";
import ReportsFilter from "./ReportsFilter";
import GenerateReportTable from "./GenerateReports/GenerateReportTable";
import React, { useState, useEffect } from "react";
import GeneratedReport from "./GeneratedReports/GeneratedReport";
import queryString from "query-string";
import { current } from "@reduxjs/toolkit";
import { useNavigate, useLocation } from "react-router-dom";
import { trackAnalytics } from "../../Analytics";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Reports() {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const query: any = React.useMemo(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(location.pathname, { replace: true });
    setValue(newValue);
  };
  useEffect(() => {
    // Updating the value state based on the currentTab
    if (query.tab) {
      setValue(query.tab === "generated" ? 1 : 0);
    }
  }, [query]);

  const { mobileNumber, response }: any = useSelector(
    (state: RootState) => state.mobileNum
  );

  const { response: userData }: any = useSelector(
    (state: RootState) => state.userDetails
  );

  //when user lands on report tab
  React.useEffect(() => {
    const prevRoute = location?.state?.prevRoute;
    //SEGMENT PART
    //when user lands on reports tab
    let pageData;
    if (query.tab && query.utm_source) {
      pageData = {
        source_page: "deeplink",
        landing_page: "reports_tab",
        event_type: "pageload",
        tab: query.tab,
      };
    } else if (query.tab && !query.utm_source) {
      pageData = {
        source_page: "notification",
        landing_page: "reports_tab",
        event_type: "pageload",
        tab: query.tab,
      };
    } else {
      pageData = {
        source_page:
          (prevRoute == "/orders" && "orders_tab") ||
          (prevRoute == "/payments" && "payment_history_tab") ||
          "reports_tab",
        landing_page: "reports_tab",
        event_type: "pageload",
        tab: value == 0 ? "generate" : "generated",
      };
    }
    trackAnalytics("reports_tab_pageload", pageData);
  }, [location]);

  useEffect(() => {
    if (!mobileNumber) {
      return;
    }
    if (query.utm_source == "email") {
      const pageData = {
        source_page: "email",
        landing_page: query.tab,
        event_type: "pageload",
        deeplink_type: query.deeplink_type,
        deeplink_url: `${window.location.href}`,
        report_id: query?.report_id,
      };

      trackAnalytics("deeplink_click", pageData);
    }
  }, [location, mobileNumber]);

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              width: "97%",
              m: "0 auto",
              mt: 6,
              // borderRadius: "8px",
              borderBottomLeftRadius: value == 1 ? "8px" : null,
              borderBottomRightRadius: value == 1 ? "8px" : null,
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              backgroundColor: "primary.contrastText",
              boxShadow: "0 3px 3px rgba(0,0,0,0.2)",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              style={{ marginLeft: "16px" }}
            >
              <Tab
                label={
                  <Typography
                    variant="h3"
                    fontWeight={500}
                    style={{ textTransform: "none" }}
                  >
                    Generate Report
                  </Typography>
                }
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <Typography
                    variant="h3"
                    fontWeight={500}
                    style={{ textTransform: "none" }}
                  >
                    Generated Reports
                  </Typography>
                }
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          {value == 0 && (
            <Grid item xs={12}>
              <Box
                sx={{
                  width: "97%",
                  m: "0 auto",
                  borderBottomLeftRadius: "16px",
                  borderBottomRightRadius: "16px",
                  backgroundColor: "primary.contrastText",
                  boxShadow: "0 3px 3px rgba(0,0,0,0.2)",
                  padding: "16px 16px 0px 16px",
                }}
              >
                <ReportsFilter />
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              width: "97%",
              m: "0 auto",
              borderRadius: 4,
              //   height:"auto",
              minHeight: "85vh",
              mb: "20px",
            }}
          >
            {value == 0 ? <GenerateReportTable /> : <GeneratedReport />}
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default Reports;
