import { createSlice, original } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { defaultMessage, errorMessage } from "../../../utils";
import { getGenerateOrdersReport } from "../ReportsApi";
import { MIRCHI_70 } from "../../../styles/colors";

export interface OrdersReportState {
  loading: boolean;
  list: any;
  error: string | null;
}

export const initialState: OrdersReportState = {
  loading: false,
  error: null,
  list: [],
};

export const ordersReportSlice = createSlice({
  name: "ordersReport",
  initialState,
  reducers: {},
  extraReducers: {
    [getGenerateOrdersReport.pending.type]: (state, action) => {
      state.loading = true;
    },
    [getGenerateOrdersReport.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.list = action.payload || [];
    },
    [getGenerateOrdersReport.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = action.payload || "Something went wrong";
      defaultMessage(
        "No invoices found for selected date range. Recheck and try again.",
        MIRCHI_70
      );
    },
  },
});

export default ordersReportSlice.reducer;
