import { Grid, Skeleton } from "@mui/material";

const NotificationSkeleton = () => {
  return (
    <Grid
      container
      wrap="nowrap"
      justifyContent={"center"}
          alignItems={"center"}
          sx = {{width:'328px',height:'44px'}}
    >
      <Grid direction="column">
        <Grid item>
          <Skeleton
            animation="wave"
            variant="circular"
            width={24}
            height={24}
            sx={{ marginBottom: "20px"}}
          />
        </Grid>
      </Grid>

      <Grid direction="column">
        <Grid item>
          <Skeleton
            animation="wave"
            sx={{ marginBottom: "4px", marginLeft: "12px"}}
            variant="rounded"
            width={292}
            height={20}
          />
        </Grid>
        <Grid item>
          <Skeleton
            animation="wave"
            sx={{ marginLeft: "12px" }}
            variant="rounded"
            width={233}
            height={20}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NotificationSkeleton;
