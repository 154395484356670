import { createSlice, original } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getPaymentHistory } from "./paymentHistoryApi";
import _ from 'lodash';
import { errorMessage } from '../../utils';
import queryString from "query-string";

export interface PaymentsStateItem {
  txnDate: string;
  amount: string;
  mode: string;
  utr: string;
  invoices: {
    invoiceNumber: string | number;
    loanId: string | number
  }[];
  charges: {
    name: string;
    amount: number
  }[];
  paymentId: string | number;
}

export interface PaymentState {
  loading: boolean;
  list: PaymentsStateItem[];
  error: string | null;
  count: number;
  filters: {
    pageNo: number;
    pageSize: number;
    startTxnDate?: string | null;
    endTxnDate?: string | null;
    utr: string | null;
    columnName: string;
    sortType: "ASC" | "DESC";
  };
}
const query: any = queryString.parse(location.search)
export const initialState: PaymentState = {
  loading: true,
  error: null,
  count: 0,
  list: [],
  filters: {
    pageNo: 0,
    pageSize: 10,
    utr: query.utr || null,
    columnName: "txnDate",
    sortType: "DESC",
    startTxnDate: null,
    endTxnDate: null,
  },
};

export const paymentHistorySlice = createSlice({
  name: "paymentHistory",
  initialState,
  reducers: {
    updateFilters: (state, action: PayloadAction<any>) => {
      if (!_.isEqual(original(state.filters), { ...state.filters, ...action.payload })) {
        state.filters = { ...state.filters, ...action.payload }
      }
    },
  },
  extraReducers: {
    [getPaymentHistory.pending.type]: (state, action) => {
      state.loading = true;
    },
    [getPaymentHistory.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.list = action.payload.buyerPaymentResponse || [];
      state.count = action.payload.totalCount || 0;
    },
    [getPaymentHistory.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = action.payload || "Something went wrong";
      errorMessage(state.error || '');
    },
  },
});

export const { updateFilters } = paymentHistorySlice.actions;

export default paymentHistorySlice.reducer;
