import { Grid, Typography } from "@mui/material";
import AuthLayout from "../layouts/auth";
import PasswordForm from "../components/Login/PasswordForm";
import { Formik } from "formik";
import * as yup from "yup";
import BijakLogo from "../components/Login/BijakLogo";
import ArrowBack from "../components/Login/ArrowBack";
import { postLoginResetPassword } from "../components/Login/loginApi";
import { AppDispatch, RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { AuthState } from "../components/Login/LoginModuleSlice";
import { useMemo, useEffect } from "react";
import Login from "./Login";
import Loader from "../components/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { updateAuthState } from "../components/Login/PasswordSlice";
import { analytics, trackAnalytics } from "../Analytics";

const validationSchema = yup.object({
  password: yup
    .string()
    .test(
      "password",
      "Password must be 8-20 characters",
      (value) => !value || (value.length >= 8 && value.length <= 20)
    )
    .test(
      "lettersAndNumbers",
      "Password must contain both letters and numbers",
      (value) => {
        if (!value) return true;
        return /[a-zA-Z]/.test(value) && /[0-9]/.test(value);
      }
    )
    .test(
      "invalidSymbols",
      "Invalid symbols used. Only @, !, #, or & are allowed.",
      (value) => {
        if (!value) return true;
        return (
          /^[!@#&]*$/.test(value) ||
          !/[-._"`'%,:;<>={}~\$\(\)\*\+\/\\\?\[\]\^\|]/.test(value)
        ); // Check if the value contains only @, !, #, or &
      }
    )
    .required("Password is required"),
  reEnter_password: yup
    .string()
    .oneOf(
      [yup.ref("password"), undefined],
      "Passwords do not match. Please re-enter your password."
    )
    .required("Please re-enter your password"),
});

function ResetPassword() {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = useSelector((state: RootState) => state.setPassword);

  const { response: loginOtpResponse, loading } = useSelector(
    (state: RootState) => state.loginOtpVerify
  );

  const { mobileNumber, response: mobResponse } = useSelector(
    (state: RootState) => state.mobileNum
  );

  const handleSubmit = (values: any) => {
    const buyerId = mobResponse.buyerId;
    const password = values.password;
    const secureUuid = loginOtpResponse.secureUuid;
    dispatch(
      postLoginResetPassword({ mobileNumber, password, secureUuid, buyerId })
    ).then((res) => {
      if (res.meta.requestStatus != "rejected") {
        const pageData = {
          source_page: "create_password_screen",
          landing_page: "password_input_screen",
          event_type: "pageload",
          Mobile_Number: mobileNumber,
          buyer_ptr_id: undefined,
        };
        trackAnalytics("password_pageload", pageData);
        navigate("/login");
      }
    });
  };

  useEffect(() => {
    dispatch(updateAuthState());
  }, [location]);

  const renderAuth = useMemo(() => {
    switch (state) {
      case AuthState.SetPassword:
        return (
          <Grid container spacing={3}>
            <BijakLogo />
            <Grid item xs={12}>
              <Typography
                className="font-bold"
                color="primary.main"
                variant="h2"
                mb={1}
              >
                <ArrowBack />
                Reset Password
              </Typography>
              <Typography
                className="font-bold"
                color="info.light"
                variant="body2"
              >
                Please create a new password to continue
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Formik
                initialValues={{
                  password: "",
                  reEnter_password: "",
                }}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values) => handleSubmit(values)}
              >
                {() => <PasswordForm />}
              </Formik>
            </Grid>
          </Grid>
        );
      case AuthState.PasswordLogin:
        return <Login source="create_password_screen" />;
    }
  }, [state]);

  return (
    <>
      <Loader loading={loading} />
      {renderAuth}
    </>
  );
}
export default ResetPassword;
