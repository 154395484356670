import { useSelector } from "react-redux";
import { store } from "./src/store";

declare global {
  interface Window {
    dataLayer: any;
  }
}

window.dataLayer = window.dataLayer || [];
const sendEvents = (...events: object[]) => {
  const dataLayer: object[] = window.dataLayer || [];
  dataLayer.push(...events);

  window.dataLayer = dataLayer;
};

const sendGtmEvent = (event: string, payload = {}) => {
  const response: any = store.getState().userDetails.response;
  let user: any = {};
  if (response?.mobileNumber) {
    user = {
      user_id: response?.mobileNumber,
      full_name: response?.businessName,
      mobile_number: response?.mobileNumber,
      buyer_id: response.buyerId,
    };
  }
  sendEvents({
    event,
    timestamp: new Date().toISOString(),
    ...payload,
    ...user,
  });
};

export default sendGtmEvent;
