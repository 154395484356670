import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Backdrop } from "@mui/material";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { MobileNumberVerifyState } from "../Login/LoginModuleSlice";
import { useEffect, useMemo } from "react";

interface LoaderProps {
  loading: boolean;
  style?: any;
}

export default function Loader(props: LoaderProps) {
  const {
    loading,
    style = {
      borderRadius: "15px",
      margin: "6px",
    },
  } = props;

  if (!loading) return null;
  return (
    <Backdrop
      sx={{
        background: "transparent",
        zIndex: (theme: any) => theme.zIndex.drawer + 99999999,
        ...style,
      }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
