import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { Box, Paper, Typography } from "@mui/material";
import { BLUE } from "../../styles/colors";
import PaymentHistoryTable from "./PaymentHistoryTable";
import PaymentFilters from "./paymentFilters";
import {useState} from "react"

const PaymentHistory = () => {
  const [dateSelectionBtn, setDateSelectionBtn] = useState("");
  return (
    <Paper
      sx={{
        width: "97%",
        m: "0 auto",
        mt: 5,
        borderRadius: 4,
        minHeight: "85vh",
        // height: "100%",
        mb: "20px",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        columnGap={1}
        color="white"
        bgcolor={BLUE}
        p={1}
        px={2}
        borderRadius="16px 16px 0 0"
      >
        <InfoRoundedIcon />
        <Typography fontSize={14} fontWeight={500}>
          Please note that it may take up to 48 hours for payments to reflect
          here.
        </Typography>
      </Box>
      <PaymentFilters setDateSelectionBtn={setDateSelectionBtn}/>
      <PaymentHistoryTable dateSelectionBtn={dateSelectionBtn} />
    </Paper>
  );
};

export default PaymentHistory;
