import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { getDownloadedLinkReport } from "../ReportsApi";
import { errorMessage } from "../../../utils";

export interface DownloadLinkState {
  loading: any;
  response: any;
  error: string | null;
}

export const initialState: DownloadLinkState = {
  loading: {},
  error: null,
  response: [],
};

export const DownloadReportLinkSlice = createSlice({
  name: "downloadReportLinkSlice",
  initialState,
  reducers: {},
  extraReducers: {
    [getDownloadedLinkReport.pending.type]: (state, action) => {
      state.loading[action.meta.arg.reportId] = true;
    },
    [getDownloadedLinkReport.fulfilled.type]: (state, action) => {
      state.loading[action.meta.arg.reportId] = false;
      state.response = action.payload.buyerInvoiceDtoList;
    },
    [getDownloadedLinkReport.rejected.type]: (state, action) => {
      state.loading[action.meta.arg.reportId] = false;
      state.response = [];
      state.error = action.payload || "Something went wrong";
      errorMessage(action.payload || "Something went wrong");
    },
  },
});

export default DownloadReportLinkSlice.reducer;
