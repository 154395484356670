interface Props {
  width?: string;
  height?: string;
  style?: React.CSSProperties;
}

const InitialReport = (props: Props) => {
 return (
   <svg
     width="350"
     height="164"
     viewBox="0 0 350 164"
     fill="none"
     xmlns="http://www.w3.org/2000/svg"
   >
     <g clip-path="url(#clip0_3457_23460)">
       <path
         d="M227 15.0666C277.523 31.1371 294.373 121.105 246.5 141C208 157 233.383 94.8877 138.218 144.112C100.995 163.366 42.897 94.7515 87.7129 36.5189C105.713 13.13 131.544 10.3566 152.134 15.0666C189 23.5001 178.033 -0.508901 227 15.0666Z"
         fill="#E8E8E8"
       />
       <path
         d="M228.741 57.8058H120.42C117.904 57.8058 115.865 59.844 115.865 62.3583V135.404C115.865 137.918 117.904 139.957 120.42 139.957H228.741C231.256 139.957 233.296 137.918 233.296 135.404V62.3583C233.296 59.844 231.256 57.8058 228.741 57.8058Z"
         fill="#424242"
       />
       <path
         d="M228.144 62.9093H121.017V130.355H228.144V62.9093Z"
         fill="#424242"
       />
       <path
         d="M175.344 61.1973C175.766 60.7753 175.766 60.0912 175.344 59.6692C174.922 59.2472 174.238 59.2472 173.816 59.6692C173.393 60.0912 173.393 60.7753 173.816 61.1973C174.238 61.6192 174.922 61.6192 175.344 61.1973Z"
         fill="#6B6B6B"
       />
       <path
         d="M248.319 134.893H100.843C100.377 134.893 100 135.27 100 135.736V139.7C100 140.165 100.377 140.543 100.843 140.543H248.319C248.784 140.543 249.162 140.165 249.162 139.7V135.736C249.162 135.27 248.784 134.893 248.319 134.893Z"
         fill="#6E6E6E"
       />
       <g style={{ mixBlendMode: "multiply" }} opacity="0.19">
         <g style={{ mixBlendMode: "multiply" }} opacity="0.19">
           <path d="M224.772 58H115.989V128.159H224.772V58Z" fill="#0B6770" />
         </g>
       </g>
       <g filter="url(#filter0_d_3457_23460)">
         <path
           d="M215.977 48.66H115.778C113.569 48.66 111.778 50.4509 111.778 52.66V119.958C111.778 122.167 113.569 123.958 115.778 123.958H215.977C218.187 123.958 219.977 122.167 219.977 119.958V52.66C219.977 50.4509 218.187 48.66 215.977 48.66Z"
           fill="#F7F7F7"
         />
       </g>
       <path
         opacity="0.2"
         d="M216.756 51.801H114.773V60.8556H216.756V51.801Z"
         fill="#114D96"
       />
       <g opacity="0.33">
         <path
           opacity="0.33"
           d="M120.639 57.4095C121.105 56.9438 121.105 56.1888 120.639 55.723C120.174 55.2573 119.418 55.2573 118.952 55.723C118.486 56.1888 118.486 56.9438 118.952 57.4095C119.418 57.8752 120.174 57.8752 120.639 57.4095Z"
           fill="white"
         />
         <path
           opacity="0.33"
           d="M124.321 57.4095C124.787 56.9438 124.787 56.1888 124.321 55.723C123.855 55.2573 123.099 55.2573 122.634 55.723C122.168 56.1888 122.168 56.9438 122.634 57.4095C123.099 57.8752 123.855 57.8752 124.321 57.4095Z"
           fill="white"
         />
         <path
           opacity="0.33"
           d="M128.373 56.5177C128.425 55.8591 127.934 55.2825 127.275 55.2298C126.616 55.1771 126.039 55.6683 125.987 56.3269C125.934 56.9855 126.425 57.5621 127.084 57.6148C127.743 57.6675 128.32 57.1763 128.373 56.5177Z"
           fill="white"
         />
       </g>
       <g opacity="0.33">
         <path
           opacity="0.33"
           d="M186.601 49.8569H147.115V51.0313H186.601V49.8569Z"
           fill="white"
         />
       </g>
       <g opacity="0.33">
         <path
           opacity="0.33"
           d="M212.76 61.7965H176.01V63.5924H212.76V61.7965Z"
           fill="#E4F2FB"
         />
       </g>
       <g opacity="0.33">
         <path
           opacity="0.33"
           d="M212.76 66.1619H194.42V67.9578H212.76V66.1619Z"
           fill="#E4F2FB"
         />
       </g>
       <g opacity="0.33">
         <path
           opacity="0.33"
           d="M134.326 61.8488H117.692V63.6447H134.326V61.8488Z"
           fill="#E4F2FB"
         />
       </g>
       <path
         opacity="0.2"
         d="M143.731 116.704L119.794 116.723C119.259 116.723 118.745 116.511 118.366 116.132C117.987 115.754 117.774 115.241 117.774 114.706L117.743 75.6345C117.743 75.0992 117.955 74.5857 118.333 74.2069C118.712 73.8282 119.225 73.6151 119.761 73.6146L143.698 73.5964C143.963 73.5962 144.225 73.6481 144.47 73.7493C144.715 73.8505 144.938 73.9989 145.126 74.1861C145.313 74.3734 145.462 74.5957 145.564 74.8404C145.665 75.0851 145.718 75.3475 145.718 75.6124L145.748 114.685C145.749 114.95 145.696 115.212 145.595 115.457C145.494 115.702 145.345 115.924 145.158 116.112C144.971 116.299 144.748 116.448 144.503 116.55C144.258 116.651 143.996 116.704 143.731 116.704Z"
         fill="#4FA9EA"
       />
       <path
         opacity="0.2"
         d="M177.158 116.678L153.221 116.697C152.956 116.697 152.693 116.645 152.448 116.544C152.203 116.443 151.981 116.294 151.793 116.107C151.605 115.92 151.456 115.697 151.355 115.452C151.253 115.208 151.201 114.945 151.2 114.68L151.17 75.6087C151.17 75.0734 151.382 74.56 151.76 74.1812C152.139 73.8024 152.652 73.5893 153.187 73.5888L177.125 73.5707C177.39 73.5704 177.652 73.6224 177.897 73.7236C178.142 73.8247 178.365 73.9732 178.553 74.1604C178.74 74.3476 178.889 74.5699 178.991 74.8146C179.092 75.0594 179.145 75.3217 179.145 75.5867L179.175 114.659C179.175 114.924 179.123 115.186 179.022 115.431C178.921 115.676 178.772 115.899 178.585 116.086C178.398 116.274 178.175 116.422 177.93 116.524C177.685 116.625 177.423 116.678 177.158 116.678Z"
         fill="#4FA9EA"
       />
       <path
         opacity="0.2"
         d="M210.585 116.652L186.647 116.671C186.382 116.671 186.12 116.619 185.875 116.518C185.63 116.417 185.407 116.268 185.219 116.081C185.032 115.893 184.883 115.671 184.781 115.426C184.68 115.182 184.627 114.919 184.627 114.654L184.599 75.5834C184.599 75.0482 184.811 74.5347 185.19 74.1559C185.568 73.7771 186.081 73.5641 186.617 73.5635L210.554 73.5454C210.819 73.5452 211.082 73.5971 211.327 73.6983C211.572 73.7995 211.794 73.9479 211.982 74.1351C212.17 74.3223 212.318 74.5447 212.42 74.7894C212.522 75.0341 212.574 75.2965 212.574 75.5614L212.605 114.633C212.605 114.899 212.553 115.161 212.451 115.406C212.35 115.651 212.201 115.874 212.013 116.061C211.825 116.249 211.603 116.397 211.358 116.499C211.113 116.6 210.85 116.652 210.585 116.652Z"
         fill="#4FA9EA"
       />
       <g filter="url(#filter1_d_3457_23460)">
         <path
           d="M196.607 24.1037L134.488 24.0062C132.279 24.0027 130.486 25.7907 130.482 27.9999L130.357 107.666C130.354 109.875 132.142 111.669 134.351 111.672L196.469 111.77C198.679 111.773 200.472 109.985 200.476 107.776L200.601 28.11C200.604 25.9008 198.816 24.1072 196.607 24.1037Z"
           fill="white"
         />
       </g>
       <path
         d="M146.708 82.1333L141.828 77.2538L143.179 75.9027L146.607 79.3294L152.58 72.4287L154.026 73.6795L146.708 82.1333Z"
         fill="#33B86B"
       />
       <path
         d="M188.756 73.9728H158.428V75.8839H188.756V73.9728Z"
         fill="#4FA9EA"
       />
       <path
         d="M179.657 78.6742H158.428V80.5853H179.657V78.6742Z"
         fill="#4FA9EA"
       />
       <path
         d="M149.193 49.0142H142.645V60.8649H149.193V49.0142Z"
         fill="#33B86B"
       />
       <path
         d="M158.999 45.8741H152.451V60.8648H158.999V45.8741Z"
         fill="#33B86B"
       />
       <path
         d="M168.806 42.3225H162.258V60.8641H168.806V42.3225Z"
         fill="#33B86B"
       />
       <path
         d="M178.613 39.1139H172.064V60.864H178.613V39.1139Z"
         fill="#33B86B"
       />
       <path
         d="M188.419 34.2079H181.87V60.8641H188.419V34.2079Z"
         fill="#33B86B"
       />
       <path
         d="M146.708 99.1074L141.828 94.2285L143.179 92.8768L146.607 96.3035L152.58 89.4034L154.026 90.6536L146.708 99.1074Z"
         fill="#33B86B"
       />
       <path
         d="M188.756 90.947H158.428V92.8582H188.756V90.947Z"
         fill="#4FA9EA"
       />
       <path
         d="M183.258 95.6483H158.428V97.5594H183.258V95.6483Z"
         fill="#4FA9EA"
       />
     </g>
     <defs>
       <filter
         id="filter0_d_3457_23460"
         x="111.778"
         y="48.66"
         width="112.199"
         height="79.2975"
         filterUnits="userSpaceOnUse"
         color-interpolation-filters="sRGB"
       >
         <feFlood flood-opacity="0" result="BackgroundImageFix" />
         <feColorMatrix
           in="SourceAlpha"
           type="matrix"
           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
           result="hardAlpha"
         />
         <feOffset dx="4" dy="4" />
         <feColorMatrix
           type="matrix"
           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
         />
         <feBlend
           mode="normal"
           in2="BackgroundImageFix"
           result="effect1_dropShadow_3457_23460"
         />
         <feBlend
           mode="normal"
           in="SourceGraphic"
           in2="effect1_dropShadow_3457_23460"
           result="shape"
         />
       </filter>
       <filter
         id="filter1_d_3457_23460"
         x="130.357"
         y="24.0061"
         width="74.2438"
         height="91.7637"
         filterUnits="userSpaceOnUse"
         color-interpolation-filters="sRGB"
       >
         <feFlood flood-opacity="0" result="BackgroundImageFix" />
         <feColorMatrix
           in="SourceAlpha"
           type="matrix"
           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
           result="hardAlpha"
         />
         <feOffset dx="4" dy="4" />
         <feColorMatrix
           type="matrix"
           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"
         />
         <feBlend
           mode="normal"
           in2="BackgroundImageFix"
           result="effect1_dropShadow_3457_23460"
         />
         <feBlend
           mode="normal"
           in="SourceGraphic"
           in2="effect1_dropShadow_3457_23460"
           result="shape"
         />
       </filter>
       <clipPath id="clip0_3457_23460">
         <rect width="350" height="164" fill="white" />
       </clipPath>
     </defs>
   </svg>
 );
}
export default InitialReport;