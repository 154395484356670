import { Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import React, { useEffect } from "react";
import HelpForm from "./HelpForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { HelpState } from "./helpSlice";
import { postHelp } from "./helpAPI";

const validationSchema = yup.object({
  mobile: yup
    .string()
    .matches(/^\d{10}$/, "Invalid phone number")
    .required("Phone number is required"),
  reason: yup.string().required("Reason is required"),
  desc: yup
    .string()
    .min(1, "Minimum 1 character required")
    .max(300, "Maximum 300 characters allowed")
    .required("Description is required"),
});

interface Props {
  handleClose: any;
}

export default function WriteToUs(props: Props) {
  const dispatch: AppDispatch = useDispatch();

  const handleSubmit = (values: any) => {
    dispatch(
      postHelp({
        ...values,
      })
    );
  };

  const { reason, desc, mobile }: HelpState = useSelector(
    (state: RootState) => state.help
  );

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} spacing={2} container>
        <Grid item xs={12}>
          <Typography variant="h3" color="primary">
            Write to us!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="info.light">
            Please fill this form to let us know
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Formik
          initialValues={{
            reason,
            desc,
            mobile,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {() => <HelpForm {...props} />}
        </Formik>
      </Grid>
    </Grid>
  );
}
