import { Grid, Typography } from "@mui/material";
import AuthLayout from "../layouts/auth";
import LogInForm from "../components/Login/LoginForm";
import { Formik } from "formik";
import * as yup from "yup";
import BijakLogo from "../components/Login/BijakLogo";
import ArrowBack from "../components/Login/ArrowBack";
import { AuthState } from "../components/Login/LoginModuleSlice";
import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { postLoginWithPassword } from "../components/Login/loginApi";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import PasswordOTPVerification from "./PasswordOTPVerification";
import { updateAuthState } from "../components/Login/LoginSlice";
import { analytics, trackAnalytics } from "../Analytics";
import { Analytics } from "analytics";

const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, "Incorrect password. Please recheck and try again.")
    .required("Password is required"),
});

function Login({ source }: any) {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // const { response, state } = useSelector((state: RootState) => state.login);
  const { mobileNumber, response: mobResponse } = useSelector(
    (state: RootState) => state.mobileNum
  );
  const { loading: setPasswordLoading } = useSelector(
    (state: RootState) => state.setPassword
  );
  const {
    loading: loginLoading,
    // response,
    state,
  } = useSelector((state: RootState) => state.login);
  const { response: userData } = useSelector(
    (state: RootState) => state.userDetails
  );

  const handleSubmit = (values: any) => {
    const buyerId = mobResponse.buyerId;
    const password = values.password;
    dispatch(postLoginWithPassword({ mobileNumber, password, buyerId })).then(
      (response: any) => {
        if (response.payload.message == "Success") {
          //SEGMENT PART
          analytics.identify(mobileNumber || "", {
            buyer_ptr_id: mobResponse.buyerId,
          });
          //SEGMENT PART
          //When user click on login button from the enter password screen
          const pageData = {
            source_page: "password_input_screen",
            landing_page: "orders_tab",
            event_type: "click",
            Mobile_Number: mobileNumber,
            last_login: userData?.lastLoginTime,
            buyer_ptr_id: undefined,
          };

          trackAnalytics("login_btn", pageData);
          navigate(sessionStorage.getItem("signedInUrl") || "/orders");
          sessionStorage.removeItem("signedInUrl");
        }
      }
    );
  };

  //SEGMENT PART
  //when enter password screen is loaded
  useEffect(() => {
    const pageData = {
      source_page: source,
      landing_page: "password_input_screen",
      event_type: "pageload",
      Mobile_Number: mobileNumber,
      buyer_ptr_id: undefined,
    };
    if (source) {
      trackAnalytics("password_pageload", pageData);
    }
  }, [source]);

  useEffect(() => {
    dispatch(updateAuthState());
  }, [location]);

  const renderAuth = useMemo(() => {
    switch (state) {
      case AuthState.VerifyOtp:
        return <PasswordOTPVerification />;
      default:
        return (
          <Grid container spacing={3}>
            <BijakLogo />
            <Grid item xs={12}>
              <Typography
                className="font-bold"
                color="primary.main"
                variant="h2"
                mb={1}
              >
                <ArrowBack />
                Login
              </Typography>
              <Typography
                className="font-bold"
                color="info.light"
                variant="body2"
              >
                Please enter your password to login
              </Typography>
            </Grid>
            <Grid item xs={12} mt={1}>
              <Formik
                initialValues={{
                  phoneNumber: "",
                  password: "",
                }}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values) => handleSubmit(values)}
              >
                {() => <LogInForm />}
              </Formik>
            </Grid>
          </Grid>
        );
    }
  }, [state]);

  return (
    <>
      <Loader loading={setPasswordLoading || loginLoading} />
      {renderAuth}
    </>
  );
}
export default Login;
