import axios from "axios";
import.meta.env.VITE_APP_API_URL
const API = axios.create({ baseURL: import.meta.env.VITE_APP_API_URL });
const BASE_API = axios.create({ baseURL: import.meta.env.VITE_APP_API_BASE_URL });
import sendGtmEvent from '../sendEvents'

API.interceptors.request.use((req: any) => {
  // if (!navigator.onLine) {
  //   return Promise.reject({ response: { data: { error: "No internet connection" } } });
  // }
  if (localStorage.getItem("token")) {
    const token: any = localStorage.getItem("token")
    req.headers.Authorization = token;
  }
  return req;
});

API.interceptors.response.use(
  (response) => {
    // Return a successful response as-is
    try {
      sendGtmEvent('user_api_call', { data: response.data, url: response.config.url, method: response.config.method })
    }
    catch (error) {
      console.log("error", error)
    }
    return response;
  },
  (error) => {
    try {
      sendGtmEvent('user_api_call', { data: error?.data, url: error?.config.url, method: error?.config.method })
    }
    catch (error) {

    }
    try {
      if (error.toJSON().message === 'Network Error' && !error.config.url?.includes('notification/toast')) {
        return Promise.reject({ response: { data: { error: "No internet connection" } } });
      }
    } catch (err) {
      console.log("errr")
    }

    if (error.response && error.response.status === 401 && window.location.pathname !== "/login") {
      localStorage.clear();
      sessionStorage.clear();
      sessionStorage.setItem("signedInUrl", `${location.pathname}${location.search}`)
      window.location.href = "/";
    } else {
      // For other error statuses, return the error object as-is
      return Promise.reject(error);
    }
  }
);
export default API;

export {
  BASE_API
}
