import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import SortRoundedIcon from "@mui/icons-material/SortRounded";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Menu,
  MenuProps,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { updateFilters } from "./paymentHistorySlice";
import { useLocation } from "react-router-dom";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    padding: 16,
  },
}));

interface SortByDropDownProps {
  disabled: boolean;
}

export default function PaymentSort(prop: SortByDropDownProps) {
  const { disabled } = prop;
  const [txnSort, setTxnSort] = React.useState({
    sortKey: "txnDate",
    sortOrder: "DESC",
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch: AppDispatch = useDispatch();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();

  React.useEffect(() => {
    if (location.state?.reload) {
      setTxnSort({...txnSort, sortOrder:  "DESC"});
    }
  }, [location]);

  const changeTxnSort = ({ target }: any) => {
    setTxnSort({ ...txnSort, sortOrder: target.value });
  };

  const getRadioLabel = (label: string) => {
    return (
      <Box display="flex" alignItems="center" columnGap="4px">
        <Typography>Transaction Date:</Typography>
        <Typography>{label}</Typography>
      </Box>
    );
  };

  React.useEffect(() => {
    dispatch(updateFilters({ pageNo: 0, sortType: txnSort.sortOrder }));
  }, [txnSort.sortOrder]);

  return (
    <div>
      <Button
        variant="outlined"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownRoundedIcon />}
        color="info"
        disabled={disabled}
        sx={{
          color: "info.dark",
          minWidth: "180px",
        }}
      >
        <Grid container>
          <Grid item paddingRight="6px">
            <SortRoundedIcon />
          </Grid>
          <Grid item>{`Sort by: Transaction Date: ${
            txnSort.sortOrder == "ASC" ? "Earliest..." : "Latest..."
          }`}</Grid>
        </Grid>
      </Button>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={txnSort.sortOrder}
            onChange={changeTxnSort}
          >
            <FormControlLabel
              sx={{ mb: 1 }}
              value="ASC"
              control={<Radio />}
              label={getRadioLabel("Earliest to Latest")}
            />
            <FormControlLabel
              value="DESC"
              control={<Radio />}
              label={getRadioLabel("Latest to Earliest")}
            />
          </RadioGroup>
        </FormControl>
      </StyledMenu>
    </div>
  );
}
