import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useFormikContext, Form } from "formik";

import {
  Grid,
  InputAdornment,
  IconButton,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { CustomListItem } from "../CustomLi";

const PasswordForm = () => {
  const { values, errors, touched, handleChange, handleBlur }: any =
    useFormikContext();

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [reEnterPasswordVisibility, setReEnterPasswordVisibility] =
    useState(false);

  const handleCopyPaste = (e: any) => {
    const isPasswordField =
      e.target.id === "password" || e.target.id === "re-enter_password";

    if (isPasswordField) {
      e.preventDefault();
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  return (
    <Form noValidate>
      <Grid container spacing={2} gap={{ xs: 1, md: 0 }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="password"
            name="password"
            label="Create a Password"
            type={passwordVisibility ? "text" : "password"}
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyPress={handleKeyPress}
            error={touched.password && Boolean(errors.password)}
            helperText={touched.password && errors.password}
            onCopy={handleCopyPaste}
            onPaste={handleCopyPaste}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setPasswordVisibility(!passwordVisibility)}
                    edge="end"
                  >
                    {passwordVisibility ? (
                      <Visibility fontSize="small" />
                    ) : (
                      <VisibilityOff fontSize="small" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="re-enter_password"
            name="reEnter_password"
            label="Confirm Password"
            type={reEnterPasswordVisibility ? "text" : "password"}
            value={values.reEnter_password}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyPress={handleKeyPress}
            onPaste={handleCopyPaste}
            onCopy={handleCopyPaste}
            error={touched.reEnter_password && Boolean(errors.reEnter_password)}
            helperText={touched.reEnter_password && errors.reEnter_password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      setReEnterPasswordVisibility(!reEnterPasswordVisibility)
                    }
                    edge="end"
                  >
                    {reEnterPasswordVisibility ? (
                      <Visibility fontSize="small" />
                    ) : (
                      <VisibilityOff fontSize="small" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ul>
            <CustomListItem>
              <Typography color="info.light" variant="body2">
                Password must be 8-20 characters
              </Typography>
            </CustomListItem>
            <CustomListItem>
              <Typography color="info.light" variant="body2">
                Password must contain both letters and numbers
              </Typography>
            </CustomListItem>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            id="password-btn"
            disabled={
              !values.password ||
              !values.reEnter_password ||
              Boolean(errors.reEnter_password) ||
              Boolean(errors.password)
            }
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default PasswordForm;
