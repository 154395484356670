import {
  Box,
  Paper,
  CssBaseline,
  ThemeProvider,
  GlobalStyles,
} from "@mui/material";
import { createTheme, styled } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";
import Help from "./features/Help";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppDispatch, RootState } from "./store";
import { useDispatch, useSelector } from "react-redux";
import { ConfigState } from "./features/maintenance/maintenanceSlice";
import React, { useEffect } from "react";
import { getConfig } from "./features/maintenance/maintenanceApi";
import Loader from "./components/Loader";
import AppRoute from "./AppRoute";
import TagManager from "react-gtm-module";
import { Amplify } from "aws-amplify";
declare module "@mui/material/styles" {
  interface TypographyVariants {
    poster: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    poster?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    poster: true;
  }
}

export function App() {
  const dispatch: AppDispatch = useDispatch();

  const { config, loading, error }: ConfigState = useSelector(
    (state: RootState) => state.config
  );
  useEffect(() => {
    dispatch(getConfig(""));
  }, [dispatch]);

  // define theme

  const defaultTheme = createTheme();
  const theme = createTheme({
    typography: {
      poster: {
        fontSize: "3rem",
        lineHeight: 1.2,
        fontWeight: "bold",
        [defaultTheme.breakpoints.down("md")]: {
          fontSize: "2rem",
        },
      },
      h1: {
        fontSize: "2rem",
        fontWeight: "bold",
        lineHeight: 1.2,
        letterSpacing: "-0.01562em",
        [defaultTheme.breakpoints.down("md")]: {
          fontSize: "1.25rem",
        },
        "&.font-normal": {
          fontWeight: "400",
        },
      },
      h2: {
        fontSize: "1.5rem",
        lineHeight: 1.2,
        letterSpacing: "-0.00833em",
        "&.font-bold": {
          fontWeight: "bold",
        },
      },
      h3: {
        fontSize: "1.25rem",
        lineHeight: 1.2,
        letterSpacing: "0em",
      },
      subtitle1: {
        fontSize: "1rem",
        lineHeight: 1.25,
        letterSpacing: "0.00938em",
      },
      subtitle2: {
        fontSize: "0.875rem",
        lineHeight: 1.25,
        letterSpacing: "0.01071em",
      },
      body1: {
        fontSize: "1rem",
        lineHeight: 1.25,
        letterSpacing: "0.01071em",
      },
      body2: {
        fontSize: "0.875rem",
        lineHeight: 1.25,
        letterSpacing: "0.01071em",
      },
      fontFamily: [
        "Noto Sans",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    palette: {
      primary: {
        light: "#198548",
        main: "#198548",
        dark: "#005db0",
        contrastText: "#fff",
      },
      info: {
        light: "#A0A4A8", // kala_50
        main: "#25282B", // kala_80
        dark: "#52575C", // kala_70
        contrastText: "#E8E8E8", // kala_5
      },
      error: {
        main: "#BD252B", // mirchi_70
      },
      secondary: {
        main: "#4db6ac",
        light: "#F7F8FB",
        dark: "#00867d",
        contrastText: "#000",
      },
    },
    components: {
      MuiListItemButton: {
        styleOverrides: {
          root: {
            padding: "20px 32px",
            "&:hover": {
              backgroundColor: "#F7F8FB",
            },
            "&.Mui-selected": {
              color: "#198548",
              fontWeight: "bold",
              ".MuiListItemIcon-root": {
                color: "#198548",
              },
              ".MuiTypography-root": {
                fontWeight: "bold",
                fontSize: "1rem",
              },
              background: "#F7F8FB",
              "&:hover": {
                backgroundColor: "#F7F8FB",
              },
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "&:-webkit-autofill": {
              background: "red",
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: "16px",
            boxShadow: "0px 0px 12px 0px #00000029",
            [defaultTheme.breakpoints.down("sm")]: {
              borderRadius: "0px",
              boxShadow: "none",
            },
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            poster: "h1",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          outlined: {
            textTransform: "none",
          },
          text: {
            textTransform: "none",
          },
          textPrimary: {
            textTransform: "none",
          },
          contained: {
            textTransform: "none",
            fontWeight: 700,
          },
          containedPrimary: {
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#198548",
              color: "#fff",
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: defaultTheme.palette.common.white,
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: defaultTheme.shadows[1],
            fontSize: "0.875rem",
            fontWeight: 600,
            lineHeight: "1.25rem",
            padding: "0.5rem 1rem",
          },
        },
      },
    },
  });

  theme.typography.h3 = {
    [theme.breakpoints.down("lg")]: {
      // fontSize: "100%",
    },
  };
  theme.typography.h5 = {
    [theme.breakpoints.down("lg")]: {
      // fontSize: "100%",
    },
  };

  const CustomToastContainer = styled(ToastContainer)({
    ".Toastify__toast-body": {
      alignItems: "flex-start",
    },
  });

  const tagManagerArgs = {
    gtmId: import.meta.env.VITE_GTM_ID,
  };

  TagManager.initialize(tagManagerArgs);

  Amplify.configure(JSON.parse(import.meta.env.VITE_APP_AWS_CONFIG));

  return (
    <ThemeProvider theme={theme}>
      <CustomToastContainer limit={config?.toastStackCount || 5} />
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: { backgroundColor: theme.palette.secondary.light },
        }}
      />
      <Box
        height="100vh"
        display="flex"
        flexDirection="column"
        sx={{ backgroundColor: "secondary.light" }}
      >
        <Router>
          <Paper elevation={0} sx={{ backgroundColor: "secondary.light" }}>
            <AppRoute />
          </Paper>
        </Router>
      </Box>
    </ThemeProvider>
  );
}

export function WrappedApp() {
  return <App />;
}
