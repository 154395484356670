import { Autocomplete, TextField } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { VarietyState, updateFilters } from "./varietySlice";
import { getVarieties } from "./varietyApi";

const VarietyDropdown = ({
  label = "Select variety",
  name = "variety_name",
  required = false,
  disabled = false,
  isMulti = false,
  classes = "",
  error = false,
  variant = "outlined",
  onChange = (args?: any) => {},
  defaultValue = "",
  id = "variety_dropdown",
  commodity_id = null,
  fullWidth = false,
  ...props
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");

  const {
    response,
    loading,
    error: serverError,
  }: VarietyState = useSelector((state: RootState) => state.varietyList);

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  const handleInputChange = (event: any) => {
    const value = event?.target?.value || "";
    setInputValue(value);

    value.length === 0 && onChange();
    // if (value.length >= 3) {
    dispatch(updateFilters(value));
    commodity_id && dispatch(getVarieties({ commodity_id, search: value }));
    // }
  };

  const handleSelectChange = (_e: any, option: any) => {
    if (option) {
      dispatch(updateFilters(option));
    } else {
      dispatch(updateFilters(defaultValue));
    }
    onChange && onChange(option);
  };

  return (
    <Autocomplete
      fullWidth={fullWidth}
      loading={loading}
      multiple={isMulti}
      disabled={disabled}
      options={commodity_id ? [...(response || [])] : []}
      filterOptions={(options) => options}
      getOptionLabel={(commodity: any) => commodity?.name}
      inputValue={!isMulti ? inputValue : undefined}
      defaultValue={isMulti ? inputValue || [] : undefined}
      style={{ marginBottom: "16px" }}
      onChange={handleSelectChange}
      className={classes}
      id={id}
      renderInput={(params: any) => (
        <TextField
          required={required}
          helperText={props.helperText}
          error={error}
          {...params}
          label={label}
          onChange={handleInputChange}
        />
      )}
    />
  );
};

export const FormikVarietyDropdown = ({ name, ...props }: any) => {
  const { errors, touched, getFieldProps, setFieldValue, values }: any =
    useFormikContext();
  const error = getIn(errors, name);
  const hasError = getIn(touched, name) && error ? true : false;
  return (
    <VarietyDropdown
      {...props}
      variant="outlined"
      name={name}
      error={hasError}
      helperText={hasError ? error : ""}
      {...getFieldProps(name)}
      defaultValue={values?.[name]?.name || ""}
      commodity_id={props?.commodity_id}
      onChange={(value) => setFieldValue(name, value)}
    />
  );
};

export default VarietyDropdown;
