import { useEffect, useState, useCallback } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "../../CustomTable/TableRow";
import Paper from "@mui/material/Paper";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Grid,
  Typography,
  Box,
  withStyles,
  Tooltip,
  Fade,
  styled,
  TooltipProps,
  tooltipClasses,
  Button,
  CircularProgress,
} from "@mui/material";
import { formatDateTime, formatNumber } from "../../../utils";
import { AppDispatch, RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { disbursementDetailsState } from "./disbursementDetailsSlice";
import {
  getDisbursementDetails,
  downloadDisbursementDetails,
} from "../financeApi";
import { DisbursementDetailsSkelton } from "./DisbursementDetailsSkelton";
import { successMessage } from "../../../utils";
import { ConfigState } from "../../../features/maintenance/maintenanceSlice";
import _ from "lodash";
import { ORDER_DIVIDER_STYLE } from "./OrderDetailsTab";
import { HeadTableCell, RowTableCell } from "./CustomComponents";
import DownloadButton from "../../DownloadButton";
import DownloadFile from "../../DownloadExcel";
import ErrorComponent from "./ErrorComponent";
import { defaultMessage } from "../../../utils";
import { KALA_80 } from "../../../styles/colors";
import { DownloadDisbursementReportState } from "./disbursementReportSlice";
import * as XLSX from "xlsx";

interface Props {
  orderId?: string | number;
  loanId?: string | number;
  invoiceNumber?: string;
}

interface Payment {
  createdtime: string;
  amount: number;
  bank_account_holder_name: string;
  bank_account_holder_number: string;
  bank_ifsc_code: string;
  utr: string;
}

interface DisbursementDetailsData {
  payments: Payment[];
  // Add other properties if present in the data
}

export const DisbursementDetails = (props: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const { orderId, loanId, invoiceNumber } = props;
  const [tooltipTitle, setTooltipTitle]: any = useState({});
  const [charges, setCharges]: any = useState([]);
  const [csvData, setCSVData] = useState<Payment[]>([]);
  const [showToast, setShowToast] = useState(false);
  const headers = [
    {
      label: "Disbursement Date",
      key: "createdtime",
      format: "Date",
    },
    {
      label: "Amount",
      key: "amount",
      format: "Number",
    },
    {
      label: "Account Holder Name",
      key: "bank_account_holder_name",
    },
    {
      label: "Account Number",
      key: "bank_account_holder_number",
      format: "Text",
    },
    {
      label: "IFSC",
      key: "bank_ifsc_code",
    },
    {
      label: "UTR",
      key: "utr",
      format: "Text",
    },
  ];

  const handleCopyClick = (row: any) => {
    navigator.clipboard.writeText(row.utr);
    setTooltipTitle({ [row.id]: "UTR copied to clipboard!" });
  };

  useEffect(() => {
    const timer = setTimeout(() => setTooltipTitle({}), 2000);
    return () => clearTimeout(timer);
  }, [tooltipTitle]);

  useEffect(() => {
    if (orderId) {
      dispatch(getDisbursementDetails({ orderId }));
    }
    if (loanId) {
      dispatch(getDisbursementDetails({ loanId }));
    }
  }, [orderId, loanId]);

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showToast]);

  useEffect(() => {
    if (showToast) {
      defaultMessage(
        `Disbursement Details for Invoice ${invoiceNumber || "N/A"}, Order ${
          orderId ? orderId : loanId
        } downloaded successfully`,
        KALA_80
      );
    }
  }, [showToast]);

  const {
    loading: downloadDisbursementReportLoading,
    error: downloadError,
  }: DownloadDisbursementReportState = useSelector(
    (state: RootState) => state.downloadDisbursementReport
  );

  const handleDownloadClick = async (
    event: React.ChangeEvent<HTMLInputElement>,
    orderId: any,
    loanId: any
  ) => {
    let res: any;
    if (orderId) {
      res = dispatch(downloadDisbursementDetails({ orderId }));
    }
    if (loanId) {
      res = dispatch(downloadDisbursementDetails({ loanId }));
    }
    if ((await res).payload != "No internet connection") {
      const xmlData = (await res).payload;
      const blob = new Blob([xmlData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `Disbursement_Details_Order#${orderId}_Invoice#${
        invoiceNumber || "NA"
      }.xlsx`;
      link.click();
      URL.revokeObjectURL(url);
      setShowToast(true);
    }
  };

  const { disbursementDetailsData, loading, error }: disbursementDetailsState =
    useSelector((state: RootState) => state.disbursementDetails);
  const { config }: ConfigState = useSelector(
    (state: RootState) => state.config
  );

  const calculateSumOfAmounts = (disbursementDetailsData: any): number => {
    const sum: number = disbursementDetailsData?.payments?.reduce(
      (accumulator: any, currentValue: any) => {
        return accumulator + currentValue.amount;
      },
      0
    );

    return sum;
  };

  useEffect(() => {
    const chargeValues: any[] = [];
    if (config?.charges?.showCharges) {
      const groupedCharges = _.groupBy(
        disbursementDetailsData?.charges,
        "parent_charge_id"
      );
      Object.entries(groupedCharges).map(([charge_id, items]) => {
        const parentCharge = items?.find(
          (charge: any) => charge.txn_type_id == charge_id
        );
        const amount: number = items.reduce(
          (acc: any, item: any) => acc + item.amount,
          0
        );
        if (
          amount > 0 &&
          (config?.charges?.chargeIds || []).includes(Number(charge_id))
        ) {
          chargeValues.push({
            ...parentCharge,
            amount,
          });
        }
      });
    }
    setCharges(chargeValues);
  }, [disbursementDetailsData?.charges, config]);

  const renderCharges = useCallback(() => {
    // const totalChunks = Math.ceil(charges?.length / 2);
    return (
      <>
        {_.chunk(charges, 2)?.map((chunk: any, index) => {
          return renderChargeRow(chunk);
        })}
      </>
    );
  }, [charges]);

  const renderChargeRow = useCallback((charges: any) => {
    return charges?.map((charge: any) => (
      <Grid item xs={3}>
        <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
          {config?.charges?.displayNames?.[charge?.txn_type_id] ||
            charge?.txn_type_name}
        </Typography>
        <Typography
          sx={{ color: "info.main", fontWeight: 500 }}
          variant="subtitle1"
        >
          {formatNumber(charge?.amount)}
        </Typography>
      </Grid>
    ));
  }, []);

  const UTRTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [` .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.info.main,
      color: "#ffffff",
      boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.16)",
      fontSize: "0.875rem",
      fontWeight: 600,
      lineHeight: "1.25rem",
      padding: "0.375rem 0.75rem",
      borderRadius: "0.25rem",
      marginRight: "8px !important",
    },
  }));

  const bankDetails = (row: any, key: string) => {
    const bankDetails = row?.bank_details;
    try {
      if (bankDetails) {
        return JSON.parse(bankDetails)[key];
      }
    } catch (e) {
      return "N/A";
    }
  };

  useEffect(() => {
    if (disbursementDetailsData?.payments) {
      const newData = disbursementDetailsData?.payments?.map((row: any) => ({
        createdtime: formatDateTime(row.createdtime),
        amount: row.amount,
        bank_account_holder_name: bankDetails(row, "bank_account_holder_name"),
        bank_account_holder_number: bankDetails(row, "bank_account_number"),
        bank_ifsc_code: bankDetails(row, "bank_ifsc_code"),
        utr: row.utr || "N/A",
      }));
      setCSVData((prevCSVData) => [...newData]);
    }
  }, [disbursementDetailsData]);

  if (error) {
    return <ErrorComponent />;
  }

  return (
    <>
      {loading ? (
        <DisbursementDetailsSkelton />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} paddingRight={"32px"} sx={{ paddingTop: 0 }}>
              <Grid
                container
                sx={charges?.length > 0 ? ORDER_DIVIDER_STYLE : {}}
                padding={"1rem 1rem"}
              >
                <Grid item xs={3}>
                  <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
                    Amount Disbursed
                  </Typography>
                  <Typography
                    sx={{ color: "info.main", fontWeight: 500 }}
                    variant="subtitle1"
                  >
                    {formatNumber(
                      calculateSumOfAmounts(disbursementDetailsData)
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ color: "info.dark" }} paddingBottom="8px">
                    Disbursement Pending
                  </Typography>
                  <Typography
                    sx={{ color: "info.main", fontWeight: 500 }}
                    variant="subtitle1"
                  >
                    {formatNumber(disbursementDetailsData.unsettled_amount_la)}
                  </Typography>
                </Grid>
                {renderCharges()}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "8px",
                paddingTop: 0,
              }}
            >
              <TableContainer component={Paper}>
                <Table sx={{ border: "3px solid #E4F2FB" }}>
                  <TableHead>
                    <TableRow
                      sx={{
                        bgcolor: "#E4F2FB",
                      }}
                    >
                      <HeadTableCell sx={{}}>Disbursement Date</HeadTableCell>
                      <HeadTableCell sx={{}} align="right">
                        Amount
                      </HeadTableCell>
                      <HeadTableCell sx={{}}>Account Holder Name</HeadTableCell>
                      <HeadTableCell sx={{}}>Account Number</HeadTableCell>
                      <HeadTableCell sx={{}}>IFSC</HeadTableCell>
                      <HeadTableCell sx={{}}>
                        <Grid
                          container
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <span>UTR</span>
                          <DownloadButton
                            onClick={(e: any) =>
                              handleDownloadClick(e, orderId, loanId)
                            }
                            disabled={
                              disbursementDetailsData?.payments?.length > 0
                                ? false
                                : true
                            }
                            style={{
                              backgroundColor: downloadDisbursementReportLoading
                                ? "#A0A4A8"
                                : "#247BC7",
                            }}
                          >
                            {downloadDisbursementReportLoading ? (
                              <CircularProgress
                                style={{
                                  width: "24px",
                                  height: "24px",
                                  color: "white",
                                }}
                              />
                            ) : (
                              <FileDownloadIcon />
                            )}
                          </DownloadButton>
                        </Grid>
                      </HeadTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {disbursementDetailsData?.payments?.length > 0 ? (
                      disbursementDetailsData?.payments?.map((row: any) => (
                        <TableRow hover={true} key={row.id}>
                          <RowTableCell sx={{ fontWeight: 500 }}>
                            {formatDateTime(row.createdtime)}
                          </RowTableCell>
                          <RowTableCell align="right" sx={{ fontWeight: 500 }}>
                            {formatNumber(row.amount)}
                          </RowTableCell>
                          <RowTableCell sx={{ fontWeight: 500 }}>
                            {bankDetails(row, "bank_account_holder_name")}
                          </RowTableCell>
                          <RowTableCell sx={{ fontWeight: 500 }}>
                            {bankDetails(row, "bank_account_number")}
                          </RowTableCell>
                          <RowTableCell sx={{ fontWeight: 500 }}>
                            {bankDetails(row, "bank_ifsc_code")}
                          </RowTableCell>
                          <RowTableCell align="left" sx={{ fontWeight: 500 }}>
                            {row.utr || "N/A"}
                            {(row.utr && (
                              <UTRTooltip
                                TransitionComponent={Fade}
                                title={tooltipTitle?.[row.id]}
                                placement="left"
                                open={!!tooltipTitle?.[row.id]}
                              >
                                <ContentCopyIcon
                                  fontSize="small"
                                  sx={{
                                    color: "grey",
                                    cursor: "pointer",
                                    marginLeft: "0.5rem",
                                  }}
                                  onClick={() => handleCopyClick(row)}
                                />
                              </UTRTooltip>
                            )) ||
                              ""}
                          </RowTableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <RowTableCell colSpan={3}>
                          <Typography
                            sx={{
                              color: "info.dark",
                              height: "120px",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            Amount disbursed to suppliers will appear here
                          </Typography>
                        </RowTableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
