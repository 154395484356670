import { Button, Grid, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import NoDataSVG from "../SVG/NoDataSVG";

type NoDataType = {
  objKey: string;
  value: string | undefined | null;
  updateFilters: any;
  desc: string;
};

function NoData({ objKey, value, updateFilters, desc }: NoDataType) {
  const dispatch: AppDispatch = useDispatch();

  const removeSearchFilter = () => {
    dispatch(updateFilters({ pageNo: 0, [objKey]: null }));
  };

  const getNoDataContent = () => {
    if (!value) {
      return (
        <Typography
          variant="subtitle1"
          color="info.main"
          sx={{ fontWeight: "400", fontSize: "1.25rem" }}
        >
          {desc}
        </Typography>
      );
    } else {
      return (
        <Button variant="contained" onClick={removeSearchFilter} style={{borderRadius:"8px"}} >
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "700" }}
            margin="4px 0px"
            padding="12px, 16px, 12px, 16px"
          >
            Reset Search
          </Typography>
        </Button>
      );
    }
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "theme.palette.primary.light",
        padding: "2rem 0",
      }}
    >
      <Grid item xs={12}>
        <NoDataSVG />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h1" color="info.dark">
          Sorry, No Results Found.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {getNoDataContent()}
      </Grid>
    </Grid>
  );
}

export default NoData;
