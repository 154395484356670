import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../api";
import { errorMessage } from "../../utils";

export const logoutUser = createAsyncThunk(
  "data/logoutUser",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await API.get("order/logout");
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
