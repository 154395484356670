import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../api";
import { trackAnalytics } from "../../Analytics";
import * as React from "react";
import { store } from "../../store";

//SEGMENT PART
//trigger when any api fails to responsed/ any error occurs 
const ErrorEventHadler = ({ event_type, error_message }: any) => {
  const response: any = store.getState().userDetails.response;
  const pageData = {
    source_page: "reports_tab",
    landing_page: "reports_tab",
    event_type: event_type,
    error_type: "frontend",
    error_message: error_message
  };
  trackAnalytics("error_event", pageData);
}

export const getInvoiceNumber = createAsyncThunk("user/invoiceNumber", async (payload: any, { rejectWithValue }) => {
  try {
    const response = await API.post(`/order/invoice/list`, { ...payload });
    return response.data;
  } catch (error: any) {
    ErrorEventHadler({
      event_type: "pageload",
      error_message: error.response.data.error
    })
    return rejectWithValue(error.response.data.error);
  }
});

export const getGenerateLedgerReport = createAsyncThunk("user/generateLedgerReport", async (payload: any, { rejectWithValue }) => {
  try {
    const response = await API.post("/report/ledger", { ...payload });
    return response.data;
  } catch (error: any) {
    ErrorEventHadler({
      event_type: "click",
      error_message: error.response.data.error
    })
    return rejectWithValue(error.response.data.error);
  }
});

export const getGeneratedReports = createAsyncThunk("user/report/generated", async (payload: any, { rejectWithValue }) => {
  try {
    const response = await API.post("/report/list", { ...payload });
    return response.data;
  } catch (error: any) {
    ErrorEventHadler({
      event_type: "pageload",
      error_message: error.response.data.error
    })
    return rejectWithValue(error.response.data.error);
  }
});

export const getGenerateReports = createAsyncThunk("user/report/generate", async (payload: any, { rejectWithValue }) => {
  try {
    const response = await API.post("/report/list", { ...payload });
    return response.data;
  } catch (error: any) {
    ErrorEventHadler({
      event_type: "pageload",
      error_message: error.response.data.error
    })
    return rejectWithValue(error.response.data.error);
  }
});

export const getGenerateLateFeeReport = createAsyncThunk("user/generateLateFeeReport", async (payload: any, { rejectWithValue }) => {
  try {
    const response = await API.post("/report/latefee", { ...payload });
    return response.data;
  } catch (error: any) {
    ErrorEventHadler({
      event_type: "click",
      error_message: error.response.data.error
    })
    return rejectWithValue(error.response.data.error);
  }
});

export const getDownloadedLinkReport = createAsyncThunk("user/downloadedLinkReport", async (payload: any, { rejectWithValue }) => {
  try {
    const response = await API.get(`/report/link/${payload.reportId}`);
    return response.data;
  } catch (error: any) {
    ErrorEventHadler({
      event_type: "click",
      error_message: error.response.data.error
    })
    return rejectWithValue(error.response.data.error);
  }
});

export const getGeneratePaymentHistoryReport = createAsyncThunk("user/generatePaymentHisotryReport", async (payload: any, { rejectWithValue }) => {
  try {
    const response = await API.post("/report/payments", { ...payload });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data.error);
  }
});

export const getGenerateOrdersReport = createAsyncThunk("user/generateOrdersReport", async (payload: any, { rejectWithValue }) => {
  try {
    const response = await API.post("/report/orders", { ...payload });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data.error);
  }
});

