import { Grid, Typography } from "@mui/material";
import AuthLayout from "../layouts/auth";
import LoginModule from "../components/Login/LoginModule";
import * as yup from "yup";
import { Formik } from "formik";
import BijakLogo from "../components/Login/BijakLogo";
import { useDispatch, useSelector } from "react-redux";
import { postLoginMobileNumber } from "../components/Login/loginApi";
import { AppDispatch, RootState } from "../store";
import {
  AuthState,
  MobileNumberVerifyState,
} from "../components/Login/LoginModuleSlice";
import Loader from "../components/Loader";
import { useEffect, useMemo } from "react";
import { errorMessage } from "../utils";
import VerifyOTP from "../components/Login/VerifyOTP";
import LoginOTPVerification from "./LoginOTPVerification";
import Login from "./Login";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object({
  phoneNumber: yup
    .string()
    .matches(/^\d{10}$/, "Please enter a valid number to continue")
    .required("Phone number is required"),
});

function MobileLogin() {
  // window.history.pushState(null, "", window.location.href);
  // window.onpopstate = function () {
  //   window.history.pushState(null, "", window.location.href);
  // };

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const {
    loading,
    error,
    response,
    state,
    mobileNumber,
  }: MobileNumberVerifyState = useSelector(
    (state: RootState) => state.mobileNum
  );

  const handleSubmit = async (values: any) => {
    const response = await dispatch(postLoginMobileNumber(values.phoneNumber));
    if (response?.payload?.response_code == 80) {
      navigate("/login");
    }
  };

  const renderAuth = useMemo(() => {
    switch (state) {
      case AuthState.Mobile:
        return (
          <Grid container spacing={3}>
            <BijakLogo />
            <Grid item xs={12}>
              <Typography
                color="primary.main"
                variant="h2"
                fontWeight={700}
                mb={1}
              >
                Login
              </Typography>
              <Typography
                className="font-bold"
                color="info.light"
                variant="body2"
              >
                Login with Mobile Number to continue
              </Typography>
            </Grid>
            <Grid item xs={12} mt={1}>
              <Formik
                initialValues={{
                  phoneNumber: mobileNumber,
                }}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values) => handleSubmit(values)}
              >
                {() => <LoginModule />}
              </Formik>
            </Grid>
          </Grid>
        );
      case AuthState.VerifyOtp:
        return <LoginOTPVerification />;
      case AuthState.PasswordLogin:
        return <Login source={"number_input_screen"} />;
    }
  }, [state]);

  return (
    <AuthLayout>
      <Loader loading={loading} />
      {renderAuth}
    </AuthLayout>
  );
}
export default MobileLogin;
