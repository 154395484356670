import { createSlice, original } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { errorMessage , defaultMessage} from "../../utils";
import { getGenerateLateFeeReport } from "./ReportsApi";

export interface LateFeeReportState {
  loading: boolean;
  list: any;
  error: string | null;
}

export const initialState: LateFeeReportState = {
  loading: false,
  error: null,
  list: [],
};

export const lateFeeReportSlice = createSlice({
  name: "lateFeeReport",
  initialState,
  reducers: {},
  extraReducers: {
    [getGenerateLateFeeReport.pending.type]: (state, action) => {
      state.loading = true;
    },
    [getGenerateLateFeeReport.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.list = action.payload || [];
    },
    [getGenerateLateFeeReport.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = action.payload || "Something went wrong";
      errorMessage(state.error || "");
    },
  },
});

export default lateFeeReportSlice.reducer;
