import * as React from "react";
import "./index.css";
import { Badge, Box, Grid, ListItem, Typography } from "@mui/material";
import { styled, useMediaQuery, useTheme } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "@mui/material/Skeleton";
import { AppDispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getNotifications } from "./notificationApi";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Menu from "@mui/material/Menu";
//import data from "./data";
import NotificationSkeleton from "./NotificationSkeleton";
import IconButton from "@mui/material/IconButton";
import ErrorSVG from "../SVG/ErrorSVG";
import InfoSVG from "../SVG/InfoSVG";
import SuccessSVG from "../SVG/SuccessSVG";
import Ellipse2 from "../SVG/Ellipse2";
import { markAsReadToastNotifications } from "../../layouts/app/appAPI";
import { useNavigate } from "react-router-dom";
import NoData from "./NoData";
import { trackAnalytics } from "../../Analytics";
import NotificationHeading from "./NotificationHeading";
import CustomDivider from "./CustomDivider";
import { reportTypeMapping } from "../../utils";

const data1 = true;
interface UserNotificationProps {
  loading?: boolean;
}
const UserNotifications: React.FC<UserNotificationProps> = (props) => {
  const navigate = useNavigate();
  const dispatchEvent = useDispatch<AppDispatch>();
  useEffect(() => {}, []);
  const { response, dataList, loading } = useSelector(
    (state: RootState) => state.notifications
  );

  const { mobileNumber, response: mobileRes }: any = useSelector(
    (state: RootState) => state.mobileNum
  );

  const { config }: any = useSelector((state: RootState) => state.config);

  console.log(response);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    //SEGMENT PART
    //when user clicks on the notification icon on the homepage
    const pageData = {
      landing_page: "notification_centre_screen",
      event_type: "pageload",
    };
    trackAnalytics("notification_centre_pageload", pageData);

    setAnchorEl(event.currentTarget);
    dispatchEvent(getNotifications({ pageNo: 0, pageSize: 10 }));
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchData = () => {
    dispatchEvent(
      getNotifications({
        pageNo: dataList?.length / 10,
        pageSize: 10,
      })
    );
  };

  const handleNotificationClick = async (notification: any) => {
    handleClose();
    const parsedUrl = new URL(notification.link, window.location.href);

    // Get the value of the "tab" parameter using URLSearchParams
    const searchParams = new URLSearchParams(parsedUrl.search);
    const tabValue = searchParams.get("tab");
    //SEGMENT PART
    //when user clicks on the particular notification
    const pageData = {
      source_page: "notification_centre_screen",
      landing_page:
        tabValue == "disbursementDetails"
          ? "orders_tab"
          : tabValue == "generated"
          ? "reports_tab"
          : "notification_centre_screen",
      event_type: "click",
      notification_type:
        tabValue == "disbursementDetails" ? "disbursement_details" : "reports",
      notification_sub_type: reportTypeMapping(
        notification?.reportType,
        config
      ),
    };
    trackAnalytics("notification_item_click", pageData);

    await dispatchEvent(
      markAsReadToastNotifications(notification?.notificationRefId)
    );
    if (notification?.link) {
      navigate(`/${notification.link}`, {
        state: { reload: true, prevRoute: location.pathname },
      });
    }
  };

  const style = {
    marginRight: "12px",
    width: "24px",
    height: "24px",
    flexShrink: "0",
  };
  const StyledNotificationsIcon = styled(NotificationsNoneIcon)`
    color: #616161;
    height: 40px;
    width: 40px;
    position: relative;
  `;

  const TypographyStyle = {
    color: "var(--kala-80, #25282B)", //figma mentioned
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Noto Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    display: "flex",
    maxWidth: "292px",
    flexDirection: "column",
    justifyContent: "center",
    flexShrink: 0,
    alignSelf: "stretch",
    overflow: "hidden",
  };
  return (
    <>
      <Tooltip
        placement="bottom-end"
        title={
          <span style={{ textAlign: "center", display: "flex" }}>
            Find all your <br /> notifications here!
          </span>
        }
      >
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 1 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <StyledNotificationsIcon />
          {response?.redIcon ? <Ellipse2 /> : <></>}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "hidden",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            width: "360px",
            "& .MuiList-padding": {
              paddingTop: "0px",
              paddingBottom: "0px",
            },
            "& .MuiAvatar-root": {
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              bgcolor: "secondary.light",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <NotificationHeading />
        {!loading && dataList?.length === 0 ? (
          <NoData />
        ) : (
          <InfiniteScroll
            height={450}
            dataLength={dataList?.length || 0} //This is important field to render the next data
            next={fetchData}
            hasMore={dataList?.length < response?.totalCount}
            loader={
              <>
                {Array.from({ length: 10 }).map((_, index) => (
                  <Box key={index} sx={{ width: 360 }}>
                    <NotificationSkeleton />
                  </Box>
                ))}
              </>
            }
          >
            {dataList?.map((item: any) => (
              <React.Fragment key={item.id}>
                <ListItem
                  onClick={handleNotificationClick.bind(this, item)}
                  style={{
                    padding: "1rem",
                    cursor: "pointer",
                    alignItems: "flex-start",
                  }}
                  sx={{
                    backgroundColor: item.read ? "#ffffff" : "#E4F2FB",
                  }}
                >
                  <Typography variant="subtitle1">
                    {item.infoIcon === "ERROR" && <ErrorSVG style={style} />}
                    {item.infoIcon === "SUCCESS" && (
                      <SuccessSVG style={style} />
                    )}
                    {item.infoIcon === "INFO" && <InfoSVG style={style} />}
                  </Typography>
                  <Typography variant="subtitle1" sx={TypographyStyle}>
                    {item.text}
                  </Typography>
                </ListItem>
                <CustomDivider />
              </React.Fragment>
            ))}
            {loading ? (
              <>
                {Array.from({ length: 10 }).map((_, index) => (
                  <>
                    <ListItem
                      style={{
                        padding: "1rem",
                        cursor: "pointer",
                      }}
                      sx={{
                        backgroundColor: "#ffffff",
                      }}
                    >
                      <NotificationSkeleton />
                    </ListItem>
                    <CustomDivider />
                  </>
                ))}
              </>
            ) : (
              <></>
            )}
          </InfiniteScroll>
        )}
      </Menu>
    </>
  );
};

export default UserNotifications;
