import {
  Grid,
  Typography,
  Button,
  styled,
  CircularProgress,
} from "@mui/material";
import { Fragment } from "react";
import React, { useState, useEffect } from "react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import {
  getInvoiceNumber,
  getGenerateLedgerReport,
  getGenerateLateFeeReport,
  getGenerateReports,
} from "../ReportsApi";
import { KALA_70 } from "../../../styles/colors";
import moment from "moment";
import { GenerateReportState } from "../GenerateReports/generateReportSlice";
import HelpIcon from "@mui/icons-material/Help";
import DateRangeReport from "../DateRangeReport";
import { LightTooltip } from "../ReportsFilter";
import { LedgerReportState } from "../ledgerReportSlice";
import { defaultMessage } from "../../../utils";
import { ConfigState } from "../../../features/maintenance/maintenanceSlice";
import { subMonths, format, endOfMonth, startOfMonth } from "date-fns";
import { analytics, trackAnalytics } from "../../../Analytics";
import { useLocation } from "react-router";

interface Option {
  id: number;
}

function LedgerFormReport() {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const [calenderFilterBy, setCalenderFilterBy] = React.useState("");
  const [openDateRange, setOpenDateRange] = React.useState(false);
  const [applied, setApplied] = React.useState(false);
  const [showToast, setShowToast] = useState(false);
  const [dateSelectionBtn, setDateSelectionBtn] = useState("Last 3 Months");

  useEffect(() => {
    setCalenderFilterBy("");
    setOpenDateRange(false);
    setApplied(false);
    setDateSelectionBtn("Last 3 Months");
  }, [location]);

  const { loading }: LedgerReportState = useSelector(
    (state: RootState) => state.LedgerReport
  );

  const { config }: ConfigState = useSelector(
    (state: RootState) => state.config
  );

  const { mobileNumber, response: mobileRes }: any = useSelector(
    (state: RootState) => state.mobileNum
  );

  const [range, setRange] = React.useState([
    {
      startDate: startOfMonth(subMonths(new Date(), 3)),
      endDate: endOfMonth(subMonths(new Date(), 1)),
      key: "selection",
    },
  ]);

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showToast]);

  useEffect(() => {
    if (showToast) {
      defaultMessage(
        "Your request has been submitted. You will be notified when the report is ready."
      );
    }
  }, [showToast]);

  const handleGenerateReport = async () => {
    //SEGMENT PART
    //when user clicks on the generate report button
    const pageData = {
      source_page: "reports_tab",
      landing_page: "reports_tab",
      event_type: "click",
      tab: "generate",
      report_name: "account_ledger",
      payload: {
        date_range: `${format(range[0].startDate, "dd MMM yyyy")} - ${format(
          range[0].endDate,
          "dd MMM yyyy"
        )}`,
        invoices: [],
        selected_range_btn: dateSelectionBtn,
      },
    };

    trackAnalytics("generate_report_btn", pageData);
    const ledgerResp = dispatch(
      await getGenerateLedgerReport({
        ledgerStartDate: moment(range[0].startDate).format("YYYY-MM-DD"),
        ledgerEndDate: moment(range[0].endDate).format("YYYY-MM-DD"),
      })
    );
    if ((await ledgerResp).payload.response_code == 80) {
      // setApplied(false);
      setShowToast(true);
      // setOpenDateRange(false);
      // setDateSelectionBtn("Last 3 Months");
      // setRange([
      //   {
      //     startDate: new Date(),
      //     endDate: new Date(),
      //     key: "selection",
      //   },
      // ]);
    }
  };

  return (
    <Fragment>
      <Grid item>
        <Grid
          container
          direction="row"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid item xs={9.7}>
            <Grid
              container
              direction="row"
              sx={{ gap: "24px", alignItems: "center" }}
            >
              <Grid item>
                <LightTooltip
                  title={config.Tooltip?.disbursementDateRangeReport}
                  placement="bottom-start"
                  style={{ cursor: "pointer" }}
                >
                  <Typography fontWeight={600} color={KALA_70}>
                    Disbursement Date Range{" "}
                    <sup>
                      <HelpIcon
                        sx={{
                          width: "10px",
                          height: "10px",
                          color: KALA_70,
                        }}
                      />
                    </sup>
                  </Typography>
                </LightTooltip>
              </Grid>
              <DateRangeReport
                applied={applied}
                setApplied={setApplied}
                openDateRange={openDateRange}
                setOpenDateRange={setOpenDateRange}
                dateSelectionBtn={dateSelectionBtn}
                setDateSelectionBtn={setDateSelectionBtn}
                range={range}
                setRange={setRange}
                calenderFilterBy={calenderFilterBy}
                setCalenderFilterBy={setCalenderFilterBy}
                reportFilter={"LedgerReport"}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={2.3}
            justifyContent={"right"}
            sx={{
              display: "flex",
              width: "fit-content",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              type="submit"
              onClick={handleGenerateReport}
              id="generate-form-btn"
              style={{
                borderRadius: "8px",
                padding: "0.75rem 1rem",
                gap: "8px",
              }}
              disabled={loading}
            >
              Generate Report
              {loading ? (
                <CircularProgress
                  color="inherit"
                  style={{ width: "20px", height: "20px" }}
                />
              ) : (
                <ArrowForwardIcon fontSize="small" />
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default LedgerFormReport;
