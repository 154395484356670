interface Props {
  width?: string;
  height?: string;
  style?: React.CSSProperties;
}

const LogoMark = (props: Props) => {
  const { width = 64, height = 64, style = {} } = props;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      style={style}
      width={width}
      height={height}
    >
      <style type="text/css">{`.st0{fill:#198548;}`}</style>
      <g>
        <path
          className="st0"
          d="M15.1,18.3h2.6c0.3,0,0.3,0,0.3-0.3v-0.3c0-0.3,0-0.3,0-0.3s0,0-0.3-0.3c-0.3-0.3-0.3,0-0.3,0h-2.3h-0.3
		c0,0,0,0-0.3,0c0,0.3,0,0.3,0,0.6C14.7,17.8,14.7,17.8,15.1,18.3C15.1,18.1,15.1,18.1,15.1,18.3z"
        />
        <path
          className="st0"
          d="M15.1,24.6h2.6c0.3,0,0.3,0,0.3-0.3V24c0-0.3,0-0.3,0-0.3l-0.3-0.3h-2.6c-0.3,0-0.3,0-0.3,0.3V24
		c0,0.3,0,0.3,0,0.3L15.1,24.6L15.1,24.6z"
        />
        <path
          className="st0"
          d="M15.1,31.2h2.6c0.3,0,0.3,0,0.3-0.3v-0.3c0-0.3,0-0.3,0-0.3L17.7,30h-2.6c-0.3,0-0.3,0-0.3,0.3v0.3
		c0,0.3,0,0.3,0,0.3S15.1,30.9,15.1,31.2L15.1,31.2z"
        />
        <path
          className="st0"
          d="M15.1,37.7h2.6c0.3,0,0.3,0,0.3-0.3v-0.3v-0.3l-0.3-0.3h-2.6h-0.3c0,0,0,0-0.3,0.3c0,0.3,0,0.3,0,0.6
		C14.7,37.1,14.7,37.1,15.1,37.7C15.1,37.4,15.4,37.7,15.1,37.7L15.1,37.7L15.1,37.7z"
        />
        <path
          className="st0"
          d="M18.1,43.1c0-0.3,0-0.3,0-0.3l-0.3-0.3h-2.8c-0.3,0-0.3,0-0.3,0.3s0,0.3,0,0.3c0,0.3,0,0.3,0,0.3l0.3,0.3h2.6
		c0.3,0,0.3-0.3,0.3-0.3S18.1,43.4,18.1,43.1z"
        />
        <path
          className="st0"
          d="M17.1,15.2v0.6h0.3c0.3,0,0.3,0,0.6,0.3c0.3,0,0.3,0.3,0.6,0.3c0.3,0.3,0.3,0.3,0.3,0.6c0,0.3,0,0.3,0,0.6
		c0,0.3,0,0.6,0,0.6c0,0.3-0.3,0.3-0.3,0.6c-0.3,0-0.3,0.3-0.6,0.3c-0.3,0-0.3,0-0.6,0h-0.3v3.1h0.3c0.3,0,0.3,0,0.6,0
		c0.3,0,0.3,0.3,0.6,0.3c0.3,0.3,0.6,0.9,0.6,1.1c0,0.6-0.3,0.9-0.6,1.1c-0.3,0.2-0.6,0.6-1.1,0.6h-0.3v3.1h0.3
		c0.3,0,0.9,0.3,1.1,0.6c0.3,0.3,0.3,0.9,0.3,1.1c0,0.2-0.3,0.9-0.3,1.1c-0.3,0.3-0.6,0.6-1.1,0.6h-0.3v3.1h0.3c0.3,0,0.6,0,0.6,0
		c0.3,0,0.3,0.3,0.6,0.3c0.3,0.3,0.3,0.3,0.3,0.6c0,0.3,0,0.3,0,0.6s0,0.6,0,0.6c0,0.3-0.3,0.3-0.3,0.6l0,0
		c-0.3,0.3-0.6,0.3-1.1,0.6h-0.3v3.1h0.3c0.6,0,0.9,0.3,1.1,0.6c0.3,0.3,0.6,0.9,0.6,1.1c0,0.3,0,0.6,0,0.6c0,0.3-0.3,0.3-0.3,0.6
		C18.7,44.6,18.1,45,18,45h-0.6c0,0.3,0,0.3,0.3,0.3l0.3,0.3h3.1v-31H18h-0.3c0,0,0,0-0.3,0C17.4,14.9,17.4,14.9,17.1,15.2
		L17.1,15.2L17.1,15.2z"
        />
        <path
          className="st0"
          d="M26.8,45.4H22V14.6h17.1h0.3c0,0,0,0,0.3,0.3l0.3,0.3v6.6H28.6c-0.3,0-0.6,0-0.6,0c-0.3,0-0.3,0.3-0.6,0.3
		c-0.3,0.3-0.3,0.3-0.3,0.6c0,0.3,0,0.3,0,0.6L26.8,45.4L26.8,45.4z M25.1,17.5c-0.3,0-0.3,0-0.3,0.3l-0.3,0.3c0,0.3,0,0.3,0.3,0.3
		l0.3,0.3h11.4c0.3,0,0.3,0,0.3-0.3l0.3-0.3v-0.3c0,0,0,0-0.3-0.3h-0.3L25.1,17.5L25.1,17.5z"
        />
        <path className="st0" d="M47.3,34.6h-3.1l1.7,3.4L47.3,34.6z" />
        <path
          className="st0"
          d="M47.6,15.8c0-0.3,0-0.6-0.3-0.9c-0.3-0.3-0.6-0.3-0.9-0.3h-1.7c-0.3,0-0.6,0-0.9,0.3l-0.3,0.3
		c0,0.3,0,0.3,0,0.3v1.4h4L47.6,15.8L47.6,15.8z"
        />
        <path className="st0" d="M47.6,18.3h-4v15.1h4V18.3z" />
        <path className="st0" d="M46.3,48l3.4-3.4h-3.4V48z" />
        <path
          className="st0"
          d="M45.3,48.8H28.5c-0.3,0-0.3,0-0.3-0.3l-0.3-0.3V23.5c0-0.3,0-0.3,0.3-0.3l0.3-0.3h14.2V34v0.3l2.6,5.1
		c0,0,0,0,0,0.3c0,0,0,0,0.3,0c0.3,0,0.3,0,0.3,0h0.3l0.3-0.3l2.6-5.1c0-1.1,0-2,0-3.1c0-1.7,0-4.3,0-8.5h1.1h0.3l0.3,0.3v20.2H46
		c-0.3,0-0.3,0-0.3,0.3l-0.3,0.3L45.3,48.8L45.3,48.8L45.3,48.8z M30.9,43.1L30.9,43.1c-0.3,0-0.3,0-0.3,0s0,0,0,0.3v0.3
		c0,0.3,0,0.3,0.3,0.3c0,0,0,0,0.3,0h0.3h10.8c0.3,0,0.3,0,0.3-0.3s0.3-0.3,0.3-0.3l0,0c0-0.3,0-0.3-0.3-0.3l-0.3-0.3L30.9,43.1
		L30.9,43.1L30.9,43.1z M30.9,39.1c-0.3,0-0.3,0-0.3,0.3c0,0,0,0,0,0.3V40c0,0.3,0,0.3,0.3,0.3c0,0,0,0,0.3,0h0.3h9.1h0.3h0.3
		c0,0,0,0,0.3-0.3c0.3-0.3,0-0.3,0-0.3v-0.3c0,0,0-0.3-0.3-0.3s-0.3,0-0.3,0h-0.3H30.9L30.9,39.1z M30.9,35.1c-0.3,0-0.3,0-0.3,0.3
		l-0.3,0.3l0,0c0,0.3,0,0.3,0.3,0.3l0.3,0.3h8.5c0.3,0,0.3,0,0.3-0.3l0.3-0.3l0,0c0-0.3,0-0.3-0.3-0.3l-0.3-0.3H30.9z M30.9,31.2
		c-0.3,0-0.3,0-0.3,0.3l-0.3,0.3l0,0c0,0.3,0,0.3,0.3,0.3l0.3,0.3h8h0.3c0,0,0,0,0.3,0c0,0,0,0,0-0.3v-0.3l0,0c0-0.3,0-0.3-0.3-0.3
		l-0.3-0.3H30.9L30.9,31.2z M30.9,27.2c-0.3,0-0.3,0-0.3,0.3l-0.3,0.3l0,0c0,0.3,0,0.3,0.3,0.3l0.3,0.3h8h0.3c0,0,0,0,0.3,0
		c0,0,0,0,0-0.3v-0.3l0,0c0-0.3,0-0.3-0.3-0.3l-0.3-0.3H30.9L30.9,27.2z"
        />
        <path
          className="st0"
          d="M40.2,1.2c0.9,0.3,1.7,0.6,2.3,0.9c-3.1-0.9-6.6-1.4-10-1.1c1.7,0,3.4,0.6,4.8,1.1c-4-0.9-8.3-0.6-12.2,0.9
		c7.7-1.4,15.9-0.3,22.5,4c5.7,3.4,9.7,8.8,12,15.1c2,6.3,2,13.1-0.3,19.1c-2.3,6.5-6.8,12-12.8,15.4s-12.8,4.6-19.7,3.4
		c-5.7-1.1-11.1-4-15.1-8c-4-4.3-6.8-9.4-8-15.4c-2-12,1.7-17.9,2.8-19.9c1.1-2,2.3-3.4,2.8-4.6c0.6-0.9,1.1-2,2-2.8
		c0,0.6,0.3,1.1,1.1,1.1c0.6,0,1.4-0.3,2-0.6c-0.3-0.3-0.9-0.9-1.4-0.9c-0.3,0-0.3,0-0.6,0c-0.3,0-0.3,0-0.6,0.3l1.1-1.1
		C13,8.4,13,8.7,13.4,9c0.3-0.3,0.6,0,0.9,0c0.6,0,1.4-0.3,2-0.6c-0.6-0.6-1.1-0.9-1.7-1.1c-0.3,0-0.9,0-1.1,0.3l0,0l1.4-1.1
		c0,0.3,0.3,0.6,0.6,0.9c0.3,0.3,0.6,0.3,0.9,0.3c0.6,0,1.4-0.3,2-0.6c-0.3-0.9-1.1-1.1-1.7-1.4c-0.3,0-0.9,0-1.1,0.3
		c0.6-0.3,0.9-0.6,1.1-0.9c0.6,0,1.1,0,1.7-0.3c0.6-0.6,0.9-1.1,0.9-1.7c-0.9,0-1.4,0-2,0.3c-0.3,0.3-0.3,0.3-0.6,0.6
		c0,0.3-0.3,0.6-0.3,0.9l-0.9,0.6c0.3-0.3,0.3-0.6,0.3-1.1c0-0.6-0.3-1.1-0.9-1.7c0,0.3-0.3,0.6-0.6,0.9c0,0.3,0,0.9-0.3,1.1
		c0,0.3,0,0.6,0,0.9c0,0.3,0.3,0.6,0.6,0.6l-0.9,0.9c0-0.3,0-0.6,0-1.1c0-0.6-0.3-1.1-0.9-1.4c-0.6,0.6-0.9,1.1-0.9,1.7
		c0,0.3,0,0.6,0,0.9c0,0.3,0.3,0.6,0.6,0.6L11.5,9V8.7c0-0.3,0-0.9,0-1.4c-0.3,0-0.3-0.3-0.6-0.9C10.3,6.7,10,7.5,10,8.1
		s0.3,1.4,0.6,2l0,0c-1.1,1.1-2.3,2.6-3.1,3.7c-4,5.7-6.3,12.2-6.3,19.1c0,5.7,2,11.4,5.1,16.2s7.7,8.8,12.8,11.1
		c15.1,7.1,32.5,0.3,40.4-14.5c2-4.3,3.1-8.8,3.4-13.4c0-4.6-0.9-9.1-2.8-13.4c-2-4.3-4.8-8-8.3-10.8C48.6,4.7,44.6,2.4,40.2,1.2
		L40.2,1.2z"
        />
      </g>
    </svg>
  );
};
export default LogoMark;
