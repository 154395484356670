import { createSlice, original } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { errorMessage } from "../../utils";
import queryString from "query-string";
import { getPurchaseOrder } from "./poManagementApi";
import { number } from "yup";
import { addPO } from "../AddPO/poApi";

export interface POStateItem {
  purchaseOrderNumber: number;
  createdDate: string;
  referenceId: number;
  commodityName: string;
  status: string;
  price: string;
  priceUnit: number
}

export interface POState {
  loading: boolean;
  list: POStateItem[];
  error: string | null;
  count: number;
  filters: {
    pageNo: number;
    pageSize: number;
    purchaseOrderNumber?: string;
    columnName: string;
    sortType?: "ASC" | "DESC";
    purchaseStatus?: string;
    startCreateDate?: string | null;
    endCreateDate?: string | null;
    buyerPtrId?: number | null;
  };
}
// const query: any = queryString.parse(location.search);
export const initialState: POState = {
  loading: true,
  error: null,
  count: 0,
  list: [],
  filters: {
    pageNo: 0,
    pageSize: 10,
    purchaseOrderNumber: undefined,
    columnName: "createdDate",
    sortType: "DESC",
    purchaseStatus: undefined,
    startCreateDate: null,
    endCreateDate: null,
    buyerPtrId: null
  },
};

export const poManagementSlice = createSlice({
  name: "poManagement",
  initialState,
  reducers: {
    updateFilters: (state, action: PayloadAction<any>) => {
      if (
        !_.isEqual(original(state.filters), {
          ...state.filters,
          ...action.payload,
        })
      ) {
        state.filters = { ...state.filters, ...action.payload };
      }
    },
  },
  extraReducers: {
    [getPurchaseOrder.pending.type]: (state, action) => {
      state.loading = true;
    },
    [getPurchaseOrder.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.list = action.payload.purchaseOrderResponse || [];
      state.count = action.payload.totalCount || 0;
    },
    [getPurchaseOrder.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = action.payload || "Something went wrong";
      errorMessage(state.error || "");
    },
    [addPO.fulfilled.type]: (state, action) => {
      state.list = [action.payload.purchaseOrderResponseDto, ...state.list];
      state.count = state.count + 1;
    },
  },
});

export const { updateFilters } = poManagementSlice.actions;

export default poManagementSlice.reducer;
