import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import SortRoundedIcon from "@mui/icons-material/SortRounded";
import {
  Button,
  Grid,
  Menu,
  MenuItem,
  MenuProps,
  Radio,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { FinanceState } from "./financeSlice";
import { useLocation } from "react-router-dom";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
  },
}));

interface SortByDropDownProps {
  sortDispatchCall: (sortKey: string | null, sortOrder: string | null) => void;
  filtersCleared: boolean;
  setfiltersCleared: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
}

export default function SortByDropDown(prop: SortByDropDownProps) {
  const { sortDispatchCall, disabled, filtersCleared, setfiltersCleared } =
    prop;
  const [sortKey, setSortKey] = React.useState("dueDate");
  const [sortOrder, setSortOrder] = React.useState("asc");
  const [noFilterSelected, setNoFilterSelected] = React.useState(false);

  const { filters }: FinanceState = useSelector(
    (state: RootState) => state.financeItems
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    sortDispatchCall(sortKey, sortOrder);
  }, [sortKey, sortOrder]);

  React.useEffect(() => {
    if (
      filters.disburseEndDate == null &&
      filters.dueEndDate == null &&
      filters.orderStatus == null
    ) {
      if (!noFilterSelected || filtersCleared) {
        setSortOrder("desc");
        setSortKey("dueDate");
        setNoFilterSelected(true);
        setfiltersCleared(false);
      }
    } else {
      setNoFilterSelected(false);
      setfiltersCleared(false);
    }
  }, [filters]);

  const location = useLocation();

  React.useEffect(() => {
    if (location.state?.reload) {
      setSortOrder("asc");
      setSortKey("dueDate");
    }
  }, [location]);

  return (
    <div>
      <Button
        id="filter-select-dropdown"
        aria-controls={open ? "filter-select-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownRoundedIcon />}
        sx={{
          color: "info.dark",
          minWidth: "180px",
          justifyContent: "space-between",
        }}
        color="info"
        disabled={disabled}
      >
        <Grid container>
          <Grid item paddingRight="6px">
            <SortRoundedIcon />
          </Grid>
          <Grid item>
            {sortKey == ""
              ? "Sort by"
              : `Sort by: ${
                  sortKey == "Disburs" ? "Disbursement Date" : "Due Date"
                }: ${sortOrder == "asc" ? "Earliest..." : "Latest..."}`}
          </Grid>
        </Grid>
      </Button>
      <StyledMenu
        id="filter-select-menu"
        MenuListProps={{
          "aria-labelledby": "filter-select-dropdown",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            setSortKey("dueDate");
            setSortOrder("asc");
          }}
          sx={{ justifyContent: "space-between" }}
        >
          <Grid container alignItems={"center"}>
            <Radio
              checked={sortKey == "dueDate" && sortOrder == "asc"}
              value="c"
              name="radio-buttons"
              inputProps={{ "aria-label": "c" }}
            />
            <Typography>{`Due Date: Earliest to Latest`}</Typography>
          </Grid>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setSortKey("dueDate");
            setSortOrder("desc");
          }}
          sx={{ justifyContent: "space-between" }}
        >
          <Grid container alignItems={"center"}>
            <Radio
              checked={sortKey == "dueDate" && sortOrder == "desc"}
              value="d"
              name="radio-buttons"
              inputProps={{ "aria-label": "d" }}
            />
            <Typography>{`Due Date: Latest to Earliest`}</Typography>
          </Grid>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSortKey("Disburs");
            setSortOrder("asc");
          }}
          sx={{ justifyContent: "space-between" }}
        >
          <Grid container alignItems={"center"}>
            <Radio
              checked={sortKey == "Disburs" && sortOrder == "asc"}
              value="a"
              name="radio-buttons"
              inputProps={{ "aria-label": "a" }}
            />
            <Typography>{`Disbursement Date: Earliest to Latest`}</Typography>
          </Grid>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setSortKey("Disburs");
            setSortOrder("desc");
          }}
          sx={{ justifyContent: "space-between" }}
        >
          <Grid container alignItems={"center"}>
            <Radio
              checked={sortKey == "Disburs" && sortOrder == "desc"}
              value="b"
              name="radio-buttons"
              inputProps={{ "aria-label": "b" }}
            />
            <Typography>{`Disbursement Date: Latest to Earliest`}</Typography>
          </Grid>
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
