import { createSlice } from '@reduxjs/toolkit'
import { getDisbursementDetails } from '../financeApi';
import { errorMessage } from '../../../utils';


export interface disbursementDetailsState {
  loading: boolean,
  error: string | null,
  disbursementDetailsData: any,

}

const initialState: disbursementDetailsState = {
  loading: false,
  error: null,
  disbursementDetailsData: {},

}

export const disbursementDetailsSlice = createSlice({
  name: 'disbursement Details',
  initialState,
  reducers: {

  },
  extraReducers: {
    [getDisbursementDetails.pending.type]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.disbursementDetailsData = {};
    },
    [getDisbursementDetails.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.disbursementDetailsData = action.payload.result
      state.error = "";

    },
    [getDisbursementDetails.rejected.type]: (state, action) => {
      state.loading = false;
      state.disbursementDetailsData = {};
      state.error = action.payload || 'Something went wrong';
    },
  }
})

// Action creators are generated for each case reducer function
// export const { updateFilters } = orderDetailsSlice.actions



export default disbursementDetailsSlice.reducer