import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useFormikContext, Form } from "formik";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Grid, InputAdornment, IconButton, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { MobileNumberVerifyState } from "./LoginModuleSlice";
import { RootState, AppDispatch } from "../../store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
// import { forgotPassword } from "./LoginSlice";
import { postLoginResetPasswordOtp } from "./loginApi";

const LogInForm = () => {
  const { values, errors, touched, handleChange, handleBlur }: any =
    useFormikContext();
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const { response: loginOtpResponse, loading } = useSelector(
    (state: RootState) => state.loginOtpVerify
  );

  const { mobileNumber, response: mobResponse } = useSelector(
    (state: RootState) => state.mobileNum
  );

  const handleForgotPassword = () => {
    dispatch(postLoginResetPasswordOtp(mobileNumber));
  };

  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  return (
    <Form noValidate>
      <Grid container spacing={2} gap={{ xs: 1, md: 0 }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="phoneNumber"
            name="phoneNumber"
            label="Phone Number"
            value={`+91 ${mobileNumber}`}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.phoneNumber && Boolean(errors.phoneNumber)}
            helperText={touched.phoneNumber && errors.phoneNumber}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="password"
            name="password"
            label="Enter Password"
            type={passwordVisibility ? "text" : "password"}
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyPress={handleKeyPress}
            error={touched.password && Boolean(errors.password)}
            helperText={touched.password && errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setPasswordVisibility(!passwordVisibility)}
                    edge="end"
                  >
                    {passwordVisibility ? (
                      <Visibility fontSize="small" />
                    ) : (
                      <VisibilityOff fontSize="small" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} mt={1}>
          <a
            style={{
              color: "green",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={handleForgotPassword}
          >
            Forgot Password?
          </a>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            id="login-form-btn"
            disabled={!values.password || Boolean(errors.password)}
            style={{padding: "12px 0"}}
          >
            Login
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default LogInForm;
