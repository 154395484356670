import { createSlice, original } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { getFinanceList } from './financeApi';
import { errorMessage } from '../../utils';

export interface FinanceStateItem {
  buyerInvoiceNumber: string,
  orderId: string,
  orderAmount: number,
  outAmount: number,
  disburseDate: string,
  dueDate: string,
}

interface FinanceStateItems extends Array<FinanceStateItem> { }

export interface FinanceState {
  loading: boolean,
  list: FinanceStateItems,
  error: string | null,
  count: number,
  filters: {
    disburseStartDate?: string,
    disburseEndDate?: string,
    dueStartDate?: string | null,
    dueEndDate?: string | null,
    outStanding?: true,
    pageNo?: number,
    pageSize?: number,
    orderStatus?: string,
    orderOrInvoiceNumber?: string,
    sortKey?: string,
    sortOrder?: string,
  }
}

export const initialState: FinanceState = {
  loading: true,
  error: null,
  count: 0,
  list: [],
  filters: {
    "pageNo": 0,
    "pageSize": 10,
    "sortKey": "dueDate",
    "sortOrder": "asc",
    orderStatus: "U",
    dueStartDate: null,
    dueEndDate: null,
    orderOrInvoiceNumber: undefined

  }
}

export const financeListSlice = createSlice({
  name: 'financeList',
  initialState,
  reducers: {
    updateFilters: (state, action: PayloadAction<any>) => {
      if (!_.isEqual(original(state.filters), { ...state.filters, ...action.payload })) {
        state.filters = { ...state.filters, ...action.payload }
      }
    },
  },
  extraReducers: {
    [getFinanceList.pending.type]: (state, action) => {
      state.loading = true;
    },
    [getFinanceList.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.list = action.payload.buyerOrderResponse;
      state.count = action.payload.totalCount || action.payload.buyerOrderResponse?.length || 0;
    },
    [getFinanceList.rejected.type]: (state, action) => {
      state.loading = false;
      state.count = 0;
      state.list = [];
      state.error = action.payload || 'Something went wrong';
      errorMessage(action.payload || 'Something went wrong')
    },
  }
})

// Action creators are generated for each case reducer function
export const { updateFilters } = financeListSlice.actions



export default financeListSlice.reducer