import React from "react";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { formatNumber } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { OrderAggregateState } from "./orderAggregateSlice";
import { getOrderAggregates } from "./orderAggregateApi";
import Loader from "../Loader";

import CheerfulReminder from "../Finance/CheerfulReminder";

function OrderAggregates() {
  const dispatch: AppDispatch = useDispatch();

  const { response, loading, error }: OrderAggregateState = useSelector(
    (state: RootState) => state.orderAggregates
  );

  React.useEffect(() => {
    dispatch(getOrderAggregates(""));
  }, [dispatch]);

  const {
    outstandingAmount,
    overdueAmount,
    creditAmount,
    creditAssigned,
    upcomingDueAmount,
    upcomingDueDate,
  } = response;
  return (
    <>
      {(((upcomingDueAmount && upcomingDueDate) || overdueAmount) && (
        <Grid item xs={12} sx={{ marginBottom: "1.25rem" }}>
          <CheerfulReminder
            loading={loading}
            upcomingDueAmount={upcomingDueAmount}
            upcomingDueDate={upcomingDueDate}
            overdueAmount={overdueAmount}
          />
        </Grid>
      )) ||
        ""}

      <Box
        sx={{
          backgroundColor: "primary.contrastText",
          padding: "20px",
          borderRadius: "16px 16px 0px 0px",
          boxShadow: "0 3px 3px rgba(0,0,0,0.2)",
        }}
      >
        <Grid
          container
          spacing={3}
          justifyContent={"center"}
          alignItems={"center"}
          m={0}
          width="100%"
        >
          <Grid
            item
            xs={6}
            lg={overdueAmount ? 3 : 4}
            style={{ textAlign: "center", padding: "12px" }}
          >
            <Typography className="font-bold" color="info.light" mb="4px">
              Total Limit Assigned
            </Typography>
            <Typography
              className="font-bold"
              fontSize="1.5rem"
              color="info.dark"
              sx={{ fontWeight: "bold" }}
            >
              {loading ? (
                <CircularProgress size={25} />
              ) : (
                formatNumber(creditAssigned || 0)
              )}
            </Typography>
          </Grid>
          <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />

          <Grid
            item
            xs={6}
            lg={3}
            style={{ textAlign: "center", padding: "12px" }}
          >
            <Typography className="font-bold" color="info.light" mb="4px">
              Net Amount Outstanding
            </Typography>
            <Typography
              className="font-bold"
              fontSize="1.5rem"
              color="info.dark"
              sx={{ fontWeight: "bold" }}
            >
              {loading ? (
                <CircularProgress size={25} />
              ) : (
                formatNumber(outstandingAmount || 0)
              )}
            </Typography>
          </Grid>
          <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />

          {!!overdueAmount && (
            <>
              {" "}
              <Grid
                item
                xs={6}
                lg={overdueAmount ? 3 : 4}
                style={{ textAlign: "center", padding: "12px" }}
              >
                <Typography className="font-bold" color="info.light" mb="4px">
                  Total Overdue Amount
                </Typography>
                <Typography
                  className="font-bold"
                  color="error.main"
                  fontSize="1.5rem"
                  sx={{ fontWeight: "bold" }}
                >
                  {loading ? (
                    <CircularProgress size={25} />
                  ) : (
                    formatNumber(overdueAmount || 0)
                  )}
                </Typography>
              </Grid>
            </>
          )}
          <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
          <Grid
            item
            xs={6}
            lg={overdueAmount ? 3 : 4}
            style={{ textAlign: "center", padding: "12px" }}
          >
            <Typography className="font-bold" color="info.light" mb="4px">
              Available Credit Limit
            </Typography>
            <Typography
              className="font-bold"
              color={
                creditAmount && creditAmount < 0 ? "#BD252B" : "primary.main"
              }
              fontSize="1.5rem"
              sx={{ fontWeight: "bold" }}
            >
              {loading ? (
                <CircularProgress size={25} />
              ) : (
                formatNumber(creditAmount || 0)
              )}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        sx={{
          boxShadow: "0 3px 3px rgba(0,0,0,0.2)",
          bgcolor: "#247BC7",
          color: "#FFFFFF",
          borderRadius: "0  0 16px 16px",
          alignItems: "center",
          columnGap: "8px",
        }}
        padding="8px 16px"
      >
        <InfoRoundedIcon />
        <Typography fontWeight={500} variant="body2">
          It may take up to 48 hours for payments to be processed and clear
          outstanding balances.
        </Typography>
      </Grid>
    </>
  );
}

export default OrderAggregates;
