import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { getToastNotifications } from "./appAPI";

export interface AppState {
  minimizeSidebar: boolean;
  notificationResponse?: any;
  dataList: any[];
}

export const initialState: AppState = {
  minimizeSidebar: true,
  notificationResponse: {},
  dataList: [],
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    toggleSideBar: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.minimizeSidebar = !state.minimizeSidebar;
    },
  },
  extraReducers: {
    [getToastNotifications.pending.type]: (state, action) => {},
    [getToastNotifications.fulfilled.type]: (state, action) => {
      state.notificationResponse = {
        ...action.payload,
      };
    },
    [getToastNotifications.rejected.type]: (state, action) => {},
  },
});

// Action creators are generated for each case reducer function
export const { toggleSideBar } = appSlice.actions;

export default appSlice.reducer;
