import { Grid } from "@mui/material";
import AppLayout from "../layouts/app";
// import PurchaseOrderM from "../components/POManagement";
import Reports from "../components/reports/Reports";
import { useNavigate, useLocation } from "react-router-dom";
import { analytics } from "../Analytics";
import * as React from "react";

const ReportsPage = () => {
  return (
    <AppLayout
      window={undefined}
      heading="Finance"
    >
      <Grid container spacing={2} mt={0}>
        <Grid item xs={12} style={{ paddingTop: 0 }}>
            <Reports />
        </Grid>
      </Grid>
    </AppLayout>
  );
};

export default ReportsPage;
