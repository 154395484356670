import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Grid } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { KALA_80 } from "./styles/colors";

const formatNumber = (
  number: number,
  precision: number = 1,
  withCurrency: boolean = true
) => {
  const formattedNumber = new Intl.NumberFormat("en-IN", {
    maximumFractionDigits: precision,
    currency: "INR",
  }).format(number);
  if (withCurrency) {
    return `₹${formattedNumber}`;
  }
  return formattedNumber;
};

const formatDateTime = (dateString: string) => {
  return moment(dateString, "YYYY/MM/DD HH:mm:ss")
    .utcOffset(330)
    .format("DD MMM YYYY hh:mm A");
};

const successMessage = (message: string) => {
  toast.clearWaitingQueue();
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    theme: "colored",
  });
};

const defaultMessage = (message: any, bg?: any) => {
  toast.clearWaitingQueue();
  toast.dark(
    ({ closeToast }) => (
      <Grid container direction={"row"} alignItems={"center"} spacing={1}>
        <Grid item xs={10}>
          {message}
        </Grid>
        <Grid item xs={2}>
          <CloseRoundedIcon
            onClick={closeToast}
            sx={{
              color: KALA_80,
              backgroundColor: "#CACCCF",
              borderRadius: "25px",
              width: "36px",
              height: "36px",
            }}
          />
        </Grid>
      </Grid>
    ),
    {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: false,
      style: { backgroundColor: bg, right: "45px", width: "360px" },
      closeButton: false,
    }
  );
};

const errorMessage = (message: string) => {
  toast.clearWaitingQueue();
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    theme: "colored",
  });
};

const formatDate = (date?: string) => {
  if (!date) {
    return "-";
  }
  const formattedDate = moment(date, "DD-MMM-YYYY");
  if (moment(formattedDate).isValid()) {
    return formattedDate.format("DD MMM YYYY");
  }
  return date;
};

const validateFileFormatSize = (file: File, maxFileSize = 5) => {
  const validFileTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "application/pdf",
  ];
  if (file.size > maxFileSize * 1024 * 1024) {
    return `File size should be less than ${maxFileSize}MB`;
  }
  if (!validFileTypes.includes(file.type)) {
    return "File format should be jpeg, jpg, png or pdf";
  }
  return "";
};

const getBucketName = () => {
  return JSON.parse(import.meta.env.VITE_APP_AWS_CONFIG)
    .aws_user_files_s3_bucket;
};
function getImageName(fileName: string) {
  try {
    let fileExt: any = fileName.split(".").pop();
    let filenameWithoutSpace = fileName
      .split(" ")
      .join("")
      .replace(`.${fileExt}`, "");
    let epocTime = new Date().getTime();
    return (
      filenameWithoutSpace.toLowerCase() +
      "_" +
      epocTime +
      "." +
      fileExt.toLowerCase()
    );
  } catch (err) {
    return fileName;
  }
}

function getContentType(fileName: string) {
  try {
    let fileExt: any = fileName.split(".").pop();
    let contentType = "image/jpeg";
    if (["png", "doc", "svg", "jpg", "jpeg"].includes(fileExt)) {
      contentType = "image/png";
    } else if (fileExt === "pdf") {
      contentType = "application/pdf";
    } else {
      contentType = "*";
    }
    return contentType;
  } catch (err) {
    return "image/jpeg";
  }
}

function displayPDForImage(fileUrl: string, style: any = { width: "150" }) {
  try {
    const contentType = getContentType(fileUrl);
    if (contentType === "application/pdf") {
      return (
        <div>
          <object data={fileUrl} style={{ ...style }} />
        </div>
      );
    } else {
      return (
        <img src={fileUrl} style={{ width: "100%", ...style }} alt="pdf" />
      );
    }
  } catch (err) {
    return "image/jpeg";
  }
}

export const reportTypeMapping = (reportType: string, config: any) => {
  return config?.notificationSubTypeMapping?.[reportType] || "";
};
export {
  formatNumber,
  formatDateTime,
  successMessage,
  defaultMessage,
  errorMessage,
  formatDate,
  validateFileFormatSize,
  getBucketName,
  getImageName,
  getContentType,
  displayPDForImage,
};
