import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../api";

export const getPaymentHistory = createAsyncThunk("user/payments", async (payload: any, { rejectWithValue }) => {
  try {
    const response = await API.post("/payments/all", { ...payload });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data.error);
  }
});
