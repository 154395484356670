import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import {
  Button,
  Divider,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  Radio,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { updateFilters } from "./poManagementSlice";

const POStateMapping: any = {
  Rejected: "REJECTED",
  "Upload GRN": "GRN_UPLOAD",
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
  },
}));

export default function POStateMenu(props: any) {
  const { filterBy, setFilterBy } = props;
  const dispatch: AppDispatch = useDispatch();
  const dropDownNames = ["Rejected", "Upload GRN"];

  const [anchorElOrder, setAnchorElOrder] = React.useState<null | HTMLElement>(
    null
  );
  const openPOState = Boolean(anchorElOrder);
  const openPoStateMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElOrder(event.currentTarget);
  };
  const closePOStateMenu = () => {
    setAnchorElOrder(null);
  };

  const checkboxSelected = (value: string) => {
    filterBy == value ? setFilterBy("") : setFilterBy(value);
    setAnchorElOrder(null);
  };

  React.useEffect(() => {
    dispatch(
      updateFilters({
        purchaseStatus: POStateMapping[filterBy],
        pageNo: 0,
      })
    );
  }, [filterBy]);

  const closeButtonclicked = (event: any) => {
    event.stopPropagation();
    setFilterBy("");
    setAnchorElOrder(null);
  };

  return (
    <div>
      <Button
        id="po-state-dropdown"
        aria-controls={openPOState ? "po-state-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openPOState ? "true" : undefined}
        variant="outlined"
        disableElevation
        onClick={openPoStateMenu}
        endIcon={
          filterBy == "" ? (
            <KeyboardArrowDownRoundedIcon />
          ) : (
            <CloseRoundedIcon
              onClick={closeButtonclicked}
              sx={{
                color: "primary.main",
                backgroundColor: "white",
                borderRadius: "25px",
              }}
            />
          )
        }
        sx={{
          color: filterBy == "" ? "info.dark" : "white",
          justifyContent: "space-between",
          borderRadius: "25px",
          backgroundColor: filterBy == "" ? "" : "primary.main",
          "&.MuiButtonBase-root:hover": {
            bgcolor: filterBy == "" ? "" : "primary.main",
          },
        }}
        color="info"
      >
        {filterBy == "" ? "PO State" : `PO State: ${filterBy}`}
      </Button>
      <StyledMenu
        id="order-status-menu"
        MenuListProps={{
          "aria-labelledby": "order-status-dropdown",
        }}
        anchorEl={anchorElOrder}
        open={openPOState}
        onClose={closePOStateMenu}
      >
        {dropDownNames.map((item: string) => (
          <MenuItem onClick={(e) => checkboxSelected(item)}>
            <Radio
              checked={filterBy == item}
              value="d"
              name="radio-buttons"
              inputProps={{ "aria-label": "d" }}
            />
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}
