import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Error from "../../SVG/ErrorTechnical";

const sharedTextStyle = {
  color: "var(--Kala-70, #52575C)",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Noto Sans",
  linHeight: "20px",
};

const ErrorComponent = () => {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "160px",
          padding: "20px 0",
          margin: "16px",
          gap: "16px",
          alignSelf: "stretch",
        }}
      >
        <Error />
        <Grid>
          <Typography
            sx={{
              ...sharedTextStyle,
              fontSize: "16px",
              fontWeight: "700",
              marginBottom: "12px",
            }}
          >
            There were some technical difficulties
          </Typography>
          <Typography
            sx={{
              ...sharedTextStyle,
              fontSize: "14px",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            Please refresh your screen to try again
          </Typography>
        </Grid>
      </Stack>
    </>
  );
};

export default ErrorComponent