interface Props {
  style?: any;
}
const BijakLogo = (props: Props) => {
  const { style = { margin: "20px" } } = props;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="128"
      height="50"
      style={style}
      viewBox="0 0 64 27"
    >
      <style type="text/css">
        {`.st0{fill:#198548;}
	.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#198548;}`}
      </style>
      <g>
        <path
          className="st0"
          d="M5,10.4H3.1V8.6h1.7c0.2,0,0.3,0,0.5-0.2s0.2-0.2,0.3-0.3c0-0.2,0-0.3,0-0.5S5.4,7.3,5.4,7.3
		C5.2,7.1,5.1,7.1,4.9,7.1H3.2V5.6h1.4c0.2,0,0.2,0,0.3,0s0.2-0.2,0.2-0.2c0.2-0.2,0.2-0.2,0.2-0.3s0-0.3,0-0.5S5.1,4.4,5,4.3
		C4.8,4.1,4.7,4.1,4.5,4.1H3.1V2.4h1.4c0.3,0,0.6,0,0.9,0.2s0.5,0.3,0.8,0.5C6.5,3.4,6.8,4,6.8,4.5S6.7,5.6,6.6,6.1l0,0
		c0.3,0.3,0.6,0.8,0.8,1.1c0.2,0.5,0.2,0.9,0,1.4S6.9,9.4,6.6,9.7C5.9,10.2,5.5,10.2,5,10.4L5,10.4z"
        />
        <path
          className="st0"
          d="M19,10.4h-1.7l2.8-7.7h1.3l2.7,7.7h-1.7l-0.4-1h-2.4l0.6-1.7h1.1l-0.5-2.1L19,10.4L19,10.4z"
        />
        <path className="st0" d="M10,10.4H8.3V2.5H10V10.4L10,10.4z" />
        <path className="st0" d="M2.9,10.4H1.2V2.5h1.7V10.4L2.9,10.4z" />
        <path className="st0" d="M26.8,10.2H25V2.5h1.7L26.8,10.2L26.8,10.2z" />
        <path
          className="st0"
          d="M27.9,6.6l-0.8,0.8v0.8l2.1,2.1h2.2l0,0L27.9,6.6z"
        />
        <path
          className="st0"
          d="M29,2.5l-1.9,2.1V7l0.8-0.8l3.5-3.8L29,2.5L29,2.5z"
        />
        <path
          className="st0"
          d="M13.5,8.6c-0.3,0-0.5-0.2-0.6-0.3c-0.2-0.2-0.3-0.5-0.3-0.6H11c0,0.6,0.3,1.4,0.8,1.9s1.1,0.8,1.7,0.8V8.6z"
        />
        <path
          className="st0"
          d="M14.8,2.5v5.1c0,0.2,0,0.3,0,0.3c0,0.2-0.2,0.2-0.2,0.3c-0.2,0.2-0.5,0.3-0.6,0.3v1.6c0.6,0,1.3-0.3,1.7-0.8
		c0.5-0.5,0.8-1.1,0.8-1.9V2.5H14.8L14.8,2.5z"
        />
        <path
          className="st0"
          d="M1.2,25.5v-12c0-0.6,0.5-1.1,1.1-1.1l0,0l0,0h2.8c2.2,0,3.9,1.7,3.9,3.9c0,0.9-0.3,1.7-0.8,2.4
		c1.3,0.8,2.1,2.2,2.1,3.6c0,2.4-1.9,4.4-4.4,4.4H2.3l0,0l0,0C1.7,26.6,1.2,26.2,1.2,25.5z M3.6,17.9h1.7c0.9,0,1.6-0.8,1.6-1.7
		c0-0.9-0.8-1.6-1.6-1.6H3.6V17.9z M3.6,24.4h2.5c1.1,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1H3.6V24.4z"
        />
        <path
          className="st0"
          d="M21.9,21.6v3.9c0,0.6-0.5,1.1-1.1,1.1c-0.5,0-0.9-0.3-1.1-0.9c-0.8,0.6-1.8,0.9-2.7,0.9
		c-1.4,0-2.5-0.6-3.5-1.6c-0.9-0.9-1.4-2.2-1.4-3.6c0-1.4,0.5-2.7,1.4-3.6c0.9-0.9,2.1-1.6,3.5-1.6c1.1,0,1.9,0.3,2.7,0.9
		c0.2-0.5,0.5-0.9,1.1-0.9s1.1,0.5,1.1,1.1C21.9,17.3,21.9,21.6,21.9,21.6z M19.7,21.6c0-0.8-0.3-1.6-0.8-2.1
		c-0.5-0.6-1.3-0.8-1.9-0.8c-0.8,0-1.4,0.3-1.9,0.8s-0.8,1.3-0.8,2.1s0.3,1.6,0.8,2.1s1.1,0.8,1.9,0.8s1.4-0.3,1.9-0.8
		S19.7,22.4,19.7,21.6z"
        />
        <path
          className="st0"
          d="M31.5,18.1l-4.6,6.3h3.8c0.6,0,1.1,0.5,1.1,1.1s-0.4,1.1-1.1,1.1h-5.8h-0.2l0,0h-0.2c0,0,0,0-0.2,0
		c0,0,0,0-0.2,0l0,0l0,0l0,0h-0.2c0,0,0-0.2-0.2-0.2c0,0,0,0,0-0.2s0,0,0-0.2c0,0,0,0,0-0.2c0,0,0,0,0-0.2c0,0,0,0,0-0.2v-0.2l0,0
		l0,0V25c0,0,0,0,0-0.2c0,0,0,0,0-0.2v-0.2l0,0v-0.2l0,0l4.6-6.3h-3.8c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1h5.8h0.2
		c0,0,0,0,0.2,0h0.2h0.2l0,0h0.2l0,0l0,0c0,0,0,0,0.2,0.2l0,0c0,0,0,0,0,0.2l0,0v0.2c0,0,0,0,0,0.2v0.2v0.2c0,0,0,0,0,0.2l0,0v0.2
		v0.3c0,0,0,0,0,0.2L31.5,18.1C31.5,17.9,31.5,17.9,31.5,18.1L31.5,18.1L31.5,18.1z"
        />
        <path
          className="st0"
          d="M43.1,21.6v3.9c0,0.6-0.5,1.1-1.1,1.1c-0.5,0-0.9-0.3-1.1-0.9c-0.8,0.6-1.7,0.9-2.7,0.9
		c-1.4,0-2.5-0.6-3.5-1.6c-0.9-0.9-1.4-2.2-1.4-3.6c0-1.4,0.5-2.7,1.4-3.6c0.9-0.9,2.1-1.6,3.5-1.6c1.1,0,1.9,0.3,2.7,0.9
		c0.2-0.5,0.5-0.9,1.1-0.9s1.1,0.5,1.1,1.1V21.6z M40.8,21.6c0-0.8-0.3-1.6-0.8-2.1c-0.5-0.6-1.3-0.8-1.9-0.8
		c-0.8,0-1.4,0.3-1.9,0.8s-0.8,1.3-0.8,2.1s0.3,1.6,0.8,2.1s1.1,0.8,1.9,0.8c0.8,0,1.4-0.3,1.9-0.8S40.8,22.4,40.8,21.6z"
        />
        <path
          className="st0"
          d="M54.6,21.6v3.9c0,0.6-0.5,1.1-1.1,1.1c-0.5,0-0.9-0.3-1.1-0.9c-0.8,0.6-1.7,0.9-2.7,0.9
		c-1.4,0-2.5-0.6-3.5-1.6c-0.9-0.9-1.4-2.2-1.4-3.6c0-1.4,0.5-2.7,1.4-3.6c0.9-0.9,2.1-1.6,3.5-1.6c1.1,0,1.9,0.3,2.7,0.9
		c0.2-0.5,0.5-0.9,1.1-0.9s1.1,0.5,1.1,1.1V21.6z M52.4,21.6c0-0.8-0.3-1.6-0.8-2.1c-0.5-0.6-1.3-0.8-1.9-0.8
		c-0.8,0-1.4,0.3-1.9,0.8S47,20.8,47,21.6s0.3,1.6,0.8,2.1s1.1,0.8,1.9,0.8c0.8,0,1.4-0.3,1.9-0.8C52.1,23.2,52.4,22.4,52.4,21.6z"
        />
        <path
          className="st0"
          d="M62.8,17.5c0,0.6-0.5,1.1-1.1,1.1c-0.5,0-0.9,0.2-1.3,0.3c-0.3,0.3-0.6,0.6-0.9,0.9c-0.5,0.6-0.8,1.4-0.8,1.7
		v3.9c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1v-8.1c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1v0.3c0.2-0.2,0.3-0.3,0.5-0.5
		c0.6-0.5,1.6-0.8,2.5-0.8C62.3,16.5,62.8,17,62.8,17.5z"
        />
        <path className="st0" d="M36.3,6.3" />
        <path
          className="st1"
          d="M45.6,7.5c-0.3,0-0.6-0.2-0.8-0.2v2.1c0,0.3-0.3,0.6-0.6,0.6h-0.3h-6.2h-0.3c-0.3,0-0.6-0.3-0.6-0.6V7.3
		c-0.3,0.2-0.5,0.2-0.8,0.2c0,0,0,0-0.2,0v4.1c0,0.5,0.3,0.9,0.9,0.9h0.2h7.7h0.2c0.5,0,0.9-0.5,0.9-0.9L45.6,7.5L45.6,7.5z"
        />
        <path
          className="st1"
          d="M40.1,5.8c-0.2,0.6-0.6,1.1-1.3,1.1c-0.6,0-1.3-0.5-1.3-1.1H40.1z M37.8,0.4H40v4.9h-2.7L37.8,0.4z"
        />
        <path
          className="st1"
          d="M36.9,5.8L36.9,5.8c-0.2,0.6-0.6,1.1-1.3,1.1c-0.6,0-1.3-0.5-1.3-1.1H36.9L36.9,5.8z M35.8,0.4h1.6l-0.5,4.9
		h-2.7l0.5-3.9C34.7,0.7,35.2,0.4,35.8,0.4z"
        />
        <path
          className="st1"
          d="M43.4,5.8c-0.2,0.6-0.6,1.1-1.3,1.1s-1.3-0.5-1.3-1.1H43.4z M43.4,5.3h-2.7V0.4h2.2L43.4,5.3z"
        />
        <path
          className="st1"
          d="M46.7,5.8c-0.2,0.6-0.6,1.1-1.3,1.1c-0.6,0-1.3-0.5-1.3-1.1l0,0H46.7L46.7,5.8z M46.7,5.3H44l-0.5-4.9h1.6
		c0.5,0,0.9,0.5,1.1,0.9L46.7,5.3z"
        />
      </g>
    </svg>
  );
};
export default BijakLogo;
