import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit';
import { postLoginMobileNumber, postLoginResetPassword } from './loginApi';
import { errorMessage } from '../../utils';
import { getUserDetails } from '../UserProfile/userApi';


export enum AuthState {
  Mobile = "Mobile",
  VerifyOtp = "VerifyOtp",
  SetPassword = "SetPassword",
  PasswordLogin = "PasswordLogin",
}
export interface MobileNumberVerifyState {
  state: AuthState,
  loading: boolean,
  error: string,
  mobileNumber?: string,
  response?: any
}

const initialState: MobileNumberVerifyState = {
  state: AuthState.Mobile,
  loading: false,
  error: '',
  mobileNumber: '',
  response: ''
}

export const mobileListSlice = createSlice({
  name: 'mobileNumberVerify',
  initialState,
  reducers: {
    setMobileNumber: (state, action) => {
      state.mobileNumber = action.payload;
    },
    redirectToLogin: (state) => {
      state.state = AuthState.Mobile;
    },
  },
  extraReducers: {
    [postLoginMobileNumber.pending.type]: (state, action) => {
      state.error = '';
      state.mobileNumber = action.meta.arg;
      state.loading = true;
    },
    [postLoginMobileNumber.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.error = '';
      if (action.payload.response_code === 90) {
        state.state = AuthState.VerifyOtp;
      } else {
        state.state = AuthState.PasswordLogin;
      }
      state.response = action.payload;
    },
    [postLoginMobileNumber.rejected.type]: (state, action) => {
      state.loading = false;
      state.response = '';
      state.error = action.payload || 'Something went wrong';
      state.state = AuthState.Mobile;
      errorMessage(action.payload || 'Something went wrong');
    },
    [postLoginResetPassword.fulfilled.type]: (state, action) => {
      state.state = AuthState.PasswordLogin;
    },
    [getUserDetails.fulfilled.type]: (state, action) => {
      state.state = AuthState.PasswordLogin;
      state.mobileNumber = action.payload.mobileNumber;
      state.response = { buyerId: action.payload.userId };
    }
  }
})

export const { setMobileNumber, redirectToLogin } = mobileListSlice.actions
export default mobileListSlice.reducer