import { Grid, Typography, Button, styled } from "@mui/material";
import { Fragment } from "react";
import React, { useState, useEffect } from "react";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { KALA_70 } from "../../styles/colors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LedgerFormReport from "./LedgerReport/LedgerFormReport";
import LateFeeFormReport from "./LateFeeBreakdownReport/LateFeeFormReport";
import PaymentHistoryFormReport from "./PaymentHistoryReport/PaymentHistoryFormReport";
import OrdersFormReport from "./OrdersReport/OrdersFormReport";
import { useLocation } from "react-router";
import { ConfigState } from "../../features/maintenance/maintenanceSlice";
interface Option {
  id: number;
}

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px",
    marginTop: "8px !important",
    display: "flex",
    textAlign: "center",
    maxWidth: "none",
  },
}));

function ReportsFilter() {
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const [reportFilter, setReportFilter] = useState("Account Ledger");
  const [reportType, setReportType] = useState([
    "Account Ledger",
    "Late Fees Details",
  ]);

  const handleReportBtn = (value: any) => {
    setReportFilter(value);
  };

  const { config }: ConfigState = useSelector(
    (state: RootState) => state.config
  );

  useEffect(() => {
    if (config.ordersAndPaymentHistoryReportFlag) {
      setReportType((prevReportType) => [
        ...prevReportType,
        "Orders",
        "Payment History",
      ]);
    }
  }, [config.ordersAndPaymentHistoryReportFlag]);

  useEffect(() => {
    setReportFilter("Account Ledger");
  }, [location]);

  return (
    <Fragment>
      <Grid container gap={3} direction="column">
        <Grid item>
          <Grid
            container
            direction="row"
            sx={{ gap: "12px", alignItems: "center" }}
          >
            <Grid item>
              <Typography fontWeight={600} color={KALA_70}>
                Generate
              </Typography>
            </Grid>
            {reportType.map((type, index) => {
              return (
                <Grid item key={index}>
                  <Button
                    color="primary"
                    variant={
                      reportFilter == type 
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => handleReportBtn(type)}
                    startIcon={
                      reportFilter == type && <CheckCircleIcon />
                    }
                    sx={{
                      color: reportFilter == type
                          ? "white"
                          : KALA_70,
                      // minWidth: "400px",
                      fontWeight: 500,
                      borderColor: "#CACCCF",
                      "&:hover": {
                        borderColor: "#CACCCF",
                      },
                      height: "36px",
                      padding: "8px 12px",
                      borderRadius: "8px",
                    }}
                  >
                    {type}
                  </Button>
                </Grid>
              );
            })}
            {/* <Grid item>
              <Button
                color="primary"
                variant={reportFilter == "Ledger" ? "contained" : "outlined"}
                onClick={() => handleReportBtn("Ledger")}
                startIcon={reportFilter == "Ledger" && <CheckCircleIcon />}
                sx={{
                  color: reportFilter == "Ledger" ? "white" : KALA_70,
                  // minWidth: "400px",
                  fontWeight: 500,
                  borderColor: "#CACCCF",
                  "&:hover": {
                    borderColor: "#CACCCF",
                  },
                  height: "36px",
                  padding: "8px 12px",
                  borderRadius: "8px",
                }}
              >
                Account Ledger
              </Button>
            </Grid> */}
            {/* <Grid item>
              <Button
                color="primary"
                variant={
                  reportFilter == "Late Fee Breakdown"
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleReportBtn("Late Fee Breakdown")}
                startIcon={
                  reportFilter == "Late Fee Breakdown" && <CheckCircleIcon />
                }
                sx={{
                  color:
                    reportFilter == "Late Fee Breakdown" ? "white" : KALA_70,
                  // minWidth: "400px",
                  fontWeight: 500,
                  borderColor: "#CACCCF",
                  "&:hover": {
                    borderColor: "#CACCCF",
                  },
                  height: "36px",
                  padding: "8px 12px",
                  borderRadius: "8px",
                }}
              >
                Late Fees Details
              </Button>
            </Grid> */}
            {/* {config.ordersAndPaymentHistoryReportFlag && 
            <Grid item>
              <Button
                color="primary"
                variant={reportFilter == "Orders" ? "contained" : "outlined"}
                onClick={() => handleReportBtn("Orders")}
                startIcon={reportFilter == "Orders" && <CheckCircleIcon />}
                sx={{
                  color: reportFilter == "Orders" ? "white" : KALA_70,
                  // minWidth: "400px",
                  fontWeight: 500,
                  borderColor: "#CACCCF",
                  "&:hover": {
                    borderColor: "#CACCCF",
                  },
                  height: "36px",
                  padding: "8px 12px",
                  borderRadius: "8px",
                }}
              >
                Orders
              </Button>
            </Grid>} */}
            {/* {config.ordersAndPaymentHistoryReportFlag && 
            <Grid item>
              <Button
                color="primary"
                variant={
                  reportFilter == "Payment History" ? "contained" : "outlined"
                }
                onClick={() => handleReportBtn("Payment History")}
                startIcon={
                  reportFilter == "Payment History" && <CheckCircleIcon />
                }
                sx={{
                  color: reportFilter == "Payment History" ? "white" : KALA_70,
                  // minWidth: "400px",
                  fontWeight: 500,
                  borderColor: "#CACCCF",
                  "&:hover": {
                    borderColor: "#CACCCF",
                  },
                  height: "36px",
                  padding: "8px 12px",
                  borderRadius: "8px",
                }}
              >
                Payment History
              </Button>
            </Grid>} */}
          </Grid>
        </Grid>
        {(reportFilter == "Account Ledger" && <LedgerFormReport />) ||
          (reportFilter == "Late Fees Details" && <LateFeeFormReport />) ||
          (reportFilter == "Orders" && <OrdersFormReport />) ||
          (reportFilter == "Payment History" && <PaymentHistoryFormReport />)}
      </Grid>
    </Fragment>
  );
}
export default ReportsFilter;
